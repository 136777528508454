import React, { useState } from "react";
import "../Modal/Modal.css";
import { useEffect } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Registration_Successfull_URL } from "../../Api/Api";
const RegistrationSuccess = () => {
  console.log("RegistrationSuccess");
  const { activeToken } = useParams();
  console.log(activeToken, "activeToken");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (activeToken) {
      axios
        .post(Registration_Successfull_URL, {
          activation_token: activeToken,
        })
        .then((res) => {
          swal({
            title: "Registration Successfull!",
            buttons: {
              confirm: "Proceed",
              // cancel: 'NO'
            },
            type: "success",
          }).then((okay) => {
            if (okay) {
              navigate("/login");
            } else {
              navigate("/");
            }
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err, "999999999");
          swal("Oops", `${err.response.data.message}`, "error");
          setError(true);
        });
    }
  }, []);
  console.log("here");
  useEffect(() => {}, []);
  return (
    <div className="mainsection bnk_sctn">
      <div className="modal">
        {error ? (
          <>
            <p>Your token has been expired</p>{" "}
            <button
              className="notification_btn "
              onClick={() => {
                navigate("/");
              }}
              color="primary"
            >
              Go to Sign Up Page
            </button>
          </>
        ) : (
          <>
            <p>Your account has been created successfully</p>{" "}
            <button
              className="notification_btn "
              onClick={() => {
                navigate("/login");
              }}
              color="primary"
            >
              Proceed
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default RegistrationSuccess;
