import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { ThreeDots } from 'react-loader-spinner'
import { BsEyeFill } from 'react-icons/bs'
import {MdAccountCircle} from "react-icons/md"
import { fetchOperators } from '../../Store/OperatorSlice'
import { fetchRandom } from '../../Store/BankSlice'

const OperatorListing = () => {
    const dispatch = useDispatch()
    const [isLoading,setIsLoading]=useState(false)
   useEffect(()=>{
      dispatch(fetchOperators())
      dispatch(fetchRandom())
   },[])
   const Operators = useSelector(state=>state?.Operator?.data?.data)
   const RandomData = useSelector(state=>state)

   console.log(Operators,'Operators')
   console.log(RandomData,'RandomData')
  return (
    <div className='mainsection bnk_sctn'>
      <div className="bank_content">
    <div className="cmn_hdr">
      
      <h2 >Operators List</h2>
      <br/>
     
        <br/>
     
     
      
    </div>
    <br/><br/>
    { !isLoading ? 
    <div className='outr_table'><table>
    <tbody>
      <tr>
        {/* <th>Applicants</th> */}
        {/* <th>Operator ID</th> */}
        <th>Name</th>
       <th>E-mail 
         
          </th>
        <th>Contact Number</th>
        
        <th>Created At</th>
        {/* <th>Decline</th> */}
        <th>Total No Of Applications</th>
        <th>NO. of Proposals Received</th>
        <th>No. of Proposal Accepted</th>
        <th>Log In</th>
      </tr>
      {
       Operators &&  Operators.map((operator,index)=>{
            return (<tr>
              
              {/* <td>
                <Link
              state={{category:'accepted applications'}}
              
              >{operator._id}</Link></td> */}
              <td>{operator.fullName}</td>
               <td>{operator.email}</td>
               <td>{operator.phoneNumber }</td>
               <td>{operator.createdAt}</td>
               <td>{operator.applicationCount}</td>
               <td>{operator.numProposalReceived}</td>
               <td>{operator.numProposalAccepted}</td>
               <td><div style={{marginLeft:'2rem',fontSize:'2rem',cursor:'pointer'}}>
                 <Link 
                 style={{color:'black'}}
                 state={{category:'accepted applications'}}
                 ><MdAccountCircle /></Link></div></td>
               {/* <td>{String(applicant.accountOpened)}</td> */}
              
                {/* <td>
                  { applicant.accountOpened !== 'Account Declined' ?
                    <button 
                    disabled={!applicant.proposalAccepted} onClick={()=>{DeclineAccount(applicant.application_Id)}}
                    style={{color: "red",border:'none',background:'none',marginLeft:'1.2rem',cursor:'pointer'}}><IconContext.Provider value={{ color: "", size:'25px'}}><MdCancel/></IconContext.Provider></button>
                    : <>Declined❌</>
                  }
                </td> */}
              
            </tr>)
          })  
        }
      
    </tbody>
  </table></div> : <div style={{marginLeft:'50rem'}}><ThreeDots
height="60" 
width="60" 
radius="9"
color="#b07f35" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClassName=""
visible={true}
/></div>}
  </div></div>
  )
}

export default OperatorListing