import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  userStatus: false,
  role: "",
  access: String(localStorage.getItem("access")),
  userdata: {
    bankAddress: "",
    bankCity: "",
    bankContactNumber: "",
    bankLogo: "",
    bankName: "",
    bankState: "",
    bankType: "",
    email: "",
  },
  operatorDetailsFormOne: {
    servicesWanted: [],
    accountPurpose: [],
    accountPurposeInput: "",
    acc_need_time: {
      value: "",
      daysormonths: "Days",
    },
    risk_classification: "option1",
    name: "",
    dba: "",
    physical_Address: "",
    mailing_Address: "",
    samevalue: "no",
    federal_EIN: "",
    cannabis_License_Number: "",
    licensing_Type: [],
    LicenseNumber: {},
    other: "",
    num_employees: "",
    accepted_states: [],
    foreign_operations: "no",
    primary_contact_name: localStorage.getItem("name"),
    dob: "",
    primary_address: "",
    mail: localStorage.getItem("email"),
    primary_Phone: "",
    ownership: "no",
    ownership_Percentage: "",
    authority_sign: "no",
    us_Citizenship: "yes",
  },
  operatorDetailsFormTwo: {
    products_purchased_providers: "no",
    licensed_provider: "no",
    acc_need: "",
    num_locs: "",
    transactions_Per_month: "",
    // managed_square_feet: "",
    purchase_amount_per_sale: "",
    num_plants: "",
    average_quantity_per_sale: "",
    unit: "Gram",
    facility_owned: "no",
    facility_leased: "no",
    facility: "Owned",
    lease_term: "",
    egmi: "",
    types_customers: [],
    principal_business: "no",
    beneficial_owners: "no",
    number_beneficial_owner: "",

    additional_locs: [
      {
        loc_name: "",
        license: "",
        deposit_acc_need: "no",
        loc_address: "",
      },
    ],
    amount_Initial_Deposit: "",
    source_Initial_Deposit: "",
    estimated_total_amount_monthly_deposit: "",
    estimated_total_num_monthly_deposit: "",
    anticipate_cash_deposits: "no",
    amount_cash_deposits: "",
    frequency_cash_deposits: "Daily",
    estimated_spend: "",
    anticipate_cash_withdrawals: "no",
    amount_cash_withdrawals: "",
    frequency_cash_withdrawals: "Daily",
    monthly_payroll: "",
    cash_pick_ups: "no",
    frequency_cash_pick_ups: "Daily",
    estimated_cash_pick_ups: "",
  },
  operatorDetailsFormThree: [],
  mainCheckBox: "no",
  operatorDetailsFormFour: {
    legacy_cash: "no",
    documents_available: [],
    business_acc: "no",
    bank_name: "",

    thisvalue: "",
    daysormonths: "Days",

    reason_to_close: "",
    // payroll_service: "no",
    // paid_cash: "no",
    // payroll_accepted: "no",
    // taxes_cash: "no",
    penalty_cash: "no",
    tax_payment: "no",
    number_vendors: "",
    vendor_payment_methods: [],
    international_vendor: "no",
    electronic_payment: "no",
    current_cash_managment: "",
    financial_institution: "",
    existing_bank_contact: "",
    existing_bank_name: "",
    original_deposite: "",
    prev_bank_verified: "no",
    reason_closure: "",
    prev_bank_aware: "no",
  },
  operatorDetailsFormFive: {
    settlement_num_loc: "",
    msb: "no",
    money_transmitted_license: "no",
    types_payments: [],
    mobile_app: "no",
    pos: "",
    credit_card: "no",
    bank_aware: "no",
    merchant_processor: "",
    terminal: "no",
    atm_own: "no",
    atm_loc: "",
    atm_multiple: "no",
    atm_fill: "no",
    atm_third_party: "no",
    atm_fill_company: "",
    atm_currency: "",
    atm_crypto: "no",
    atm_deposite: "no",
    atm_receipt: "no",
    accounting_system: "",
    accountant: "Internal",
    firm_name: "",

    non_compliance: "no",
    compliance_desc: "",
    compliance_officer: "no",
    compliance_partner: "no",
    compliance_partner_name: "",
    compliance_group: "no",
    onsite_inspection: "no",
    compliance_date: "",
    frequency_compliance: "Quaterly",
    compliance_support: "no",
    compliance_trainning: "no",
    operating_procedures: "no",
    follwing_services: [],
  },
  BankDetailsFormOne: {
    servicesOffered: [],
    servicesPrices: {},
    acc_creation_time: {
      value: "",
      daysormonths: "Days",
    },
    risk_classification: [],
    licensing_Type: [],
    other: "",
    num_employees_max: "",
    num_employees_min: "",
    accepted_states: [],
    foreign_operations: "no",
    ownership_Percentage_min: "",
    // ownership_Percentage_max: '',
    us_Citizenship: "no",
  },
  BankDetailsFormTwo: {
    products_purchased_providers: "no",
    licensed_provider: "no",
    accept_CRB_max: "",
    accept_CRB_min: "",
    num_locs_min: "",
    num_locs_max: "",
    transactions_Per_month_min: "",
    transactions_Per_month_max: "",
    // managed_square_feet_min: "",
    // managed_square_feet_max: "",
    // facility_owned: 'no',
    // facility_leased: 'no',
    facility: "Owned",
    types_customers: [],

    principal_business: "no",
    beneficial_owners: "no",
    number_beneficial_owner_min: "",
    number_beneficial_owner_max: "",
    amount_Initial_Deposit_min: "",
    amount_Initial_Deposit_max: "",
    estimated_total_amount_monthly_deposit_min: "",
    estimated_total_amount_monthly_deposit_max: "",
    estimated_total_num_monthly_deposit_min: "",
    estimated_total_num_monthly_deposit_max: "",
    // anticipate_cash_deposits_min: '',
    // anticipate_cash_deposits_max: '',
    anticipate_cash_deposits: "no",
    amount_cash_deposits_min: "",
    amount_cash_deposits_max: "",
    // frequency_cash_deposits_min: '',
    // frequency_cash_deposits_max: '',
    frequency_cash_deposits: [],
    estimated_spend_min: "",
    estimated_spend_max: "",
    anticipate_cash_withdrawals: "no",
    amount_cash_withdrawals_max: "",
    amount_cash_withdrawals_min: "",
    frequency_cash_withdrawals: [],
    // frequency_cash_withdrawals_max:'',
    monthly_payroll_min: "",
    monthly_payroll_max: "",
    cash_pick_ups: "no",
    frequency_cash_pick_ups: [],
    estimated_cash_pick_ups_min: "",
    estimated_cash_pick_ups_max: "",
  },
  BankDetailsFormThree: {
    Company_Documentation: [],
  },
  BankDetailsFormFour: {
    legacy_cash: "no",
    documents_available: [],
    business_acc: "no",
    // length_time: '',
    value: "",
    daysormonthsoryears: "Days",
    payroll_service: "no",
    paid_cash: "no",
    payroll_accepted: "no",
    taxes_cash: "no",
    penalty_cash: "no",
    tax_payment: "no",
    number_vendors_min: "",
    number_vendors_max: "",
    vendor_payment_methods: [],
    international_vendor: "no",
    prev_bank_verified: "no",
    prev_bank_aware: "no",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    successfullLogin: (state, action) => {
      console.log(action.payload, "payloadlogin");
      // state.userdata.email =
      state.userdata.bankName = action.payload.bankName;
      // state.userdata.bankAddress =
      // state.userdata.bankContactNumber =
      // state.userdata.bankCity =
      // state.userdata.bankState =
      state.userdata.bankLogo = action.payload.bankLogo;
      // state.userdata.bankType =
    },
    userLogin: (state, action) => {
      console.log(action.payload, "action.payload");
      state.userStatus = action.payload;
    },
    userRoleSetter: (state, action) => {
      console.log(action.payload, "action.payloadrole");
      state.role = action.payload;
    },
    nextOne: (state, action) => {
      console.log(action.payload, "action.payload..1");
      //services wanted:-
      state.operatorDetailsFormOne.servicesWanted =
        action.payload.servicesWanted;
      //account purpose
      state.operatorDetailsFormOne.accountPurpose =
        action.payload.accountPurpose;

      state.operatorDetailsFormOne.accountPurposeInput =
        action.payload.accountPurposeInput;
      //How much time for bank ac:-
      state.operatorDetailsFormOne.acc_need_time = action.payload.acc_need_time;
      // state.operatorDetailsFormOne.acc_need_time.days = action.payload.acc_need_time.days;

      // Risk_Classification
      state.operatorDetailsFormOne.risk_classification =
        action.payload.risk_classification;

      //Legal_Business
      state.operatorDetailsFormOne.name = action.payload.Legal_Business.name;
      state.operatorDetailsFormOne.dba = action.payload.Legal_Business.dba;
      state.operatorDetailsFormOne.physical_Address =
        action.payload.Legal_Business.physical_Address;
      state.operatorDetailsFormOne.mailing_Address =
        action.payload.Legal_Business.mailing_Address;
      state.operatorDetailsFormOne.samevalue =
        action.payload.Legal_Business.samevalue;
      state.operatorDetailsFormOne.federal_EIN =
        action.payload.Legal_Business.federal_EIN;
      state.operatorDetailsFormOne.cannabis_License_Number =
        action.payload.Legal_Business.cannabis_License_Number;
      state.operatorDetailsFormOne.licensing_Type =
        action.payload.Legal_Business.licensing_Type;
      state.operatorDetailsFormOne.LicenseNumber = action.payload.LicenseNumber;
      state.operatorDetailsFormOne.other = action.payload.Legal_Business.other;
      state.operatorDetailsFormOne.num_employees =
        action.payload.Legal_Business.num_employees;
      state.operatorDetailsFormOne.accepted_states =
        action.payload.Legal_Business.accepted_states;
      state.operatorDetailsFormOne.foreign_operations =
        action.payload.Legal_Business.foreign_operations;

      //Primary_Information
      state.operatorDetailsFormOne.primary_contact_name =
        action.payload.Primary_Information.primary_contact_name;
      state.operatorDetailsFormOne.primary_address =
        action.payload.Primary_Information.primary_address;
      state.operatorDetailsFormOne.dob = action.payload.Primary_Information.dob;
      state.operatorDetailsFormOne.mail =
        action.payload.Primary_Information.mail;
      state.operatorDetailsFormOne.primary_Phone =
        action.payload.Primary_Information.primary_Phone;
      state.operatorDetailsFormOne.ownership =
        action.payload.Primary_Information.ownership;
      state.operatorDetailsFormOne.ownership_Percentage =
        action.payload.Primary_Information.ownership_Percentage;

      state.operatorDetailsFormOne.authority_sign =
        action.payload.Primary_Information.authority_sign;
      state.operatorDetailsFormOne.us_Citizenship =
        action.payload.Primary_Information.us_Citizenship;
    },
    nextTwo: (state, action) => {
      console.log(action.payload, "action.payload..2");
      //Operational Details:-
      state.operatorDetailsFormTwo.products_purchased_providers =
        action.payload.Operational_Details.products_purchased_providers;
      state.operatorDetailsFormTwo.licensed_provider =
        action.payload.Operational_Details.licensed_provider;
      state.operatorDetailsFormTwo.acc_need =
        action.payload.Operational_Details.acc_need;
      state.operatorDetailsFormTwo.num_locs =
        action.payload.Operational_Details.num_locs;
      state.operatorDetailsFormTwo.transactions_Per_month =
        action.payload.Operational_Details.transactions_Per_month;
      // state.operatorDetailsFormTwo.managed_square_feet =
      //   action.payload.Operational_Details.managed_square_feet;
      state.operatorDetailsFormTwo.purchase_amount_per_sale =
        action.payload.Operational_Details.purchase_amount_per_sale;
      state.operatorDetailsFormTwo.num_plants =
        action.payload.Operational_Details.num_plants;
      state.operatorDetailsFormTwo.average_quantity_per_sale =
        action.payload.Operational_Details.average_quantity_per_sale;
      state.operatorDetailsFormTwo.unit =
        action.payload.Operational_Details.unit;
      state.operatorDetailsFormTwo.facility_owned =
        action.payload.Operational_Details.facility_owned;
      state.operatorDetailsFormTwo.facility_leased =
        action.payload.Operational_Details.facility_leased;
      // facility_ownedorleased
      state.operatorDetailsFormTwo.facility =
        action.payload.Operational_Details.facility;

      state.operatorDetailsFormTwo.lease_term =
        action.payload.Operational_Details.lease_term;
      state.operatorDetailsFormTwo.egmi =
        action.payload.Operational_Details.egmi;
      state.operatorDetailsFormTwo.types_customers =
        action.payload.Operational_Details.types_customers;

      //Other Operational Details:-
      state.operatorDetailsFormTwo.principal_business =
        action.payload.Other_Operational_Details.principal_business;
      state.operatorDetailsFormTwo.beneficial_owners =
        action.payload.Other_Operational_Details.beneficial_owners;
      state.operatorDetailsFormTwo.number_beneficial_owner =
        action.payload.Other_Operational_Details.number_beneficial_owner;

      //Additional Locations:-
      state.operatorDetailsFormTwo.additional_locs =
        action.payload.Additional_Locs;
      // state.operatorDetailsFormTwo.loc_name = action.payload.Additional_Locs.loc_name;
      // state.operatorDetailsFormTwo.license = action.payload.Additional_Locs.license;
      // state.operatorDetailsFormTwo.loc_address = action.payload.Additional_Locs.loc_address;

      //Anticipated Transaction Activity:-
      state.operatorDetailsFormTwo.amount_Initial_Deposit =
        action.payload.Anticipated_Transaction_Activity.amount_Initial_Deposit;
      state.operatorDetailsFormTwo.source_Initial_Deposit =
        action.payload.Anticipated_Transaction_Activity.source_Initial_Deposit;
      state.operatorDetailsFormTwo.estimated_total_amount_monthly_deposit =
        action.payload.Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit;
      state.operatorDetailsFormTwo.estimated_total_num_monthly_deposit =
        action.payload.Anticipated_Transaction_Activity.estimated_total_num_monthly_deposit;
      state.operatorDetailsFormTwo.anticipate_cash_deposits =
        action.payload.Anticipated_Transaction_Activity.anticipate_cash_deposits;
      state.operatorDetailsFormTwo.amount_cash_deposits =
        action.payload.Anticipated_Transaction_Activity.amount_cash_deposits;
      state.operatorDetailsFormTwo.frequency_cash_deposits =
        action.payload.Anticipated_Transaction_Activity.frequency_cash_deposits;
      state.operatorDetailsFormTwo.estimated_spend =
        action.payload.Anticipated_Transaction_Activity.estimated_spend;
      state.operatorDetailsFormTwo.anticipate_cash_withdrawals =
        action.payload.Anticipated_Transaction_Activity.anticipate_cash_withdrawals;
      state.operatorDetailsFormTwo.amount_cash_withdrawals =
        action.payload.Anticipated_Transaction_Activity.amount_cash_withdrawals;
      state.operatorDetailsFormTwo.frequency_cash_withdrawals =
        action.payload.Anticipated_Transaction_Activity.frequency_cash_withdrawals;
      state.operatorDetailsFormTwo.monthly_payroll =
        action.payload.Anticipated_Transaction_Activity.monthly_payroll;
      state.operatorDetailsFormTwo.cash_pick_ups =
        action.payload.Anticipated_Transaction_Activity.cash_pick_ups;
      state.operatorDetailsFormTwo.frequency_cash_pick_ups =
        action.payload.Anticipated_Transaction_Activity.frequency_cash_pick_ups;
      state.operatorDetailsFormTwo.estimated_cash_pick_ups =
        action.payload.Anticipated_Transaction_Activity.estimated_cash_pick_ups;
    },
    nextThree: (state, action) => {
      console.log(action.payload, "action.payload..3");
      state.operatorDetailsFormThree = action.payload.payloadOne;
      state.mainCheckBox = action.payload.payloadTwo;
    },
    nextFour: (state, action) => {
      console.log(action.payload, "action.payload..4");
      //Legacy Cash
      state.operatorDetailsFormFour.legacy_cash =
        action.payload.Legacy_Cash.legacy_cash;
      state.operatorDetailsFormFour.documents_available =
        action.payload.Legacy_Cash.documents_available;
      //Cash Management
      state.operatorDetailsFormFour.business_acc =
        action.payload.Cash_Management.business_acc;
      state.operatorDetailsFormFour.bank_name =
        action.payload.Cash_Management.bank_name;
      state.operatorDetailsFormFour.thisvalue =
        action.payload.Cash_Management.thisvalue;
      state.operatorDetailsFormFour.daysormonths =
        action.payload.Cash_Management.daysormonths;

      state.operatorDetailsFormFour.reason_to_close =
        action.payload.Cash_Management.reason_to_close;
      // state.operatorDetailsFormFour.payroll_service =
      //   action.payload.Cash_Management.payroll_service;
      // state.operatorDetailsFormFour.paid_cash =
      //   action.payload.Cash_Management.paid_cash;
      // state.operatorDetailsFormFour.payroll_accepted =
      //   action.payload.Cash_Management.payroll_accepted;
      // state.operatorDetailsFormFour.taxes_cash =
      //   action.payload.Cash_Management.taxes_cash;
      state.operatorDetailsFormFour.penalty_cash =
        action.payload.Cash_Management.penalty_cash;
      state.operatorDetailsFormFour.tax_payment =
        action.payload.Cash_Management.tax_payment;
      state.operatorDetailsFormFour.number_vendors =
        action.payload.Cash_Management.number_vendors;
      state.operatorDetailsFormFour.vendor_payment_methods =
        action.payload.Cash_Management.vendor_payment_methods;
      state.operatorDetailsFormFour.international_vendor =
        action.payload.Cash_Management.international_vendor;
      state.operatorDetailsFormFour.electronic_payment =
        action.payload.Cash_Management.electronic_payment;
      state.operatorDetailsFormFour.current_cash_managment =
        action.payload.Cash_Management.current_cash_managment;
      //Transfer from Existing Bank Account
      state.operatorDetailsFormFour.financial_institution =
        action.payload.Transfer_Existing_Bank_Account.financial_institution;
      state.operatorDetailsFormFour.existing_bank_contact =
        action.payload.Transfer_Existing_Bank_Account.existing_bank_contact;
      state.operatorDetailsFormFour.existing_bank_name =
        action.payload.Transfer_Existing_Bank_Account.existing_bank_name;
      state.operatorDetailsFormFour.original_deposite =
        action.payload.Transfer_Existing_Bank_Account.original_deposite;
      state.operatorDetailsFormFour.prev_bank_verified =
        action.payload.Transfer_Existing_Bank_Account.prev_bank_verified;
      state.operatorDetailsFormFour.reason_closure =
        action.payload.Transfer_Existing_Bank_Account.reason_closure;
      state.operatorDetailsFormFour.prev_bank_aware =
        action.payload.Transfer_Existing_Bank_Account.prev_bank_aware;
    },
    nextFive: (state, action) => {
      console.log(action.payload, "action.payloadfinal");
      //Electronic_Payments_Settlement
      state.operatorDetailsFormFive.settlement_num_loc =
        action.payload.Electronic_Payments_Settlement.settlement_num_loc;
      state.operatorDetailsFormFive.msb =
        action.payload.Electronic_Payments_Settlement.msb;
      state.operatorDetailsFormFive.money_transmitted_license =
        action.payload.Electronic_Payments_Settlement.money_transmitted_license;
      state.operatorDetailsFormFive.types_payments =
        action.payload.Electronic_Payments_Settlement.types_payments;
      state.operatorDetailsFormFive.mobile_app =
        action.payload.Electronic_Payments_Settlement.mobile_app;
      state.operatorDetailsFormFive.pos =
        action.payload.Electronic_Payments_Settlement.pos;
      state.operatorDetailsFormFive.credit_card =
        action.payload.Electronic_Payments_Settlement.credit_card;
      state.operatorDetailsFormFive.bank_aware =
        action.payload.Electronic_Payments_Settlement.bank_aware;
      state.operatorDetailsFormFive.merchant_processor =
        action.payload.Electronic_Payments_Settlement.merchant_processor;
      state.operatorDetailsFormFive.terminal =
        action.payload.Electronic_Payments_Settlement.terminal;

      //ATM Machine Transactions
      state.operatorDetailsFormFive.atm_own =
        action.payload.ATM_Machine.atm_own;
      state.operatorDetailsFormFive.atm_loc =
        action.payload.ATM_Machine.atm_loc;
      state.operatorDetailsFormFive.atm_multiple =
        action.payload.ATM_Machine.atm_multiple;
      state.operatorDetailsFormFive.atm_fill =
        action.payload.ATM_Machine.atm_fill;
      state.operatorDetailsFormFive.atm_third_party =
        action.payload.ATM_Machine.atm_third_party;
      state.operatorDetailsFormFive.atm_fill_company =
        action.payload.ATM_Machine.atm_fill_company;
      state.operatorDetailsFormFive.atm_currency =
        action.payload.ATM_Machine.atm_currency;
      state.operatorDetailsFormFive.atm_crypto =
        action.payload.ATM_Machine.atm_crypto;
      state.operatorDetailsFormFive.atm_deposite =
        action.payload.ATM_Machine.atm_deposite;
      state.operatorDetailsFormFive.atm_receipt =
        action.payload.ATM_Machine.atm_receipt;

      //Accounting
      state.operatorDetailsFormFive.accounting_system =
        action.payload.Accounting.accounting_system;
      state.operatorDetailsFormFive.accountant =
        action.payload.Accounting.accountant;
      state.operatorDetailsFormFive.firm_name =
        action.payload.Accounting.firm_name;

      //Compliance_Details
      state.operatorDetailsFormFive.non_compliance =
        action.payload.Compliance_Details.non_compliance;
      state.operatorDetailsFormFive.compliance_desc =
        action.payload.Compliance_Details.compliance_desc;
      state.operatorDetailsFormFive.compliance_officer =
        action.payload.Compliance_Details.compliance_officer;
      state.operatorDetailsFormFive.compliance_partner =
        action.payload.Compliance_Details.compliance_partner;
      state.operatorDetailsFormFive.compliance_partner_name =
        action.payload.Compliance_Details.compliance_partner_name;
      state.operatorDetailsFormFive.compliance_group =
        action.payload.Compliance_Details.compliance_group;
      state.operatorDetailsFormFive.onsite_inspection =
        action.payload.Compliance_Details.onsite_inspection;
      state.operatorDetailsFormFive.compliance_date =
        action.payload.Compliance_Details.compliance_date;
      state.operatorDetailsFormFive.compliance_support =
        action.payload.Compliance_Details.compliance_support;

      state.operatorDetailsFormFive.frequency_compliance =
        action.payload.Compliance_Details.frequency_compliance;
      state.operatorDetailsFormFive.compliance_trainning =
        action.payload.Compliance_Details.compliance_trainning;
      state.operatorDetailsFormFive.operating_procedures =
        action.payload.Compliance_Details.operating_procedures;

      //Are you interested in any of the following services:
      state.operatorDetailsFormFive.follwing_services =
        action.payload.Following_Services;
    },
    nextOneBank: (state, action) => {
      console.log(action.payload, "payload");
      // servicesPrice
      state.BankDetailsFormOne.servicesOffered = action.payload.servicesOffered;
      state.BankDetailsFormOne.servicesPrices = action.payload.servicesPrices;

      state.BankDetailsFormOne.acc_creation_time =
        action.payload.acc_creation_time;

      //Legal Business:-
      state.BankDetailsFormOne.risk_classification =
        action.payload.Risk_classification_Bank;
      state.BankDetailsFormOne.licensing_Type =
        action.payload.Legal_Business_Bank.licensing_Type;
      state.BankDetailsFormOne.other = action.payload.Legal_Business_Bank.other;
      state.BankDetailsFormOne.num_employees_max =
        action.payload.Legal_Business_Bank.num_employees_max;
      state.BankDetailsFormOne.num_employees_min =
        action.payload.Legal_Business_Bank.num_employees_min;

      state.BankDetailsFormOne.accepted_states =
        action.payload.Legal_Business_Bank.accepted_states;
      state.BankDetailsFormOne.foreign_operations =
        action.payload.Legal_Business_Bank.foreign_operations;
      state.BankDetailsFormOne.ownership_Percentage_min =
        action.payload.Primary_Information_Bank.ownership_Percentage_min;
      // state.BankDetailsFormOne.ownership_Percentage_max = action.payload.Primary_Information_Bank.ownership_Percentage_max;
      state.BankDetailsFormOne.us_Citizenship =
        action.payload.Primary_Information_Bank.us_Citizenship;
    },
    nextTwoBank: (state, action) => {
      console.log(action.payload.Operational_details_Bank, "payload");
      //operational Details:-
      state.BankDetailsFormTwo.products_purchased_providers =
        action.payload.Operational_details_Bank.products_purchased_providers;
      state.BankDetailsFormTwo.licensed_provider =
        action.payload.Operational_details_Bank.licensed_provider;
      state.BankDetailsFormTwo.accept_CRB_min =
        action.payload.Operational_details_Bank.accept_CRB_min;
      state.BankDetailsFormTwo.accept_CRB_max =
        action.payload.Operational_details_Bank.accept_CRB_max;

      state.BankDetailsFormTwo.num_locs_min =
        action.payload.Operational_details_Bank.num_locs_min;
      state.BankDetailsFormTwo.num_locs_max =
        action.payload.Operational_details_Bank.num_locs_max;
      state.BankDetailsFormTwo.transactions_Per_month_min =
        action.payload.Operational_details_Bank.transactions_Per_month_min;
      state.BankDetailsFormTwo.transactions_Per_month_max =
        action.payload.Operational_details_Bank.transactions_Per_month_max;
      // state.BankDetailsFormTwo.managed_square_feet_min =
      //   action.payload.Operational_details_Bank.managed_square_feet_min;
      // state.BankDetailsFormTwo.managed_square_feet_max =
      //   action.payload.Operational_details_Bank.managed_square_feet_max;
      // state.BankDetailsFormTwo.facility_owned = action.payload.Operational_details_Bank.facility_owned;
      // state.BankDetailsFormTwo.facility_leased = action.payload.Operational_details_Bank.facility_leased;
      state.BankDetailsFormTwo.facility =
        action.payload.Operational_details_Bank.facility;

      state.BankDetailsFormTwo.types_customers =
        action.payload.Operational_details_Bank.types_customers;

      //Other Operational Details:-
      state.BankDetailsFormTwo.principal_business =
        action.payload.Other_Operational_Details_Bank.principal_business;
      state.BankDetailsFormTwo.beneficial_owners =
        action.payload.Other_Operational_Details_Bank.beneficial_owners;
      state.BankDetailsFormTwo.number_beneficial_owner_min =
        action.payload.Other_Operational_Details_Bank.number_beneficial_owner_min;
      state.BankDetailsFormTwo.number_beneficial_owner_max =
        action.payload.Other_Operational_Details_Bank.number_beneficial_owner_max;

      //Anticipated Transaction Activity:-
      state.BankDetailsFormTwo.amount_Initial_Deposit_min =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min;
      state.BankDetailsFormTwo.amount_Initial_Deposit_max =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max;
      state.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_min =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_min;
      state.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_max =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_max;
      state.BankDetailsFormTwo.estimated_total_num_monthly_deposit_min =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_min;
      state.BankDetailsFormTwo.estimated_total_num_monthly_deposit_max =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_max;
      state.BankDetailsFormTwo.anticipate_cash_deposits =
        action.payload.Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits;
      state.BankDetailsFormTwo.anticipate_cash_deposits_max =
        action.payload.Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits_max;
      state.BankDetailsFormTwo.amount_cash_deposits_min =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min;
      state.BankDetailsFormTwo.amount_cash_deposits_max =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max;

      state.BankDetailsFormTwo.frequency_cash_deposits =
        action.payload.Anticipated_Transaction_Activity_Bank.frequency_cash_deposits;
      // state.BankDetailsFormTwo.frequency_cash_deposits_max = action.payload.Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_max
      state.BankDetailsFormTwo.estimated_spend_min =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_spend_min;
      state.BankDetailsFormTwo.estimated_spend_max =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_spend_max;
      state.BankDetailsFormTwo.anticipate_cash_withdrawals =
        action.payload.Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals;
      state.BankDetailsFormTwo.amount_cash_withdrawals_min =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min;
      state.BankDetailsFormTwo.amount_cash_withdrawals_max =
        action.payload.Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max;
      state.BankDetailsFormTwo.frequency_cash_withdrawals =
        action.payload.Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals;
      // state.BankDetailsFormTwo.frequency_cash_withdrawals_max = action.payload.Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals_max
      state.BankDetailsFormTwo.monthly_payroll_min =
        action.payload.Anticipated_Transaction_Activity_Bank.monthly_payroll_min;
      state.BankDetailsFormTwo.monthly_payroll_max =
        action.payload.Anticipated_Transaction_Activity_Bank.monthly_payroll_max;
      state.BankDetailsFormTwo.cash_pick_ups =
        action.payload.Anticipated_Transaction_Activity_Bank.cash_pick_ups;
      state.BankDetailsFormTwo.frequency_cash_pick_ups =
        action.payload.Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups;
      state.BankDetailsFormTwo.estimated_cash_pick_ups_min =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min;
      state.BankDetailsFormTwo.estimated_cash_pick_ups_max =
        action.payload.Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max;
    },
    nextThreeBank: (state, action) => {
      state.BankDetailsFormThree.Company_Documentation =
        action.payload.Company_Documentation_Bank;
    },
    nextFourBank: (state, action) => {
      console.log(action.payload.Legacy_Cash_Bank.legacy_cash, "780");
      state.BankDetailsFormFour.legacy_cash =
        action.payload.Legacy_Cash_Bank.legacy_cash;
      state.BankDetailsFormFour.documents_available =
        action.payload.Legacy_Cash_Bank.documents_available;

      state.BankDetailsFormFour.business_acc =
        action.payload.Cash_Management_Bank.business_acc;
      // state.BankDetailsFormFour.length_time = action.payload.Cash_Management_Bank.length_time
      state.BankDetailsFormFour.value =
        action.payload.Cash_Management_Bank.value;
      state.BankDetailsFormFour.daysormonthsoryears =
        action.payload.Cash_Management_Bank.daysormonthsoryears;

      state.BankDetailsFormFour.payroll_service =
        action.payload.Cash_Management_Bank.payroll_service;
      state.BankDetailsFormFour.paid_cash =
        action.payload.Cash_Management_Bank.paid_cash;
      state.BankDetailsFormFour.payroll_accepted =
        action.payload.Cash_Management_Bank.payroll_accepted;
      state.BankDetailsFormFour.taxes_cash =
        action.payload.Cash_Management_Bank.taxes_cash;
      state.BankDetailsFormFour.penalty_cash =
        action.payload.Cash_Management_Bank.penalty_cash;
      state.BankDetailsFormFour.tax_payment =
        action.payload.Cash_Management_Bank.tax_payment;
      state.BankDetailsFormFour.number_vendors_min =
        action.payload.Cash_Management_Bank.number_vendors_min;
      state.BankDetailsFormFour.number_vendors_max =
        action.payload.Cash_Management_Bank.number_vendors_max;
      state.BankDetailsFormFour.vendor_payment_methods =
        action.payload.Cash_Management_Bank.vendor_payment_methods;
      state.BankDetailsFormFour.international_vendor =
        action.payload.Cash_Management_Bank.international_vendor;

      state.BankDetailsFormFour.prev_bank_aware =
        action.payload.Transfer_Existing_Bank_Bank.prev_bank_aware;
      state.BankDetailsFormFour.prev_bank_verified =
        action.payload.Transfer_Existing_Bank_Bank.prev_bank_verified;
    },
    CleanUpStore: (state, action) => {
      //  state.userdata ={}
      //  state.operatorDetailsFormOne ={}
      //  state.operatorDetailsFormTwo ={}
      //  state.operatorDetailsFormThree ={}
      //  state.operatorDetailsFormfour ={}
      //  state.operatorDetailsFormFive ={}
      //  state.BankDetailsFormOne ={}
      //  state.BankDetailsFormTwo ={}
      //  state.BankDetailsFormThree ={}
      //  state.BankDetailsFormFour ={}
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  successfullLogin,
  userLogin,
  nextOne,
  nextTwo,
  nextThree,
  nextFour,
  nextFive,
  userRoleSetter,
  nextOneBank,
  nextTwoBank,
  nextThreeBank,
  nextFourBank,
  CleanUpStore,
} = userSlice.actions;

export default userSlice.reducer;
