import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";

const CountdownTimer = ({ totalSec,credentials,user,LoginHandler }) => {
  console.log(credentials,user,'seetheprops')
  const countRef = useRef("");
  const [restart, setRestart] = useState(true);
  const [timee, setTime] = useState(Date.now() + totalSec);
  const [customTime,setCustomTime] = useState(30)
 console.log(totalSec,'targettime')
 
  // console.log(countRef);
  const resendVerificationCode = (e, apiii) => {
    e.preventDefault();
    setTime(Date.now() + totalSec);
    // apiii.start();
  };
// useEffect(()=>{
//  const timeIntervalId = setInterval(()=>{
//        setCustomTime(customTime - 1)
//   },1000)
//   // clearInterval(timeIntervalId)
// },[customTime])
  const renderer = ({ hours, minutes, seconds, completed, api }) => {

    const myTime = minutes * 60 + seconds
    console.log(seconds,'seetime')
    if (myTime === 150 || (myTime < 150 )){
      console.log(api);
      return (
        <div>
         
          
          <span>{minutes > 9 ? minutes : `0 ${minutes}`}</span>
          <span>{" : "}</span>
          <span>{seconds > 9 ? seconds : `0 ${seconds}`}</span>
          <br/><br/>
         <div style={{marginLeft:'10rem',marginTop:'2rem',display:'flex'}}> 
         <p>Didn't receive OTP ?</p>&nbsp; &nbsp; 
          <button 
          className="notification_btn" 
          onClick={(e) => {resendVerificationCode(e, api)
            LoginHandler()
          }}
          
          >Resend</button>   &nbsp; 
          
          </div>
        </div>
      );
    } 
     else if((myTime > 150 )){
      return (
        <div>
           
          <span>{minutes > 9 ? minutes : `0 ${minutes}`}</span>
          <span>{" : "}</span>
          <span>{seconds > 9 ? seconds : `0 ${seconds}`}</span>
         
        </div>
      );
      }
  };

  useEffect(() => {
    if (restart) {
      countRef?.current?.start();
    }
  }, [restart, timee]);

  return (
    <Countdown
      date={timee}
      renderer={renderer}
      autoStart={false}
      ref={countRef}
    ></Countdown>
  );
};

export default CountdownTimer;
