import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../CustomHook/useForm";
import { nextOne } from "../../Store/userSlice";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import "react-toastify/dist/ReactToastify.css";
import { Validate } from "../../Helper/Validate";
import { omit } from "lodash";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import CurrencyInput from "../../Helper/CurrencyInput";
const FormOne = (props) => {
  // const [startDate, setDate] = React.useState(new Date());
  // const [rangeStart, setRangeStart] = React.useState(new Date());
  // const defaultEndDate = new Date();
  // defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  // const [rangeEnd, setRangeEnd] = React.useState(defaultEndDate);
  // const today = new Date();

  // const selectDateHandler = (d) => {
  //   setDate(d);
  // };

  // const selectStartDate = (d) => {
  //   setRangeStart(d);
  // };

  // const selectEndDate = (d) => {
  //   setRangeEnd(d);
  // };
  // minimum_ownership
  const {
    servicesWanted,
    ServicesWantedHandler,
    acc_need_time,
    RiskClassificationHandler,
    risk_classification,
    LegalBusinessHandler,
    Legal_Business,
    setLegal_Business,
    PrimaryInformationHandler,
    Primary_Information,
    errors,
    AccountNeedTimehandler,
    LicenseNumberHandler,
    LicenseNumber,
    accountPurpose,
    AccountPurposeHandler,
    accountPurposeInput,
  } = useForm();
  console.log(acc_need_time, "acc_need_time");
  console.log(Legal_Business, "Legal_Business");
  console.log(accountPurpose, "accountPurpose");

  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  const formInfo = useSelector((state) => state.user);
  const [errorState, setErrorstate] = useState();
  const [focus, setFocus] = useState();
  const dispatch = useDispatch();
  const [sameValue, setSameValue] = useState(Legal_Business.samevalue);
  console.log(focus, "ui-tar");
  useEffect(() => {
    setErrorstate(errors);
  }, [errors]);
  const USASTATES = [
    "Alabama(AL)",
    "Alaska (AK)",
    "Arizona (AZ)",
    " Arkansas (AR)",
    " California (CA)",
    "Colorado (CO)",
    "Connecticut (CT)",
    " Delaware (DE)",
    "Florida (FL)",
    "Georgia (GA)",
    "Hawaii (HI)",
    "Idaho (ID)",
    "Illinois (IL)",
    "Indiana (IN)",
    "Iowa (IA)",
    "Kansas (KS)",
    "Kentucky (KY)",
    "Louisiana (LA)",
    " Maine (ME)",
    "Maryland (MD)",
    "Massachusetts (MA)",
    "Michigan (MI)",
    "Minnesota (MN)",
    "Mississippi (MS)",
    " Missouri (MO)",
    "Montana (MT)",
    "Nebraska (NE)",
    "Nevada (NV)",
    "New Hampshire (NH)",
    "New Jersey (NJ)",
    "NewMexico (NM)",
    "NewYork (NY)",
    "North Carolina (NC)",
    "North Dakota (ND)",
    "Ohio (OH)",
    "Oklahoma (OK)",
    "Oregon (OR)",
    "Pennsylvania (PA)",
    "RhodeIsland (RI)",
    " South Carolina (SC)",
    "South Dakota (SD)",
    "Tennessee (TN)",
    "Texas (TX)",
    " Utah (UT)",
    "Vermont (VT)",
    "Virginia (VA)",
    "Washington (WA)",
    " West Virginia (WV)",
    "Wisconsin (WI)",
    "Wyoming (WY)",
  ];

  const StateOptions = USASTATES.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });
  useEffect(() => {}, [errorState]);
  const NextHandlerOne = (event) => {
    event.preventDefault();
    console.log(errorState, "78889");
    var u = true;
    if (servicesWanted.length === 0) {
      u = false;
    } else {
      u = true;
    }
    console.log(
      Legal_Business?.licensing_Type,
      LicenseNumber,
      Legal_Business?.other,
      "97666"
    );
    console.log(Legal_Business, Primary_Information, acc_need_time, "7888yu");
    if (acc_need_time.value === "") {
      errorState.value = "Cannot be empty";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.value;
      setErrorstate({ ...errorState });
    }
    if (Legal_Business.mailing_Address === "") {
      setSameValue(true);
      Legal_Business.mailing_Address = Legal_Business.physical_Address;
    }
    const keys = [...Object.keys(Legal_Business)];
    console.log(Object.keys(LicenseNumber), "9191");
    for (let u in LicenseNumber) {
      if (!Legal_Business.licensing_Type.includes(u)) {
        errorState[u] && delete errorState[u];
        setErrorstate({ ...errorState });
      }
    }
    for (let x of Legal_Business.licensing_Type) {
      if (!LicenseNumber[x]) {
        errorState[x] = "Cannot be empty";
        setErrorstate({ ...errorState });
      } else {
        delete errorState[x];
        setErrorstate({ ...errorState });
      }
    }
    console.log(
      Legal_Business.federal_EIN
        .split("")
        .filter((o) => o !== " ")
        .join("").length,
      "1823507"
    );
    console.log(Legal_Business.federal_EIN, "1823507");
    for (let x in Legal_Business) {
      if (x === "federal_EIN") {
        if (Legal_Business.federal_EIN === "") {
          errorState.federal_EIN = "This field is mandatory";
          setErrorstate({ ...errorState });
        } else if (Legal_Business.federal_EIN.replaceAll(" ", "").length < 10) {
          errorState.federal_EIN = "Must be of 9 digits";
          setErrorstate({ ...errorState });
        } else {
          delete errorState.federal_EIN;
          setErrorstate({ ...errorState });
        }
      }
      if (
        x !== "mailing_Address" &&
        x !== "other" &&
        x !== "accepted_states" &&
        x !== "licensing_Type" &&
        x !== "dba" &&
        x !== "federal_EIN"
      ) {
        if (String(Legal_Business[x]).length === 0) {
          errorState[x] = "Cannot be empty";

          setErrorstate({ ...errorState });
        } else {
          // let newObj = omit(errorState, x);
          // setErrorstate(newObj);
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      }
      if (Legal_Business.licensing_Type.includes("Other")) {
        if (Legal_Business.other === "") {
          errorState.other = "Cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState.other;
          setErrorstate({ ...errorState });
        }
      } else {
        delete errorState.other;
        setErrorstate({ ...errorState });
      }
      // if (x === "other") {
      //   console.log("uiiii");
      //   if (Legal_Business.licensing_Type === "Other") {
      //     if (
      //       Legal_Business.other === "" ||
      //       Legal_Business.other === undefined
      //     ) {
      //       console.log("here");
      //       errorState.other = "Cannot be empty";

      //       setErrorstate({ ...errorState });
      //     } else {
      //       let newObj = omit(errorState, "other");
      //       setErrorstate(newObj);
      //     }
      //   } else {
      //     delete errorState.other;
      //     setErrorstate({ ...errorState });
      //   }
      // }
    }
    for (let x in Primary_Information) {
      if (x !== "ownership_Percentage") {
        if (String(Primary_Information[x]).length === 0) {
          errorState[x] = "Cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else {
        if (Primary_Information.ownership === "yes") {
          if (String(Primary_Information.ownership_Percentage) === "") {
            errorState[x] = "Cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.ownership_Percentage;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.ownership_Percentage;
          setErrorstate({ ...errorState });
        }
      }
    }

    event.preventDefault();

    const res = [...Object.values(Legal_Business), risk_classification];
    console.log(res, "myui");
    const finalres = res.map((i) => String(i).length);

    console.log(errorState, "1823507");
    //  , ...(Object.values(Primary_Information))
    // && finalres.every(x => x !== 0)
    if (
      Object.keys(errorState).length === 0 &&
      u &&
      Legal_Business.accepted_states.length !== 0 &&
      accountPurpose.length !== 0 &&
      Legal_Business.licensing_Type.length !== 0
    ) {
      props.setFormNo(2);

      dispatch(
        nextOne({
          accountPurpose,
          accountPurposeInput,
          servicesWanted,
          acc_need_time,
          risk_classification,
          Legal_Business,
          LicenseNumber,
          Primary_Information,
        })
      );
      window.scrollTo(0, 0);
    } else {
      if (!u) {
        toast.error(
          "Please select atleast one service from Services You Want",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else if (Legal_Business.accepted_states.length === 0) {
        toast.error("Please select atleast one state from accepted states!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (accountPurpose.length === 0) {
        toast.error("Please select atleast one state from Account Purpose!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (Legal_Business.licensing_Type.length === 0) {
        toast.error("Please select atleast one state from Licensing Type!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <section id="section1" className="section1">
      <form onSubmit={NextHandlerOne}>
        <div className="pg1-mn-otr">
          <div className="pg1-mn-inr">
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <div className="pg3-mn-otrfld">
                    <div className="check__in__bx">
                      <h6>
                        Services You Want:
                        <span className="red-star">*</span>
                      </h6>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Business Checking")}
                          value="Business Checking"
                          checked={servicesWanted.includes("Business Checking")}
                          onChange={ServicesWantedHandler}
                        />
                        <label>Business Checking</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Interest Bearing Accounts")}
                          checked={servicesWanted.includes(
                            "Interest Bearing Accounts"
                          )}
                          onChange={ServicesWantedHandler}
                          value="Interest Bearing Accounts"
                        />
                        <label>Interest Bearing Accounts</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Debit Cards")}
                          checked={servicesWanted.includes("Debit Cards")}
                          onChange={ServicesWantedHandler}
                          value="Debit Cards"
                        />
                        <label>Debit Cards</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("ACH")}
                          checked={servicesWanted.includes("ACH")}
                          onChange={ServicesWantedHandler}
                          value="ACH"
                        />
                        <label>ACH</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Wires")}
                          checked={servicesWanted.includes("Wires")}
                          onChange={ServicesWantedHandler}
                          value="Wires"
                        />
                        <label>Wires</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Discount for Social Equity")}
                          checked={servicesWanted.includes(
                            "Discount for Social Equity"
                          )}
                          onChange={ServicesWantedHandler}
                          value="Discount for Social Equity"
                        />
                        <label>Discount for Social Equity</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Accounts for Employees")}
                          checked={servicesWanted.includes(
                            "Accounts for Employees"
                          )}
                          onChange={ServicesWantedHandler}
                          value="Accounts for Employees"
                        />
                        <label>Accounts for Employees</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Payroll")}
                          checked={servicesWanted.includes("Payroll")}
                          onChange={ServicesWantedHandler}
                          value="Payroll"
                        />
                        <label>Payroll</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("401K")}
                          checked={servicesWanted.includes("401K")}
                          onChange={ServicesWantedHandler}
                          value="401K"
                        />
                        <label>401K</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <div className="pg3-mn-otrfld">
                    <div className="check__in__bx">
                      <h6>
                        Account purpose:
                        <span className="red-star">*</span>
                      </h6>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          // onFocus={() => setFocus("Business Checking")}
                          value="Process Payroll"
                          checked={accountPurpose?.includes("Process Payroll")}
                          onChange={AccountPurposeHandler}
                        />
                        <label>Process Payroll</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          // onFocus={() => setFocus("Interest Bearing Accounts")}
                          checked={accountPurpose?.includes(
                            "Day-to-day Expensive"
                          )}
                          onChange={AccountPurposeHandler}
                          value="Day-to-day Expensive"
                        />
                        <label>Day-to-day Expensive</label>
                      </div>
                    </div>

                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          // onFocus={() => setFocus("ACH")}
                          checked={accountPurpose?.includes("Reserve")}
                          onChange={AccountPurposeHandler}
                          value="Reserve"
                        />
                        <label>Reserve</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Wires")}
                          checked={accountPurpose?.includes("Tax")}
                          onChange={AccountPurposeHandler}
                          value="Tax"
                        />
                        <label>Tax</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Discount for Social Equity")}
                          checked={accountPurpose?.includes("Savings")}
                          onChange={AccountPurposeHandler}
                          value="Savings"
                        />
                        <label>Savings</label>
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          onFocus={() => setFocus("Discount for Social Equity")}
                          checked={accountPurpose?.includes("Others")}
                          onChange={AccountPurposeHandler}
                          value="Others"
                        />
                        <label>Others</label>
                      </div>
                      {accountPurpose?.includes("Others") && (
                        <input
                          name="accountPurposeInput"
                          onChange={AccountPurposeHandler}
                          value={accountPurposeInput}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <div className="pg3-mn-otrfld">
                    <div className="check__in__bx">
                      <h6>How fast do you need a bank account?</h6>
                      {/* <div style={{display:'flex'}}>
              <div >
              <h4>Months</h4><br/>
             <select 
             name='months'
             style={{width:'150px',height:'42px',cursor:'pointer'}} onChange={AccountNeedTimehandler}>
              <option selected={acc_need_time.months === '0' ? true : false}>0</option>
              <option selected={acc_need_time.months === '1' ? true : false}>1</option>
              <option selected={acc_need_time.months === '2' ? true : false}>2</option>
              <option selected={acc_need_time.months === '3' ? true : false}>3</option>
              <option selected={acc_need_time.months === '4' ? true : false}>4</option>
              <option selected={acc_need_time.months === '5' ? true : false}>5</option>
              <option selected={acc_need_time.months === '6' ? true : false}>6</option>
              <option selected={acc_need_time.months === '7' ? true : false}>7</option>
              <option selected={acc_need_time.months === '8' ? true : false}>8</option>
              <option selected={acc_need_time.months === '9' ? true : false}>9</option>
              <option selected={acc_need_time.months === '10' ? true : false}>10</option>
              <option selected={acc_need_time.months === '11' ? true : false}>11</option>
              <option selected={acc_need_time.months === '12' ? true : false}>12</option>
             </select>
              </div>
            <div style={{marginLeft:'2rem'}}> <h4>Days</h4><br/>
             <select 
             name='days'
             style={{width:'150px',height:'42px'}} onChange={AccountNeedTimehandler}>
             <option selected={acc_need_time.days === '0' ? true : false}>0</option>
              <option selected={acc_need_time.days === '1' ? true : false}>1</option>
              <option selected={acc_need_time.days === '2' ? true : false}>2</option>
              <option selected={acc_need_time.days === '3' ? true : false}>3</option>
              <option selected={acc_need_time.days === '4' ? true : false}>4</option>
              <option selected={acc_need_time.days === '5' ? true : false}>5</option>
              <option selected={acc_need_time.days === '6' ? true : false}>6</option>
              <option selected={acc_need_time.days === '7' ? true : false}>7</option>
              <option selected={acc_need_time.days === '9' ? true : false}>8</option>
              <option selected={acc_need_time.days === '10' ? true : false}>9</option>
              <option selected={acc_need_time.days === '11' ? true : false}>10</option>
              <option selected={acc_need_time.days === '12' ? true : false}>11</option>
              <option selected={acc_need_time.days === '13' ? true : false}>13</option>
              <option selected={acc_need_time.days === '14' ? true : false}>14</option>
              <option selected={acc_need_time.days === '15' ? true : false}>15</option>
              <option selected={acc_need_time.days === '16' ? true : false}>16</option>
              <option selected={acc_need_time.days === '17' ? true : false}>17</option>
              <option selected={acc_need_time.days === '18' ? true : false}>18</option>
              <option selected={acc_need_time.days === '19' ? true : false}>19</option>
              <option selected={acc_need_time.days === '20' ? true : false}>20</option>
              <option selected={acc_need_time.days === '21' ? true : false}>21</option>
              <option selected={acc_need_time.days === '22' ? true : false}>22</option>
              <option selected={acc_need_time.days === '23' ? true : false}>23</option>
              <option selected={acc_need_time.days === '24' ? true : false}>24</option>
              <option selected={acc_need_time.days === '25' ? true : false}>25</option>
              <option selected={acc_need_time.days === '26' ? true : false}>26</option>
              <option selected={acc_need_time.days === '27' ? true : false}>27</option>
              <option selected={acc_need_time.days === '28' ? true : false}>28</option>
              <option selected={acc_need_time.days === '29' ? true : false}>29</option>
              <option selected={acc_need_time.days === '30' ? true : false}>30</option>
             </select></div>
             </div> */}
                      <div style={{ display: "flex" }}>
                        {" "}
                        <div className="pg1-prt-2-inrfld">
                          <input
                            id="acc_need_time"
                            onInput={(e) => {
                              if (e.target.value.length > 3) {
                                e.target.value = e.target.value.slice(0, 3);
                              }
                            }}
                            onFocus={() => setFocus("acc_need_time")}
                            type="number"
                            name="value"
                            value={acc_need_time.value}
                            onChange={AccountNeedTimehandler}
                            className="form-control"
                            // defaultValue={10}
                          />
                          <span className="red-star">*</span>
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.value && focus !== "acc_need_time" && (
                            <p style={{ color: "red" }}>{errorState.value}</p>
                          )}
                        </div>
                        <div className="pg1-prt-2-inrfld">
                          <select
                            name="daysormonths"
                            onFocus={() => setFocus("daysormonths")}
                            className="form-control"
                            onChange={AccountNeedTimehandler}
                          >
                            <option
                              selected={
                                acc_need_time.daysormonths === "Years"
                                  ? true
                                  : false
                              }
                            >
                              Years
                            </option>
                            <option
                              selected={
                                acc_need_time.daysormonths === "Months"
                                  ? true
                                  : false
                              }
                            >
                              Months
                            </option>
                            <option
                              selected={
                                acc_need_time.daysormonths === "Days"
                                  ? true
                                  : false
                              }
                            >
                              Days
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <h6>
                    Select one that applies to your business:
                    {/* <span className="red-star">*</span> */}
                  </h6>

                  <div className="pg1-prt-1-inrfld">
                    <input
                      type="radio"
                      onFocus={() => setFocus("risk_classification")}
                      name="risk_classification"
                      checked={risk_classification === "Option1"}
                      value="Option1"
                      onChange={RiskClassificationHandler}
                    />
                    <label>
                      My business touches marijuana at any point from seed to
                      sale and my income relies strictly on the marijuana
                      industry. This includes any business or farm that
                      manufactures, processes, or distributes illegal hemp.
                    </label>
                  </div>
                  <div className="pg1-prt-1-inrfld">
                    <input
                      type="radio"
                      name="risk_classification"
                      onFocus={() => setFocus("risk_classification")}
                      checked={risk_classification === "Option2"}
                      value="Option2"
                      onChange={RiskClassificationHandler}
                    />
                    <label>
                      My business activity focuses on providing products and
                      services specifically to MarijuanaRelated Businesses and
                      the cannabis industry as a whole. My business revenue is
                      expected to come from cannabis-related activities.
                    </label>
                  </div>
                  <div className="pg1-prt-1-inrfld">
                    <input
                      type="radio"
                      onFocus={() => setFocus("risk_classification")}
                      name="risk_classification"
                      checked={risk_classification === "Option3"}
                      value="Option3"
                      onChange={RiskClassificationHandler}
                    />
                    <label>
                      My business activity is not specifically focused on
                      providing services to Marijuana-Related Businesses or the
                      cannabis industry, but rather such services are incidental
                      to their overall business and revenue.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-2">
              <div className="pg1-prt-2-txt">
                <div className="cmn_hdr">
                  <h6>Legal Business Information</h6>
                  <div className="pg1-prt-2-otrfld">
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Legal Business Name:<span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onFocus={() => setFocus("Legal Business Name")}
                        // value={Legal_Business.name}
                        value={Legal_Business["name"]}
                        onChange={LegalBusinessHandler}
                        // required minlength="3"
                      />

                      {errorState?.name && focus !== "Legal Business Name" && (
                        <p style={{ color: "red" }}>{errorState.name}</p>
                      )}
                    </div>

                    <div className="pg1-prt-2-inrfld">
                      <label>Other DBAs:</label>
                      <input
                        type="text"
                        name="dba"
                        onFocus={() => setFocus("dba")}
                        value={Legal_Business.dba}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      />
                      {errorState?.dba && focus !== "dba" && (
                        <p style={{ color: "red" }}>{errorState.dba}</p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Physical Address:<span className="red-star">*</span>
                      </label>
                      {/* <input
                        type="text"
                        name="physical_Address"
                        onFocus={() => setFocus("physical_Address")}
                        value={Legal_Business.physical_Address}
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                      <textarea
                        type="text"
                        name="physical_Address"
                        onFocus={() => setFocus("physical_Address")}
                        value={Legal_Business.physical_Address}
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                        textarea
                      />
                      {errorState?.physical_Address && (
                        <p style={{ color: "red" }}>
                          {errorState.physical_Address}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <div className="pg1__inr">
                        <label>Mailing Address:</label>
                        <span className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="samevalue"
                            onFocus={() => setFocus("samevalue")}
                            value="Secretary of State Registration"
                            checked={sameValue === "yes"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSameValue("yes");
                              } else {
                                setSameValue("no");
                              }
                            }}
                          />
                          <label>Same as Physical Address</label>
                        </span>
                      </div>
                      {/* <input
                        type="text"
                        onFocus={() => setFocus("mailing_Address")}
                        name="mailing_Address"
                        value={
                          sameValue === "yes"
                            ? Legal_Business.physical_Address
                            : Legal_Business.mailing_Address
                        }
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                      <textarea
                        type="text"
                        onFocus={() => setFocus("mailing_Address")}
                        name="mailing_Address"
                        value={
                          sameValue === "yes"
                            ? Legal_Business.physical_Address
                            : Legal_Business.mailing_Address
                        }
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                        textarea
                      />
                      {errorState?.mailing_Address &&
                        focus !== "mailing_Address" && (
                          <p style={{ color: "red" }}>
                            {errorState.mailing_Address}
                          </p>
                        )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Federal EIN:<span className="red-star">*</span>
                      </label>
                      {/* Legal_Business.federal_EIN.substr(0,2) + '-' + Legal_Business.federal_EIN.substr(3, 6)  */}
                      {/* <input
                        type="number"
                        onFocus={() => setFocus("federal_EIN")}
                        onInput={(e) => {
                          if (e.target.value.length > 9) {
                            e.target.value = e.target.value.slice(0, 9);
                          }
                        }}
                        name="federal_EIN"
                        value={Legal_Business.federal_EIN}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                      <NumberFormat
                        value={Legal_Business.federal_EIN}
                        className="form-control"
                        format={"##-#######"}
                        // mask="*"
                        onChange={LegalBusinessHandler}
                        name="federal_EIN"
                      />
                      {errorState?.federal_EIN && focus !== "federal_EIN" && (
                        <p style={{ color: "red" }}>{errorState.federal_EIN}</p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Cannabis License Number:
                        <span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        onFocus={() => setFocus("cannabis_License_Number")}
                        name="cannabis_License_Number"
                        //     onInput={(e)=>{

                        //       if(e.target.value.length > 12){
                        //        e.target.value = e.target.value.slice(0,12)
                        //       }
                        //  }}
                        value={Legal_Business.cannabis_License_Number}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      />
                      {errorState?.cannabis_License_Number &&
                        focus !== "cannabis_License_Number" && (
                          <p style={{ color: "red" }}>
                            {errorState.cannabis_License_Number}
                          </p>
                        )}
                    </div>
                    {/* <div className="pg1-prt-2-inrfld nd2">
                      <label>Licensing Type:</label>
                      <div className="pg1-prt-2-prt-otrfld">
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Cultivation")}
                            value="Cultivation"
                            checked={
                              Legal_Business.licensing_Type === "Cultivation"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Cultivation</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Manufacturer")}
                            value="Manufacturer"
                            checked={
                              Legal_Business.licensing_Type === "Manufacturer"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Manufacturer</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Distribution")}
                            value="Distribution"
                            checked={
                              Legal_Business.licensing_Type === "Distribution"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Distribution</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Testing Lab")}
                            value="Testing Lab"
                            checked={
                              Legal_Business.licensing_Type === "Testing Lab"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Testing Lab</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Dispensary")}
                            value="Dispensary"
                            checked={
                              Legal_Business.licensing_Type === "Dispensary"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Dispensary</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Delivery")}
                            value="Delivery"
                            checked={
                              Legal_Business.licensing_Type === "Delivery"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Delivery</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Other")}
                            value="Other"
                            checked={Legal_Business.licensing_Type === "Other"}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Other</label>
                        </div>
                      </div>
                    </div> */}
                    <div className="pg3-mn-otrfld">
                      <div className="check__in__bx">
                        <h6>
                          Licensing Type:<span className="red-star">*</span>
                        </h6>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Cultivation")}
                            value="Cultivation"
                            checked={Legal_Business.licensing_Type.includes(
                              "Cultivation"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Cultivation</label>
                          {Legal_Business.licensing_Type.includes(
                            "Cultivation"
                          ) && (
                            <>
                              {" "}
                              <input
                                name="Cultivation"
                                value={LicenseNumber?.Cultivation}
                                onChange={LicenseNumberHandler}
                              />
                              {/* <CurrencyInput
                                placeholder="$0.00"
                                maxLength={9}
                                onFocus={() => setFocus("Cultivation")}
                                value={LicenseNumber?.Cultivation}
                                type="text"
                                name="Cultivation"
                                onChange={LicenseNumberHandler}
                              /> */}
                              <span className="red-star">*</span>
                            </>
                          )}

                          {errorState?.Cultivation && (
                            <p style={{ color: "red" }}>
                              {errorState.Cultivation}
                            </p>
                          )}
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Manufacturer")}
                            value="Manufacturer"
                            checked={Legal_Business.licensing_Type.includes(
                              "Manufacturer"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Manufacturer</label>
                          {Legal_Business.licensing_Type.includes(
                            "Manufacturer"
                          ) && (
                            <>
                              <input
                                name="Manufacturer"
                                value={LicenseNumber?.Manufacturer}
                                onChange={LicenseNumberHandler}
                              />

                              {/* <CurrencyInput
                                placeholder="$0.00"
                                maxLength={9}
                                onFocus={() => setFocus("Manufacturer")}
                                value={LicenseNumber?.Manufacturer}
                                type="text"
                                name="Manufacturer"
                                onChange={LicenseNumberHandler}
                              /> */}
                              <span className="red-star">*</span>
                            </>
                          )}

                          {errorState?.Manufacturer &&
                            focus !== "Manufacturer" && (
                              <p style={{ color: "red" }}>
                                {errorState.Manufacturer}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Distribution")}
                            value="Distribution"
                            checked={Legal_Business.licensing_Type.includes(
                              "Distribution"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Distribution</label>
                          {Legal_Business.licensing_Type.includes(
                            "Distribution"
                          ) && (
                            <>
                              <input
                                name="Distribution"
                                value={LicenseNumber?.Distribution}
                                onChange={LicenseNumberHandler}
                              />
                              <span className="red-star">*</span>
                            </>
                          )}
                          {errorState?.Distribution &&
                            focus !== "Distribution" && (
                              <p style={{ color: "red" }}>
                                {errorState.Distribution}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Testing Lab")}
                            value="Testing Lab"
                            checked={Legal_Business.licensing_Type.includes(
                              "Testing Lab"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Testing Lab</label>
                          {Legal_Business.licensing_Type.includes(
                            "Testing Lab"
                          ) && (
                            <>
                              {" "}
                              <input
                                name="Testing Lab"
                                value={
                                  LicenseNumber &&
                                  LicenseNumber["Testing Lab"] &&
                                  LicenseNumber["Testing Lab"]
                                }
                                onChange={LicenseNumberHandler}
                              />
                              {/* <CurrencyInput
                                placeholder="$0.00"
                                maxLength={9}
                                value={
                                  LicenseNumber &&
                                  LicenseNumber["Testing Lab"] &&
                                  LicenseNumber["Testing Lab"]
                                }
                                type="text"
                                name="Testing Lab"
                                onChange={LicenseNumberHandler}
                              /> */}
                              <span className="red-star">*</span>
                            </>
                          )}
                          {errorState &&
                            errorState["Testing Lab"] &&
                            focus !== "Testing Lab" && (
                              <p style={{ color: "red" }}>
                                {errorState["Testing Lab"]}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("ACH")}
                            checked={Legal_Business.licensing_Type?.includes(
                              "ACH"
                            )}
                            onChange={LegalBusinessHandler}
                            value="ACH"
                          />
                          <label>ACH</label>
                          {Legal_Business.licensing_Type?.includes("ACH") && (
                            <>
                              <input
                                name="ACH"
                                value={LicenseNumber?.ACH}
                                onChange={LicenseNumberHandler}
                              />
                              <span className="red-star">*</span>
                            </>
                          )}
                          {errorState?.ACH && focus !== "ACH" && (
                            <p style={{ color: "red" }}>{errorState?.ACH}</p>
                          )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Dispensary")}
                            value="Dispensary"
                            checked={Legal_Business.licensing_Type?.includes(
                              "Dispensary"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Dispensary</label>
                          {Legal_Business.licensing_Type?.includes(
                            "Dispensary"
                          ) && (
                            <>
                              <input
                                name="Dispensary"
                                value={LicenseNumber?.Dispensary}
                                onChange={LicenseNumberHandler}
                              />
                              <span className="red-star">*</span>
                            </>
                          )}
                          {errorState?.Dispensary && focus !== "Dispensary" && (
                            <p style={{ color: "red" }}>
                              {errorState?.Dispensary}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Delivery")}
                            value="Delivery"
                            checked={Legal_Business.licensing_Type?.includes(
                              "Delivery"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Delivery</label>
                          {Legal_Business.licensing_Type?.includes(
                            "Delivery"
                          ) && (
                            <>
                              {" "}
                              <input
                                name="Delivery"
                                value={LicenseNumber?.Delivery}
                                onChange={LicenseNumberHandler}
                              />
                              <span className="red-star">*</span>
                            </>
                          )}
                          {errorState?.Delivery && focus !== "Delivery" && (
                            <p style={{ color: "red" }}>
                              {errorState?.Delivery}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            onFocus={() => setFocus("Other")}
                            value="Other"
                            checked={Legal_Business.licensing_Type?.includes(
                              "Other"
                            )}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Other</label>
                          {Legal_Business.licensing_Type?.includes("Other") && (
                            <div>
                              <input
                                placeholder="Type licensing type.."
                                name="other"
                                value={Legal_Business?.other}
                                onChange={LegalBusinessHandler}
                                // onChange={LicenseNumberHandler}
                              />
                              <span className="red-star">*</span>
                              <br />
                              <br />
                              <>
                                <input
                                  name="Other"
                                  value={LicenseNumber?.Other}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                              {errorState?.Other && focus !== "Other" && (
                                <p style={{ color: "red" }}>
                                  {errorState?.Other}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pg1-prt-2-inrfld nd3">
                      <div className="pg1-prt-2-lwr-otrfld">
                        {Legal_Business.licensing_Type === "Other" && (
                          <div className="pg1-prt-2-lwr-inrfld">
                            <label>Other:</label>
                            <input
                              type="text"
                              onFocus={() => setFocus("other")}
                              className="form-control"
                              name="other"
                              value={Legal_Business.other}
                              onChange={LegalBusinessHandler}
                            />
                            {errorState?.other && focus !== "other" && (
                              <p style={{ color: "red" }}>{errorState.other}</p>
                            )}
                          </div>
                        )}
                        <div className="pg1-prt-2-lwr-inrfld">
                          <label>
                            No of Employees:<span className="red-star">*</span>
                          </label>
                          <NumberFormat
                            style={{ width: "14rem", height: "5rem" }}
                            className="form-control"
                            maxLength={12}
                            onFocus={() => setFocus("num_employees")}
                            value={Legal_Business.num_employees}
                            // format={"$##,###,###,###"}
                            // 78,979,797,979,797
                            thousandSeparator={","}
                            // decimalSeparator={"."}
                            // decimalScale={2}
                            onChange={LegalBusinessHandler}
                            name="num_employees"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.num_employees &&
                            focus !== "num_employees" && (
                              <p style={{ color: "red" }}>
                                {errors.num_employees}
                              </p>
                            )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1b">
                          <label>
                            List All States of Operation (State that the
                            business grows, processes, or distributes
                            marijuana-related products):
                            <span className="red-star">*</span>
                          </label>
                          <CreatableSelect
                            onFocus={() => setFocus("CreatableSelect")}
                            name="accepted_states"
                            defaultValue={Legal_Business?.accepted_states?.map(
                              (i) => {
                                return {
                                  value: i,
                                  label: i,
                                  color: "#0052CC",
                                };
                              }
                            )}
                            isMulti
                            options={StateOptions}
                            onChange={(val) => {
                              console.log(val, "states");
                              const data = val.map((item) => item.value);
                              //  setAcceptedStates([...data])

                              setLegal_Business({
                                ...Legal_Business,
                                accepted_states: data,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        Does your business have any foreign operations?
                      </label>
                      <div className="switch_box">
                        <h4>no</h4>
                        <label className="switch2">
                          <input
                            type="checkbox"
                            name="foreign_operations"
                            onFocus={() => setFocus("foreign_operations")}
                            checked={
                              Legal_Business.foreign_operations === "yes"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <span className="slider round" />
                        </label>
                        <h4>yes</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-3">
              <div className="pg1-prt-3-txt">
                <div className="cmn_hdr">
                  <h6>Primary Contact Information:</h6>
                  <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Primary Contact Name:<span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        name="primary_contact_name"
                        onFocus={() => setFocus("primary_contact_name")}
                        value={Primary_Information.primary_contact_name}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      />
                      {errorState?.primary_contact_name && (
                        <p style={{ color: "red" }}>
                          {errorState.primary_contact_name}
                        </p>
                      )}
                    </div>
                    {/* <div className="pg1-prt-2-inrfld">
                    <label>Date of Birth:</label>
                    <input type="text" name="dob"
                      value={Primary_Information.dob}
                      onChange={PrimaryInformationHandler} className="form-control" />
                    {
                      errorState?.dob && <p style={{ color: 'red' }}>{errorState?.dob}</p>

                    }
                  </div> */}
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Date of Birth:<span className="red-star">*</span>
                      </label>
                      <input
                        type="date"
                        name="dob"
                        onFocus={() => setFocus("dob")}
                        value={Primary_Information.dob}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      />
                      {/* <DatePicker className="form-control" style={{width:'20rem'}}
                     dateFormat="MM-dd-yyyy"
                     selected={startDate}
                     onChange={selectDateHandler}
                     minDate={today}
                     todayButton={"Today"}
                     /> */}
                      {errorState?.dob && (
                        <p style={{ color: "red" }}>{errorState?.dob}</p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Address:<span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        id="ui-tar"
                        onFocus={() => {
                          setFocus("ui-tar");
                        }}
                        name="primary_address"
                        style={{
                          height: "10rem",
                          width: "30rem",
                          paddingTop: "0px",
                        }}
                        value={Primary_Information.primary_address}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      />
                      {errorState?.primary_address && (
                        <p style={{ color: "red" }}>
                          {errorState.primary_address}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        E-mail:<span className="red-star">*</span>
                      </label>
                      <input
                        type="email"
                        name="mail"
                        id="e-mail"
                        onFocus={() => setFocus("e-mail")}
                        style={{ width: "30rem" }}
                        value={Primary_Information.mail}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      />
                      {errorState?.mail && (
                        <p style={{ color: "red" }}>{errorState.mail}</p>
                      )}
                    </div>
                    <div className="pg1-prt-2-inrfld">
                      <label>
                        Primary Phone No.<span className="red-star">*</span>
                      </label>
                      {/* <input type="tel" name="primary_Phone"
                      value={Primary_Information.primary_Phone}
                      onChange={PrimaryInformationHandler} className="form-control" />
                    <br /><br /> */}
                      <NumberFormat
                        value={Primary_Information.primary_Phone}
                        className="form-control"
                        format={"(###) ###-####"}
                        // mask="*"
                        type="text"
                        onChange={PrimaryInformationHandler}
                        name="primary_Phone"
                      />
                      {/* <input
                        type="text"
                        name="primary_Phone"
                        onFocus={() => setFocus("primary_Phone")}
                        value={Primary_Information.primary_Phone}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      /> */}
                      {errorState?.primary_Phone && (
                        <p style={{ color: "red" }}>
                          {errorState.primary_Phone}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                    <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                      <label>Ownership in business applying for account?</label>
                      <div className="switch_box">
                        <h4>no</h4>
                        <label className="switch2">
                          <input
                            type="checkbox"
                            name="ownership"
                            onFocus={() => setFocus("ownership")}
                            checked={Primary_Information.ownership === "yes"}
                            onChange={PrimaryInformationHandler}
                          />
                          <span className="slider round" />
                        </label>
                        <h4>yes</h4>
                      </div>
                    </div>
                    {Primary_Information.ownership === "yes" && (
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Ownership Percentage
                          <span className="red-star">*</span>
                        </label>
                        <input
                          type="number"
                          name="ownership_Percentage"
                          onFocus={() => setFocus("ownership_Percentage")}
                          onInput={(e) => {
                            if (e.target.value.length > 2) {
                              e.target.value = e.target.value.slice(0, 2);
                            }
                          }}
                          value={Primary_Information.ownership_Percentage}
                          onChange={PrimaryInformationHandler}
                          className="form-control"
                        />
                        <h6
                          style={{
                            float: "right",
                            marginRight: "30rem",
                            width: "20px",
                            marginTop: "1rem",
                          }}
                        >
                          %
                        </h6>
                        {errorState?.ownership_Percentage && (
                          <p style={{ color: "red" }}>
                            {errorState.ownership_Percentage}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="pg1-prt-2-inrfld nd2">
                      <label>U.S. Citizenship?</label>
                      <div className="pg1-prt-2-prt-otrfld">
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="us_Citizenship"
                            value="yes"
                            onFocus={() => setFocus("us_CitizenshipYes")}
                            checked={
                              Primary_Information.us_Citizenship === "yes"
                            }
                            onChange={PrimaryInformationHandler}
                          />
                          <label>Yes</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="us_Citizenship"
                            value="no"
                            onFocus={() => setFocus("us_CitizenshipNo")}
                            checked={
                              Primary_Information.us_Citizenship === "no"
                            }
                            onChange={PrimaryInformationHandler}
                          />
                          <label>No</label>
                        </div>

                        {/* {Primary_Information.us_Citizenship === "no" && (
                          <p style={{ color: "red" }}>
                            Continue filling up the form and contact GBS admin
                            later.
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                      <label>
                        Authority to sign on behalf of the business?
                      </label>
                      <div className="switch_box">
                        <h4>no</h4>
                        <label className="switch2">
                          <input
                            type="checkbox"
                            name="authority_sign"
                            onFocus={() => setFocus("authority_sign")}
                            checked={
                              Primary_Information.authority_sign === "yes"
                            }
                            onChange={PrimaryInformationHandler}
                          />
                          <span className="slider round" />
                        </label>
                        <h4>yes</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="button-container">
          {/* <button className='btn btn-prev' onClick={PreviousHandler}>Previous</button> */}
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormOne;
