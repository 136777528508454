import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Bank_ProfileUpdate,
  DeleteBankSetup_URL,
  Fill_BusinessSetUp_URL,
  GetBank_Profile,
  GetBank_SetUp_URL,
  GetOperator_Profile,
  Operator_ProfileUpdate,
  UpdateBankSetup_URL,
} from "../Api/Api";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader } from "react-spinners";
import { BsFillTrashFill } from "react-icons/bs";
import { BsPencilSquare } from "react-icons/bs";
import { type } from "@testing-library/user-event/dist/type";
import BusinessSetupDetails from "../Components/BankMainPages/BusinessSetupDetails";

const ProfilePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionName, setSectionName] = useState("Profile Details");
  const [toggle, setToggle] = useState(1);
  const [errorState, setErrorState] = useState("");

  const [names, setNames] = useState({});
  const [targetVal, setTargetValue] = useState({});
  const [types, setTypes] = useState({});
  const [services, setServices] = useState([
    {
      service: "Business Checking",
      price: "",
      type: "onetime",
    },
    {
      service: "Interest Bearing Accounts",
      price: "",
      type: "onetime",
    },
    {
      service: "Debit Cards",
      price: "",
      type: "onetime",
    },
    {
      service: "ACH",
      price: "",
      type: "onetime",
    },
    {
      service: "Wires",
      price: "",
      type: "onetime",
    },
    {
      service: "Discount for Social Equity",
      price: "",
      type: "onetime",
    },
    {
      service: "Accounts For Employees",
      price: "",
      type: "onetime",
    },
    {
      service: "Cash Pickup Services",
      price: "",
      type: "onetime",
    },
    {
      service: "Legacy Cash",
      price: "",
      type: "onetime",
    },
  ]);
  const [newEntry, setNewEntry] = useState("");
  const [api, setapi] = useState(false);
  const [ind, setInd] = useState("");
  const [modifiedServices, setModifiedServices] = useState([]);
  const [businessSetUp, setBusinessSetUp] = useState({
    servicesOffered: [],
  });
  const [profile, setProfile] = useState({
    // bankName: "",
    // bankState: "",
    // bankCity: "",
    // bankAddress: "",
    // bankType: "",
    // bankLogo: "",
    // email: "",
    // bankContactNumber: ""
  });
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(GetBank_SetUp_URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data.servicesOffered, "setups");
        // setServices(res.data.servicesOffered)
        setIsLoading(false);
        setModifiedServices(res.data.servicesOffered);
      })
      .catch((err) => console.log(err, "error"));
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("role") === "bank") {
      axios
        .get(GetBank_Profile, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          setIsLoading(false);

          console.log(res.data, "profiledata");
          setProfile(res.data);
        })
        .catch((err) => console.log(err, "error"));
    } else {
      axios
        .get(GetOperator_Profile, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          setIsLoading(false);
          console.log(res.data, "profiledata");
          setProfile(res.data);
        })
        .catch((err) => console.log(err, "error"));
    }
  }, [api]);
  // const ServiceChangeHandler = (event, index) => {
  //   console.log(index, 'indexx')
  //   if (event.target.checked) {
  //     const data = [...modifiedServices, { service: services[index].service, price: services[index].price }]

  //     setModifiedServices([...data])
  //   }
  //   else {
  //     const data = modifiedServices.filter(service => service.service !== event.target.value)
  //     setModifiedServices([...data])
  //   }
  // }
  const DataChangeHandler = (event, index) => {
    console.log(index, "asd");
    const data = [...services];
    data[index][event.target.name] = event.target.value;
    setServices(data);
  };
  const BankProfileChangeHandler = (event) => {
    if (event.target.type === "file") {
      console.log(URL.createObjectURL(event.target.files[0]), "900");
      setProfile({
        ...profile,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setProfile({
        ...profile,
        [event.target.name]: event.target.value,
      });
    }
  };
  const OperatorProfileChangeHandler = (event) => {
    if (event.target.type === "file") {
      console.log(URL.createObjectURL(event.target.files[0]), "900");
      setProfile({
        ...profile,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setProfile({
        ...profile,
        [event.target.name]: event.target.value,
      });
    }
  };
  console.log(profile.bankLogo, "1000");
  const BankUpdateProfileInformation = () => {
    console.log(profile, "updated");
    const formdata = new FormData();
    for (const key in profile) {
      if (key === "bankLogo") {
        if (typeof profile[key] === "object") {
          formdata.append(key, profile[key]);
        }
      } else {
        if (key !== "servicesOffered") {
          formdata.append(key, profile[key]);
        }
      }
    }
    axios
      .put(Bank_ProfileUpdate, formdata, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "afterupdate");
        Swal.fire("Done!", `${res.data.message}`, "success");
        // window.location.reload()
        setapi(!api);
      })
      .catch((err) => console.log(err, "error"));
  };
  const OperatorUpdateProfileInformation = (event) => {
    event.preventDefault();
    console.log(profile, "updated");
    // const formdata = new FormData()
    // for(const key in profile){
    //  if( key === 'bankLogo'){
    //     if(typeof profile[key] === 'object'){
    //       formdata.append(key,profile[key])
    //     }
    //  }else{
    //   formdata.append(key,profile[key])
    //  }
    // }
    axios
      .put(Operator_ProfileUpdate, profile, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "afterupdate");
        Swal.fire("Done!", `${res.data.message}`, "success");
        window.location.reload();
      });
  };
  const UpdateBankSetupData = () => {
    swal({
      title: "Are you sure?",
      buttons: {
        confirm: "YES",
        cancel: "NO",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        setIsLoading(true);

        axios
          .put(
            UpdateBankSetup_URL,
            {
              servicesOffered: services,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data, "afterupdate");
            setServices(res.data.servicesOffered);
            setInd("");
            setIsLoading(false);
            swal("Updated!", `${res.data.message}`, "success");
          });
      }
    });
  };
  const RemoveSetUp = (index) => {
    swal({
      title: "Are you sure?",
      buttons: {
        confirm: "YES",
        cancel: "NO",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        setIsLoading(true);

        axios
          .delete(`${DeleteBankSetup_URL}/${index}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res.data, "afterupdate");
            setServices(res.data.servicesOffered);
            setInd("");
            setIsLoading(false);
            swal("Updated!", `${res.data.message}`, "success");
          })
          .catch((err) => console.log(err, "error"));
      }
    });
  };
  const SaveNewSetUp = () => {
    swal({
      title: "Are you sure?",
      buttons: {
        confirm: "YES",
        cancel: "NO",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        setIsLoading(true);

        axios
          .post(
            Fill_BusinessSetUp_URL,
            {
              servicesOffered: services,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data, "aftersave");
            setServices(res.data.servicesOffered);
            setInd("");
            setNewEntry("");
            setIsLoading(false);
            swal("Saved!", "Business Setup added succesfully!", "success");
          })
          .catch((err) => console.log(err, "error"));
      }
    });
  };
  useEffect(() => {
    if (isLoading) {
      return (
        <div
          className="mainsection bnk_sctn bank_content"
          style={{ fontWeight: "bold", marginLeft: "48%", fontSize: "3rem" }}
        >
          <FadeLoader color={"#b07f35"} />
        </div>
      );
    }
  }, []);
  useEffect(() => {
    console.log(targetVal, "target455");
  }, [targetVal]);
  const BusinessSetUpDataChangeHandler = (event, i) => {
    console.log(event.target.value, i, "seethis");

    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        const data = [
          ...modifiedServices,
          {
            service: event.target.value,
          },
        ];
        setModifiedServices([...data]);
      } else {
        const data = modifiedServices.filter(
          (x) => x.service !== event.target.value
        );
        setModifiedServices([...data]);
      }
    } else {
      console.log(modifiedServices, "target");
      // setBusinessSetUp(businessSetUp)
    }
  };
  const PriceChangeHandler = (event) => {
    setNames({ ...names, [event.target.name]: event.target.value });
  };
  const TypeChangehandler = (event, name) => {
    console.log(event.target.value, name, "0000012");
    setTypes({ ...types, [event.target.name]: event.target.value });
  };
  const ValueDeterminant = (val) => {
    console.log(val, "opop");
    const myArr = modifiedServices.map((i) => i.service);
    return modifiedServices[myArr.indexOf(val)]?.price;
  };
  useEffect(() => {
    console.log(types, "inprrr");
  }, [types]);
  return (
    <>
      {localStorage.getItem("role") === "bank" && (
        <div className="mainsection bnk_sctn">
          <div className="container rounded bg-white mt-5 mb-5">
            <br />
            <div className="tabs">
              <div
                className={`${toggle === 1 ? "tab active-tab" : "tab"}`}
                onClick={() => {
                  setToggle(1);
                  setSectionName("Profile Details");
                }}
              >
                Profile Details
              </div>
              <div
                className={`${toggle === 2 ? "tab active-tab" : "tab"}`}
                onClick={() => {
                  setToggle(2);
                  setSectionName("Business Set-up Details");
                }}
              >
                Business Set-up Details
              </div>
            </div>
            {sectionName === "Profile Details" && (
              <>
                {!isLoading ? (
                  <div className="bnk_profile_page">
                    <div className="bpp_upper">
                      <div className="cmn_hdr">
                        <h2>Account Settings</h2>
                      </div>
                      <div className="profile-pic">
                        <label className="-label" htmlFor="file">
                          <span className="glyphicon glyphicon-camera" />
                          <span>Change Image</span>
                        </label>
                        <input
                          id="file"
                          type="file"
                          onChange={BankProfileChangeHandler}
                          name="bankLogo"
                        />
                        <img
                          // src="https://scontent.fccu25-1.fna.fbcdn.net/v/t39.30808-6/291453188_402270755251555_7487858768217886587_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=hHGPfswXk24AX_Fphor&_nc_ht=scontent.fccu25-1.fna&oh=00_AfAhg6MYwr2PwzyLMHTLHeOJJFY3MszZcNctRFKCyINo_A&oe=65800CAE"
                          src={profile.bankLogo}
                          id="output"
                          width={200}
                        />
                      </div>
                    </div>
                    <div className="bank_profile_from">
                      <div className="form__group field">
                        <input
                          type="text"
                          className="form__field"
                          placeholder=" Bank Name"
                          name="bankName"
                          required=""
                          onChange={BankProfileChangeHandler}
                          value={profile.bankName}
                        />
                        <label htmlFor=" Bank Name" className="form__label">
                          {" "}
                          Bank Name
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="Email"
                          className="form__field"
                          placeholder=" Email"
                          name="email"
                          required=""
                          onChange={BankProfileChangeHandler}
                          value={profile.email}
                        />
                        <label htmlFor=" Email" className="form__label">
                          {" "}
                          Email
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="tel"
                          className="form__field"
                          placeholder=" Bank Contact Number"
                          required=""
                          name="bankContactNumber"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankContactNumber}
                        />
                        <label
                          htmlFor=" Bank Contact Number"
                          className="form__label"
                        >
                          Bank Contact Number
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          className="form__field"
                          placeholder=" Bank State"
                          required=""
                          name="bankState"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankState}
                        />
                        <label htmlFor=" Bank State" className="form__label">
                          {" "}
                          Bank State
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          className="form__field"
                          placeholder="  Bank City"
                          required=""
                          name="bankCity"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankCity}
                        />
                        <label htmlFor="  Bank City" className="form__label">
                          {" "}
                          Bank City
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="number"
                          className="form__field"
                          placeholder="  Bank Zip"
                          required=""
                          name="bankZip"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankZip}
                        />
                        <label htmlFor="  Bank Zip" className="form__label">
                          {" "}
                          Bank Zip
                        </label>
                      </div>
                      <div className="form__group field">
                        <textarea
                          id="BankAddress"
                          className="form__field"
                          placeholder="Bank Address"
                          defaultValue={""}
                          name="bankAddress"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankAddress}
                        />
                        <label htmlFor="BankAddress" className="form__label">
                          {" "}
                          Bank Address
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          className="form__field"
                          placeholder="  Bank Type"
                          required=""
                          name="bankType"
                          onChange={BankProfileChangeHandler}
                          value={profile.bankType}
                        />
                        <label htmlFor="  Bank Type" className="form__label">
                          {" "}
                          Bank Type
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="url"
                          className="form__field"
                          placeholder="Website"
                          required=""
                          name="website"
                          onChange={BankProfileChangeHandler}
                          value={profile.website}
                        />
                        <label htmlFor="Website" className="form__label">
                          {" "}
                          Website
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          disabled
                          className="form__field"
                          placeholder="Account Created At"
                          required=""
                          value={profile?.paymentStage?.stage}
                        />
                        <label
                          htmlFor="Account Created At"
                          className="form__label"
                        >
                          Payement Stage
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          disabled
                          className="form__field"
                          placeholder="Account Created At"
                          required=""
                          value={profile?.paymentStage?.price}
                        />
                        <label
                          htmlFor="Account Created At"
                          className="form__label"
                        >
                          Payement Price
                        </label>
                      </div>
                      <div className="form__group field">
                        <input
                          type="text"
                          disabled
                          className="form__field"
                          placeholder="Account Created At"
                          required=""
                          name="createdAt"
                          onChange={BankProfileChangeHandler}
                          value={profile.createdAt}
                        />
                        <label
                          htmlFor="Account Created At"
                          className="form__label"
                        >
                          Account Created At
                        </label>
                      </div>
                      <div className="submit_btn button-container">
                        <input
                          type="button"
                          className="btn"
                          defaultValue="save"
                          onClick={BankUpdateProfileInformation}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="mainsection bnk_sctn bank_content"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "42%",
                      fontSize: "3rem",
                    }}
                  >
                    <FadeLoader color={"#b07f35"} />
                  </div>
                )}
              </>
            )}
            {sectionName === "Business Set-up Details" && (
              //    <div className="">
              //     <div className="content" style={{ borderRadius: '20px' }}>
              //       <h4>Business Set-Up</h4>
              //       <div className="">
              //         <div className="">
              //           {/* <div className="cmn_hdr">

              //             <div className='business_box'><h6>Services Offered:</h6>
              //               <div className="pg3-mn-otrfld">
              //                 <table>
              //                   <tbody>
              //                     <tr>
              //                       <th>SL. No.</th>
              //                       <th>Service Name</th>
              //                       <th>Price</th>
              //                       <th>Actions</th>
              //                     </tr>
              //                     {
              //                       services && services.map((service, index) => {
              //                         return <tr>
              //                           <td>{index + 1}</td>
              //                           <td> {ind === index ? <input type='text' className="form-control"
              //                             name='service'
              //                             onChange={(event) => { DataChangeHandler(event, index) }}

              //                             value={service.service} /> : <>{service.service}</>}</td>
              //                           <td>
              //                             {
              //                               ind === index ? <input type='number' onChange={(event) => { DataChangeHandler(event, index) }} name='price' value={service.price} /> : <>{service.price}</>
              //                             }
              //                           </td>
              //                           <td>
              //                             {newEntry !== index && ind !== index && <button className='profile-page-icons'

              //                               onClick={() => { setInd(index) }}
              //                             ><BsPencilSquare/></button>}
              //                             {!newEntry && ind === index && <button style={{ marginRight: '10px' }}
              //                             className='notification_btn'
              //                               onClick={UpdateBankSetupData}
              //                             >Update</button>}
              //                             {newEntry !== index  && ind !== index &&<button className='profile-page-icons'  onClick={()=>{RemoveSetUp(index)}}><BsFillTrashFill/></button>}
              //                             {
              //                               newEntry === index && ind === index && <button
              //                               className='notification_btn' onClick={SaveNewSetUp}
              //                               >Save</button>
              //                             }
              //                           </td>
              //                         </tr>
              //                       })
              //                     }

              //                   </tbody>
              //                 </table>

              //               </div>
              //             </div>
              //             <br />

              //           </div> */}
              //           <div className="cmn_hdr">
              //       {/* <h6>Company Documentation</h6> */}
              //       <div className='business_box'><h6>Services Offered:</h6>
              //         <div className="pg3-mn-otrfld">

              //         <table>
              //         <tbody>
              //                  <tr>
              //                  <th>Service</th>
              //                   <th>Price</th>
              //                   <th>Select type</th>
              //                  </tr>
              //                 </tbody>
              //             {
              //               services.map((service,index)=>{
              //                 console.log(service,'pricesee')
              //                 return (
              //               //   <div className='check__in__bx' >
              //               //   <div className="pg3-mn-inrfld" >
              //               //     <input type="checkbox" name="pg-bx" defaultValue={service.service}
              //               //       checked={modifiedServices.map(x=>x.service).includes(service.service)}
              //               //       onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
              //               //     />
              //               //     <label>{service.service}</label>

              //               //   </div>
              //               //   {
              //               //     modifiedServices.map(x=>x.service).includes(service.service) && <div className='price_box' >
              //               //       <h5>Price</h5>
              //               //       <div className="" >
              //               //         <input type="number"
              //               //       defaultValue={ValueDeterminant(service.service)}
              //               //         placeholder="price" required name={service.service} onChange={(event)=>{PriceChangeHandler(event)}}
              //               //         />
              //               //       </div>
              //               //       <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               //     </div>
              //               //   }
              //               // </div>

              //                 <tr>
              //                   <td>  <div className="pg3-mn-inrfld" >
              //                    <input type="checkbox" name="pg-bx" defaultValue={service.service}
              //                    checked={modifiedServices.map(x=>x.service).includes(service.service)}
              //                     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
              //                   />
              //                   <label>{service.service}</label>

              //                </div></td>

              //                    <td><div className='price_box' >
              //                    {/* <h5>Price</h5> */}
              //                   <div className="" >
              //                     <input type="number"
              //                   defaultValue={ValueDeterminant(service.service)}
              //                   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
              //                      placeholder="price" required name={service.service} onChange={(event)=>{PriceChangeHandler(event)}}
              //                      />
              //                    </div>
              //                    <h3 style={{ color: 'red' }}>{errorState}</h3>
              //                  </div></td>
              //                  <td>
              //                   <div className="form-input-group">

              //   <div className="">
              //     <div className="form_radio_group">
              //       <input type="radio" defaultValue="" id="Per"
              //         // checked={Cash_Management_Bank.vendor_payment_methods === 'ACH'}
              //         name={service.service}
              //         value='Per'
              //          onChange={(event)=>{TypeChangehandler(event,service.service)}}
              //       />
              //       {/* <span class="radio"></span>  */}
              //       <label htmlFor="Per" className="radio-button">
              //         {" "}
              //        Per
              //       </label>
              //       <br/><br/>
              //       {
              //         types[service.service] === 'Per' && <input
              //         style={{width:'15rem',height:'4rem'}}
              //         // placeholder='year/week/month'
              //         className='form-control' onChange={(event)=>{
              //           setTargetValue({
              //             ...targetVal,[service.service] : event.target.value
              //           })
              //         }}/>
              //       }
              //     </div>
              //     <div className="form_radio_group">
              //       <input type="radio" defaultValue="" id="onetime"
              //         // checked={modifiedServices.map(x=>x.type).includes(service.type)}
              //          defaultChecked
              //         name={service.service}
              //         value='onetime'
              //         onChange={(event)=>{TypeChangehandler(event,service.service)}}
              //          />
              //       {/* <span class="radio"></span> */}
              //       <label htmlFor="onetime" className="radio-button">
              //         {" "}
              //         Onetime
              //       </label>
              //     </div>

              //   </div>
              // </div>

              // </td>
              //                 </tr>

              //               )
              //               })
              //             }

              //           </table>
              //           {/* <div className='check__in__bx'>
              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //                 checked={modifiedServices.map(x=>x.service).includes('Interest Bearing Accounts')}
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,1)}}
              //                 value='Interest Bearing Accounts' />
              //               <label>Interest Bearing Accounts</label>

              //             </div>
              //             {
              //              modifiedServices.map(x=>x.service).includes('Interest Bearing Accounts') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices[1]?.price}
              //                   placeholder="price" required name='Interest Bearing Accounts' onChange={(event)=>{PriceChangeHandler(event,1)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div>
              //           <div className='check__in__bx'>
              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //               checked={modifiedServices.map(x=>x.service).includes('Debit Cards')}
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,2)}}
              //                 value='Debit Cards'
              //               />
              //               <label>
              //                 Debit Cards
              //               </label>

              //             </div>
              //             {
              //               modifiedServices.map(x=>x.service).includes('Debit Cards') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices[2]?.price}
              //                   placeholder="price" required name='Debit Cards' onChange={(event)=>{PriceChangeHandler(event,2)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div>
              //           <div className='check__in__bx'>
              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //                checked={modifiedServices.map(x=>x.service).includes('ACH')}
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,3)}}
              //                 value='ACH'
              //               />
              //               <label>ACH</label>

              //             </div>
              //             {
              //               modifiedServices.map(x=>x.service).includes('ACH') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices.filter(i=> i.service === 'ACH')[0].price}
              //                   placeholder="ACH" required name='ACH' onChange={(event)=>{PriceChangeHandler(event,3)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div>
              //           <div className='check__in__bx'>
              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //                checked={modifiedServices.map(x=>x.service).includes('Wires') }
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,4)}}
              //                 value='Wires'
              //               />
              //               <label>
              //                 Wires
              //               </label>

              //             </div>
              //             {
              //               modifiedServices.map(x=>x.service).includes('Wires') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices[4]?.price}
              //                   placeholder="price" required name='Wires' onChange={(event)=>{PriceChangeHandler(event,4)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div>
              //           <div className='check__in__bx'>

              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //               checked={modifiedServices.map(x=>x.service).includes('Discount for Social Equity')}
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,5)}}
              //                 value='Discount for Social Equity'
              //               />
              //               <label>
              //                 Discount for Social Equity
              //               </label>

              //             </div>
              //             {
              //               modifiedServices.map(x=>x.service).includes('Discount for Social Equity') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices[5]?.price}
              //                   placeholder="price" required name='Discount for Social Equity'
              //               onChange={(event)=>{PriceChangeHandler(event,5)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div>
              //           <div className='check__in__bx'>
              //             <div className="pg3-mn-inrfld">
              //               <input type="checkbox" name="pg-bx"
              //               checked={modifiedServices.map(x=>x.service).includes('Accounts for Employees')}
              //                 onChange={(event)=>{BusinessSetUpDataChangeHandler(event,6)}}
              //                 value='Accounts for Employees'
              //               />
              //               <label>
              //                 Accounts for Employees
              //               </label>

              //             </div>
              //             {
              //               modifiedServices.map(x=>x.service).includes('Accounts for Employees') && <div className='price_box'>
              //                 <h5>Price</h5>
              //                 <div className="" style={{}}>
              //                   <input type="number"
              //                   value={modifiedServices[6]?.price}
              //                   placeholder="Accounts for Employees" required name='Accounts for Employees'
              //                   onChange={(event)=>{PriceChangeHandler(event,6)}}
              //                   />
              //                 </div>
              //                 <h3 style={{ color: 'red' }}>{errorState}</h3>
              //               </div>
              //             }
              //           </div> */}
              //         </div>
              //       </div>
              //       <br />
              //       {/* <div className='business_box'>
              //         <h6>Other</h6>
              //         <div className="inpt_bx">
              //           <input type="text" placeholder="other" required name='other' onChange={BusinessSetUpDataChangeHandler}
              //           />
              //         </div>
              //         <h3 style={{ color: 'red' }}>{errorState}</h3>
              //       </div> */}
              //     </div>
              //         </div>

              //       </div>
              //       <div className="footer">
              //         {/* <button  className='notification_btn' style={{marginLeft:"40%"}}
              //           onClick={() => {
              //             setInd(services.length)
              //             setNewEntry(services.length)
              //             setServices([...services, {}])
              //           }}
              //         >Add New</button> */}
              //         <button className='notification_btn'
              //           onClick={() => {

              //             const finalData = modifiedServices.map((a)=>{
              //               for(let x in names){
              //                 if(a.service === x){
              //                   a.price = names[x]
              //                 }
              //               }
              //                return a;
              //             })

              //             console.log(finalData, 'finalData')
              //             console.log(types,'finalData')
              //             console.log(targetVal,'finalData')
              //             const keys = Object.keys(targetVal)
              //             console.log(keys,'finalData')
              //             const test = finalData.map((c,index)=>{
              //                   if(keys.includes(c.service)){
              //                     let i = keys.indexOf(c.service)

              //                     //  c.type =  `${types[keys[index]]} + ${targetVal[keys[index]]}`
              //                     if(types[keys[i]] === 'Per'){
              //                       c.type = `Per ${targetVal[keys[i]]}`
              //                     }
              //                   }else{
              //                     c.type = 'onetime'
              //                   }
              //                   return c
              //             })
              //             console.log(test,'datatosendtoapi')
              //             // if (finalData.length !==0 && finalData.every(o=>o.price !== ('' || undefined))) {

              //             //   axios.post(Fill_BusinessSetUp_URL, {
              //             //     servicesOffered: finalData
              //             //   }, {
              //             //     headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
              //             //   }).then(res => {

              //             //     console.log(res, '70000')
              //             //     swal('Saved!', 'Set Up updated succesfully', 'success')
              //             //   })

              //             // }
              //             // if(finalData.length ===0){

              //             //   swal('Oops!', 'Please choose atleast one!', 'error', {
              //             //     buttons: {

              //             //       // roll: {
              //             //       //   text:'Ok',
              //             //       //   className:'deny-button-class',
              //             //       // },
              //             //       // roll: {
              //             //       //   text: "Do a barrell roll!",
              //             //       //   value: "roll",
              //             //       // },
              //             //     },
              //             //   });
              //             // }
              //             // if(!finalData.every(o=>o.price !== ('' || undefined))){
              //             //   swal('Oops!', 'Amount Can,t be empty!', 'error', {
              //             //     buttons: {

              //             //       // roll: {
              //             //       //   text:'Ok',
              //             //       //   className:'deny-button-class',
              //             //       // },
              //             //       // roll: {
              //             //       //   text: "Do a barrell roll!",
              //             //       //   value: "roll",
              //             //       // },
              //             //     },})
              //             // }

              //           }}

              //           color="primary" >
              //           Submit
              //         </button>
              //         {' '}

              //       </div>
              //     </div>
              //   </div>
              <BusinessSetupDetails />
            )}
          </div>
        </div>
      )}
      {localStorage.getItem("role") === "operator" && (
        <div className="mainsection bnk_sctn">
          <div className="container rounded bg-white mt-5 mb-5">
            <div className="profile_div">
              <div className="col-md-3 border-right m-4">
                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                  <img
                    className="rounded-circle mt-5 prfl-img"
                    width="150px"
                    style={{ paddingTop: "20px", borderRadius: "20px" }}
                    src={
                      typeof profile.operatorLogo === "object"
                        ? URL.createObjectURL(profile.operatorLogo)
                        : profile.operatorLogo
                    }
                  />
                  {/* <span className="font-weight-bold">Edogaru</span> */}
                  {/* <span className="text-black-50">edogaru@mail.com.my</span> */}
                  <span> </span>
                </div>
              </div>
              <div className="bnk_profile_page">
                <div className="bpp_upper">
                  <div className="cmn_hdr">
                    <h2>Account Settings</h2>
                  </div>
                  <div className="profile-pic">
                    <label className="-label" htmlFor="file">
                      <span className="glyphicon glyphicon-camera" />
                      <span>Change Image</span>
                    </label>
                    <input
                      id="file"
                      type="file"
                      onChange={OperatorProfileChangeHandler}
                      name="operatorLogo"
                    />
                    <img
                      // src="https://scontent.fccu25-1.fna.fbcdn.net/v/t39.30808-6/291453188_402270755251555_7487858768217886587_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=hHGPfswXk24AX_Fphor&_nc_ht=scontent.fccu25-1.fna&oh=00_AfAhg6MYwr2PwzyLMHTLHeOJJFY3MszZcNctRFKCyINo_A&oe=65800CAE"
                      src={
                        typeof profile.operatorLogo === "object"
                          ? URL.createObjectURL(profile.operatorLogo)
                          : profile.operatorLogo
                      }
                      id="output"
                      width={200}
                    />
                  </div>
                </div>
                <div className="bank_profile_from">
                  <div className="form__group field">
                    <input
                      type="text"
                      name="fullName"
                      value={profile.fullName}
                      onChange={OperatorProfileChangeHandler}
                      className="form__field"
                      placeholder="full name"
                      defaultValue=""
                    />
                    <label htmlFor=" Bank Name" className="form__label">
                      {" "}
                      Name
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="Email"
                      placeholder=" Email"
                      name="email"
                      required=""
                      onChange={OperatorProfileChangeHandler}
                      className="form__field"
                      value={profile.email}
                    />
                    <label htmlFor=" Email" className="form__label">
                      {" "}
                      Email
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="phoneNumber"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.phoneNumber}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Contact Number
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="address"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.address}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Address
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="state"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.state}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      State
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="zip"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.zip}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Zip
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="city"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.city}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      City
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="companyName"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.companyName}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Company Name
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="companyWebsite"
                      onChange={OperatorProfileChangeHandler}
                      value={profile.companyWebsite}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Company Website
                    </label>
                  </div>
                  <div className="form__group field">
                    <input
                      type="tel"
                      className="form__field"
                      placeholder=" Bank Contact Number"
                      required=""
                      name="createdAt"
                      disabled
                      onChange={OperatorProfileChangeHandler}
                      value={profile.createdAt}
                    />
                    <label
                      htmlFor=" Bank Contact Number"
                      className="form__label"
                    >
                      Created At
                    </label>
                  </div>
                  {/* <div className="form__group field">
      <input
        type="text"
        className="form__field"
        placeholder=" Bank State"
        required=""
        name='bankState'
        onChange={BankProfileChangeHandler}
        value={profile.bankState}
      />
      <label htmlFor=" Bank State" className="form__label">
        {" "}
        Bank State
      </label>
    </div>
    <div className="form__group field">
      <input
        type="text"
        className="form__field"
        placeholder="  Bank City"
        required=""
        name='bankCity'
        onChange={BankProfileChangeHandler}
        value={profile.bankCity}
      />
      <label htmlFor="  Bank City" className="form__label">
        {" "}
        Bank City
      </label>
    </div>
    <div className="form__group field">
      <input
        type="number"
        className="form__field"
        placeholder="  Bank Zip"
        required=""
        name='bankZip'
        onChange={BankProfileChangeHandler}
        value={profile.bankZip}
      />
      <label htmlFor="  Bank Zip" className="form__label">
        {" "}
        Bank Zip
      </label>
    </div>
    <div className="form__group field">
      <textarea
        
        id="BankAddress"
        className="form__field"
        placeholder="Bank Address"
        defaultValue={""}
        name='bankAddress'
        onChange={BankProfileChangeHandler}
        value={profile.bankAddress}
      />
      <label htmlFor="BankAddress" className="form__label">
        {" "}
        Bank Address
      </label>
    </div>
    <div className="form__group field">
      <input
        type="text"
        className="form__field"
        placeholder="  Bank Type"
        required=""
        name='bankType'
        onChange={BankProfileChangeHandler}
        value={profile.bankType}
      />
      <label htmlFor="  Bank Type" className="form__label">
        {" "}
        Bank Type
      </label>
    </div>
    <div className="form__group field">
      <input
        type="url"
        className="form__field"
        placeholder="Website"
        required=""
        name='website'
        onChange={BankProfileChangeHandler}
        value={profile.website}
      />
      <label htmlFor="Website" className="form__label">
        {" "}
        Website
      </label>
    </div>
    <div className="form__group field">
      <input
        type="text"
        disabled
        className="form__field"
        placeholder="Account Created At"
        required=""
        
       
        value={profile?.paymentStage?.stage}
      />
      <label htmlFor="Account Created At" className="form__label">
        Payement Stage
      </label>
    </div>
    <div className="form__group field">
      <input
        type="text"
        disabled
        className="form__field"
        placeholder="Account Created At"
        required=""
        
       
        value={profile?.paymentStage?.price}
      />
      <label htmlFor="Account Created At" className="form__label">
        Payement Price
      </label>
    </div>
    <div className="form__group field">
      <input
        type="text"
        disabled
        className="form__field"
        placeholder="Account Created At"
        required=""
        name='createdAt'
        onChange={BankProfileChangeHandler}
        value={profile.createdAt}
      />
      <label htmlFor="Account Created At" className="form__label">
        Account Created At
      </label>
    </div> */}
                  <div className="btn button-container">
                    <button
                      className="btn btn-next"
                      type="button"
                      onClick={OperatorUpdateProfileInformation}
                    >
                      Save Profile
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
       <div className="p-3 py-5">
         <div className="d-flex justify-content-between align-items-center experience">
           <span>Edit Experience</span>
           <span className="border px-3 p-1 add-experience">
             <i className="fa fa-plus" />
             &nbsp;Experience
           </span>
         </div>
         <br />
         <div className="col-md-12">
           <label className="labels">Experience in Designing</label>
           <input
             type="text"
             className="form-control"
             placeholder="experience"
             defaultValue=""
           />
         </div>{" "}
         <br />
         <div className="col-md-12">
           <label className="labels">Additional Details</label>
           <input
             type="text"
             className="form-control"
             placeholder="additional details"
             defaultValue=""
           />
         </div>
       </div>
     </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
