import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { current } from "@reduxjs/toolkit";
import {
  Admin_ActiveBankAccountStatus_URL,
  Admin_UpdateBankStage_URL,
  GetAllbanks_URL,
} from "../Api/Api";
//Action:-
export const fetchBanks = createAsyncThunk("fetchBanks", async () => {
  // const response = await fetch('https://jsonplaceholder.typicode.com/todos')
  // return response.json()
  return await axios.get(GetAllbanks_URL, {
    headers: { Authorization: "Bearer " + localStorage.getItem("admintoken") },
  });
});
export const paymentStageAndPriceUpdate = createAsyncThunk(
  "paymentStageAndPriceUpdate",
  async (data) => {
    console.log(data, "ui890786");
    // const response = await fetch('https://jsonplaceholder.typicode.com/todos')
    // return response.json()
    return await axios.put(
      `${Admin_UpdateBankStage_URL}/${data.id}`,
      data.stage,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admintoken"),
        },
      }
    );
  }
);
export const bankActiveStatusHandler = createAsyncThunk(
  "bankActiveStatusHandler",
  async (data) => {
    return await axios.put(
      `${Admin_ActiveBankAccountStatus_URL}/${data.id}`,
      {
        matchedApplicationStatus: !data.status,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admintoken"),
        },
      }
    );
  }
);

export const fetchRandom = createAsyncThunk("fetchRandom", async () => {
  // const response = await fetch('https://jsonplaceholder.typicode.com/todos')
  // return response.json()
  console.log(
    await axios.get("https://jsonplaceholder.typicode.com/todos"),
    "9929292"
  );
  return await axios.get("https://jsonplaceholder.typicode.com/todos");
});
const BankSlice = createSlice({
  name: "Bank",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    message: null,
  },
  extraReducers: {
    [fetchBanks.pending]: (state, action) => {
      state.isLoading = true;
      state.data = null;
    },
    [fetchBanks.fulfilled]: (state, action) => {
      console.log(action.payload, "action.payload");
      state.isLoading = false;
      state.data = action?.payload?.data;
    },

    [fetchBanks.rejected]: (state, action) => {
      console.log(action.payload, "action.payload");
      state.isError = true;
    },

    //
    [paymentStageAndPriceUpdate.pending]: (state, action) => {
      state.isLoading = true;
    },
    [paymentStageAndPriceUpdate.fulfilled]: (state, action) => {
      console.log(action.payload, "action.payload");
      state.isLoading = false;
      state.message = action.payload.data.message;
    },
    [paymentStageAndPriceUpdate.rejected]: (state, action) => {
      console.log(action.payload, "action.payload");
      // state.isError = action.payload.message;
    },

    [bankActiveStatusHandler.pending]: (state, action) => {
      state.isLoading = true;
    },
    [bankActiveStatusHandler.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log(current(state), "888888");
      console.log(current(state).data.data, "seeeeee");
      console.log(action.payload.data, "action.payload3");

      const updatedData = current(state).data.map(
        (i) => {
          if (i._id !== action.payload.data.updatedDetails._id) {
            return i;
          } else {
            const obj = {
              ...i,
              matchedApplicationStatus:
                action.payload.data.updatedDetails.matchedApplicationStatus,
            };
            return obj;
          }
        }
        // i._id !== action.payload.data.updatedDetails._id ? i :
        // (i.matchedApplicationStatus = action.payload.data.updatedDetails.matchedApplicationStatus)
      );
      console.log(updatedData, "updatedData");

      state.data = updatedData;
      state.message = `${action.payload.data.message}`;
    },
    [bankActiveStatusHandler.rejected]: (state, action) => {
      console.log(action.payload, "action.payload");
      state.isError = true;
    },
  },
});
// const RandomSlice = createSlice({
//     name: 'Random',
//     initialState2: {
//         isLoading: false,
//         data: null,
//         isError: false
//     },
//     extraReducers: (builder) => {
//         builder.addCase(fetchRandom.pending, (state, action) => {
//        state.isLoading = true;
//        state.data = null;
//         });
//             builder.addCase(fetchRandom.fulfilled, (state, action) => {
//                 console.log(action.payload,'action.payload')
//                 state.isLoading = false;
//                 state.data = action.payload;
//             });

//             builder.addCase(fetchRandom.rejected, (state, action) => {
//                console.log(action.payload,'action.payload')
//                 state.isError = true;
//             })
//     }
// })
// console.log(BankSlice.reducer,'BankSlice')
export default BankSlice.reducer;
// export {BankSlice,RandomSlice}
