import React from 'react'
import '../Modal/Modal.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { ForgotPassword_URL } from '../../Api/Api'
import swal from 'sweetalert'

const ForgetPassword = () => {
  const [forgotPassword, setForgotPassword] = useState({
    email: '', role: ''
  })
  const ForgotPasswordChangeHandler = (event) => {
 setForgotPassword({
  ...forgotPassword,[event.target.name]: event.target.value
 })
  }
  const ForgotPasswordHandler = () => {
   console.log(forgotPassword,'forgotPassword')
   axios.post(ForgotPassword_URL,{
    email: forgotPassword.email
   },{
    params: {
      //  role: forgotPassword.role
      role:localStorage.getItem('role').toLocaleLowerCase()
    },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }).then(res=>{
    swal('Done?',`${res.data.message}`,'success')
    console.log(res,'theresponse')
  }).catch(err=>{
    swal('Oops!',`${err.response.data.message}`,'error')
    console.log(err,'the error')
  })
  }
  return (
    <div className='mainsection bnk_sctn'>
      <div className='modal'
      >
        <div className="content-passwordreset" style={{ borderRadius: '20px' }}>
          <h4>Forget Password</h4>
          <div className="">
            <div className="">
              <div className="cmn_hdr" style={{ marginLeft: '30%' }}>
                <div className="inpt_bx">
                  <label>Enter E-mail: &nbsp;</label>
                  <input type="email"
                    onChange={ForgotPasswordChangeHandler}
                    name='email' placeholder="E-mail" required />
                </div>
                <br />
                {/* <div className="inpt_bx">


                  <div className="form-input-group">
                    <label>Choose role:</label>
                    <div className="frm_radio_prnt">
                      <div className="form_radio_group">
                        <input type="radio" defaultValue="" id="ACH1"
                          onChange={ForgotPasswordChangeHandler}
                          name="role"
                          value='bank'
                        />
                        
                        <label htmlFor="ACH1" className="radio-button">
                          {" "}
                          Bank
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input type="radio" defaultValue="" id="ACH1"
                          onChange={ForgotPasswordChangeHandler}

                          name="role"
                          value='operator'
                        />
                       
                        <label htmlFor="ACH1" className="radio-button">
                          {" "}
                          Operator
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
          <div className="footer">
            {/* <button type='submit' className='notification_btn ' style={{marginLeft:'28%'}}
               
                color="primary" >
                Change
              </button>{' '} &nbsp; */}
            <button type='' className='notification_btn ' onClick={ForgotPasswordHandler}
              // onClick={()=>{setShow(false)}}
              color="primary" >
              Submit
            </button>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword