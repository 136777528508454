import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { nextFive } from "../../Store/userSlice";
import {
  Customer_Form_FillUp,
  OperatorApplicationsList_URL,
} from "../../Api/Api";
import CreatableSelect from "react-select/creatable";

import axios from "axios";
import swal from "@sweetalert/with-react";
import NumberFormat from "react-number-format";
import CurrencyInput from "../../Helper/CurrencyInput";
const FormFive = (props) => {
  const OperatorData = useSelector((state) => state.user);
  console.log(OperatorData, "OperatorData");
  const OPTIONS = ["Credit", "Debit", "ACH", "PIN Debit", "Cashless ATM"];

  const Options = OPTIONS.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });
  const previousData = useSelector((state) => state);
  console.log(previousData, "previousData");
  const dispatch = useDispatch();
  const [errorState, setErrorstate] = useState();

  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  const {
    Electronic_Payments_Settlement,
    setElectronic_Payments_Settlement,
    ATM_Machine,
    Accounting,
    Compliance_Details,
    Following_Services,
    ElectronicPaymentsSettlementChangeHandler,
    ATMMachineTransactionsChangeHandler,
    AccountingChangeHandler,
    Compliance_DetailsChangeHandler,
    FollowingServicesHandler,
    errors,
  } = useForm();
  console.log(Compliance_Details, "Compliance_Details");
  console.log(Electronic_Payments_Settlement.types_payments, "12345678");
  console.log(errors, "errors1");
  useEffect(() => {
    setErrorstate(errors);
  }, [errors]);
  console.log(Following_Services, "234");
  const PreviousHandler = () => {
    props.setFormNo(4);
    window.scrollTo(0, 0);
  };
  const FinalSubmitHandler = (event) => {
    event.preventDefault();
    console.log(OperatorData.operatorDetailsFormOne, "8935432643284");
    for (let x in Electronic_Payments_Settlement) {
      console.log(x, "ll");
      if (
        x !== "types_payments" &&
        String(Electronic_Payments_Settlement[x]).length === 0
      ) {
        errorState[x] = "cannot be empty";

        setErrorstate({ ...errorState });
      } else {
        delete errorState[x];
        setErrorstate({ ...errorState });
      }
    }
    for (let x in ATM_Machine) {
      if (String(ATM_Machine[x]).length === 0) {
        errorState[x] = "cannot be empty";
        setErrorstate({ ...errorState });
      } else {
        delete errorState[x];
        setErrorstate({ ...errorState });
      }
    }

    for (let x in Accounting) {
      if (x !== "firm_name") {
        if (String(Accounting[x]).length === 0) {
          errorState[x] = "cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else if (x === "firm_name") {
        if (Accounting.accountant === "External") {
          if (Accounting.firm_name === "") {
            errorState.firm_name = "cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.firm_name;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.firm_name;
          setErrorstate({ ...errorState });
        }
      }
    }
    for (let x in Compliance_Details) {
      if (x !== "compliance_desc" && x !== "compliance_partner_name") {
        if (String(Compliance_Details[x]).length === 0) {
          errorState[x] = "cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else {
        if (Compliance_Details.non_compliance === "yes") {
          if (Compliance_Details.compliance_desc === "") {
            errorState.compliance_desc = "cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.compliance_desc;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.compliance_desc;
          setErrorstate({ ...errorState });
        }

        if (Compliance_Details.compliance_partner === "yes") {
          if (Compliance_Details.compliance_partner_name === "") {
            errorState.compliance_partner_name = "cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.compliance_partner_name;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.compliance_partner_name;
          setErrorstate({ ...errorState });
        }
      }
    }

    console.log(Electronic_Payments_Settlement, "noone");
    console.log(ATM_Machine, "noone");

    console.log(Accounting, "noone");
    console.log(Compliance_Details, "noone");
    console.log(Following_Services, "noone");
    console.log(errorState, "errors2");

    const res = [
      ...Object.values(Electronic_Payments_Settlement),
      ...Object.values(ATM_Machine),
      ...Object.values(Accounting),
      ...Object.values(Compliance_Details),
    ];

    const finalres = res.map((i) => String(i).length);

    console.log(Object.keys(errors).length, "finalRes");
    // && finalres.every(x => x !== 0) && Following_Services.length !== 0
    if (
      Object.keys(errorState).length === 0 &&
      Following_Services.length !== 0
    ) {
      console.log("Call post api");

      dispatch(
        nextFive({
          Electronic_Payments_Settlement,
          ATM_Machine,
          Accounting,
          Compliance_Details,
          Following_Services,
        })
      );
      swal({
        title: "All your data will be submitted. Are you sure to continue?",
        className: "custom-content",
        buttons: {
          confirm: { text: "YES", className: "sweet-warning" },
          cancel: "NO",
          // confirmButtonColor: '#8CD4F5',
        },
        type: "success",
      }).then((okay) => {
        if (okay) {
          let accNeedTime = 0;
          if (
            OperatorData.operatorDetailsFormOne.acc_need_time.daysormonths ===
            "Years"
          ) {
            accNeedTime =
              Number(OperatorData.operatorDetailsFormOne.acc_need_time.value) *
              365;
          } else if (
            OperatorData.operatorDetailsFormOne.acc_need_time.daysormonths ===
            "Months"
          ) {
            accNeedTime =
              Number(OperatorData.operatorDetailsFormOne.acc_need_time.value) *
              30;
          } else {
            accNeedTime = Number(
              OperatorData.operatorDetailsFormOne.acc_need_time.value
            );
          }
          let unitData =
            OperatorData.operatorDetailsFormTwo.average_quantity_per_sale.replaceAll(
              ",",
              ""
            );
          if (OperatorData.operatorDetailsFormTwo.unit === "Gram") {
          } else if (OperatorData.operatorDetailsFormTwo.unit === "Kilogram") {
            unitData = unitData * 1000;
          } else {
            unitData = unitData * 453.592;
          }

          let length_time = 0;
          if (OperatorData.operatorDetailsFormFour.daysormonths === "Years") {
            length_time =
              Number(OperatorData.operatorDetailsFormFour.thisvalue) * 365;
          } else if (
            OperatorData.operatorDetailsFormFour.daysormonths === "Months"
          ) {
            length_time =
              Number(OperatorData.operatorDetailsFormFour.thisvalue) * 30;
          } else {
            length_time = Number(
              OperatorData.operatorDetailsFormFour.thisvalue
            );
          }
          const license =
            OperatorData.operatorDetailsFormOne.licensing_Type.map((x) => {
              const Obj = {};
              if (x !== "Other") {
                Obj.licensing_Type = x;
                Obj.license_Num =
                  OperatorData.operatorDetailsFormOne.LicenseNumber[x];
              } else {
                Obj.licensing_Type = OperatorData.operatorDetailsFormOne.other;
                Obj.license_Num =
                  OperatorData.operatorDetailsFormOne.LicenseNumber.Other;
              }
              return Obj;
            });
          console.log(license, "license5666");
          const finalData = {
            //Form-One
            servicesWanted: OperatorData.operatorDetailsFormOne.servicesWanted,
            // acc_need_time: accNeedTime,

            input: Number(
              OperatorData.operatorDetailsFormOne.acc_need_time.value
            ),
            unit: OperatorData.operatorDetailsFormOne.acc_need_time
              .daysormonths,
            convertedToDays: accNeedTime,

            accountPurpose: OperatorData.operatorDetailsFormOne.accountPurpose,
            risk_classification:
              OperatorData.operatorDetailsFormOne.risk_classification,
            name: OperatorData.operatorDetailsFormOne.name,
            name: OperatorData.operatorDetailsFormOne.name,
            dba: OperatorData.operatorDetailsFormOne.dba,
            other: OperatorData.operatorDetailsFormOne.other,
            physical_Address:
              OperatorData.operatorDetailsFormOne.physical_Address,
            mailing_Address:
              OperatorData.operatorDetailsFormOne.mailing_Address,
            federal_EIN: OperatorData.operatorDetailsFormOne.federal_EIN,
            cannabis_License_Number:
              OperatorData.operatorDetailsFormOne.cannabis_License_Number,
            // licensing_Type: OperatorData.operatorDetailsFormOne.licensing_Type,
            license: license,
            num_employees:
              OperatorData.operatorDetailsFormOne.num_employees.replaceAll(
                ",",
                ""
              ),
            accepted_states:
              OperatorData.operatorDetailsFormOne.accepted_states,
            foreign_operations:
              OperatorData.operatorDetailsFormOne.foreign_operations,
            primary_contact_name:
              OperatorData.operatorDetailsFormOne.primary_contact_name,
            dob: OperatorData.operatorDetailsFormOne.dob,
            primary_address:
              OperatorData.operatorDetailsFormOne.primary_address,
            mail: OperatorData.operatorDetailsFormOne.mail,
            primary_Phone: OperatorData.operatorDetailsFormOne.primary_Phone,
            ownership: OperatorData.operatorDetailsFormOne.ownership,
            ownership_Percentage:
              OperatorData.operatorDetailsFormOne.ownership_Percentage,
            us_Citizenship: OperatorData.operatorDetailsFormOne.us_Citizenship,
            authority_sign: OperatorData.operatorDetailsFormOne.authority_sign,

            //Form-Two
            products_purchased_providers:
              OperatorData.operatorDetailsFormTwo.products_purchased_providers,
            licensed_provider:
              OperatorData.operatorDetailsFormTwo.licensed_provider,
            acc_need: OperatorData.operatorDetailsFormTwo.acc_need.replaceAll(
              ",",
              ""
            ),
            num_locs: OperatorData.operatorDetailsFormTwo.num_locs.replaceAll(
              ",",
              ""
            ),
            transactions_Per_month:
              OperatorData.operatorDetailsFormTwo.transactions_Per_month?.replaceAll(
                ",",
                ""
              ),
            // managed_square_feet:
            //   OperatorData.operatorDetailsFormTwo.managed_square_feet?.replaceAll(
            //     ",",
            //     ""
            //   ),
            purchase_amount_per_sale:
              OperatorData.operatorDetailsFormTwo.purchase_amount_per_sale
                .slice(1)
                .replaceAll(",", ""),
            num_plants:
              OperatorData.operatorDetailsFormTwo.num_plants?.replaceAll(
                ",",
                ""
              ),
            quantityInput: Number(
              OperatorData.operatorDetailsFormTwo.average_quantity_per_sale.replaceAll(
                ",",
                ""
              )
            ),
            quantityUnit: OperatorData.operatorDetailsFormTwo.unit,
            convertedToGram: Number(unitData),
            // average_quantity_per_sale: Number(unitData),
            facility: OperatorData.operatorDetailsFormTwo.facility,
            egmi: OperatorData.operatorDetailsFormTwo.egmi
              ?.slice(1)
              .replaceAll(",", ""),
            types_customers:
              OperatorData.operatorDetailsFormTwo.types_customers,
            principal_business:
              OperatorData.operatorDetailsFormTwo.principal_business,
            beneficial_owners:
              OperatorData.operatorDetailsFormTwo.beneficial_owners,
            number_beneficial_owner:
              OperatorData.operatorDetailsFormTwo.number_beneficial_owner?.replaceAll(
                ",",
                ""
              ),
            Additional_Locs:
              OperatorData.operatorDetailsFormTwo.additional_locs,
            amount_Initial_Deposit:
              OperatorData.operatorDetailsFormTwo.amount_Initial_Deposit
                ?.slice(1)
                .replaceAll(",", ""),
            source_Initial_Deposit:
              OperatorData.operatorDetailsFormTwo.source_Initial_Deposit,
            estimated_total_amount_monthly_deposit:
              OperatorData.operatorDetailsFormTwo.estimated_total_amount_monthly_deposit
                ?.slice(1)
                .replaceAll(",", ""),
            estimated_total_num_monthly_deposit:
              OperatorData.operatorDetailsFormTwo.estimated_total_num_monthly_deposit.replaceAll(
                ",",
                ""
              ),
            anticipate_cash_deposits:
              OperatorData.operatorDetailsFormTwo.anticipate_cash_deposits,
            amount_cash_deposits:
              OperatorData.operatorDetailsFormTwo.amount_cash_deposits
                ?.slice(1)
                .replaceAll(",", ""),
            frequency_cash_deposits:
              OperatorData.operatorDetailsFormTwo.frequency_cash_deposits,
            estimated_spend: OperatorData.operatorDetailsFormTwo.estimated_spend
              ?.slice(1)
              .replaceAll(",", ""),
            anticipate_cash_withdrawals:
              OperatorData.operatorDetailsFormTwo.anticipate_cash_withdrawals,
            amount_cash_withdrawals:
              OperatorData.operatorDetailsFormTwo.amount_cash_withdrawals
                .slice(1)
                .replaceAll(",", ""),
            frequency_cash_withdrawals:
              OperatorData.operatorDetailsFormTwo.frequency_cash_withdrawals,
            monthly_payroll: OperatorData.operatorDetailsFormTwo.monthly_payroll
              .slice(1)
              .replaceAll(",", ""),
            cash_pick_ups: OperatorData.operatorDetailsFormTwo.cash_pick_ups,
            frequency_cash_pick_ups:
              OperatorData.operatorDetailsFormTwo.frequency_cash_pick_ups,
            estimated_cash_pick_ups:
              OperatorData.operatorDetailsFormTwo.estimated_cash_pick_ups
                .slice(1)
                .replaceAll(",", ""),

            //Form_Three
            Company_Documentation: OperatorData.operatorDetailsFormThree.map(
              (item) => item.documentation
            ),
            //Form-Four
            legacy_cash: OperatorData.operatorDetailsFormFour.legacy_cash,
            documents_available:
              OperatorData.operatorDetailsFormFour.documents_available.map(
                (p) => p.docs_avail
              ),
            business_acc: OperatorData.operatorDetailsFormFour.business_acc,
            bank_name: OperatorData.operatorDetailsFormFour.bank_name,
            // length_time: length_time,
            // OperatorData.operatorDetailsFormFour.thisvalue
            lengthTimeInput: OperatorData.operatorDetailsFormFour.thisvalue,
            lengthTimeUnit: OperatorData.operatorDetailsFormFour.daysormonths,
            lengthTimeConvertedToDays: length_time,
            reason_to_close:
              OperatorData.operatorDetailsFormFour.reason_to_close,
            // payroll_service:
            //   OperatorData.operatorDetailsFormFour.payroll_service,
            // paid_cash: OperatorData.operatorDetailsFormFour.paid_cash,
            // payroll_accepted:
            //   OperatorData.operatorDetailsFormFour.payroll_accepted,
            // taxes_cash: OperatorData.operatorDetailsFormFour.taxes_cash,
            penalty_cash: OperatorData.operatorDetailsFormFour.penalty_cash,
            tax_payment: OperatorData.operatorDetailsFormFour.tax_payment,
            number_vendors:
              OperatorData.operatorDetailsFormFour.number_vendors?.replaceAll(
                ",",
                ""
              ),
            vendor_payment_methods:
              OperatorData.operatorDetailsFormFour.vendor_payment_methods,
            international_vendor:
              OperatorData.operatorDetailsFormFour.international_vendor,
            electronic_payment:
              OperatorData.operatorDetailsFormFour.electronic_payment,
            current_cash_managment:
              OperatorData.operatorDetailsFormFour.current_cash_managment,
            existing_bank_name:
              OperatorData.operatorDetailsFormFour.existing_bank_name,
            existing_bank_contact:
              OperatorData.operatorDetailsFormFour.existing_bank_contact,
            financial_institution:
              OperatorData.operatorDetailsFormFour.financial_institution,
            original_deposite:
              OperatorData.operatorDetailsFormFour.original_deposite,
            prev_bank_verified:
              OperatorData.operatorDetailsFormFour.prev_bank_verified,
            reason_closure: OperatorData.operatorDetailsFormFour.reason_closure,
            prev_bank_aware:
              OperatorData.operatorDetailsFormFour.prev_bank_aware,

            //Form_five
            settlement_num_loc:
              Electronic_Payments_Settlement.settlement_num_loc.replaceAll(
                ",",
                ""
              ),
            msb: Electronic_Payments_Settlement.msb,
            money_transmitted_license:
              Electronic_Payments_Settlement.money_transmitted_license,
            types_payments: Electronic_Payments_Settlement.types_payments,
            mobile_app: Electronic_Payments_Settlement.mobile_app,
            pos: Electronic_Payments_Settlement.pos,
            credit_card: Electronic_Payments_Settlement.credit_card,
            bank_aware: Electronic_Payments_Settlement.bank_aware,
            merchant_processor:
              Electronic_Payments_Settlement.merchant_processor,
            terminal: Electronic_Payments_Settlement.terminal,
            atm_own: ATM_Machine.atm_own,
            atm_loc: ATM_Machine.atm_loc,
            atm_multiple: ATM_Machine.atm_multiple,
            atm_fill: ATM_Machine.atm_fill,
            atm_third_party: ATM_Machine.atm_third_party,
            atm_fill_company: ATM_Machine.atm_fill_company,
            atm_currency: ATM_Machine.atm_currency
              ?.slice(1)
              .replaceAll(",", ""),
            atm_crypto: ATM_Machine.atm_crypto,
            atm_deposite: ATM_Machine.atm_deposite,
            atm_receipt: ATM_Machine.atm_receipt,
            accounting_system: Accounting.accounting_system,
            accountant: Accounting.accountant,
            firm_name: Accounting.firm_name,
            non_compliance: Compliance_Details.non_compliance,
            compliance_desc: Compliance_Details.compliance_desc,
            compliance_officer: Compliance_Details.compliance_officer,
            compliance_partner: Compliance_Details.compliance_partner,
            compliance_partner_name: Compliance_Details.compliance_partner_name,
            onsite_inspection: Compliance_Details.onsite_inspection,
            compliance_date: Compliance_Details.compliance_date,
            compliance_support: Compliance_Details.compliance_support, //group
            frequency_compliance: Compliance_Details.frequency_compliance,
            compliance_trainning: Compliance_Details.compliance_trainning,
            operating_procedures: Compliance_Details.operating_procedures,
            Interested_Services: Following_Services.map((r) => r.services),
          };
          console.log(finalData, "average_quantity_per_sale");
          axios
            .post(Customer_Form_FillUp, finalData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              swal(
                "Great!",
                "Your application has been submitted successfully, matching is in progress, you'll be notified once you find your match with a bank!",
                "success",
                {
                  buttons: {
                    confirm: { text: "OK", className: "sweet-warning" },
                  },
                  className: "",
                }
              );

              console.log(res, "response");
            })
            .catch((err) => console.log(err));
        } else {
          // setActivitystatus('No')
        }
      });
    } else {
      if (Following_Services.length === 0) {
        toast.error(
          "Please select atleast one option from Following Services",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else {
        toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  return (
    <section
      id="section5"
      className="section5"
      // style={{ display: "block" }}
    >
      <form onSubmit={FinalSubmitHandler}>
        {/* Electronic Payments Settlement */}
        <div className="epaysettle bg_grey">
          <h3 className="hdng_h3">
            Electronic Payments Settlement | Merchant Processing
          </h3>
          <div className="outr_finptgrp">
            <div className="form-input-group">
              <label htmlFor="">
                Number of Locations:<span className="red-star">*</span>
              </label>
              <NumberFormat
                maxLength={12}
                value={Electronic_Payments_Settlement.settlement_num_loc}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={ElectronicPaymentsSettlementChangeHandler}
                name="settlement_num_loc"
              />
              {errorState?.settlement_num_loc && (
                <h3 style={{ color: "red" }}>
                  {errorState.settlement_num_loc}
                </h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is the provider a registered MSB?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    name="msb"
                    type="checkbox"
                    checked={Electronic_Payments_Settlement.msb === "yes"}
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does the provider have a money transmitter license in the
                state(s) of operations?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    name="money_transmitted_license"
                    type="checkbox"
                    checked={
                      Electronic_Payments_Settlement.money_transmitted_license ===
                      "yes"
                    }
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Type(s) of payments methods provided:
                <span className="red-star">*</span>
              </label>
              <br />
              {/* <input type="text" name='types_payments' value={Electronic_Payments_Settlement.types_payments} onChange={ElectronicPaymentsSettlementChangeHandler} />
            {
                      errorState?.types_payments && <h3 style={{ color: 'red' }}>{errorState.types_payments}</h3>

                    } */}
              <CreatableSelect
                name="types_payments"
                defaultValue={Electronic_Payments_Settlement?.types_payments?.map(
                  (i) => {
                    return {
                      value: i,
                      label: i,
                      color: "#0052CC",
                    };
                  }
                )}
                isMulti
                options={Options}
                onChange={(val) => {
                  console.log(val, "states");
                  const data = val.map((item) => item.value);
                  //  setAcceptedStates([...data])
                  console.log(data, "567");
                  setElectronic_Payments_Settlement({
                    ...Electronic_Payments_Settlement,
                    types_payments: data,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Mobile delivery application?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="mobile_app"
                    checked={
                      Electronic_Payments_Settlement.mobile_app === "yes"
                    }
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                POS name:<span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="pos"
                value={Electronic_Payments_Settlement.pos}
                onChange={ElectronicPaymentsSettlementChangeHandler}
              />
              {errorState?.pos && (
                <h3 style={{ color: "red" }}>{errorState.pos}</h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does the provider allow acceptance of credit card payments?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    name="credit_card"
                    type="checkbox"
                    checked={
                      Electronic_Payments_Settlement.credit_card === "yes"
                    }
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is the acquiring bank aware they are sponsoring a
                cannabis-related transaction?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="bank_aware"
                    checked={
                      Electronic_Payments_Settlement.bank_aware === "yes"
                    }
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Name of merchant processor:<span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="merchant_processor"
                value={Electronic_Payments_Settlement.merchant_processor}
                onChange={ElectronicPaymentsSettlementChangeHandler}
              />
              {errorState?.merchant_processor && (
                <h3 style={{ color: "red" }}>
                  {errorState.merchant_processor}
                </h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is the solution a “cashless ATM” or “Point-of-Banking” terminal?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="terminal"
                    checked={Electronic_Payments_Settlement.terminal === "yes"}
                    onChange={ElectronicPaymentsSettlementChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
          </div>
        </div>
        {/* ATM Machine Transactions */}
        <div className="atmmtrancast bg_grey">
          <h3 className="hdng_h3">ATM Machine Transactions</h3>
          <div className="outr_finptgrp">
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does the business own or lease the ATM(s) on the premises?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    checked={ATM_Machine.atm_own === "yes"}
                    name="atm_own"
                    onChange={ATMMachineTransactionsChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Location of ATM (e.g., lobby or inside storefront):
                <span className="red-star">*</span>
              </label>
              <input
                type="text"
                value={ATM_Machine.atm_loc}
                onChange={ATMMachineTransactionsChangeHandler}
                name="atm_loc"
              />
              {errorState?.atm_loc && (
                <h3 style={{ color: "red" }}>{errorState.atm_loc}</h3>
              )}
            </div>
            <div className="pflex">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the business own or lease multiple ATMs?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      name="atm_multiple"
                      checked={ATM_Machine.atm_multiple === "yes"}
                      onChange={ATMMachineTransactionsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the business fill the ATM(s) itself?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      name="atm_fill"
                      checked={ATM_Machine.atm_fill === "yes"}
                      onChange={ATMMachineTransactionsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is the ATM filled by a third-party?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="atm_third_party"
                    checked={ATM_Machine.atm_third_party === "yes"}
                    onChange={ATMMachineTransactionsChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="pflex">
              <div className="form-input-group c">
                <label htmlFor="">
                  Name of company or armored service that fills ATM(s)
                  <span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  value={ATM_Machine.atm_fill_company}
                  onChange={ATMMachineTransactionsChangeHandler}
                  name="atm_fill_company"
                />
                {errorState?.atm_fill_company && (
                  <h3 style={{ color: "red" }}>
                    {errorState.atm_fill_company}
                  </h3>
                )}
              </div>
              <div className="form-input-group c">
                <label htmlFor="">
                  How much currency are ATM(s) filled with weekly?(USD)
                  <span className="red-star">*</span>
                </label>
                {/* <input type="text"
                  // value={ATM_Machine.atm_currency} 
                  defaultValue={Number(ATM_Machine.atm_currency).toFixed(2)}
                  onChange={ATMMachineTransactionsChangeHandler} name='atm_currency' /> */}
                <CurrencyInput
                  placeholder="$0.00"
                  maxLength={20}
                  value={ATM_Machine.atm_currency}
                  type="text"
                  name="atm_currency"
                  onChange={ATMMachineTransactionsChangeHandler}
                />
                {errorState?.atm_currency && (
                  <h3 style={{ color: "red" }}>{errorState.atm_currency}</h3>
                )}
              </div>
            </div>
            {/* <div className="form-input-group">
            <label htmlFor="">
              Does ATM accept or dispense cryptocurrency?
            </label>
            <input type="text" value={ATM_Machine.atm_crypto} onChange={ATMMachineTransactionsChangeHandler} name='atm_crypto' />
            {
                      errorState?.atm_crypto && <h3 style={{ color: 'red' }}>{errorState.atm_crypto}</h3>

                    }
          </div> */}
            <br />
            <div className="form-input-group">
              <label htmlFor="">
                Does ATM accept or dispense cryptocurrency?
              </label>
              <div className="frm_radio_prnt">
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="yes"
                    onChange={ATMMachineTransactionsChangeHandler}
                    checked={ATM_Machine.atm_crypto === "yes"}
                    id="Accept"
                    name="atm_crypto"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="Accept" className="radio-button">
                    Yes
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="no"
                    onChange={ATMMachineTransactionsChangeHandler}
                    checked={ATM_Machine.atm_crypto === "no"}
                    name="atm_crypto"
                    id="Dispense"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="Dispense" className="radio-button">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="pflex">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the ATM(s) accept deposits?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      name="atm_deposite"
                      checked={ATM_Machine.atm_deposite === "yes"}
                      onChange={ATMMachineTransactionsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the ATM(s) dispense receipts rather than cash?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={ATM_Machine.atm_receipt === "yes"}
                      name="atm_receipt"
                      onChange={ATMMachineTransactionsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Accounting */}
        <div className="accounting bg_grey">
          <h3 className="hdng_h3">Accounting</h3>
          <div className="outr_finptgrp">
            <div className="form-input-group">
              <label htmlFor="">
                Does the business utilize an accounting system?
                <span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="accounting_system"
                value={Accounting.accounting_system}
                onChange={AccountingChangeHandler}
              />
              {errorState?.accounting_system && (
                <h3 style={{ color: "red" }}>{errorState.accounting_system}</h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is accounting handled internally or externally through an
                accountant/firm?
              </h5>
              <div className="form-input-group">
                <div className="frm_radio_prnt">
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="External"
                      onChange={AccountingChangeHandler}
                      checked={Accounting.accountant === "External"}
                      id="External"
                      name="accountant"
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="External" className="radio-button">
                      External
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="Internal"
                      onChange={AccountingChangeHandler}
                      checked={Accounting.accountant === "Internal"}
                      name="accountant"
                      id="Internal"
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Internal" className="radio-button">
                      Internal
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {Accounting.accountant === "External" && (
              <div className="form-input-group">
                <label htmlFor="">
                  Accountant/Firm Name:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  name="firm_name"
                  value={Accounting.firm_name}
                  onChange={AccountingChangeHandler}
                />
                {errorState?.firm_name && (
                  <h3 style={{ color: "red" }}>{errorState.firm_name}</h3>
                )}
              </div>
            )}
          </div>
        </div>
        {/* Compliance Details */}
        <div className="com_dtails bg_grey">
          <h3 className="hdng_h3">Compliance Details</h3>
          <div className="outr_finptgrp">
            <div className="pflex">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Has the business ever cited for non-compliance?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={Compliance_Details.non_compliance === "yes"}
                      name="non_compliance"
                      onChange={Compliance_DetailsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Compliance_Details.non_compliance === "yes" && (
                <div className="form-input-group c">
                  <label htmlFor="">
                    If yes, please provide a description:
                    <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={Compliance_Details.compliance_desc}
                    onChange={Compliance_DetailsChangeHandler}
                    name="compliance_desc"
                  />
                  {errorState?.compliance_desc && (
                    <h3 style={{ color: "red" }}>
                      {errorState.compliance_desc}
                    </h3>
                  )}
                </div>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Business employs in-house Compliance Officer
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="compliance_officer"
                    checked={Compliance_Details.compliance_officer === "yes"}
                    onChange={Compliance_DetailsChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="pflex">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Business engaged a third-party compliance partner.
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      name="compliance_partner"
                      checked={Compliance_Details.compliance_partner === "yes"}
                      onChange={Compliance_DetailsChangeHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Compliance_Details.compliance_partner === "yes" && (
                <div className="form-input-group c">
                  <label htmlFor="">
                    If yes, please provide name of entity and services provided:
                    <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={Compliance_Details.compliance_partner_name}
                    onChange={Compliance_DetailsChangeHandler}
                    name="compliance_partner_name"
                  />
                  {errorState?.compliance_partner_name && (
                    <h3 style={{ color: "red" }}>
                      {errorState.compliance_partner_name}
                    </h3>
                  )}
                </div>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Busines has auditor/compliance group to conduct on-site
                inspections:
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="onsite_inspection"
                    checked={Compliance_Details.onsite_inspection === "yes"}
                    onChange={Compliance_DetailsChangeHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Date of most recently completed on-site inspection:
                <span className="red-star">*</span>
              </label>
              <input
                type="date"
                value={Compliance_Details.compliance_date}
                className="form-control"
                onChange={Compliance_DetailsChangeHandler}
                name="compliance_date"
              />
              {errorState?.compliance_date && (
                <h3 style={{ color: "red" }}>{errorState.compliance_date}</h3>
              )}
            </div>
            <div className="form-input-group w100">
              <label>
                Does the onsite inspection support the structure, security,
                operations, staff, and stated purpose of the business and
                intended activity with the Bank?
              </label>
              <div className="frm_radio_prnt">
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="yes"
                    onChange={Compliance_DetailsChangeHandler}
                    id="yes1"
                    name="compliance_support"
                    checked={Compliance_Details.compliance_support === "yes"}
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="yes1" className="radio-button">
                    {" "}
                    Yes
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="no"
                    onChange={Compliance_DetailsChangeHandler}
                    id="no1"
                    name="compliance_support"
                    checked={Compliance_Details.compliance_support === "no"}
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="no1" className="radio-button">
                    {" "}
                    No
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="unsure"
                    onChange={Compliance_DetailsChangeHandler}
                    id="unsure1"
                    name="compliance_support"
                    checked={Compliance_Details.compliance_support === "unsure"}
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="unsure1" className="radio-button">
                    Unsure
                  </label>
                </div>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">Frequency of compliance audits:</label>
              <div className="frm_radio_prnt">
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="Quaterly"
                    onChange={Compliance_DetailsChangeHandler}
                    id="yes1"
                    name="frequency_compliance"
                    checked={
                      Compliance_Details.frequency_compliance === "Quaterly"
                    }
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="yes1" className="radio-button">
                    {" "}
                    Quaterly
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="Bi-Monthly"
                    onChange={Compliance_DetailsChangeHandler}
                    id="no1"
                    name="frequency_compliance"
                    checked={
                      Compliance_Details.frequency_compliance === "Bi-Monthly"
                    }
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="no1" className="radio-button">
                    {" "}
                    Bi-Monthly
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="Annually"
                    onChange={Compliance_DetailsChangeHandler}
                    id="unsure1"
                    name="frequency_compliance"
                    checked={
                      Compliance_Details.frequency_compliance === "Annually"
                    }
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="unsure1" className="radio-button">
                    Annually
                  </label>
                </div>
              </div>
            </div>
            <div className="pflex">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Compliance Training conducted?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={
                        Compliance_Details.compliance_trainning === "yes"
                      }
                      onChange={Compliance_DetailsChangeHandler}
                      name="compliance_trainning"
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Standard Operating Procedures in place?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      onChange={Compliance_DetailsChangeHandler}
                      checked={
                        Compliance_Details.operating_procedures === "yes"
                      }
                      name="operating_procedures"
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Are you interested in any of the following services */}
        <div className="fservc bg_grey">
          <h3 className="hdng_h3">
            Are you interested in any of the following services:
            <span className="red-star">*</span>
          </h3>
          <div className="check_form-group">
            <div className="check_checking">
              <input
                type="checkbox"
                id="icheck2"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - Credit Card"
                )}
                value="Payment Processing - Credit Card"
                onChange={FollowingServicesHandler}
              />
              <label htmlFor="icheck2">Payment Processing - Credit Card</label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - Debit Card"
                )}
                value="Payment Processing - Debit Card"
                onChange={FollowingServicesHandler}
              />
              <label> Payment Processing - Debit Card </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - PIN Debit"
                )}
                value="Payment Processing - PIN Debit"
                onChange={FollowingServicesHandler}
              />
              <label> Payment Processing - PIN Debit </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - Cashless ATM"
                )}
                value="Payment Processing - Cashless ATM"
                onChange={FollowingServicesHandler}
              />
              <label> Payment Processing - Cashless ATM </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - ACH Processing"
                )}
                value="Payment Processing - ACH Processing"
                onChange={FollowingServicesHandler}
              />
              <label> Payment Processing - ACH Processing </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payment Processing - Crypto Payments"
                )}
                value="Payment Processing - Crypto Payments"
                onChange={FollowingServicesHandler}
              />
              <label> Payment Processing - Crypto Payments </label>
            </div>
            {/* <div className="check_checking">
              <input type="checkbox" />
              <label> Payment Processing - Crypto Payments </label>
            </div> */}
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Lending Services"
                )}
                value="Lending Services"
                onChange={FollowingServicesHandler}
              />
              <label> Lending Services </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Insurance Services"
                )}
                value="Insurance Services"
                onChange={FollowingServicesHandler}
              />
              <label> Insurance Services </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Payroll"
                )}
                value="Payroll"
                onChange={FollowingServicesHandler}
              />
              <label> Payroll </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Benefits"
                )}
                value="Benefits"
                onChange={FollowingServicesHandler}
              />
              <label> Benefits </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "401K"
                )}
                value="401K"
                onChange={FollowingServicesHandler}
              />
              <label> 401K </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Human Resources"
                )}
                value="Human Resources"
                onChange={FollowingServicesHandler}
              />
              <label> Human Resources </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Billboard / Outdoor Advertising"
                )}
                value="Billboard / Outdoor Advertising"
                onChange={FollowingServicesHandler}
              />
              <label> Billboard / Outdoor Advertising </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "ERC Processing"
                )}
                value="ERC Processing"
                onChange={FollowingServicesHandler}
              />
              <label> ERC Processing </label>
            </div>
            <div className="check_checking">
              <input
                type="checkbox"
                name="pg-bx"
                checked={Following_Services.map((u) => u.services).includes(
                  "Compliance complitency"
                )}
                value="Compliance complitency"
                onChange={FollowingServicesHandler}
              />
              <label> Compliance complitency </label>
            </div>
          </div>
        </div>
        {/* <div class="pg1-mn-otr">
<div class="pg1-mn-inr">
<div class="pg1-prt-1">
<div class="pg1-prt-1-txt">
  <div class="cmn_hdr">
    <h6>Select one that applies to your business:</h6>
    <div class="pg1-prt-1-inrfld">
      <input type="radio" name="pg-rd" />
      <label
        >My business touches marijuana at any point from
        seed to sale and my income relies strictly on the
        marijuana industry. This includes any business or
        farm that manufactures, processes, or distributes
        illegal hemp.</label
      >
    </div>
    <div class="pg1-prt-1-inrfld">
      <input type="radio" name="pg-rd" />
      <label
        >My business touches marijuana at any point from
        seed to sale and my income relies strictly on the
        marijuana industry. This includes any business or
        farm that manufactures, processes, or distributes
        illegal hemp.</label
      >
    </div>
    <div class="pg1-prt-1-inrfld">
      <input type="radio" name="pg-rd" />
      <label
        >My business touches marijuana at any point from
        seed to sale and my income relies strictly on the
        marijuana industry. This includes any business or
        farm that manufactures, processes, or distributes
        illegal hemp.</label
      >
    </div>
  </div>
</div>
</div>
<div class="pg1-prt-2">
<div class="pg1-prt-2-txt">
  <div class="cmn_hdr">
    <h6>Legal Business Information</h6>
    <div class="pg1-prt-2-otrfld">
      <div class="pg1-prt-2-inrfld">
        <label>Legal Business Name:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Other DBAs:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Physical Address:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Mailing Address:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Federal EIN:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Cannabis License Number:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld nd2">
        <label>Licensing Type:</label>
        <div class="pg1-prt-2-prt-otrfld">
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Cultivation</label>
          </div>
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Manufacturer</label>
          </div>
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Distribution</label>
          </div>
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Testing Lab</label>
          </div>
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Dispensary</label>
          </div>
          <div class="pg1-prt-2-prtfld">
            <input type="radio" name="pg-rd" />
            <label>Delivery</label>
          </div>
        </div>
      </div>
      <div class="pg1-prt-2-inrfld nd3">
        <div class="pg1-prt-2-lwr-otrfld">
          <div class="pg1-prt-2-lwr-inrfld">
            <label>Other:</label>
            <input type="text" class="form-control" />
          </div>
          <div class="pg1-prt-2-lwr-inrfld">
            <label># of Employees:</label>
            <input
              type="number"
              class="form-control"
              value="10"
            />
            <a href="javascript:void(0);" class="nd3-a"
              ><i class="fal fa-plus"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="pg1-prt-2-inrfld nd4">
        <div class="pg1-prt-2-end-otrfld">
          <div class="pg1-prt-2-end-inrfld">
            <label
              >Does your business have any foreign
              operations?</label
            >
            <div class="pg1-prt-2-end-prtfld">
              <span>No</span>
              <span class="toggle-bg">
                <input
                  type="radio"
                  name="toggle"
                  value="off"
                />
                <input
                  type="radio"
                  name="toggle"
                  value="on"
                />
                <span class="switch"></span>
              </span>
              <span>Yes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="pg1-prt-3">
<div class="pg1-prt-3-txt">
  <div class="cmn_hdr">
    <h6>Primary Contact Information:</h6>
    <div class="pg1-prt-2-otrfld pg1-prt-3-otrfld">
      <div class="pg1-prt-2-inrfld">
        <label>Primary Contact Name:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Date of Birth:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Address:</label>
        <input type="text" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>E-mail:</label>
        <input type="email" name="" class="form-control" />
      </div>
      <div class="pg1-prt-2-inrfld">
        <label>Primary Phone #</label>
        <input type="tel" name="" class="form-control" />
      </div>
    </div>
    <div class="pg1-prt-2-otrfld pg1-prt-3-otrfld">
      <div class="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
        <label
          >Ownership in business applying for
          account?</label
        >
        <div class="pg1-prt-2-end-prtfld">
          <span>No</span>
          <span class="toggle-bg">
            <input type="radio" name="toggle" value="off" />
            <input type="radio" name="toggle" value="on" />
            <span class="switch"></span>
          </span>
          <span>Yes</span>
        </div>
      </div>
      <div class="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
        <label>U.S. Citizenship?</label>
        <div class="pg1-prt-2-end-prtfld">
          <span>No</span>
          <span class="toggle-bg">
            <input type="radio" name="toggle" value="off" />
            <input type="radio" name="toggle" value="on" />
            <span class="switch"></span>
          </span>
          <span>Yes</span>
        </div>
      </div>
      <div class="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
        <label
          >Authority to sign on behalf of the business?
        </label>
        <div class="pg1-prt-2-end-prtfld">
          <span>No</span>
          <span class="toggle-bg">
            <input type="radio" name="toggle" value="off" />
            <input type="radio" name="toggle" value="on" />
            <span class="switch"></span>
          </span>
          <span>Yes</span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div> */}
        <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormFive;
