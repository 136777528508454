import React, { useState } from "react";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { nextOne } from "../Store/userSlice";
import { Validate } from "../Helper/Validate";
import { BankValidator } from "../Helper/BankValidator";

const useForm = () => {
  const formInfo = useSelector((state) => state.user);
  console.log(formInfo, "ggh");
  const dispatch = useDispatch();
  console.log(formInfo.operatorDetailsFormOne, "formInfoOne");
  console.log(formInfo.operatorDetailsFormTwo, "formInfoTwo");
  console.log(formInfo.operatorDetailsFormThree, "formInfoThree");
  console.log(formInfo.operatorDetailsFormFour, "formInfoFour");

  console.log(formInfo.BankDetailsFormOne, "formOneBank");
  console.log(formInfo.BankDetailsFormTwo, "formInfoTwobank");
  const [servicesWanted, setServicesWanted] = useState(
    formInfo.operatorDetailsFormOne.servicesWanted
  );
  const [accountPurpose, setAccountPurpose] = useState(
    formInfo.operatorDetailsFormOne.accountPurpose
  );
  const [accountPurposeInput, setAccountPurposeInput] = useState(
    formInfo.operatorDetailsFormOne.accountPurposeInput
  );
  const ServicesWantedHandler = (event) => {
    console.log(event.target.value);
    if (event.target.checked) {
      setServicesWanted([...servicesWanted, event.target.value]);
    } else {
      const data = servicesWanted.filter(
        (service) => service !== event.target.value
      );
      setServicesWanted([...data]);
    }
  };
  const AccountPurposeHandler = (event) => {
    // accountPurposeInput
    if (event.target.name === "accountPurposeInput") {
      setAccountPurposeInput(event.target.value);
    } else {
      if (event.target.checked) {
        setAccountPurpose([...accountPurpose, event.target.value]);
      } else {
        const data = accountPurpose.filter(
          (service) => service !== event.target.value
        );
        setAccountPurpose([...data]);
      }
    }
  };
  const [acc_need_time, setAcc_need_time] = useState(
    formInfo.operatorDetailsFormOne.acc_need_time
  );
  const AccountNeedTimehandler = (event) => {
    console.log(event.target.value, "8112354");
    // Validate(event, event.target.name, event.target.value, errors, setErrors);

    setAcc_need_time({
      ...acc_need_time,
      [event.target.name]: event.target.value,
    });
  };
  const [risk_classification, setRisk_classification] = useState(
    formInfo.operatorDetailsFormOne.risk_classification
  );
  const [LicenseNumber, setLicenseNumber] = useState(
    formInfo.operatorDetailsFormOne.LicenseNumber
  );
  const [Legal_Business, setLegal_Business] = useState({
    name: formInfo.operatorDetailsFormOne.name,
    dba: formInfo.operatorDetailsFormOne.dba,
    physical_Address: formInfo.operatorDetailsFormOne.physical_Address,
    mailing_Address: formInfo.operatorDetailsFormOne.mailing_Address,

    samevalue: formInfo.operatorDetailsFormOne.samevalue,

    federal_EIN: formInfo.operatorDetailsFormOne.federal_EIN,
    cannabis_License_Number:
      formInfo.operatorDetailsFormOne.cannabis_License_Number,
    licensing_Type: formInfo.operatorDetailsFormOne.licensing_Type,
    other: formInfo.operatorDetailsFormOne.other,
    num_employees: formInfo.operatorDetailsFormOne.num_employees,
    accepted_states: formInfo.operatorDetailsFormOne.accepted_states,
    foreign_operations: formInfo.operatorDetailsFormOne.foreign_operations,
  });
  const commaSeparators = (num) => {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numParts.join(".");
  };
  //Primary Information
  const [Primary_Information, setPrimary_Information] = useState({
    primary_contact_name: formInfo.operatorDetailsFormOne.primary_contact_name,
    // primary_contact_name: localStorage.getItem('name'),
    dob: formInfo.operatorDetailsFormOne.dob,
    primary_address: formInfo.operatorDetailsFormOne.primary_address,
    mail: formInfo.operatorDetailsFormOne.mail,
    // mail: localStorage.getItem('email'),
    primary_Phone: formInfo.operatorDetailsFormOne.primary_Phone,
    // primary_Phone: localStorage.getItem('phoneNumber'),
    ownership: formInfo.operatorDetailsFormOne.ownership,
    ownership_Percentage: formInfo.operatorDetailsFormOne.ownership_Percentage,

    authority_sign: formInfo.operatorDetailsFormOne.authority_sign,
    us_Citizenship: formInfo.operatorDetailsFormOne.us_Citizenship,
  });
  // Operational Details:-
  const [Operational_Details, setOperational_Details] = useState({
    products_purchased_providers:
      formInfo.operatorDetailsFormTwo.products_purchased_providers,
    licensed_provider: formInfo.operatorDetailsFormTwo.licensed_provider,
    acc_need: formInfo.operatorDetailsFormTwo.acc_need,

    num_locs: formInfo.operatorDetailsFormTwo.num_locs,
    transactions_Per_month:
      formInfo.operatorDetailsFormTwo.transactions_Per_month,
    // managed_square_feet: formInfo.operatorDetailsFormTwo.managed_square_feet,
    purchase_amount_per_sale:
      formInfo.operatorDetailsFormTwo.purchase_amount_per_sale,
    num_plants: formInfo.operatorDetailsFormTwo.num_plants,
    average_quantity_per_sale:
      formInfo.operatorDetailsFormTwo.average_quantity_per_sale,
    unit: formInfo.operatorDetailsFormTwo.unit,

    facility_owned: formInfo.operatorDetailsFormTwo.facility_owned,
    facility_leased: formInfo.operatorDetailsFormTwo.facility_leased,
    // facility_ownedorleased
    facility: formInfo.operatorDetailsFormTwo.facility,

    lease_term: formInfo.operatorDetailsFormTwo.lease_term,
    egmi: formInfo.operatorDetailsFormTwo.egmi,
    types_customers: formInfo.operatorDetailsFormTwo.types_customers,
  });
  //Other Operational Details:-
  const [Other_Operational_Details, setOther_Operational_Details] = useState({
    principal_business: formInfo.operatorDetailsFormTwo.principal_business,
    beneficial_owners: formInfo.operatorDetailsFormTwo.beneficial_owners,
    number_beneficial_owner:
      formInfo.operatorDetailsFormTwo.number_beneficial_owner,
  });
  // Additional_Locs :-
  const [Additional_Locs, setAdditional_Locs] = useState([
    ...formInfo.operatorDetailsFormTwo.additional_locs,
  ]);
  // const [Additional_Locs, setAdditional_Locs] = useState({
  //   loc_name: formInfo.operatorDetailsFormTwo.loc_name,
  //   license: formInfo.operatorDetailsFormTwo.license,
  //   deposit_acc_need: formInfo.operatorDetailsFormTwo.deposit_acc_need,
  //   loc_address: formInfo.operatorDetailsFormTwo.loc_address,
  // })
  // Anticipated_Transaction_Activity :-
  const [
    Anticipated_Transaction_Activity,
    setAnticipated_Transaction_Activity,
  ] = useState({
    amount_Initial_Deposit:
      formInfo.operatorDetailsFormTwo.amount_Initial_Deposit,
    source_Initial_Deposit:
      formInfo.operatorDetailsFormTwo.source_Initial_Deposit,
    estimated_total_amount_monthly_deposit:
      formInfo.operatorDetailsFormTwo.estimated_total_amount_monthly_deposit,
    estimated_total_num_monthly_deposit:
      formInfo.operatorDetailsFormTwo.estimated_total_num_monthly_deposit,
    anticipate_cash_deposits:
      formInfo.operatorDetailsFormTwo.anticipate_cash_deposits,
    amount_cash_deposits: formInfo.operatorDetailsFormTwo.amount_cash_deposits,
    frequency_cash_deposits:
      formInfo.operatorDetailsFormTwo.frequency_cash_deposits,
    estimated_spend: formInfo.operatorDetailsFormTwo.estimated_spend,
    anticipate_cash_withdrawals:
      formInfo.operatorDetailsFormTwo.anticipate_cash_withdrawals,
    amount_cash_withdrawals:
      formInfo.operatorDetailsFormTwo.amount_cash_withdrawals,
    frequency_cash_withdrawals:
      formInfo.operatorDetailsFormTwo.frequency_cash_withdrawals,
    monthly_payroll: formInfo.operatorDetailsFormTwo.monthly_payroll,
    cash_pick_ups: formInfo.operatorDetailsFormTwo.cash_pick_ups,
    frequency_cash_pick_ups:
      formInfo.operatorDetailsFormTwo.frequency_cash_pick_ups,
    estimated_cash_pick_ups:
      formInfo.operatorDetailsFormTwo.estimated_cash_pick_ups,
  });

  // Company_Documentation:-

  const [Company_Documentation, setCompany_Documentation] = useState(
    formInfo.operatorDetailsFormThree
  );
  const [mainCheckBox, setMainCheckBox] = useState(formInfo.mainCheckBox);
  const MainCheckBoxHandler = (e) => {
    if (e.target.checked) {
      setMainCheckBox("yes");
    } else {
      setMainCheckBox("no");
    }
    if (Company_Documentation.length < 25 && Company_Documentation.length > 0) {
      setMainCheckBox("intermediate");
    }
  };
  //Legacy Cash
  const [Legacy_Cash, setLegacy_Cash] = useState({
    legacy_cash: formInfo.operatorDetailsFormFour.legacy_cash,
    documents_available: formInfo.operatorDetailsFormFour.documents_available,
  });

  // Cash Management:-
  const [Cash_Management, setCash_Management] = useState({
    business_acc: formInfo.operatorDetailsFormFour.business_acc,
    bank_name: formInfo.operatorDetailsFormFour.bank_name,
    thisvalue: formInfo.operatorDetailsFormFour.thisvalue,
    daysormonths: formInfo.operatorDetailsFormFour.daysormonths,

    reason_to_close: formInfo.operatorDetailsFormFour.reason_to_close,
    // payroll_service: formInfo.operatorDetailsFormFour.payroll_service,
    // paid_cash: formInfo.operatorDetailsFormFour.paid_cash,
    // payroll_accepted: formInfo.operatorDetailsFormFour.payroll_accepted,
    // taxes_cash: formInfo.operatorDetailsFormFour.taxes_cash,
    penalty_cash: formInfo.operatorDetailsFormFour.penalty_cash,
    tax_payment: formInfo.operatorDetailsFormFour.tax_payment,
    number_vendors: formInfo.operatorDetailsFormFour.number_vendors,
    vendor_payment_methods:
      formInfo.operatorDetailsFormFour.vendor_payment_methods,
    international_vendor: formInfo.operatorDetailsFormFour.international_vendor,
    electronic_payment: formInfo.operatorDetailsFormFour.electronic_payment,
    current_cash_managment:
      formInfo.operatorDetailsFormFour.current_cash_managment,
  });
  //Transfer from Existing Bank Account
  const [Transfer_Existing_Bank_Account, setTransfer_Existing_Bank_Account] =
    useState({
      financial_institution:
        formInfo.operatorDetailsFormFour.financial_institution,
      existing_bank_contact:
        formInfo.operatorDetailsFormFour.existing_bank_contact,
      existing_bank_name: formInfo.operatorDetailsFormFour.existing_bank_name,
      original_deposite: formInfo.operatorDetailsFormFour.original_deposite,
      prev_bank_verified: formInfo.operatorDetailsFormFour.prev_bank_verified,
      reason_closure: formInfo.operatorDetailsFormFour.reason_closure,
      prev_bank_aware: formInfo.operatorDetailsFormFour.prev_bank_aware,
    });

  // Electronic Payments Settlement

  const [Electronic_Payments_Settlement, setElectronic_Payments_Settlement] =
    useState({
      settlement_num_loc: formInfo.operatorDetailsFormFive.settlement_num_loc,
      msb: formInfo.operatorDetailsFormFive.msb,
      money_transmitted_license:
        formInfo.operatorDetailsFormFive.money_transmitted_license,
      types_payments: formInfo.operatorDetailsFormFive.types_payments,
      mobile_app: formInfo.operatorDetailsFormFive.mobile_app,
      pos: formInfo.operatorDetailsFormFive.pos,
      credit_card: formInfo.operatorDetailsFormFive.credit_card,
      bank_aware: formInfo.operatorDetailsFormFive.bank_aware,
      merchant_processor: formInfo.operatorDetailsFormFive.merchant_processor,
      terminal: formInfo.operatorDetailsFormFive.terminal,
    });
  // ATM Machine Transactions

  const [ATM_Machine, setATM_Machine] = useState({
    atm_own: formInfo.operatorDetailsFormFive.atm_own,
    atm_loc: formInfo.operatorDetailsFormFive.atm_loc,
    atm_multiple: formInfo.operatorDetailsFormFive.atm_multiple,
    atm_fill: formInfo.operatorDetailsFormFive.atm_fill,
    atm_third_party: formInfo.operatorDetailsFormFive.atm_third_party,
    atm_fill_company: formInfo.operatorDetailsFormFive.atm_fill_company,
    atm_currency: formInfo.operatorDetailsFormFive.atm_currency,
    atm_crypto: formInfo.operatorDetailsFormFive.atm_crypto,
    atm_deposite: formInfo.operatorDetailsFormFive.atm_deposite,
    atm_receipt: formInfo.operatorDetailsFormFive.atm_receipt,
    atm_receipt: formInfo.operatorDetailsFormFive.atm_receipt,
  });

  // Accounting

  const [Accounting, setAccounting] = useState({
    accounting_system: formInfo.operatorDetailsFormFive.accounting_system,
    accountant: formInfo.operatorDetailsFormFive.accountant,
    firm_name: formInfo.operatorDetailsFormFive.firm_name,
  });

  // Compliance_Details

  const [Compliance_Details, setCompliance_Details] = useState({
    non_compliance: formInfo.operatorDetailsFormFive.non_compliance,
    compliance_desc: formInfo.operatorDetailsFormFive.compliance_desc,
    compliance_officer: formInfo.operatorDetailsFormFive.compliance_officer,
    compliance_partner: formInfo.operatorDetailsFormFive.compliance_partner,
    compliance_partner_name:
      formInfo.operatorDetailsFormFive.compliance_partner_name,
    compliance_group: formInfo.operatorDetailsFormFive.compliance_group,
    onsite_inspection: formInfo.operatorDetailsFormFive.onsite_inspection,
    compliance_date: formInfo.operatorDetailsFormFive.compliance_date,
    compliance_support: formInfo.operatorDetailsFormFive.compliance_support,

    frequency_compliance: formInfo.operatorDetailsFormFive.frequency_compliance,
    compliance_trainning: formInfo.operatorDetailsFormFive.compliance_trainning,
    operating_procedures: formInfo.operatorDetailsFormFive.operating_procedures,
  });

  // Following services:-
  const [Following_Services, setFollowing_Services] = useState(
    formInfo.operatorDetailsFormFive.follwing_services
  );
  const [servicesOffered, setServicesOffered] = useState(
    formInfo.BankDetailsFormOne.servicesOffered
  );
  const [servicesPrices, setServicesPrices] = useState(
    formInfo.BankDetailsFormOne.servicesPrices
  );
  const ServicesPriceChangeHandler = (event) => {
    setServicesPrices({
      ...servicesPrices,
      [event.target.name]: event.target.value,
    });
  };
  const ServicesOfferedHandler = (event) => {
    if (event.target.checked) {
      setServicesOffered([...servicesOffered, event.target.value]);
    } else {
      const data = servicesOffered.filter(
        (service) => service !== event.target.value
      );
      setServicesOffered([...data]);
    }
  };
  const [acc_creation_time, setAcc_creation_time] = useState(
    formInfo.BankDetailsFormOne.acc_creation_time
  );
  const AccountCreationTimehandler = (event) => {
    console.log(event.target.value, "8112354");
    // Validate(event, event.target.name, event.target.value, errors, setErrors);

    setAcc_creation_time({
      ...acc_creation_time,
      [event.target.name]: event.target.value,
    });
  };
  //States for bank form:-
  const [Risk_classification_Bank, setRisk_classification_Bank] = useState(
    formInfo.BankDetailsFormOne.risk_classification
  );

  const [Legal_Business_Bank, setLegal_Business_Bank] = useState({
    licensing_Type: formInfo.BankDetailsFormOne.licensing_Type,
    // LicenseNumber: formInfo.BankDetailsFormOne.LicenseNumber,

    other: formInfo.BankDetailsFormOne.other,
    num_employees_min: formInfo.BankDetailsFormOne.num_employees_min,
    num_employees_max: formInfo.BankDetailsFormOne.num_employees_max,

    accepted_states: formInfo.BankDetailsFormOne.accepted_states,
    foreign_operations: formInfo.BankDetailsFormOne.foreign_operations,
  });
  const [Primary_Information_Bank, setPrimary_Information_Bank] = useState({
    ownership_Percentage_min:
      formInfo.BankDetailsFormOne.ownership_Percentage_min,
    // ownership_Percentage_max: formInfo.BankDetailsFormOne.ownership_Percentage_max,

    us_Citizenship: formInfo.BankDetailsFormOne.us_Citizenship,
  });
  const [Operational_details_Bank, setOperational_Details_Bank] = useState({
    products_purchased_providers:
      formInfo.BankDetailsFormTwo.products_purchased_providers,
    licensed_provider: formInfo.BankDetailsFormTwo.licensed_provider,
    accept_CRB_min: formInfo.BankDetailsFormTwo.accept_CRB_min,
    accept_CRB_max: formInfo.BankDetailsFormTwo.accept_CRB_max,

    num_locs_min: formInfo.BankDetailsFormTwo.num_locs_min,
    num_locs_max: formInfo.BankDetailsFormTwo.num_locs_max,
    transactions_Per_month_min:
      formInfo.BankDetailsFormTwo.transactions_Per_month_min,
    transactions_Per_month_max:
      formInfo.BankDetailsFormTwo.transactions_Per_month_max,
    // managed_square_feet_min:
    //   formInfo.BankDetailsFormTwo.managed_square_feet_min,
    // managed_square_feet_max:
    //   formInfo.BankDetailsFormTwo.managed_square_feet_max,
    // facility_owned: formInfo.BankDetailsFormTwo.facility_owned,
    // facility_leased: formInfo.BankDetailsFormTwo.facility_leased,
    facility: formInfo.BankDetailsFormTwo.facility,

    types_customers: formInfo.BankDetailsFormTwo.types_customers,
  });

  const [Other_Operational_Details_Bank, setOther_Operational_Details_Bank] =
    useState({
      principal_business: formInfo.BankDetailsFormTwo.principal_business,
      beneficial_owners: formInfo.BankDetailsFormTwo.beneficial_owners,
      number_beneficial_owner_min:
        formInfo.BankDetailsFormTwo.number_beneficial_owner_min,
      number_beneficial_owner_max:
        formInfo.BankDetailsFormTwo.number_beneficial_owner_max,
    });
  const [
    Anticipated_Transaction_Activity_Bank,
    setAnticipated_Transaction_Activity_Bank,
  ] = useState({
    amount_Initial_Deposit_min:
      formInfo.BankDetailsFormTwo.amount_Initial_Deposit_min,
    amount_Initial_Deposit_max:
      formInfo.BankDetailsFormTwo.amount_Initial_Deposit_max,
    estimated_total_amount_monthly_deposit_min:
      formInfo.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_min,
    estimated_total_amount_monthly_deposit_max:
      formInfo.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_max,
    estimated_total_num_monthly_deposit_min:
      formInfo.BankDetailsFormTwo.estimated_total_num_monthly_deposit_min,
    estimated_total_num_monthly_deposit_max:
      formInfo.BankDetailsFormTwo.estimated_total_num_monthly_deposit_max,
    anticipate_cash_deposits:
      formInfo.BankDetailsFormTwo.anticipate_cash_deposits,
    // anticipate_cash_deposits_max: formInfo.BankDetailsFormTwo.anticipate_cash_deposits_max,
    amount_cash_deposits_min:
      formInfo.BankDetailsFormTwo.amount_cash_deposits_min,
    amount_cash_deposits_max:
      formInfo.BankDetailsFormTwo.amount_cash_deposits_max,
    frequency_cash_deposits:
      formInfo.BankDetailsFormTwo.frequency_cash_deposits,
    // frequency_cash_deposits_max: formInfo.BankDetailsFormTwo.frequency_cash_deposits_max,
    estimated_spend_min: formInfo.BankDetailsFormTwo.estimated_spend_min,
    estimated_spend_max: formInfo.BankDetailsFormTwo.estimated_spend_max,
    anticipate_cash_withdrawals:
      formInfo.BankDetailsFormTwo.anticipate_cash_withdrawals,
    amount_cash_withdrawals_max:
      formInfo.BankDetailsFormTwo.amount_cash_withdrawals_max,
    frequency_cash_withdrawals:
      formInfo.BankDetailsFormTwo.frequency_cash_withdrawals,
    // frequency_cash_withdrawals_max: formInfo.BankDetailsFormTwo.frequency_cash_withdrawals_max,
    monthly_payroll_min: formInfo.BankDetailsFormTwo.monthly_payroll_min,
    monthly_payroll_max: formInfo.BankDetailsFormTwo.monthly_payroll_max,
    cash_pick_ups: formInfo.BankDetailsFormTwo.cash_pick_ups,
    frequency_cash_pick_ups:
      formInfo.BankDetailsFormTwo.frequency_cash_pick_ups,
    estimated_cash_pick_ups_min:
      formInfo.BankDetailsFormTwo.estimated_cash_pick_ups_min,
    estimated_cash_pick_ups_max:
      formInfo.BankDetailsFormTwo.estimated_cash_pick_ups_max,
  });
  const [Company_Documentation_Bank, setCompany_Documentation_Bank] = useState(
    formInfo.BankDetailsFormThree.Company_Documentation
  );

  const [Legacy_Cash_Bank, setLegacy_Cash_Bank] = useState({
    legacy_cash: formInfo.BankDetailsFormFour.legacy_cash,
    documents_available: formInfo.BankDetailsFormFour.documents_available,
  });

  const [Cash_Management_Bank, setCash_Management_Bank] = useState({
    business_acc: formInfo.BankDetailsFormFour.business_acc,
    // length_time: formInfo.BankDetailsFormFour.length_time,
    value: formInfo.BankDetailsFormFour.value,
    daysormonthsoryears: formInfo.BankDetailsFormFour.daysormonthsoryears,
    payroll_service: formInfo.BankDetailsFormFour.payroll_service,
    paid_cash: formInfo.BankDetailsFormFour.paid_cash,
    payroll_accepted: formInfo.BankDetailsFormFour.payroll_accepted,
    taxes_cash: formInfo.BankDetailsFormFour.taxes_cash,
    penalty_cash: formInfo.BankDetailsFormFour.penalty_cash,
    tax_payment: formInfo.BankDetailsFormFour.tax_payment,
    number_vendors_min: formInfo.BankDetailsFormFour.number_vendors_min,
    number_vendors_max: formInfo.BankDetailsFormFour.number_vendors_max,
    vendor_payment_methods: formInfo.BankDetailsFormFour.vendor_payment_methods,
    international_vendor: formInfo.BankDetailsFormFour.international_vendor,
  });

  const [Transfer_Existing_Bank_Bank, setTransfer_Existing_Bank_Bank] =
    useState({
      prev_bank_verified: formInfo.BankDetailsFormFour.prev_bank_verified,
      prev_bank_aware: formInfo.BankDetailsFormFour.prev_bank_aware,
    });
  //Form values
  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});
  const [errosBank, setErrorsBank] = useState({});

  //A method to handle form inputs
  const LegalBusinessHandler = (event) => {
    console.log(event.target.value, "event");
    if (event.target.type === "checkbox") {
      if (event.target.name === "pg-bx") {
        if (event.target.checked) {
          const data = [...Legal_Business.licensing_Type, event.target.value];
          setLegal_Business({ ...Legal_Business, licensing_Type: data });
        } else {
          const data = Legal_Business.licensing_Type.filter(
            (i) => i !== event.target.value
          );
          setLegal_Business({ ...Legal_Business, licensing_Type: data });
        }
      } else {
        if (event.target.checked) {
          setLegal_Business({
            ...Legal_Business,
            [event.target.name]: "yes",
          });
        } else {
          setLegal_Business({
            ...Legal_Business,
            [event.target.name]: "no",
          });
        }
      }
    } else {
      //To stop default events
      event.persist();
      console.log(event.target.name, event.target.value, "targetsssss");
      let name = event.target.name;
      let val = event.target.value;

      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      //Let's set these values in state

      setLegal_Business({
        ...Legal_Business,
        [name]: val,
      });
    }
  };
  const LicenseNumberHandler = (event) => {
    setLicenseNumber({
      ...LicenseNumber,
      [event.target.name]: event.target.value,
    });
  };
  const RiskClassificationHandler = (event) => {
    console.log(event.target.value, "risky");
    setRisk_classification(event.target.value);
  };
  const PrimaryInformationHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: "yes",
        });
      } else {
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: "no",
        });
      }
    } else {
      // primary_contact_name
      if (event.target.name === "primary_contact_name") {
        const reg = /^([^0-9$%*@!&#^{}()/;+.-=<>?,""''_`~|]*)$/;
        if (reg.test(event.currentTarget.value)) {
          //  Validate (
          //     event,
          //     "primary_contact_name",
          //     event.target.value,
          //     errors,
          //     setErrors
          //   );
          setPrimary_Information({
            ...Primary_Information,
            primary_contact_name: event.target.value,
          });
        }
      } else {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, "seethevalue");
        // calling the custom validate function
        // Validate(event, name, val, errors, setErrors);
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  // Operational Details handler Function:-
  const OperationalDetailshandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.name !== "pg-bx") {
        if (event.target.checked) {
          setOperational_Details({
            ...Operational_Details,
            [event.target.name]: "yes",
          });
        } else {
          setOperational_Details({
            ...Operational_Details,
            [event.target.name]: "no",
          });
        }
      } else {
        if (event.target.checked) {
          const myData = [
            ...Operational_Details.types_customers,
            event.target.value,
          ];
          setOperational_Details({
            ...Operational_Details,
            types_customers: myData,
          });
        } else {
          const myData = Operational_Details.types_customers.filter(
            (i) => i !== event.target.value
          );
          setOperational_Details({
            ...Operational_Details,
            types_customers: myData,
          });
        }
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, "6725737");
      console.log(name, "name");
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setOperational_Details({
        ...Operational_Details,
        [event.target.name]: event.target.value,
      });
    }
  };
  // OtherOperationDetailsHandler
  const OtherOperationDetailsHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setOther_Operational_Details({
          ...Other_Operational_Details,
          [event.target.name]: "yes",
        });
      } else {
        setOther_Operational_Details({
          ...Other_Operational_Details,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setOther_Operational_Details({
        ...Other_Operational_Details,
        [event.target.name]: event.target.value,
      });
    }
  };
  //AdditionalLocshandler
  const AdditionalLocshandler = (event, index) => {
    console.log(event.target.name, index, "handle-index");
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        // Additional_Locs[index].deposit_acc_need = "yes";
        const Obj = { ...Additional_Locs[index] };
        Obj.deposit_acc_need = "yes";
        Additional_Locs[index] = { ...Obj };
        // setAdditional_Locs({
        //   ...Additional_Locs, [event.target.name]: 'yes'
        // })
        setAdditional_Locs([...Additional_Locs]);
      } else {
        const Obj = { ...Additional_Locs[index] };
        Obj.deposit_acc_need = "no";
        Additional_Locs[index] = { ...Obj };
        // setAdditional_Locs({
        //   ...Additional_Locs, [event.target.name]: 'no'
        // })
        setAdditional_Locs([...Additional_Locs]);
      }
    } else {
      // event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, "val12");
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      const data = JSON.parse(JSON.stringify(Additional_Locs));
      // console.log(data[index].loc_name,'868tg')
      // console.log(data[index][event.target.name],'llppprrtr')
      console.log(data, "my-data");
      data[index][name] = val;
      console.log(data, "datapp6p6");

      // setAdditional_Locs({
      //   ...Additional_Locs, [event.target.name]: event.target.value
      // })
      setAdditional_Locs([...data]);
    }
  };
  //Anticipated_Transaction_ActivityHandler Function:-
  const AnticipatedTransactionActivityHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setAnticipated_Transaction_Activity({
          ...Anticipated_Transaction_Activity,
          [event.target.name]: "yes",
        });
      } else {
        setAnticipated_Transaction_Activity({
          ...Anticipated_Transaction_Activity,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors)
      if (name === "amount_cash_deposits" || name === "estimated_spend") {
        if (
          Anticipated_Transaction_Activity.anticipate_cash_deposits === "yes"
        ) {
          // Validate(event, name, val, errors, setErrors);
        }
      }
      setAnticipated_Transaction_Activity({
        ...Anticipated_Transaction_Activity,
        [event.target.name]: event.target.value,
      });
    }
  };
  // CompanyDocumentationhandler :-
  const CompanyDocumentationhandler = (event) => {
    console.log("firedd");
    if (event.target.checked) {
      setCompany_Documentation([
        ...Company_Documentation,
        {
          documentation: event.target.value,
        },
      ]);
    } else {
      const data = Company_Documentation.filter(
        (term) => term.documentation !== event.target.value
      );
      setCompany_Documentation([...data]);
    }
  };

  //Legacy_Cash Handler:-
  const LegacyCashhandler = (event) => {
    if (event.target.name === "legacy_cash") {
      if (event.target.checked) {
        setLegacy_Cash({
          ...Legacy_Cash,
          legacy_cash: "yes",
        });
      } else {
        setLegacy_Cash({
          ...Legacy_Cash,
          legacy_cash: "no",
        });
      }
    } else {
      if (event.target.checked) {
        // Legacy_Cash.documents_available.push({
        //   docs_avail: event.target.value
        // })

        const data = { docs_avail: event.target.value };
        // Legacy_Cash.documents_available.push(data)
        setLegacy_Cash({
          ...Legacy_Cash,
          documents_available: [...Legacy_Cash.documents_available, data],
        });
      } else {
        const data = Legacy_Cash.documents_available.filter(
          (term) => term.docs_avail !== event.target.value
        );
        setLegacy_Cash({
          ...Legacy_Cash,
          documents_available: data,
        });
      }
    }
  };

  //// Cash Management Handler:-
  const CashManagementHandler = (event) => {
    console.log(event.target.value, "83749574925742974");
    if (
      event.target.type === "checkbox" &&
      event.target.name !== "vendor_payment_methods"
    ) {
      if (event.target.checked) {
        setCash_Management({
          ...Cash_Management,
          [event.target.name]: "yes",
        });
      } else {
        setCash_Management({
          ...Cash_Management,
          [event.target.name]: "no",
        });
      }
    }
    if (event.target.name === "vendor_payment_methods") {
      if (event.target.checked) {
        setCash_Management({
          ...Cash_Management,
          vendor_payment_methods: [
            ...Cash_Management.vendor_payment_methods,
            event.target.value,
          ],
        });
      } else {
        console.log(Cash_Management.vendor_payment_methods, "4555555");
        const data = Cash_Management.vendor_payment_methods.filter(
          (term) => term !== event.target.value
        );
        console.log(data, "09237529357");
        setCash_Management({
          ...Cash_Management,
          vendor_payment_methods: data,
        });
      }
    }
    if (event.target.type !== "checkbox") {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setCash_Management({
        ...Cash_Management,
        [event.target.name]: val,
      });
    }
  };

  const TransferExistingBankHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setTransfer_Existing_Bank_Account({
          ...Transfer_Existing_Bank_Account,
          [event.target.name]: "yes",
        });
      } else {
        setTransfer_Existing_Bank_Account({
          ...Transfer_Existing_Bank_Account,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setTransfer_Existing_Bank_Account({
        ...Transfer_Existing_Bank_Account,
        [event.target.name]: event.target.value,
      });
    }
  };
  const FollowingServicesHandler = (event) => {
    if (event.target.checked) {
      setFollowing_Services([
        ...Following_Services,
        {
          services: event.target.value,
        },
      ]);
    } else {
      const data = Following_Services.filter(
        (term) => term.services !== event.target.value
      );
      setFollowing_Services([...data]);
    }
  };
  const ElectronicPaymentsSettlementChangeHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setElectronic_Payments_Settlement({
          ...Electronic_Payments_Settlement,
          [event.target.name]: "yes",
        });
      } else {
        setElectronic_Payments_Settlement({
          ...Electronic_Payments_Settlement,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setElectronic_Payments_Settlement({
        ...Electronic_Payments_Settlement,
        [event.target.name]: event.target.value,
      });
    }
  };

  //  ATMMachineTransactions Change Handler:-
  const ATMMachineTransactionsChangeHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setATM_Machine({
          ...ATM_Machine,
          [event.target.name]: "yes",
        });
      } else {
        setATM_Machine({
          ...ATM_Machine,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setATM_Machine({
        ...ATM_Machine,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Accounting Change Handler:-

  const AccountingChangeHandler = (event) => {
    console.log(event.target.value, "radioval");
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setAccounting({
          ...Accounting,
          [event.target.name]: "yes",
        });
      } else {
        setAccounting({
          ...Accounting,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setAccounting({
        ...Accounting,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Compliance_Details ChangeHandler

  const Compliance_DetailsChangeHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setCompliance_Details({
          ...Compliance_Details,
          [event.target.name]: "yes",
        });
      } else {
        setCompliance_Details({
          ...Compliance_Details,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      console.log(val, name, "890");
      // Validate(event, name, val, errors, setErrors);
      setCompliance_Details({
        ...Compliance_Details,
        [event.target.name]: event.target.value,
      });
    }
  };

  //For Bank
  //For Risk classification:-
  const RiskClassificationBankhandler = (event) => {
    if (event.target.checked) {
      const data = [...Risk_classification_Bank, event.target.value];
      setRisk_classification_Bank(data);
    } else {
      const data = Risk_classification_Bank.filter(
        (x) => x !== event.target.value
      );
      setRisk_classification_Bank(data);
    }
  };
  //Legal Business Information Handler

  const BankLegalBusinessInfoHandler = (event) => {
    console.log(event, "event");
    if (event.target.name === "licensing_Type") {
      if (event.target.checked) {
        const data = [
          ...Legal_Business_Bank.licensing_Type,
          event.target.value,
        ];
        console.log(data, "001");
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          licensing_Type: data,
        });
      } else {
        const data = Legal_Business_Bank.licensing_Type.filter(
          (term) => term !== event.target.value
        );
        console.log(data, "002");
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          licensing_Type: data,
        });
      }
    } else {
      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          setLegal_Business_Bank({
            ...Legal_Business_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setLegal_Business_Bank({
            ...Legal_Business_Bank,
            [event.target.name]: "no",
          });
        }
      } else if (event.target.name !== "accepted_states") {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        BankValidator(event, name, val, errosBank, setErrorsBank);
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  const BankPrimaryContactInfoHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setPrimary_Information_Bank({
          ...Primary_Information_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setPrimary_Information_Bank({
          ...Primary_Information_Bank,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setPrimary_Information_Bank({
        ...Primary_Information_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const BankOperationalDetailsHandler = (event) => {
    if (event.target.name === "types_customers") {
      if (event.target.checked) {
        const data = [
          ...Operational_details_Bank.types_customers,
          event.target.value,
        ];
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          types_customers: data,
        });
      } else {
        const data = Operational_details_Bank.types_customers.filter(
          (x) => x !== event.target.value
        );
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          types_customers: data,
        });
      }
    }
    if (
      event.target.type === "checkbox" &&
      event.target.name !== "types_customers"
    ) {
      if (event.target.checked) {
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          [event.target.name]: "no",
        });
      }
    } else if (event.target.name !== "types_customers") {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setOperational_Details_Bank({
        ...Operational_details_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const BankOtherOperationaldetailsHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setOther_Operational_Details_Bank({
          ...Other_Operational_Details_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setOther_Operational_Details_Bank({
          ...Other_Operational_Details_Bank,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setOther_Operational_Details_Bank({
        ...Other_Operational_Details_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const BankAnticipatedTransactionActivityHandler = (event) => {
    console.log(event.target.value, "888");
    console.log(event.target.name, "888");
    if (event.target.type === "checkbox") {
      if (
        event.target.name === "anticipate_cash_withdrawals" ||
        event.target.name === "cash_pick_ups" ||
        event.target.name === "anticipate_cash_deposits"
      ) {
        if (event.target.checked) {
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            [event.target.name]: "no",
          });
        }
      } else if (event.target.name === "frequency_cash_withdrawals") {
        console.log("8908");
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals,
            event.target.value,
          ];
          console.log(data, "my-data");
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_withdrawals: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_withdrawals: data,
          });
        }
      } else if (event.target.name === "frequency_cash_pick_ups") {
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups,
            event.target.value,
          ];
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_pick_ups: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_pick_ups: data,
          });
        }
      } else if (event.target.name === "frequency_cash_deposits") {
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_deposits,
            event.target.value,
          ];
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_deposits: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_deposits.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_deposits: data,
          });
        }
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setAnticipated_Transaction_Activity_Bank({
        ...Anticipated_Transaction_Activity_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const BankCompanyDocumentationHandler = (event) => {
    if (event.target.checked) {
      setCompany_Documentation_Bank([
        ...Company_Documentation_Bank,
        event.target.value,
      ]);
    } else {
      setCompany_Documentation_Bank(
        Company_Documentation_Bank.filter((x) => x !== event.target.value)
      );
    }
  };

  const BankLegacyCashhandler = (event) => {
    console.log(event.target.name, "122222");
    if (event.target.name === "legacy_cash") {
      if (event.target.checked) {
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          [event.target.name]: "no",
        });
      }
    } else if (event.target.name === "documents_available") {
      console.log("111111");
      if (event.target.checked) {
        const data = [
          ...Legacy_Cash_Bank.documents_available,
          event.target.value,
        ];

        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          documents_available: data,
        });
      } else {
        const data = Legacy_Cash_Bank.documents_available.filter(
          (x) => x !== event.target.value
        );
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          documents_available: data,
        });
      }
    }
  };

  const BankCashManagementhandler = (event) => {
    if (event.target.name === "vendor_payment_methods") {
      if (event.target.checked) {
        setCash_Management_Bank({
          ...Cash_Management_Bank,
          vendor_payment_methods: [
            ...Cash_Management_Bank.vendor_payment_methods,
            event.target.value,
          ],
        });
      } else {
        // console.log(Cash_Management.vendor_payment_methods, '4555555')
        const data = Cash_Management_Bank.vendor_payment_methods.filter(
          (term) => term !== event.target.value
        );
        console.log(data, "09237529357");
        setCash_Management_Bank({
          ...Cash_Management_Bank,
          vendor_payment_methods: data,
        });
      }
    } else {
      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          setCash_Management_Bank({
            ...Cash_Management_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setCash_Management_Bank({
            ...Cash_Management_Bank,
            [event.target.name]: "no",
          });
        }
      } else {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        BankValidator(event, name, val, errosBank, setErrorsBank);
        setCash_Management_Bank({
          ...Cash_Management_Bank,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const BankTransferExistingBankhandler = (event) => {
    if (event.target.checked) {
      setTransfer_Existing_Bank_Bank({
        ...Transfer_Existing_Bank_Bank,
        [event.target.name]: "yes",
      });
    } else {
      setTransfer_Existing_Bank_Bank({
        ...Transfer_Existing_Bank_Bank,
        [event.target.name]: "no",
      });
    }
  };
  return {
    Legal_Business,
    errors,
    errosBank,
    setErrorsBank,
    RiskClassificationHandler,
    risk_classification,
    LegalBusinessHandler,
    setLegal_Business,
    PrimaryInformationHandler,
    Primary_Information,
    OperationalDetailshandler,
    OtherOperationDetailsHandler,
    AdditionalLocshandler,
    AnticipatedTransactionActivityHandler,
    Operational_Details,
    Other_Operational_Details,
    Additional_Locs,
    Anticipated_Transaction_Activity,
    CompanyDocumentationhandler,
    Company_Documentation,
    LegacyCashhandler,
    Legacy_Cash,
    CashManagementHandler,
    Cash_Management,
    TransferExistingBankHandler,
    Transfer_Existing_Bank_Account,
    Electronic_Payments_Settlement,
    ATM_Machine,
    Accounting,
    Compliance_Details,
    Following_Services,
    ElectronicPaymentsSettlementChangeHandler,
    ATMMachineTransactionsChangeHandler,
    AccountingChangeHandler,
    Compliance_DetailsChangeHandler,
    FollowingServicesHandler,
    //Banks
    RiskClassificationBankhandler,
    Risk_classification_Bank,
    BankLegalBusinessInfoHandler,
    Legal_Business_Bank,
    setLegal_Business_Bank,
    setElectronic_Payments_Settlement,
    BankPrimaryContactInfoHandler,
    Primary_Information_Bank,
    Operational_details_Bank,
    BankOperationalDetailsHandler,
    Other_Operational_Details_Bank,
    BankOtherOperationaldetailsHandler,
    Anticipated_Transaction_Activity_Bank,
    BankAnticipatedTransactionActivityHandler,
    Company_Documentation_Bank,
    BankCompanyDocumentationHandler,
    Legacy_Cash_Bank,
    BankLegacyCashhandler,
    Cash_Management_Bank,
    BankCashManagementhandler,
    Transfer_Existing_Bank_Bank,
    BankTransferExistingBankhandler,
    setAdditional_Locs,
    setCompany_Documentation,
    mainCheckBox,
    setMainCheckBox,
    acc_need_time,
    setAcc_need_time,
    AccountNeedTimehandler,
    servicesWanted,
    setServicesWanted,
    ServicesWantedHandler,
    servicesOffered,
    ServicesOfferedHandler,
    acc_creation_time,
    AccountCreationTimehandler,
    servicesPrices,
    ServicesPriceChangeHandler,
    LicenseNumberHandler,
    LicenseNumber,
    accountPurpose,
    setAccountPurpose,
    AccountPurposeHandler,
    accountPurposeInput,
    // NextHandlerOne,
  };
};

export default useForm;
