import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsEnvelopeCheckFill, BsEyeFill, BsToggleOn } from "react-icons/bs";
import { MdAccountCircle } from "react-icons/md";
import { FcMoneyTransfer } from "react-icons/fc";

import axios from "axios";
import {
  AdminAsUser_LogIn_URL,
  Admin_ActiveBankAccountStatus_URL,
  Admin_UpdateBankStage_URL,
} from "../../Api/Api";
import {
  bankActiveStatusHandler,
  fetchBanks,
  paymentStageAndPriceUpdate,
} from "../../Store/BankSlice";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
const BankListing = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const [stage, setStage] = useState({
    stage: "",
    price: "",
  });
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [api, setApi] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  // const [isLoading, setIsLoading] = useState(false)
  const { message, data, isLoading } = useSelector((state) => state.Bank);
  console.log(data, "data");
  const Pagination = () => {
    let len = data?.length;
    let perPagedatalength = Math.ceil(len / perPage);
    console.log(perPagedatalength, "111111111111");
    let target = [];
    for (let x = 0; x < perPagedatalength; x++) {
      target.push(perPagedatalength[x]);
    }
    const arr = data?.slice(perPage * (page - 1), perPage * page);
    return {
      arr: arr,
      perPagedatalength: target,
    };
  };
  useEffect(() => {
    dispatch(fetchBanks());
  }, [open, page, perPage]);
  // useEffect(()=>{
  //   Pagination()
  // },[perPage])

  const navigate = useNavigate();
  const AdminAsUserLoginHandler = (email) => {
    console.log(email, "target-email");
    axios
      .post(
        AdminAsUser_LogIn_URL,
        {
          email: email,
          role: "bank",
        },
        {
          params: {
            role: "admin",
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admintoken"),
          },
        }
      )
      .then((res) => {
        console.log(res.data, "logintargetdata");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("name", res.data.user.bankName);

        const URL = `http://localhost:3000/bank-dashboard`;
        const url = "https://main.dqis8qiw3e3yi.amplifyapp.com/bank-dashboard";
        // navigate({to: `${URL}`})
        // window.location.href =`${URL}`
        window.open(`${URL}`, "_blank");

        //   window.location.reload()
        //   setTimeout(()=>{
        //     swal('Done!',`Logged In As ${res.data.user.bankName}`,'success')
        // },[1200])
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const StageHandler = (e) => {
    setStage({
      ...stage,
      [e.target.name]: e.target.value,
    });
  };

  const UpdateHandler = () => {
    console.log(stage, "finaldata-stage");

    swal({
      title: "Are you sure you want to change the stage?",
      className: "custom-content",
      buttons: {
        confirm: { text: "YES", className: "sweet-warning" },
        cancel: "NO",
        // confirmButtonColor: '#8CD4F5',
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        dispatch(paymentStageAndPriceUpdate({ id: id, stage: stage }));
        setTimeout(() => {
          setOpen(!open);
          swal("Updated!", ` Details updated successfully!`, "success");
        }, 1200);
      }
    });
  };
  const BankActiveStatusHandler = (status, id) => {
    //  console.log(e.target.checked,id,'checkactivee')
    swal({
      title: "Are you sure you want to change the active status of the bank?",
      className: "custom-content",
      buttons: {
        confirm: { text: "YES", className: "sweet-warning" },
        cancel: "NO",
        // confirmButtonColor: '#8CD4F5',
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        //   axios.put(`${Admin_ActiveBankAccountStatus_URL}/${id}`,{
        //     headers: { 'Authorization': 'Bearer ' + localStorage.getItem('admintoken') }
        // }).then(res=>{
        //   console.log(res.data,'9879797')
        //     setApi(!api)
        //     swal('Changed',`${res.data.message}`,'success')
        //   }).catch(err=>console.log(err,'error'))

        const data = {
          status: status,
          id: id,
        };
        dispatch(bankActiveStatusHandler(data));
        setTimeout(() => {
          swal("Updted!", "Active status updated successfully!", "success");
          // setOpen(false)
        }, [1500]);
      } else {
        // setActivitystatus('No')
      }
    });
  };
  // const DetermineTotalCahrges = (stage,viewed,bankaccepted,accountOpened) => {
  //   switch (stage) {
  //     default:
  //       // text = "Looking forward to the Weekend";
  //       break;
  //     case 'View':
  //      return viewed
  //     case 'applicationAccepted':
  //       return bankaccepted
  //     case 'accountOpen':
  //       return accountOpened
  //     case 'documentsUploaded':
  //       // text = "Today is Saturday";
  //       break;
  //   }
  // }
  return (
    <div className="mainsection bnk_sctn">
      <div className="bank_content">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Select Stage
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}
            <div className="form-input-group">
              <div className="iform-group">
                {/* <h5 className="fromgroup_hdng">Facility Owned or Leased?</h5> */}
                <div className="">
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="View"
                      checked={stage.stage === "View"}
                      id="View Only"
                      name="stage"
                      onChange={StageHandler}
                      // checked={Operational_Details.types_customers === "Medical"}
                    />

                    {/* <span class="radio"></span>  */}
                    <label htmlFor="Owned" className="radio-button">
                      View
                    </label>
                    <br />
                    <br />
                    {stage.stage === "View" && (
                      <div
                        className="inpt_bx"
                        style={{
                          width: "17rem",
                          height: "",
                          marginTop: "",
                          marginLeft: "12rem",
                        }}
                      >
                        <input
                          type="number"
                          value={stage.price}
                          onChange={StageHandler}
                          name="price"
                          placeholder=""
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="applicationAccepted"
                      onChange={StageHandler}
                      id="Accept"
                      name="stage"
                      checked={stage.stage === "applicationAccepted"}
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Accept" className="radio-button">
                      ApplicationAccepted
                    </label>
                    <br />
                    <br />
                    {stage.stage === "applicationAccepted" && (
                      <div
                        className="inpt_bx"
                        style={{
                          width: "17rem",
                          height: "",
                          marginLeft: "12rem",
                        }}
                      >
                        <input
                          type="number"
                          value={stage.price}
                          onChange={StageHandler}
                          name="price"
                          placeholder=""
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="proposalAccepted"
                      checked={stage.stage === "proposalAccepted"}
                      id="Proposal Sent"
                      name="stage"
                      onChange={StageHandler}
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Proposal Sent" className="radio-button">
                      ProposalAccepted
                    </label>
                    <br />
                    <br />
                    {stage.stage === "proposalAccepted" && (
                      <div
                        className="inpt_bx"
                        style={{
                          width: "17rem",
                          height: "",
                          marginTop: "",
                          marginLeft: "12rem",
                        }}
                      >
                        <input
                          type="number"
                          value={stage.price}
                          onChange={StageHandler}
                          name="price"
                          placeholder=""
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="documentsUploaded"
                      id="Approved"
                      name="stage"
                      checked={stage.stage === "documentsUploaded"}
                      onChange={StageHandler}
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Approved" className="radio-button">
                      DocumentsUploaded
                    </label>
                    <br />
                    <br />
                    {stage.stage === "documentsUploaded" && (
                      <div
                        className="inpt_bx"
                        style={{
                          width: "17rem",
                          height: "",
                          marginTop: "",
                          marginLeft: "12rem",
                        }}
                      >
                        <input
                          type="number"
                          value={stage.price}
                          onChange={StageHandler}
                          name="price"
                          placeholder=""
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="accountOpen"
                      onChange={StageHandler}
                      id="Account Opened"
                      name="stage"
                      checked={stage.stage === "accountOpen"}
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Account Opened" className="radio-button">
                      AccountOpen
                    </label>
                    <br />
                    <br />
                    {stage.stage === "accountOpen" && (
                      <div
                        className="inpt_bx"
                        style={{
                          width: "17rem",
                          height: "",
                          marginTop: "",
                          marginLeft: "12rem",
                        }}
                      >
                        <input
                          type="number"
                          value={stage.price}
                          onChange={StageHandler}
                          name="price"
                          placeholder=""
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={UpdateHandler}
                sx={{ mt: 7, mx: 30, width: 100, height: 40 }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Update
                </Typography>
              </Button>
            </div>

            {/* </Typography> */}
          </Box>
        </Modal>
        <div className="cmn_hdr">
          <h2>Banks List</h2>
          <br />

          <br />

          <div style={{ display: "flex", float: "right" }}>
            {" "}
            <h2>Data per page:</h2>{" "}
            <select
              style={{
                width: "10rem",
                height: "3rem",
                cursor: "pointer",
                marginLeft: "2rem",
              }}
              onChange={(e) => {
                setPerPage(Number(e.target.value));
                console.log(Pagination().arr, "900");
                if (Pagination().arr?.length === 0) {
                  setPage(page - 1);
                }
              }}
            >
              <option>3</option>
              <option>6</option>
              <option>10</option>
            </select>
          </div>
        </div>
        <br />
        <br />
        {!isLoading ? (
          <>
            <div className="outr_table">
              <table>
                <tbody>
                  <tr>
                    {/* <th>Applicants</th> */}
                    {/* <th>Application ID</th> */}
                    <th>Name</th>
                    {/* <th>E-mail

                </th>
                <th>Contact Number</th> */}

                    <th>Created At</th>
                    <th>Number of viewed applications</th>
                    <th>Accepted Applications</th>
                    {/* <th>Viewed Applications</th> */}
                    <th>Number of Underwriting Applications</th>
                    <th>Number of Accounts Opened</th>
                    <th>Number of Declined Applications</th>

                    <th>Payment Charges</th>
                    <th>Charges Applicable Stage</th>
                    {/* <th>Decline</th> */}
                    <th style={{ minWidth: "80px !important" }}>
                      Enable Active Status
                    </th>
                    {/* <th>Log In</th> */}
                    {/* <th>Charges</th> */}
                  </tr>
                  {Pagination()?.length !== 0 ? (
                    Pagination()?.arr?.map((bank, index) => {
                      return (
                        <tr>
                          {/* <td>
                  <Link
                state={{category:'accepted applications'}}
                
                >{bank._id}</Link></td> */}
                          <td>{bank.bankName}</td>
                          {/* <td>{bank.email}</td>
                    <td>{bank.bankContactNumber}</td> */}
                          <td>{bank.createdAt}</td>
                          <td>{bank.numViewedApplications}</td>
                          <td>
                            {bank.numAcceptedApplications === 0
                              ? "Haven't accepted applications yet!"
                              : bank.numAcceptedApplications}
                          </td>
                          {/* <td>10</td> */}
                          <td>{bank.numUnderwriting}</td>
                          <td>{bank.numAccountOpen}</td>
                          <td>{bank.numAccountDecline}</td>

                          <td>{bank.paymentStage?.price}</td>
                          <td>
                            {bank.stage}
                            <Button
                              variant="contained"
                              sx={{ mx: 2 }}
                              onClick={() => {
                                setOpen(true);
                                setStage({
                                  stage: bank.paymentStage.stage,
                                  price: bank.paymentStage.price,
                                });
                                setId(bank._id);
                              }}
                            >
                              Update Stage
                            </Button>
                          </td>
                          <td>
                            <div
                              className="switch_box"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <h4>no</h4> */}
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  checked={bank.matchedApplicationStatus}
                                  name="products_purchased_providers"
                                  onChange={(e) => {
                                    BankActiveStatusHandler(
                                      bank.matchedApplicationStatus,
                                      bank._id
                                    );
                                  }}
                                />
                                <span className="slider round" />
                              </label>
                              {/* <h4>yes</h4> */}
                            </div>
                          </td>
                          {/* <td><div style={{ marginLeft: '4rem', fontSize: '3rem', cursor: 'pointer' }}>
                      <Link
                        // to=  '/bank-dashboard' state={{email:bank.email,role:'bank'}} target='_blank'
                        onClick={() => { AdminAsUserLoginHandler(bank.email) }}
                        style={{ color: 'black' }}
                      //  state={{ category: 'accepted applications' }}
                      ><MdAccountCircle /></Link>
                    </div></td> */}
                          {/* <td><div style={{ marginLeft: '4rem', fontSize: '3rem', cursor: 'pointer' }}><FcMoneyTransfer/></div></td> */}
                          {/* <td>{String(applicant.accountOpened)}</td> */}

                          {/* <td>
                    { applicant.accountOpened !== 'Account Declined' ?
                      <button 
                      disabled={!applicant.proposalAccepted} onClick={()=>{DeclineAccount(applicant.application_Id)}}
                      style={{color: "red",border:'none',background:'none',marginLeft:'1.2rem',cursor:'pointer'}}><IconContext.Provider value={{ color: "", size:'25px'}}><MdCancel/></IconContext.Provider></button>
                      : <>Declined❌</>
                    }
                  </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <h2>No data left.</h2>
                  )}
                </tbody>
              </table>
            </div>
            <br />
            <div
              style={{
                float: "right",
                justifyContent: "space-between",
                marginRight: "20rem",
                marginBottom: "3rem",
              }}
            >
              <button
                className="pagination_btn"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1);
                  }
                }}
              >
                &#8249;
              </button>
              {Pagination()?.perPagedatalength.map((btn, i) => {
                return (
                  <button
                    className={
                      page === i + 1
                        ? "pagination_btn activepgbtn"
                        : "pagination_btn"
                    }
                    style={{
                      float: "",
                      marginLeft: "1rem",
                      marginBottom: "",
                      cursor: "pointer",
                    }}
                    onClick={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                );
              })}
              {/* <button className={page === 2 ? 'pagination_btn activepgbtn' : 'pagination_btn'} style={{ float: '', marginLeft: '1rem', marginBottom: '', cursor: 'pointer' }} onClick={() => setPage(2)}>2</button>
              <button className={page === 3 ? 'pagination_btn activepgbtn' : 'pagination_btn'} style={{ float: '', marginLeft: '1rem', marginBottom: '', cursor: 'pointer' }} onClick={() => setPage(3)}>3</button> */}
              <button
                className="pagination_btn"
                style={{
                  float: "",
                  marginLeft: "1rem",
                  marginBottom: "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (data?.length > page * perPage) {
                    setPage(page + 1);
                  }
                }}
              >
                &#8250;
              </button>
            </div>
          </>
        ) : (
          <div style={{ marginLeft: "50rem" }}>
            <ThreeDots
              height="60"
              width="60"
              radius="9"
              color="#b07f35"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BankListing;
