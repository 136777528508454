import { omit } from "lodash";
import { useState } from "react";

const Validate = (event, name, value, errors, setErrors) => {
  //A function to validate each input values
  console.log(value, "value23");
  console.log(name, value.length, "value23");
  switch (name) {
    case "value":
      if (value === "") {
        setErrors({
          ...errors,
          value: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "value");
        setErrors(newObj);
      }
      break;

    case "name":
      if (value.length <= 4) {
        // we will set the error state

        setErrors({
          ...errors,
          name: "Username atleast have 5 letters",
        });
      } else if (value.length === 0) {
        setErrors({
          ...errors,
          name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "name");
        setErrors(newObj);
      }
      break;

    // case "dba":
    //   if (value.length === 0) {
    //     // we will set the error state

    //     setErrors({
    //       ...errors,
    //       dba: "Cannot be Empty",
    //     });
    //   } else {
    //     // set the error state empty or remove the error for username input

    //     //omit function removes/omits the value from given object and returns a new object
    //     let newObj = omit(errors, "dba");
    //     setErrors(newObj);
    //   }
    //   break;
    // case 'mailing_Address':
    //     if (
    //         !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
    //     ) {
    //         setErrors({
    //             ...errors,
    //             mailing_Address: 'Enter a valid email address'
    //         })
    //     } else {

    //         let newObj = omit(errors, "mailing_Address");
    //         setErrors(newObj);

    //     }
    //     break;

    case "password":
      if (
        !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
      ) {
        setErrors({
          ...errors,
          password:
            "Password should contain atleast 8 charaters and containing uppercase,lowercase and numbers",
        });
      } else {
        let newObj = omit(errors, "password");
        setErrors(newObj);
      }
      break;
    case "physical_Address":
      if (value.length <= 4) {
        // we will set the error state

        setErrors({
          ...errors,
          physical_Address: "Physical Address atleast have 5 letters",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "physical_Address");
        setErrors(newObj);
      }
      break;
    // case "federal_EIN":
    //   if (value.length !== 10) {
    //     // we will set the error state

    //     setErrors({
    //       ...errors,
    //       federal_EIN: "Must be of 9 digits!",
    //     });
    //   } else {
    //     // set the error state empty or remove the error for username input

    //     //omit function removes/omits the value from given object and returns a new object
    //     let newObj = omit(errors, "federal_EIN");
    //     setErrors(newObj);
    //   }
    //   break;
    case "cannabis_License_Number":
      if (value.length === 0) {
        // we will set the error state

        setErrors({
          ...errors,
          cannabis_License_Number: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "cannabis_License_Number");
        setErrors(newObj);
      }
      break;
    case "other":
      console.log(value, "0000000");
      if (value === "") {
        // we will set the error state

        setErrors({
          ...errors,
          other: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "other");
        setErrors(newObj);
      }
      break;
    case "num_employees":
      if (value.length < 1) {
        // we will set the error state

        setErrors({
          ...errors,
          num_employees: "Num of Employees atleast have 1 digit",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "num_employees");
        setErrors(newObj);
      }
      break;

    //primary information
    case "primary_contact_name":
      if (value.length === 0) {
        // we will set the error state

        setErrors({
          ...errors,
          primary_contact_name: "Cannot be empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "primary_contact_name");
        setErrors(newObj);
      }
      break;
    case "primary_address":
      if (value.length === 0) {
        // we will set the error state

        setErrors({
          ...errors,
          primary_address: "Cannot be empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "primary_address");
        setErrors(newObj);
      }
      break;
    case "dob":
      if (value.length <= 5) {
        // we will set the error state

        setErrors({
          ...errors,
          dob: "DOB atleast have 6 letters",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "dob");
        setErrors(newObj);
      }
      break;
    case "mail":
      if (
        !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
      ) {
        setErrors({
          ...errors,
          mail: "Enter a valid email address",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "mail");
        setErrors(newObj);
      }
      break;
    // && String(value.length) < 10
    case "primary_Phone":
      // we will set the error state
      if (String(value.length) < 10) {
        setErrors({
          ...errors,
          primary_Phone: "Primary Phone atleast have 10 digits!",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "primary_Phone");
        setErrors(newObj);
      }
      break;
    case "num_locs":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          num_locs: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "num_locs");
        setErrors(newObj);
      }
      break;
    case "transactions_Per_month":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          num_locs: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "transactions_Per_month");
        setErrors(newObj);
      }
      break;
    case "managed_square_feet":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          managed_square_feet: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "managed_square_feet");
        setErrors(newObj);
      }
      break;
    case "purchase_amount_per_sale":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          purchase_amount_per_sale: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "purchase_amount_per_sale");
        setErrors(newObj);
      }
      break;
    case "num_plants":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          num_plants: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "num_plants");
        setErrors(newObj);
      }
      break;
    case "average_quantity_per_sale":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          average_quantity_per_sale: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "average_quantity_per_sale");
        setErrors(newObj);
      }
      break;
    case "facility_owned":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          facility_owned: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "facility_owned");
        setErrors(newObj);
      }
      break;
    case "facility_leased":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          facility_leased: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "facility_leased");
        setErrors(newObj);
      }
      break;
    case "lease_term":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          lease_term: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "lease_term");
        setErrors(newObj);
      }
      break;
    case "egmi":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          egmi: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "egmi");
        setErrors(newObj);
      }
      break;
    // case "types_customers":
    //   // we will set the error state
    //   if (String(value.length) === 0) {
    //     setErrors({
    //       ...errors,
    //       types_customers: "Cannot be Empty",
    //     });
    //   } else {
    //     // set the error state empty or remove the error for username input

    //     //omit function removes/omits the value from given object and returns a new object
    //     let newObj = omit(errors, "types_customers");
    //     setErrors(newObj);
    //   }
    //   break;
    case "principal_business":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          principal_business: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "principal_business");
        setErrors(newObj);
      }
      break;
    case "beneficial_owners":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          beneficial_owners: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "beneficial_owners");
        setErrors(newObj);
      }
      break;
    case "number_beneficial_owner":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          number_beneficial_owner: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "number_beneficial_owner");
        setErrors(newObj);
      }
      break;
    case "loc_name":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          loc_name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "loc_name");
        setErrors(newObj);
      }
      break;
    case "license":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          license: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "license");
        setErrors(newObj);
      }
      break;
    case "loc_address":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          loc_address: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "loc_address");
        setErrors(newObj);
      }
      break;
    case "deposit_acc_need":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          deposit_acc_need: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "deposit_acc_need");
        setErrors(newObj);
      }
      break;
    case "ownership_Percentage":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          ownership_Percentage: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "ownership_Percentage");
        setErrors(newObj);
      }
      break;
    case "acc_need":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          acc_need: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "acc_need");
        setErrors(newObj);
      }
      break;
    case "amount_Initial_Deposit":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          amount_Initial_Deposit: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "amount_Initial_Deposit");
        setErrors(newObj);
      }
      break;
    case "source_Initial_Deposit":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          source_Initial_Deposit: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "source_Initial_Deposit");
        setErrors(newObj);
      }
      break;
    case "estimated_total_amount_monthly_deposit":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          estimated_total_amount_monthly_deposit: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "estimated_total_amount_monthly_deposit");
        setErrors(newObj);
      }
      break;
    case "estimated_total_num_monthly_deposit":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          estimated_total_num_monthly_deposit: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "estimated_total_num_monthly_deposit");
        setErrors(newObj);
      }
      break;
    case "anticipate_cash_deposits":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          anticipate_cash_deposits: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "anticipate_cash_deposits");
        setErrors(newObj);
      }
      break;
    case "amount_cash_deposits":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          amount_cash_deposits: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "amount_cash_deposits");
        setErrors(newObj);
      }
      break;
    case "frequency_cash_deposits":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          frequency_cash_deposits: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "frequency_cash_deposits");
        setErrors(newObj);
      }
      break;
    case "estimated_spend":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          estimated_spend: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "estimated_spend");
        setErrors(newObj);
      }
      break;
    case "anticipate_cash_withdrawals":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          anticipate_cash_withdrawals: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "anticipate_cash_withdrawals");
        setErrors(newObj);
      }
      break;
    case "amount_cash_withdrawals":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          amount_cash_withdrawals: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "amount_cash_withdrawals");
        setErrors(newObj);
      }
      break;
    case "frequency_cash_withdrawals":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          frequency_cash_withdrawals: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "frequency_cash_withdrawals");
        setErrors(newObj);
      }
      break;
    case "monthly_payroll":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          monthly_payroll: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "monthly_payroll");
        setErrors(newObj);
      }
      break;
    case "cash_pick_ups":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          cash_pick_ups: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "cash_pick_ups");
        setErrors(newObj);
      }
      break;
    case "frequency_cash_pick_ups":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          frequency_cash_pick_ups: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "frequency_cash_pick_ups");
        setErrors(newObj);
      }
      break;
    case "estimated_cash_pick_ups":
      // we will set the error state
      if (String(value.length) === 0) {
        setErrors({
          ...errors,
          estimated_cash_pick_ups: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "estimated_cash_pick_ups");
        setErrors(newObj);
      }
      break;
    case "bank_name":
      // we will set the error state
      if (value.length === 0) {
        setErrors({
          ...errors,
          bank_name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "bank_name");
        setErrors(newObj);
      }
      break;
    case "length_time":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          length_time: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "length_time");
        setErrors(newObj);
      }
      break;
    case "length_time":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          length_time: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "length_time");
        setErrors(newObj);
      }
      break;
    // case "reason_to_close":
    //   // we will set the error state
    //   if (String(value).length === 0) {
    //     setErrors({
    //       ...errors,
    //       reason_to_close: "Cannot be Empty",
    //     });
    //   } else {
    //     // set the error state empty or remove the error for username input

    //     //omit function removes/omits the value from given object and returns a new object
    //     let newObj = omit(errors, "reason_to_close");
    //     setErrors(newObj);
    //   }
    //   break;
    case "number_vendors":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          number_vendors: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "number_vendors");
        setErrors(newObj);
      }
      break;
    case "current_cash_managment":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          current_cash_managment: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "current_cash_managment");
        setErrors(newObj);
      }
      break;
    case "financial_institution":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          financial_institution: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "financial_institution");
        setErrors(newObj);
      }
      break;
    // case "existing_bank_contact":
    //   // we will set the error state
    //   if (String(value).length === 0) {
    //     setErrors({
    //       ...errors,
    //       existing_bank_contact: "Cannot be Empty",
    //     });
    //   } else {
    //     // set the error state empty or remove the error for username input

    //     //omit function removes/omits the value from given object and returns a new object
    //     let newObj = omit(errors, "existing_bank_contact");
    //     setErrors(newObj);
    //   }
    //   break;
    case "existing_bank_name":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          existing_bank_name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "existing_bank_name");
        setErrors(newObj);
      }
      break;
    case "original_deposite":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          original_deposite: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "original_deposite");
        setErrors(newObj);
      }
      break;
    case "reason_closure":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          reason_closure: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "reason_closure");
        setErrors(newObj);
      }
      break;
    case "settlement_num_loc":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          settlement_num_loc: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "settlement_num_loc");
        setErrors(newObj);
      }
      break;
    case "types_payments":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          types_payments: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "types_payments");
        setErrors(newObj);
      }
      break;
    case "pos":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          pos: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "pos");
        setErrors(newObj);
      }
      break;
    case "merchant_processor":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          merchant_processor: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "merchant_processor");
        setErrors(newObj);
      }
      break;
    case "atm_loc":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          atm_loc: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "atm_loc");
        setErrors(newObj);
      }
      break;
    case "atm_fill":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          atm_fill: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "atm_fill");
        setErrors(newObj);
      }
      break;
    case "atm_crypto":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          atm_crypto: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "atm_crypto");
        setErrors(newObj);
      }
      break;
    case "atm_currency":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          atm_currency: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "atm_currency");
        setErrors(newObj);
      }
      break;
    case "atm_fill_company":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          atm_fill_company: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "atm_fill_company");
        setErrors(newObj);
      }
      break;
    case "accounting_system":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          accounting_system: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "accounting_system");
        setErrors(newObj);
      }
      break;
    case "firm_name":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          firm_name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "firm_name");
        setErrors(newObj);
      }
      break;
    case "compliance_desc":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          compliance_desc: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "compliance_desc");
        setErrors(newObj);
      }
      break;
    case "compliance_partner_name":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          compliance_partner_name: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "compliance_partner_name");
        setErrors(newObj);
      }
      break;
    case "onsite_inspection":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          onsite_inspection: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "onsite_inspection");
        setErrors(newObj);
      }
      break;
    case "frequency_compliance":
      // we will set the error state
      if (String(value).length === 0) {
        console.log(value, "valuee");
        setErrors({
          ...errors,
          frequency_compliance: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "frequency_compliance");
        setErrors(newObj);
      }
      break;
    case "compliance_date":
      // we will set the error state
      if (String(value).length === 0) {
        console.log(value, "valuee");
        setErrors({
          ...errors,
          compliance_date: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "compliance_date");
        setErrors(newObj);
      }
      break;
    case "operating_procedures":
      // we will set the error state
      if (String(value).length === 0) {
        setErrors({
          ...errors,
          operating_procedures: "Cannot be Empty",
        });
      } else {
        // set the error state empty or remove the error for username input

        //omit function removes/omits the value from given object and returns a new object
        let newObj = omit(errors, "operating_procedures");
        setErrors(newObj);
      }
      break;

    default:
      break;
  }
  return errors;
};

export { Validate };
