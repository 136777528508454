import React from "react";
import targetImage from "./Assets/images/hm_sltn_bg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaFacebookF } from "react-icons/fa";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const LandingPage = () => {
  return (
    <div className="mainsection">
      <>
        {/*Header Start*/}
        <header className="header landinghdr hm_hdr">
          <div className="outr_header">
            <div className="container">
              <nav className="navigation_bar">
                <div className="logo_outr">
                  <a href="javascipt:void(0);">
                    <img
                      src={require("./Assets/images/bank_logo.png")}
                      alt="logo"
                    />
                  </a>
                </div>
                <button className="hamburger hamburger--arrow" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <div className="menu" id="menu">
                  {/* <ul className="menu-inner">
                    <li className="menu-item active">
                      <a href="javascript:void(0);" className="menu-link">
                        Home{" "}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="javascript:void(0);" className="menu-link">
                        Industries
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="javascript:void(0);" className="menu-link">
                        Solutions{" "}
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="javascript:void(0);" className="menu-link">
                        Referral Program
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="javascript:void(0);" className="menu-link">
                        Referral Program
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="javascript:void(0);" className="menu-link">
                        Appointment
                      </a>
                    </li>
                  </ul> */}
                  <div className="srch_icon">
                    <i className="fas fa-search" />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        {/*Header End*/}
        {/* banner start */}
        <section className="hm_bnr">
          <div className="hm_bnr_img">
            <img src={require("./Assets/images/hm_Bnr.jpg")} alt="banner" />
          </div>
          <div className="container">
            <div className="hm_bnr_txt hm cmn_hdr wht">
              <h6>Welcome To</h6>
              <h1>
                Guardian Banking <br />
                Solutions
              </h1>
              <p>
                Guardian is your strategic partner in connecting with precisely
                matched cannabis operators seeking banking services
              </p>
              <div className="hm_bnr_btn">
                <a href="javascript:void(0)" className="hm cmn_btn">
                  BOOK A FREE CONSULTATION
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* banner end */}
        {/* features start */}
        <section className="hm_features cmn_pdding">
          <div className="container">
            <div className="hm_fetr_dtl">
              <div className="hm_fetr_part_hdng">
                <div className="hm_fetr_hdng hm cmn_hdr">
                  <h3>The Challenge in Cannabis Banking</h3>
                </div>
              </div>
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h4>Sifting</h4>
                  <p>
                    through countless
                    <br />
                    applications
                  </p>
                </div>
                <div className="hm_ftr_icon">
                  <img
                    src={require("./Assets/images/hm_icon4.png")}
                    alt="icon"
                  />
                  <img
                    src={require("./Assets/images/hm_icon2.png")}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h4>
                    Wasting
                    <br />
                    time
                  </h4>
                  <p>
                    on applications that don't
                    <br />
                    match your criteria
                  </p>
                </div>
                <div className="hm_ftr_icon">
                  <img
                    src={require("./Assets/images/hm_icon3.png")}
                    alt="icon"
                  />
                  <img
                    src={require("./Assets/images/hm_icon5.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  features end */}
        {/*  key features start */}
        <section className="hm_key cmn_pdding">
          <div className="container">
            <div className="hm_key_hdng hm cmn_hdr">
              <h2>Key Features</h2>
              <h5>Key Features of Guardian Banking Solutions</h5>
            </div>
            <div className="hm_fetr_dtl key_hm">
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h5>01</h5>
                  <h6>
                    Precision <br />
                    Matching
                  </h6>
                </div>
                <div className="hm_ftr_icon_key">
                  <img
                    src={require("./Assets/images/key-icon1.png")}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h5>02</h5>
                  <h6>
                    Effortless <br />
                    Underwriting
                  </h6>
                </div>
                <div className="hm_ftr_icon_key">
                  <img
                    src={require("./Assets/images/key-icon2.png")}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h5>03</h5>
                  <h6>
                    Private Marketing <br />
                    Support
                  </h6>
                </div>
                <div className="hm_ftr_icon_key">
                  <img
                    src={require("./Assets/images/key-icon3.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  key features end */}
        {/* solution start */}
        <section
          className="hm_solution cmn_pdding"
          style={{
            // background: "url(./Assets/images/hm_sltn_bg.jpg) no-repeat",
            background: `url(${targetImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="container">
            <div className="hm_key_hdng hm cmn_hdr wht">
              <h2>Guardian's Solution</h2>
              <h5>Guardian's Revolutionary Approach</h5>
            </div>
            <div className="hm_sltn_dtl">
              <div className="hm_sltn_part">
                <div className="hm_sltn_icon">
                  <img
                    src={require("./Assets/images/hm_sltn_icon1.png")}
                    alt="solution-img"
                  />
                </div>
                <div className="hm_sltn_text hm cmn_hdr wht">
                  <h5>Define</h5>
                  <p>your perfect customer</p>
                </div>
              </div>
              <div className="hm_sltn_part">
                <div className="hm_sltn_icon">
                  <img
                    src={require("./Assets/images/hm_sltn_icon2.png")}
                    alt="solution-img"
                  />
                </div>
                <div className="hm_sltn_text hm cmn_hdr wht">
                  <h5>Specialized questionnaire</h5>
                  <p>for cannabis operators</p>
                </div>
              </div>
              <div className="hm_sltn_part">
                <div className="hm_sltn_icon">
                  <img
                    src={require("./Assets/images/hm_sltn_icon3.png")}
                    alt="solution-img"
                  />
                </div>
                <div className="hm_sltn_text hm cmn_hdr wht">
                  <h5>Algorithmic precision</h5>
                  <p>– only exact matches sent your way</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* solution end */}
        {/* streamline process start */}
        <section className="strmln">
          <div className="strmln_otr cmn_pdding">
            <div className="strmln_hdr hm cmn_hdr clr">
              <h2>Streamlined Process</h2>
              <p>Guardian's Revolutionary vvApproach</p>
            </div>
            <div className="container">
              <div className="strmln_innr">
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>01</h5>
                    </div>
                    <p>Cannabis Operator fills out questionnaire</p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img1.png")} />
                  </div>
                </div>
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>03</h5>
                    </div>
                    <p>Review and decide on the best match for the FI</p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img2.png")} />
                  </div>
                </div>
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>05</h5>
                    </div>
                    <p>Operator selects offer and FI is notified</p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img3.png")} />
                  </div>
                </div>
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>02</h5>
                    </div>
                    <p>Algorithm matches ideal candidates</p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img4.png")} />
                  </div>
                </div>
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>04</h5>
                    </div>
                    <p>Send a personalized offer to the Operator</p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img5.png")} />
                  </div>
                </div>
                <div className="strml_div stml_arw">
                  <div className="strmln_lft">
                    <div className="numb hm cmn_hdr">
                      <h5>06</h5>
                    </div>
                    <p>
                      Equaling smooth underwriting with specified document
                      uploads
                    </p>
                  </div>
                  <div className="strmln_rght">
                    <img src={require("./Assets/images/str_img6.png")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* streamline process end */}
        {/*  efficiency start */}
        <section className="hm_efcncy cmn_pdding">
          <div className="container">
            <div className="hm_fetr_part_hdng">
              <div className="hm_fetr_hdng hm cmn_hdr text-center">
                <h2>Efficiency &amp; Time Savings</h2>
              </div>
            </div>
            <div className="hm_fetr_dtl">
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h4>No more sifting</h4>
                  <p>
                    through mismatched
                    <br />
                    businesses
                  </p>
                </div>
                <div className="hm_ftr_icon">
                  <img
                    src={require("./Assets/images/hm_icon4.png")}
                    alt="icon"
                  />
                  <img
                    src={require("./Assets/images/hm_icon2.png")}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="hm_fetr_part">
                <div className="hm_fetr_txt hm cmn_hdr">
                  <h4>Exact matches</h4>
                  <p>
                    save valuable <br />
                    time
                  </p>
                </div>
                <div className="hm_ftr_icon">
                  <img
                    src={require("./Assets/images/hm_icon1.png")}
                    alt="icon"
                  />
                  <img
                    src={require("./Assets/images/hm_icon_6.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  efficiency end */}
        {/* marketting start 15.2.24 */}
        <section className="mrktng_sec cmn_pdding">
          <div className="mrktng_otr">
            <div className="container">
              <div className="mrktng_innr">
                <div className="mrktng_lft">
                  <div className="mrkt__img">
                    <img src={require("./Assets/images/mrktng_img.jpg")} />
                  </div>
                </div>
                <div className="mrktng_rght hm cmn_hdr clr">
                  <h2>Marketing Support</h2>
                  <p>Marketing Support, Privacy Guaranteed</p>
                  <div className="mrkt_div">
                    <div className="mrkt_icn">
                      <img src={require("./Assets/images/mrkt_icn1.png")} />
                    </div>
                    <div className="mrkt_txt hm cmn_hdr">
                      <p>
                        <span>No marketing team?</span> Guardian handles it for
                        you
                      </p>
                    </div>
                  </div>
                  <div className="mrkt_div">
                    <div className="mrkt_icn">
                      <img src={require("./Assets/images/mrkt_icn2.png")} />
                    </div>
                    <div className="mrkt_txt hm cmn_hdr">
                      <p>Your bank name remains private</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="side_font">
            <h4>Guardian Banking Solutions</h4>
          </div>
        </section>

        {/* marketting ends 15.2.24 */}
        {/* get started moumi */}
        <section className="get_started_bg">
          <div className="get_started">
            <div className="container">
              <div className="get_started_bg hm cmn_hdr">
                <h2>
                  Connect with Guardian
                  <br />
                  Banking Solutions
                </h2>
                <p>Ready to Transform Your Cannabis Banking?</p>
                <div className="get_btn">
                  <a href="javascript:void(0)" className="cmn_btn">
                    GET STARTED TODAY
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* get started end */}
        {/* main section end  */}
        {/* footr moumi 15.2.24*/}
        <footer className="cmn_pdding">
          <div className="container">
            <div className="tp_ftr">
              <div className="ftr_list">
                <div className="ftr_logo">
                  <a href="javascript:void(0)">
                    <img src={require("./Assets/images/bank_logo.png")} />
                  </a>
                </div>
              </div>
              <div className="ftr_list">
                <div className="ftr_hdng">
                  <h4>USEFUL LINKS</h4>
                </div>
                <div className="ftr_lists">
                  <ul>
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>
                      <a href="aboutUs.html">About Us</a>
                    </li>
                    {/* <li>
                      <a href="javascript:void(0);">Industries</a>
                    </li> */}
                    {/* <li>
                      <a href="javascript:void(0);">Solutions</a>
                    </li> */}
                    <li>
                      <a href="javascript:void(0);">Referral Program</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Appointment</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ftr_list">
                <div className="ftr_hdng">
                  <h4>CONTACT Us</h4>
                </div>
                <div className="ftr_lists">
                  <ul>
                    <li>
                      <span className="ftr_icon">
                        <i className="fa-solid fa-envelope" />
                      </span>
                      <span>
                        <a href="javascript:void(0)">
                          info@guardianbankingapplications.com
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="ftr_icon">
                        <i className="fa-solid fa-phone" />
                      </span>
                      <span className="ftr_plc">
                        <a href="javascript:void(0)">713-929-4344</a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ftr_list">
                <div className="ftr_hdng">
                  <h4>About Us</h4>
                </div>
                <div className="ftr_lists">
                  <p>
                    GBS was officially founded in 2017 to provide banking
                    solutions for the High-Risk industry. It started as a basic
                    sourcing service, finding bank accounts for merchants
                    through safe and compliant practices.
                  </p>
                </div>
              </div>
            </div>
            <div className="btm_ftr">
              <div className="ftr_lft ftr_lists">
                <p>
                  © 2024
                  <a href="javascript:void(0);">Guarding Banking Solutions</a> -
                  Confidential
                </p>
              </div>
              <div className="ftr_rgft">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/Guardian-Banking-Solutions-104879487967146/?view_public_for=104879487967146">
                      {/* <i className="fa-brands fa-facebook-f" /> */}
                      {/* <FontAwesomeIcon icon="fa-brands fa-facebook" /> */}
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UClJxww1yKnaIZ31fsm1SbIg/featured?view_as=public">
                      {/* <i className="fa-brands fa-youtube" /> */}
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.instagram.com/guardianbankingsolutions/">
                      {/* <i className="fa-brands fa-instagram" /> */}
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/BankingGuardian">
                      {/* <i className="fa-brands fa-twitter" /> */}
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ftr_aftr">
            <div className="ftr_aftr_img">
              <img src={require("./Assets/images/aftr.png")} />
            </div>
          </div>
        </footer>
        {/* Back to top button */}
        <a href="javascript:void(0);" id="backToTop">
          <i className="fa fa-solid fa-arrow-up" />
        </a>
        {/* Back to top button */}
        {/* Jquery  */}
        {/* WOW JS */}
        {/* Font Awesome JS */}
        {/* Icon Scout */}
        {/* AOS JS */}
        {/* Bootstrap JS */}
        {/*  */}
        {/* Custom JS */}
      </>
    </div>
  );
};

export default LandingPage;
