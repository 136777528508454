import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { nextOne, nextOneBank } from "../../Store/userSlice";
import CreatableSelect from "react-select/creatable";
import { omit } from "lodash";
import { BankValidator } from "../../Helper/BankValidator";
import NumberFormat from "react-number-format";
const FormOneBank = (props) => {
  const USASTATES = [
    "Alabama(AL)",
    "Alaska (AK)",
    "Arizona (AZ)",
    " Arkansas (AR)",
    " California (CA)",
    "Colorado (CO)",
    "Connecticut (CT)",
    " Delaware (DE)",
    "Florida (FL)",
    "Georgia (GA)",
    "Hawaii (HI)",
    "Idaho (ID)",
    "Illinois (IL)",
    "Indiana (IN)",
    "Iowa (IA)",
    "Kansas (KS)",
    "Kentucky (KY)",
    "Louisiana (LA)",
    " Maine (ME)",
    "Maryland (MD)",
    "Massachusetts (MA)",
    "Michigan (MI)",
    "Minnesota (MN)",
    "Mississippi (MS)",
    " Missouri (MO)",
    "Montana (MT)",
    "Nebraska (NE)",
    "Nevada (NV)",
    "New Hampshire (NH)",
    "New Jersey (NJ)",
    "NewMexico (NM)",
    "NewYork (NY)",
    "North Carolina (NC)",
    "North Dakota (ND)",
    "Ohio (OH)",
    "Oklahoma (OK)",
    "Oregon (OR)",
    "Pennsylvania (PA)",
    "RhodeIsland (RI)",
    " South Carolina (SC)",
    "South Dakota (SD)",
    "Tennessee (TN)",
    "Texas (TX)",
    " Utah (UT)",
    "Vermont (VT)",
    "Virginia (VA)",
    "Washington (WA)",
    " West Virginia (WV)",
    "Wisconsin (WI)",
    "Wyoming (WY)",
  ];

  const StateOptions = USASTATES.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });
  useEffect(() => {
    // let result = document.getElementById("input");

    function GFG_Fun() {
      let input = document.getElementById("input");
      let targets = document.getElementsByTagName("input");
      console.log(targets, "890");
      for (let i = 0; i < targets.length; i++) {
        if (targets[i].type === "number") {
          targets[i].addEventListener("mousewheel", function (event) {
            this.blur();
          });
        }
      }
    }
    GFG_Fun();
  }, []);
  const [errorState, setErrorstate] = useState(false);
  const dispatch = useDispatch();
  const {
    RiskClassificationBankhandler,
    Risk_classification_Bank,
    Legal_Business_Bank,
    setLegal_Business_Bank,
    BankLegalBusinessInfoHandler,
    BankPrimaryContactInfoHandler,
    Primary_Information_Bank,
    errosBank,
    setErrorsBank,
    acc_creation_time,
    AccountCreationTimehandler,
    servicesOffered,
    ServicesOfferedHandler,
    servicesPrices,
    ServicesPriceChangeHandler,
  } = useForm();
  console.log(servicesPrices, "servicesPrices");
  console.log(Risk_classification_Bank, "maindatabankform1");
  console.log(Legal_Business_Bank.accepted_states, "look states");
  console.log(acc_creation_time, "acc_creation_time");
  // useEffect(()=>{
  //   setAcceptedStates(Legal_Business_Bank.accepted_states)
  // },[Legal_Business_Bank])
  console.log(errosBank, "100");
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  useEffect(() => {
    setErrorstate(errosBank);
  }, [errosBank]);
  console.log(Primary_Information_Bank, "Primary_Information_Bank");
  const NextHandlerOne = (event) => {
    event.preventDefault();
    var detector1 = false;
    var detector2 = false;
    if (
      Number(Primary_Information_Bank.ownership_Percentage_min) >
      Number(Primary_Information_Bank.ownership_Percentage_max)
    ) {
      detector1 = false;
      toast.error(
        "ownership_Percentage_min must be less than ownership_Percentage_max!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } else {
      detector1 = true;
    }
    if (
      Number(Legal_Business_Bank.num_employees_min) >
      Number(Legal_Business_Bank.num_employees_max)
    ) {
      errorState.num_employeesError =
        "Minimum nuber of employees must be less than the maximum number of employees!";
      setErrorstate({ ...errorState });
      detector2 = false;
      toast.error("num_employees_min must be less than num_employees_max!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      delete errorState.num_employeesError;
      setErrorstate({ ...errorState });
      detector2 = true;
    }

    if (acc_creation_time.value === "") {
      errorState.value = "Can't be empty!";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.value;
      setErrorstate({ ...errorState });
    }
    for (let x in Legal_Business_Bank) {
      if (String(Legal_Business_Bank[x]).length === 0) {
        if (x !== "licensing_Type" && x !== "accepted_states") {
          errorState[x] = "Can't be empty!";

          setErrorstate({ ...errorState });
        }
      }
    }
    for (let x in Primary_Information_Bank) {
      if (String(Primary_Information_Bank[x]).length === 0) {
        errorState[x] = "Can't be empty!";
        setErrorstate({ ...errorState });
      }
    }

    if (Legal_Business_Bank.licensing_Type.includes("Other")) {
      if (Legal_Business_Bank.other === "") {
        errorState.other = "Can't be empty!";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.other;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.other;
      setErrorstate({ ...errorState });
    }
    // const modified_Legal_Business_Bank = omit(Legal_Business_Bank, "accepted_states");
    const res = [
      ...Object.values(Legal_Business_Bank),
      ...Object.values(Primary_Information_Bank),
      Risk_classification_Bank,
    ];
    console.log(res, "ress");
    const finalres = res.map((i) => String(i).length);
    console.log(finalres, "finalres");
    console.log(errorState, "theerrors");
    console.log(detector1, detector2, "detector");
    // && finalres.every(x => x !== 0)
    if (
      Object.keys(errorState).length === 0 &&
      Legal_Business_Bank.licensing_Type.length !== 0 &&
      Legal_Business_Bank.accepted_states.length !== 0 &&
      Risk_classification_Bank.length !== 0 &&
      detector1 &&
      detector2
    ) {
      props.setFormNo(2);
      console.log(Legal_Business_Bank, "submittingtoredux");
      dispatch(
        nextOneBank({
          servicesPrices,
          servicesOffered,
          acc_creation_time,
          Risk_classification_Bank,
          Legal_Business_Bank,
          Primary_Information_Bank,
        })
      );
    } else {
      console.log(
        Primary_Information_Bank.ownership_Percentage_min >
          Primary_Information_Bank.ownership_Percentage_max,
        "seeeeees"
      );
      // Legal_Business_Bank.num_employees_min
      if (
        Legal_Business_Bank.other === "" &&
        Legal_Business_Bank.licensing_Type.length === 0
      ) {
        toast.error("Please select atleast one from Licensing types!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      if (Risk_classification_Bank.length === 0) {
        toast.error("Please select atleast one from Risk Classification!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <section id="section1" className="section1">
      <form onSubmit={NextHandlerOne}>
        <div className="pg1-mn-otr">
          <div className="pg1-mn-inr">
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <div className="pg3-mn-otrfld">
                    <div className="check__in__bx">
                      <h6>Services Offered:</h6>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          value="Business Checking"
                          checked={servicesOffered.includes(
                            "Business Checking"
                          )}
                          onChange={ServicesOfferedHandler}
                        />
                        <label>Business Checking</label>
                        {servicesOffered.includes("Business Checking") && (
                          <div
                            className="price_box"
                            style={{ marginTop: "0rem" }}
                          >
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                value={
                                  servicesPrices &&
                                  servicesPrices["Business Checking"]
                                }
                                name="Business Checking"
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes(
                            "Interest Bearing Accounts"
                          )}
                          onChange={ServicesOfferedHandler}
                          value="Interest Bearing Accounts"
                        />
                        <label>Interest Bearing Accounts</label>
                        {servicesOffered.includes(
                          "Interest Bearing Accounts"
                        ) && (
                          <div
                            className="price_box"
                            style={{ marginTop: "0rem" }}
                          >
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                value={
                                  servicesPrices &&
                                  servicesPrices["Interest Bearing Accounts"]
                                }
                                name="Interest Bearing Accounts"
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes("Debit Cards")}
                          onChange={ServicesOfferedHandler}
                          value="Debit Cards"
                        />
                        <label>Debit Cards</label>
                        {servicesOffered.includes("Debit Cards") && (
                          <div
                            className="price_box"
                            syle={{ marginLeft: "0rem" }}
                          >
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                value={
                                  servicesPrices &&
                                  servicesPrices["Debit Cards"]
                                }
                                name="Debit Cards"
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes("ACH")}
                          onChange={ServicesOfferedHandler}
                          value="ACH"
                        />
                        <label>ACH</label>
                        {servicesOffered.includes("ACH") && (
                          <div className="price_box">
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                style={{ marginTop: "0rem" }}
                                name="ACH"
                                value={servicesPrices && servicesPrices["ACH"]}
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes("Wires")}
                          onChange={ServicesOfferedHandler}
                          value="Wires"
                        />
                        <label>Wires</label>
                        {servicesOffered.includes("Wires") && (
                          <div className="price_box">
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                style={{ marginTop: "0rem" }}
                                name="Wires"
                                value={
                                  servicesPrices && servicesPrices["Wires"]
                                }
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes(
                            "Discount for Social Equity"
                          )}
                          onChange={ServicesOfferedHandler}
                          value="Discount for Social Equity"
                        />
                        <label>Discount for Social Equity</label>
                        {servicesOffered.includes(
                          "Discount for Social Equity"
                        ) && (
                          <div className="price_box">
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                style={{ marginTop: "0rem" }}
                                name="Discount for Social Equity"
                                value={
                                  servicesPrices &&
                                  servicesPrices["Discount for Social Equity"]
                                }
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="check__in__bx">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          name="pg-bx"
                          checked={servicesOffered.includes(
                            "Accounts for Employees"
                          )}
                          onChange={ServicesOfferedHandler}
                          value="Accounts for Employees"
                        />
                        <label>Accounts for Employees</label>
                        {servicesOffered.includes("Accounts for Employees") && (
                          <div
                            className="price_box"
                            style={{ marginTop: "0rem" }}
                          >
                            <h5>Price</h5>
                            <div className="">
                              <input
                                type="number"
                                name="Accounts for Employees"
                                value={
                                  servicesPrices &&
                                  servicesPrices["Accounts for Employees"]
                                }
                                placeholder="price"
                                required
                                onChange={ServicesPriceChangeHandler}
                              />
                            </div>
                            <p style={{ color: "red" }}>{/* {errorState} */}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <div className="pg3-mn-otrfld">
                    <div className="check__in__bx">
                      <h6>Timeframe to open a bank account:</h6>

                      <div style={{ display: "flex", marginRight: "30rem" }}>
                        {" "}
                        <div className="pg1-prt-2-lwr-inrfld">
                          <input
                            onInput={(e) => {
                              if (e.target.value.length > 3) {
                                e.target.value = e.target.value.slice(0, 3);
                              }
                            }}
                            style={{ height: "4rem" }}
                            type="number"
                            name="value"
                            value={acc_creation_time.value}
                            onChange={AccountCreationTimehandler}
                            className="form-control"
                            // defaultValue={10}
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.value && (
                            <p style={{ color: "red" }}>{errorState.value}</p>
                          )}
                        </div>
                        <div>
                          <select
                            name="daysormonths"
                            style={{
                              width: "150px",
                              height: "42px",
                              cursor: "pointer",
                              marginLeft: "1rem",
                            }}
                            onChange={AccountCreationTimehandler}
                          >
                            <option
                              selected={
                                acc_creation_time.daysormonths === "Years"
                                  ? true
                                  : false
                              }
                            >
                              Years
                            </option>
                            <option
                              selected={
                                acc_creation_time.daysormonths === "Months"
                                  ? true
                                  : false
                              }
                            >
                              Months
                            </option>
                            <option
                              selected={
                                acc_creation_time.daysormonths === "Days"
                                  ? true
                                  : false
                              }
                            >
                              Days
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-1">
              <div className="pg1-prt-1-txt">
                <div className="cmn_hdr">
                  <h6>Select Accepted Risk Classifications:</h6>
                  <div className="pg1-prt-1-inrfld bnkpge">
                    <input
                      type="checkbox"
                      name="pg-rd"
                      checked={
                        Risk_classification_Bank &&
                        Risk_classification_Bank.includes("Option1")
                      }
                      value="Option1"
                      onChange={RiskClassificationBankhandler}
                    />
                    <label>
                      High Risk: Business activity meets regulatory definition
                      of a Marijuana-Related Business such that the business
                      “touches” marijuana at any point from seed to sale. This
                      includes any business or farm that manufactures,
                      processes, or distributes illegal hemp. Income relies
                      strictly on the marijuana industry.
                    </label>
                  </div>
                  <div className="pg1-prt-1-inrfld bnkpge">
                    <input
                      type="checkbox"
                      name="pg-rd"
                      checked={
                        Risk_classification_Bank &&
                        Risk_classification_Bank.includes("Option2")
                      }
                      value="Option2"
                      onChange={RiskClassificationBankhandler}
                    />
                    <label>
                      Moderate Risk: Business activity focuses on providing
                      products and services specifically to Marijuana-Related
                      Businesses and the cannabis industry as a whole. Business
                      revenue is expected to come from Tier 1 MRB’s and
                      cannabis-related activities.
                    </label>
                  </div>
                  <div className="pg1-prt-1-inrfld bnkpge">
                    <input
                      type="checkbox"
                      name="pg-rd"
                      checked={
                        Risk_classification_Bank &&
                        Risk_classification_Bank.includes("Option3")
                      }
                      value="Option3"
                      onChange={RiskClassificationBankhandler}
                    />
                    <label>
                      Low Risk: Business activity is not specifically focused on
                      providing services to Tier 1 MRB’s or the cannabis
                      industry, but rather such services are incidental to their
                      overall business and revenue.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-2">
              <div className="pg1-prt-2-txt">
                <div className="cmn_hdr">
                  <h6>Legal Business Information</h6>
                  <div className="pg1-prt-2-otrfld">
                    <div className="pg1-prt-2-inrfld nd2">
                      <label>Accepting License Types:</label>
                      <div className="pg1-prt-2-prt-otrfld">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Cultivation"
                            )}
                            value="Cultivation"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Cultivation</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Manufacturer"
                            )}
                            value="Manufacturer"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Manufacturer</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Distribution"
                            )}
                            value="Distribution"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Distribution</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Testing Lab"
                            )}
                            value="Testing Lab"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Testing Lab</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Dispensary"
                            )}
                            value="Dispensary"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Dispensary</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Delivery"
                            )}
                            value="Delivery"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Delivery</label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="licensing_Type"
                            checked={Legal_Business_Bank.licensing_Type.includes(
                              "Other"
                            )}
                            value="Other"
                            onChange={BankLegalBusinessInfoHandler}
                          />
                          <label>Other</label>
                        </div>
                      </div>
                    </div>
                    <div className="pg1-prt-2-inrfld nd3">
                      <div className="pg1-prt-2-lwr-otrfld">
                        {Legal_Business_Bank.licensing_Type.includes(
                          "Other"
                        ) && (
                          <div className="pg1-prt-2-lwr-inrfld">
                            <label>Other:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="other"
                              maxLength={20}
                              value={Legal_Business_Bank.other}
                              onChange={BankLegalBusinessInfoHandler}
                            />
                            {errorState?.other && (
                              <p style={{ color: "red" }}>{errorState.other}</p>
                            )}
                          </div>
                        )}
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Min Number of Employees:</label>

                          <input
                            type="number"
                            id="input"
                            name="num_employees_min"
                            value={Legal_Business_Bank.num_employees_min}
                            onInput={(e) => {
                              if (e.target.value.length > 3) {
                                e.target.value = e.target.value.slice(0, 3);
                              }
                            }}
                            onChange={BankLegalBusinessInfoHandler}
                            className="form-control"
                            // onScroll={()=>{console.log('onwheel')}}
                            // defaultValue={10}
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            {/* <i className="fal fa-plus" /> */}
                            {/* <i onClick={()=>{
                              const data = Number(Legal_Business_Bank.num_employees) + 1
                              setLegal_Business_Bank({
                                ...Legal_Business_Bank, num_employees : data
                              })
                            }}>+</i> */}
                          </a>
                          {errorState?.num_employees_min && (
                            <p style={{ color: "red" }}>
                              {errorState.num_employees_min}
                            </p>
                          )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label htmlFor="num_employees_max">
                            Max Number of Employees:
                          </label>

                          <NumberFormat
                            style={{ width: "20rem" }}
                            maxLength={19}
                            value={Legal_Business_Bank.num_employees_max}
                            // format={"$##,###,###,###"}
                            // 78,979,797,979,797
                            thousandSeparator={","}
                            // decimalSeparator={"."}
                            // decimalScale={2}
                            className="form-control"
                            onChange={BankLegalBusinessInfoHandler}
                            name="num_employees_max"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            {/* <i className="fal fa-plus" /> */}
                            {/* <i onClick={()=>{
                              const data = Number(Legal_Business_Bank.num_employees) + 1
                              setLegal_Business_Bank({
                                ...Legal_Business_Bank, num_employees : data
                              })
                            }}>+</i> */}
                          </a>
                          {errorState?.num_employees_max && (
                            <p style={{ color: "red" }}>
                              {errorState.num_employees_max}
                            </p>
                          )}
                          {errorState?.num_employeesError && (
                            <p style={{ color: "red" }}>
                              {errorState.num_employeesError}
                            </p>
                          )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1b">
                          <label>Accepted States:</label>
                          <CreatableSelect
                            name="accepted_states"
                            defaultValue={Legal_Business_Bank?.accepted_states?.map(
                              (i) => {
                                return {
                                  value: i,
                                  label: i,
                                  color: "#0052CC",
                                };
                              }
                            )}
                            isMulti
                            options={StateOptions}
                            onChange={(val) => {
                              console.log(val, "states");
                              const data = val.map((item) => item.value);
                              //  setAcceptedStates([...data])

                              setLegal_Business_Bank({
                                ...Legal_Business_Bank,
                                accepted_states: data,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pg1-prt-2-inrfld nd4">
                      <div className="pg1-prt-2-end-otrfld">
                        <div className="pg1-prt-2-end-inrfld">
                          <label>Foreign operations acceepted?</label>
                          <div className="pg1-prt-2-end-prtfld">
                            <span>No</span>
                            <span className="toggle-bg">
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="foreign_operations"
                                  checked={
                                    Legal_Business_Bank.foreign_operations ===
                                    "yes"
                                  }
                                  onChange={BankLegalBusinessInfoHandler}
                                />
                                <span className="slider round" />
                              </label>
                            </span>
                            <span>Yes</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pg1-prt-3">
              <div className="pg1-prt-3-txt">
                <div className="cmn_hdr">
                  <h6>Primary Contact Information:</h6>
                  {/* <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                    <div className="pg1-prt-2-inrfld">
                      <label>Minimum Ownership % of Primary Contact:</label>
                      <input type="text" name="minimum_ownership" className="form-control"
                        value={Primary_Information_Bank.minimum_ownership}
                        onChange={BankPrimaryContactInfoHandler}
                      />
                      {
                        errorState?.minimum_ownership && <p style={{ color: 'red' }}>{errorState.minimum_ownership}</p>

                      }
                    </div>
                  </div> */}
                  <div className="bnk_add_grp">
                    <p>Minimum Ownership % of Primary Contact</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      {/* <label>Minimum:</label> */}
                      <input
                        type="number"
                        className="form-control"
                        // defaultValue={2}
                        onInput={(e) => {
                          if (e.target.value.length > 2) {
                            e.target.value = e.target.value.slice(0, 2);
                          }
                        }}
                        name="ownership_Percentage_min"
                        value={
                          Primary_Information_Bank.ownership_Percentage_min
                        }
                        onChange={BankPrimaryContactInfoHandler}
                      />
                      {/* <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  <br/> */}
                      {errorState?.ownership_Percentage_min && (
                        <p style={{ color: "red" }}>
                          {errorState.ownership_Percentage_min}
                        </p>
                      )}
                    </div>
                    {/* <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <input type="number" className="form-control" defaultValue={10}
                  onInput={(e)=>{
                 
                    if(e.target.value.length > 3){
                     e.target.value = e.target.value.slice(0,3)
                    }
               }}
                    name='ownership_Percentage_max'
                    value={Primary_Information_Bank.ownership_Percentage_max}
                    onChange={BankPrimaryContactInfoHandler} />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {
                      errorState?.ownership_Percentage_max && <p style={{ color: 'red'}}>{errorState.ownership_Percentage_max}</p>

                    }
                </div> */}
                  </div>
                  <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                    <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                      <label>Accept Non-U.S. Citizen?</label>
                      <div className="pg1-prt-2-end-prtfld">
                        <span>No</span>
                        <span className="toggle-bg">
                          <label className="switch2">
                            <input
                              type="checkbox"
                              checked={
                                Primary_Information_Bank.us_Citizenship ===
                                "yes"
                              }
                              name="us_Citizenship"
                              onChange={BankPrimaryContactInfoHandler}
                            />
                            <span className="slider round" />
                          </label>
                        </span>
                        <span>Yes</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          {/* <button className='btn btn-prev' onClick={PreviousHandler}>Previous</button> */}
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormOneBank;
