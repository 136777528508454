import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { nextTwo } from "../../Store/userSlice";
import { RiFileAddFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { AiFillDelete } from "react-icons/ai";
import NumberFormat from "react-number-format";
import { PiCurrencyDollar } from "react-icons/pi";
import CurrencyInput from "../../Helper/CurrencyInput";

// IoAddOutline
// import { IoAddOutline } from 'react-icons/io'

const FormTwo = (props) => {
  const [customErr, setCustomErr] = useState([
    {
      loc_name: "",
      license: "",
      loc_address: "",
    },
    {
      loc_name: "",
      license: "",
      loc_address: "",
    },
    {
      loc_name: "",
      license: "",
      loc_address: "",
    },
    {
      loc_name: "",
      license: "",
      loc_address: "",
    },
  ]);
  const {
    OperationalDetailshandler,
    OtherOperationDetailsHandler,
    AdditionalLocshandler,
    AnticipatedTransactionActivityHandler,
    Operational_Details,
    Other_Operational_Details,
    Additional_Locs,
    setAdditional_Locs,
    Anticipated_Transaction_Activity,
    errors,
  } = useForm();
  console.log(Operational_Details.types_customers, "types_customers");
  console.log(
    Anticipated_Transaction_Activity.anticipate_cash_withdrawals,
    "345555"
  );
  console.log(errors, "errors");
  console.log(
    "$" + Number(Operational_Details.purchase_amount_per_sale).toFixed(2),
    "888888"
  );
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  console.log(Additional_Locs, "Additional_Locs");
  const [errorState, setErrorstate] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setErrorstate(errors);
  }, [errors]);
  const commaSeparators = (num) => {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numParts.join(".");
  };
  const NextHandlerTwo = (event) => {
    console.log(Additional_Locs, "93456776");

    for (let x in Operational_Details) {
      console.log(x, "ll");
      if (
        String(Operational_Details[x]).length === 0 &&
        x !== "types_customers"
      ) {
        errorState[x] = "Cannot be empty";

        setErrorstate({ ...errorState });
      }
    }
    for (let x in Other_Operational_Details) {
      if (String(Other_Operational_Details[x]).length === 0) {
        errorState[x] = "Cannot be empty";
        setErrorstate({ ...errorState });
      }
    }
    for (let x in Additional_Locs) {
      if (String(Additional_Locs[x]).length === 0) {
        errorState[x] = "Cannot be empty";
        setErrorstate({ ...errorState });
      }
    }
    for (let x in Anticipated_Transaction_Activity) {
      if (
        x === "amount_Initial_Deposit" ||
        x === "source_Initial_Deposit" ||
        x === "estimated_total_amount_monthly_deposit" ||
        x === "estimated_total_num_monthly_deposit"
      ) {
        if (String(Anticipated_Transaction_Activity[x]).length === 0) {
          errorState[x] = "Cannot be empty";
          setErrorstate({ ...errorState });
        }
      } else if (x === "amount_cash_deposits" || x === "estimated_spend") {
        if (
          Anticipated_Transaction_Activity.anticipate_cash_deposits === "yes"
        ) {
          if (String(Anticipated_Transaction_Activity[x]).length === 0) {
            errorState[x] = "Cannot be empty";
            setErrorstate({ ...errorState });
          }
        }
      }
    }
    event.preventDefault();

    const res = [
      ...Object.values(Operational_Details),
      ...Object.values(Other_Operational_Details),
      ...Object.values(Additional_Locs),
      ...Object.values(Anticipated_Transaction_Activity),
    ];

    const finalres = res.map((i) => String(i).length);

    console.log(errors, "see-errors");

    if (Object.keys(errors).length === 0 && finalres.every((x) => x !== 0)) {
      props.setFormNo(3);
      window.scrollTo(0, 0);
      dispatch(
        nextTwo({
          Other_Operational_Details,
          Operational_Details,
          Additional_Locs,
          Anticipated_Transaction_Activity,
        })
      );
    } else {
      // setShowErr("Cannot be empty")
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  console.log(USDollar.format(Number("8900")), "23664");
  const NextHandler20 = (event) => {
    event.preventDefault();
    console.log(Anticipated_Transaction_Activity, "275");
    // if(Anticipated_Transaction_Activity.anticipate_cash_deposits === 'yes'){

    // }else{
    //   var data1 = Object.values(Anticipated_Transaction_Activity)
    // }
    // const data =[...Object.values(Operational_Details),...Object.values(Other_Operational_Details),...Object.values(Additional_Locs),...Object.values(Anticipated_Transaction_Activity)]
    // console.log(data,'1111111111')
    // console.log(errorState,'788888')
    // lease_term
    for (let x in Operational_Details) {
      console.log(x, "ll");
      if (x !== "lease_term") {
        if (x !== "types_customers") {
          if (String(Operational_Details[x]).length === 0) {
            errorState[x] = "Cannot be empty";

            setErrorstate({ ...errorState });
          } else {
            delete errorState[x];
            setErrorstate({ ...errorState });
          }
        } else {
          if (Operational_Details.types_customers.length === 0) {
            errorState.types_customers = "Please select atleast one option";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.types_customers;
            setErrorstate({ ...errorState });
          }
        }
      } else {
        if (Operational_Details.facility === "Leased") {
          if (Operational_Details.lease_term === "") {
            errorState[x] = "Cannot be empty";

            setErrorstate({ ...errorState });
          } else {
            delete errorState.lease_term;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.lease_term;
          setErrorstate({ ...errorState });
        }
      }
    }
    for (let x in Other_Operational_Details) {
      if (Other_Operational_Details[x] === "") {
        errorState[x] = "Cannot be empty";
        setErrorstate({ ...errorState });
      } else {
        delete errorState[x];
        setErrorstate({ ...errorState });
      }
    }
    for (let x in Anticipated_Transaction_Activity) {
      if (
        x === "amount_Initial_Deposit" ||
        x === "source_Initial_Deposit" ||
        x === "estimated_total_amount_monthly_deposit" ||
        x === "estimated_total_num_monthly_deposit"
      ) {
        if (Anticipated_Transaction_Activity[x] === "") {
          errorState[x] = "Cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else if (x === "amount_cash_deposits" || x === "estimated_spend") {
        if (
          Anticipated_Transaction_Activity.anticipate_cash_deposits === "yes"
        ) {
          if (Anticipated_Transaction_Activity[x] === "") {
            errorState[x] = "Cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState[x];
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else if (x === "amount_cash_withdrawals" || x === "monthly_payroll") {
        if (
          Anticipated_Transaction_Activity.anticipate_cash_withdrawals === "yes"
        ) {
          if (Anticipated_Transaction_Activity[x] === "") {
            errorState[x] = "Cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState[x];
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      } else if (x === "estimated_cash_pick_ups") {
        if (Anticipated_Transaction_Activity.cash_pick_ups === "yes") {
          if (Anticipated_Transaction_Activity[x] === "") {
            errorState[x] = "Cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState[x];
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      }
    }
    const finalErr = [];
    for (let i = 0; i < Additional_Locs.length; i++) {
      for (let y in Additional_Locs[i]) {
        finalErr.push(Additional_Locs[i][y]);
        if (Additional_Locs[i][y] === "") {
          // let data = [...customErr]
          customErr[i][y] = "Cannot be empty";
          setCustomErr([...customErr]);
        } else {
          console.log(customErr, "customErr");
          delete customErr[i][y];
          setCustomErr([...customErr]);
        }
      }
    }
    console.log(
      finalErr.some((o) => o === ""),
      "1120000"
    );
    console.log(errorState, "2345678");
    if (
      Object.values(errorState).length === 0 &&
      !finalErr.some((o) => o === "")
    ) {
      props.setFormNo(3);
      window.scrollTo(0, 0);
      dispatch(
        nextTwo({
          Other_Operational_Details,
          Operational_Details,
          Additional_Locs,
          Anticipated_Transaction_Activity,
        })
      );
    } else {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const PreviousHandler = () => {
    props.setFormNo(1);
    window.scrollTo(0, 0);
  };

  return (
    <section id="section2" className="section2">
      <form action="" onSubmit={NextHandler20}>
        <div className="ope_dtls bg_grey">
          <h3 className="hdng_h3">Operational Details</h3>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Are any marijuana-related products purchased from providers?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="products_purchased_providers"
                  onChange={OperationalDetailshandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group">
            <h5 className="fromgroup_hdng">Licensed Provider?</h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="licensed_provider"
                  onChange={OperationalDetailshandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-input-group">
            <label htmlFor="">
              How many Bank accounts do you need?
              <span className="red-star">*</span>
            </label>
            <NumberFormat
              maxLength={2}
              value={Operational_Details.acc_need}
              // format={"$##,###,###,###"}
              // 78,979,797,979,797
              thousandSeparator={","}
              // decimalSeparator={"."}
              // decimalScale={2}
              onChange={OperationalDetailshandler}
              name="acc_need"
            />
            {errorState?.acc_need && (
              <h3 style={{ color: "red" }}>{errorState.acc_need}</h3>
            )}
          </div>
          <div className="parent_forminput_group">
            <div className="form-input-group">
              <label htmlFor="">
                Number of Locations:<span className="red-star">*</span>
              </label>
              <NumberFormat
                maxLength={2}
                value={Operational_Details.num_locs}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OperationalDetailshandler}
                name="num_locs"
              />
              {errorState?.num_locs && (
                <h3 style={{ color: "red" }}>{errorState.num_locs}</h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Average No. of Transactions Per Month:
                <span className="red-star">*</span>
              </label>
              {/* <input type='number'
               onInput={(e)=>{
                 
                 if(e.target.value.length > 8){
                  e.target.value = e.target.value.slice(0,8)
                 }
            }}
                value={Operational_Details.transactions_Per_month}
                name='transactions_Per_month' onChange={OperationalDetailshandler} /> */}
              <NumberFormat
                maxLength={6}
                value={Operational_Details.transactions_Per_month}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OperationalDetailshandler}
                name="transactions_Per_month"
              />
              {errorState?.transactions_Per_month && (
                <h3 style={{ color: "red" }}>
                  {errorState.transactions_Per_month}
                </h3>
              )}
            </div>
            {/* <div className="form-input-group">
              <label htmlFor="">Total Managed Square Feet:</label>
              
              <NumberFormat
                maxLength={19}
                value={Operational_Details.managed_square_feet}
                // format={"$#########"}
                thousandSeparator={","}
                decimalSeparator={"."}
                decimalScale={2}
                onChange={OperationalDetailshandler}
                name="managed_square_feet"
              />
              {errorState?.managed_square_feet && (
                <h3 style={{ color: "red" }}>
                  {errorState.managed_square_feet}
                </h3>
              )}
            </div> */}
            <div className="form-input-group">
              <label htmlFor="">
                Average Purchase Amount Per Sale(USD):
                <span className="red-star">*</span>
              </label>

              <CurrencyInput
                placeholder="$0.00"
                maxLength={9}
                value={Operational_Details.purchase_amount_per_sale}
                type="text"
                name="purchase_amount_per_sale"
                onChange={OperationalDetailshandler}
              />
              <br />
              {errorState?.purchase_amount_per_sale && (
                <h3 style={{ color: "red" }}>
                  {errorState.purchase_amount_per_sale}
                </h3>
              )}
              {/* </div> */}
              {/* <input type="text" 
                          value={USDollar.format(Operational_Details.purchase_amount_per_sale)}
                          // defaultValue={Number(Operational_Details.purchase_amount_per_sale).toFixed(2)}
                          // defaultValue={'$'+Number(Operational_Details.purchase_amount_per_sale).toFixed(2)}
                            onChange={OperationalDetailshandler} name='purchase_amount_per_sale'  />
                            {
                      errorState?.purchase_amount_per_sale && <h3 style={{ color: 'red' }}>{errorState.purchase_amount_per_sale}</h3>

                    } */}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Number of Plants: <span className="red-star">*</span>
              </label>
              <NumberFormat
                maxLength={19}
                value={Operational_Details.num_plants}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OperationalDetailshandler}
                name="num_plants"
              />
              {errorState?.num_plants && (
                <h3 style={{ color: "red" }}>{errorState.num_plants}</h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Average Quantity Per Sale:<span className="red-star">*</span>
              </label>

              <NumberFormat
                maxLength={19}
                value={Operational_Details.average_quantity_per_sale}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OperationalDetailshandler}
                name="average_quantity_per_sale"
              />
              {errorState?.average_quantity_per_sale && (
                <h3 style={{ color: "red" }}>
                  {errorState.average_quantity_per_sale}
                </h3>
              )}
              <select
                style={{
                  height: "5rem",
                  width: "20%",
                  marginTop: "10px",
                  marginLeft: "5px",
                }}
                name="unit"
                onChange={OperationalDetailshandler}
                className="form-control"
              >
                <option
                  value="Gram"
                  selected={Operational_Details.unit === "Gram"}
                >
                  Gram
                </option>
                <option
                  value="Kilogram"
                  selected={Operational_Details.unit === "Kilogram"}
                >
                  Kilogram
                </option>
                <option
                  value="Pound"
                  selected={Operational_Details.unit === "Pound"}
                >
                  Pound
                </option>
              </select>
            </div>

            <div className="form-input-group">
              <div className="iform-group">
                <h5 className="fromgroup_hdng">Facility Owned or Leased?</h5>
                <div className="frm_radio_prnt">
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="Owned"
                      checked={
                        Operational_Details.facility === "Owned" ? true : false
                      }
                      onChange={OperationalDetailshandler}
                      id="Owned"
                      name="facility"
                      // checked={Operational_Details.types_customers === "Medical"}
                    />
                    {/* <span class="radio"></span>  */}
                    <label htmlFor="Owned" className="radio-button">
                      Owned
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      value="Leased"
                      checked={
                        Operational_Details.facility === "Leased" ? true : false
                      }
                      onChange={OperationalDetailshandler}
                      id="Leased"
                      name="facility"
                      // checked={Operational_Details.types_customers === "Recreational"}
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Leased" className="radio-button">
                      Leased
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {Operational_Details.facility === "Leased" && (
              <div className="form-input-group">
                <label htmlFor="">Lease Term:</label>
                <input
                  type="text"
                  name="lease_term"
                  value={Operational_Details.lease_term}
                  onChange={OperationalDetailshandler}
                />
                {errorState?.lease_term && (
                  <h3 style={{ color: "red" }}>{errorState.lease_term}</h3>
                )}
              </div>
            )}
            <div className="form-input-group">
              <label htmlFor="">
                {" "}
                Estimated Gross Monthly Income(USD):
                <span className="red-star">*</span>
              </label>
              {/* <IconContext.Provider value={{ color: "", size:'3rem',marginTop:'4rem'}}> <PiCurrencyDollar/></IconContext.Provider> */}
              {/* <input type="number"
                value={Operational_Details.egmi}
                defaultValue={'$' + Number(Operational_Details.egmi).toFixed(2)}
                onChange={OperationalDetailshandler} name='egmi' /> */}
              <CurrencyInput
                placeholder="$0.00"
                maxLength={9}
                value={Operational_Details.egmi}
                type="text"
                name="egmi"
                onChange={OperationalDetailshandler}
              />

              {errorState?.egmi && (
                <h3 style={{ color: "red" }}>{errorState.egmi}</h3>
              )}
            </div>
            <div className="form-input-group">
              <label>
                {" "}
                Types of Customers to Be Served:
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="pg3-mn-otrfld">
                <div className="check__in__bx">
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      value="Medical"
                      onChange={OperationalDetailshandler}
                      checked={Operational_Details.types_customers.includes(
                        "Medical"
                      )}
                      id="medical"
                    />
                    <label>Medical</label>
                  </div>
                </div>
                <div className="check__in__bx">
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      value="Recreational"
                      onChange={OperationalDetailshandler}
                      checked={Operational_Details.types_customers.includes(
                        "Recreational"
                      )}
                      id="recreational"
                    />
                    <label>Recreational</label>
                  </div>
                </div>
                {errorState?.types_customers &&
                  Operational_Details.types_customers.length === 0 && (
                    <h3 style={{ color: "red" }}>
                      {errorState?.types_customers}
                    </h3>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="other_ope_dtls bg_grey">
          <h3 className="hdng_h3">Other Operational Details</h3>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Are any of the principals of the CRB (e.g., officer, directors, or
              significant investors) principals of any other entity that
              conducts marijuana-related business in any other state?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="principal_business"
                  onChange={OtherOperationDetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Does the CRB have any Beneficial Owners? (anyone with a stake of
              10% or more in the CRB)
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="beneficial_owners"
                  onChange={OtherOperationDetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-input-group">
            <label htmlFor="">
              Number of Beneficial Owners:<span className="red-star">*</span>{" "}
            </label>
            <NumberFormat
              maxLength={12}
              value={Other_Operational_Details.number_beneficial_owner}
              // format={"$##,###,###,###"}
              // 78,979,797,979,797
              thousandSeparator={","}
              // decimalSeparator={"."}
              // decimalScale={2}
              onChange={OtherOperationDetailsHandler}
              name="number_beneficial_owner"
            />
            {errorState?.number_beneficial_owner && (
              <h3 style={{ color: "red" }}>
                {errorState.number_beneficial_owner}
              </h3>
            )}
          </div>
        </div>
        <div className="additional_location bg_grey">
          <h3 className="hdng_h3">Additional Locations</h3>
          <br />
          {Additional_Locs.map((addloc, index) => {
            return (
              <>
                <div className="form-input-group">
                  <label> Location {index + 1}</label>
                </div>
                <div className="parent_forminput_group">
                  <div className="form-input-group">
                    <label htmlFor="">
                      Location Name:<span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={Additional_Locs[index].loc_name}
                      name="loc_name"
                      onChange={(event) => {
                        AdditionalLocshandler(event, index);
                      }}
                    />
                    {customErr[index]?.loc_name && (
                      <h3 style={{ color: "red" }}>
                        {customErr[index]?.loc_name}
                      </h3>
                    )}
                  </div>
                  <div className="form-input-group">
                    <label htmlFor="">
                      License No:<span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={12}
                      // onInput={(e) => {
                      //   if (e.target.value.length > 8) {
                      //     e.target.value = e.target.value.slice(0, 8);
                      //   }
                      // }}
                      value={Additional_Locs[index].license}
                      name="license"
                      onChange={(event) => {
                        AdditionalLocshandler(event, index);
                      }}
                    />
                    {customErr[index].license && (
                      <h3 style={{ color: "red" }}>
                        {customErr[index].license}
                      </h3>
                    )}
                    {/* {
                      Additional_Locs[index].license === '' ? showErr : ''
                    } */}
                  </div>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Address:<span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={Additional_Locs[index].loc_address}
                      name="loc_address"
                      onChange={(event) => {
                        AdditionalLocshandler(event, index);
                      }}
                    />
                    {customErr[index].loc_address && (
                      <h3 style={{ color: "red" }}>
                        {customErr[index].loc_address}
                      </h3>
                    )}
                    {/* {
                      Additional_Locs[index].loc_address === '' ? showErr : ''
                    } */}
                  </div>
                  <div className="form-input-group">
                    <div className="iform-group">
                      <h5 className="fromgroup_hdng">
                        Deposit Account Needed:
                      </h5>
                      <div className="switch_box">
                        <h4>no</h4>
                        <label className="switch2">
                          <input
                            type="checkbox"
                            name="deposit_acc_need"
                            checked={
                              Additional_Locs[index].deposit_acc_need === "yes"
                            }
                            onChange={(event) => {
                              AdditionalLocshandler(event, index);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                        <h4>yes</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{
                    marginTop: "10px",
                    float: "right",
                    backgroundColor: "#fff",
                    color: "#4e4e4e",
                  }}
                >
                  <IconContext.Provider
                    value={{ color: "#ba8c4a", size: "4rem" }}
                  >
                    <div
                      onClick={() => {
                        const data = [...Additional_Locs];
                        data.splice(index, 1);
                        setAdditional_Locs([...data]);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <AiFillDelete />
                    </div>
                  </IconContext.Provider>
                </div>
                <br />
                <br />
                <br />
              </>
            );
          })}
          <div
            style={{
              marginTop: "10px",
              float: "right",
              backgroundColor: "#fff",
              color: "#4e4e4e",
            }}
          >
            <IconContext.Provider value={{ color: "#ba8c4a", size: "5rem" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  let data = [
                    ...Additional_Locs,
                    {
                      loc_name: "",
                      license: "",
                      deposit_acc_need: "no",
                      loc_address: "",
                    },
                  ];
                  setAdditional_Locs(data);
                }}
              >
                <RiFileAddFill />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <div className="Atactivity bg_grey">
          <h3 className="hdng_h3">Anticipated Transaction Activity</h3>
          <div className="parent_forminput_group">
            <div className="form-input-group">
              <label htmlFor="">
                Amount of Initial Deposit (USD):
                <span className="red-star">*</span>
              </label>
              {/* <input type="number" name='amount_Initial_Deposit'
                value={Anticipated_Transaction_Activity.amount_Initial_Deposit}
                defaultValue={'$' + Number(Anticipated_Transaction_Activity.amount_Initial_Deposit).toFixed(2)}
                onChange={AnticipatedTransactionActivityHandler}
              /> */}
              {/* <IconContext.Provider value={{ color: "", size:'3rem',marginLeft:''}}> <PiCurrencyDollar/></IconContext.Provider>
               */}
              <CurrencyInput
                placeholder="$0.00"
                maxLength={9}
                value={Anticipated_Transaction_Activity.amount_Initial_Deposit}
                type="text"
                name="amount_Initial_Deposit"
                onChange={AnticipatedTransactionActivityHandler}
              />
              {errorState?.amount_Initial_Deposit && (
                <h3 style={{ color: "red" }}>
                  {errorState.amount_Initial_Deposit}
                </h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Source of Initial Deposit:<span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="source_Initial_Deposit"
                value={Anticipated_Transaction_Activity.source_Initial_Deposit}
                onChange={AnticipatedTransactionActivityHandler}
              />
              {errorState?.source_Initial_Deposit && (
                <h3 style={{ color: "red" }}>
                  {errorState.source_Initial_Deposit}
                </h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Estimated Total Amount of Monthly Deposits (USD):
                <span className="red-star">*</span>
              </label>
              {/* <IconContext.Provider value={{ color: "", size:'3rem',marginLeft:''}}> <PiCurrencyDollar/></IconContext.Provider> */}
              {/* <input type="number" name='estimated_total_amount_monthly_deposit'
                value={Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit}
                defaultValue={'$' + Number(Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit).toFixed(2)}
                onChange={AnticipatedTransactionActivityHandler} /> */}
              <CurrencyInput
                placeholder="$0.00"
                maxLength={9}
                value={
                  Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit
                }
                type="text"
                name="estimated_total_amount_monthly_deposit"
                onChange={AnticipatedTransactionActivityHandler}
              />
              {errorState?.estimated_total_amount_monthly_deposit && (
                <h3 style={{ color: "red" }}>
                  {errorState.estimated_total_amount_monthly_deposit}
                </h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Estimated Total Number of Deposits Per Month:
                <span className="red-star">*</span>
              </label>
              {/* <input type="number" name='estimated_total_num_monthly_deposit'
              
                value={Anticipated_Transaction_Activity.estimated_total_num_monthly_deposit}
                onChange={AnticipatedTransactionActivityHandler} /> */}
              <NumberFormat
                maxLength={10}
                value={
                  Anticipated_Transaction_Activity.estimated_total_num_monthly_deposit
                }
                // format={"$#########"}
                thousandSeparator={","}
                decimalSeparator={"."}
                decimalScale={2}
                onChange={AnticipatedTransactionActivityHandler}
                name="estimated_total_num_monthly_deposit"
              />
              {errorState?.estimated_total_num_monthly_deposit && (
                <h3 style={{ color: "red" }}>
                  {errorState.estimated_total_num_monthly_deposit}
                </h3>
              )}
            </div>
            <div className="iform-group">
              <h5 className="fromgroup_hdng">
                Anticipate making cash deposits?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="anticipate_cash_deposits"
                    checked={
                      Anticipated_Transaction_Activity.anticipate_cash_deposits ===
                      "yes"
                        ? true
                        : false
                    }
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity.anticipate_cash_deposits ===
              "yes" && (
              <>
                <div className="form-input-group">
                  <label htmlFor="">
                    Amount of Monthly Cash Deposits (USD):
                    <span className="red-star">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity.amount_cash_deposits
                    }
                    type="text"
                    name="amount_cash_deposits"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  {errorState?.amount_cash_deposits && (
                    <h3 style={{ color: "red" }}>
                      {errorState.amount_cash_deposits}
                    </h3>
                  )}
                </div>
                <div className="form-input-group">
                  <label> Frequency of Cash Deposits:</label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        name="frequency_cash_deposits"
                        value="Daily"
                        onChange={AnticipatedTransactionActivityHandler}
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_deposits ===
                          "Daily"
                        }
                        id="daily"
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="daily" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Weekly"
                        onChange={AnticipatedTransactionActivityHandler}
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_deposits ===
                          "Weekly"
                        }
                        id="weekly"
                        name="frequency_cash_deposits"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="weekly" className="radio-button">
                        {" "}
                        Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Bi-Weekly"
                        onChange={AnticipatedTransactionActivityHandler}
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_deposits ===
                          "Bi-Weekly"
                        }
                        id="Bi-Weekly"
                        name="frequency_cash_deposits"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Bi-Weekly" className="radio-button">
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Monthly"
                        onChange={AnticipatedTransactionActivityHandler}
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_deposits ===
                          "Monthly"
                        }
                        name="frequency_cash_deposits"
                        id="Monthly"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Monthly" className="radio-button">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-input-group">
                  <label htmlFor="">
                    Estimated Spend or Withdrawals Monthly (USD):
                    <span className="red-star">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={Anticipated_Transaction_Activity.estimated_spend}
                    type="text"
                    name="estimated_spend"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  {errorState?.estimated_spend && (
                    <h3 style={{ color: "red" }}>
                      {errorState.estimated_spend}
                    </h3>
                  )}
                </div>
              </>
            )}
            <div className="iform-group">
              <h5 className="fromgroup_hdng">
                Anticipate making cash withdrawals?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    onChange={AnticipatedTransactionActivityHandler}
                    checked={
                      Anticipated_Transaction_Activity.anticipate_cash_withdrawals ===
                      "yes"
                        ? true
                        : false
                    }
                    name="anticipate_cash_withdrawals"
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity.anticipate_cash_withdrawals ===
              "yes" && (
              <>
                <div className="form-input-group">
                  <label htmlFor="">
                    Amount of Monthly Cash Withdrawals (USD):
                    <span className="red-star">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity.amount_cash_withdrawals
                    }
                    type="text"
                    name="amount_cash_withdrawals"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  {errorState?.amount_cash_withdrawals && (
                    <h3 style={{ color: "red" }}>
                      {errorState.amount_cash_withdrawals}
                    </h3>
                  )}
                </div>
                <div className="form-input-group">
                  <label> Frequency of Cash Withdrawals:</label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Daily"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                          "Daily"
                        }
                        name="frequency_cash_withdrawals"
                        onChange={AnticipatedTransactionActivityHandler}
                        id="daily2"
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="daily2" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        id="weekly2"
                        value="Weekly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                          "Weekly"
                        }
                        name="frequency_cash_withdrawals"
                        onChange={AnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="weekly2" className="radio-button">
                        Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        id="Bi-Weekly2"
                        value="Bi-Weekly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                          "Bi-Weekly"
                        }
                        name="frequency_cash_withdrawals"
                        onChange={AnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Bi-Weekly2" className="radio-button">
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        id="Monthly2"
                        value="Monthly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                          "Monthly"
                        }
                        name="frequency_cash_withdrawals"
                        onChange={AnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Monthly2" className="radio-button">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-input-group">
                  <label htmlFor="">
                    Average Expected Monthly Payroll (USD):
                    <span className="red-star">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={Anticipated_Transaction_Activity.monthly_payroll}
                    type="text"
                    name="monthly_payroll"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  {errorState?.monthly_payroll && (
                    <h3 style={{ color: "red" }}>
                      {errorState.monthly_payroll}
                    </h3>
                  )}
                </div>
              </>
            )}
            <div className="iform-group">
              <h5 className="fromgroup_hdng">Cash pick-ups required?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="cash_pick_ups"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity.cash_pick_ups === "yes" && (
              <>
                <div className="form-input-group">
                  <label>Frequency of Cash Pick-Ups:</label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        id="daily3"
                        value="Daily"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                          "Daily"
                        }
                        name="frequency_cash_pick_ups"
                        onChange={AnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="daily3" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Weekly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                          "Weekly"
                        }
                        name="frequency_cash_pick_ups"
                        onChange={AnticipatedTransactionActivityHandler}
                        id="weekly3"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="weekly3" className="radio-button">
                        Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Bi-Weekly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                          "Bi-Weekly"
                        }
                        name="frequency_cash_pick_ups"
                        onChange={AnticipatedTransactionActivityHandler}
                        id="Bi-Weekly3"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Bi-Weekly3" className="radio-button">
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Monthly"
                        checked={
                          Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                          "Monthly"
                        }
                        name="frequency_cash_pick_ups"
                        onChange={AnticipatedTransactionActivityHandler}
                        id="Monthly3"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Monthly3" className="radio-button">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-input-group">
                  <label htmlFor="">
                    Estimated Pick-Up Amount (USD):
                    <span className="red-star">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity.estimated_cash_pick_ups
                    }
                    type="text"
                    name="estimated_cash_pick_ups"
                    onChange={AnticipatedTransactionActivityHandler}
                  />
                  {errorState?.estimated_cash_pick_ups && (
                    <h3 style={{ color: "red" }}>
                      {errorState.estimated_cash_pick_ups}
                    </h3>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormTwo;
