import React from 'react'
import useForm from '../../CustomHook/useForm'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { nextThreeBank } from '../../Store/userSlice';

const FormThreeBank = (props) => {
  toast.configure({
    autoClose: 500000,
    draggable: true
  });
  const { Company_Documentation_Bank, BankCompanyDocumentationHandler } = useForm()
  const dispatch = useDispatch()
  console.log(Company_Documentation_Bank, 'u888')
  const NextHandlerThree = (event) => {
    event.preventDefault()
    if (Company_Documentation_Bank.length !== 0) {
      dispatch(nextThreeBank({ Company_Documentation_Bank }))
      props.setFormNo(4)
    } else {
      toast.error('Please Select Atleast One Option!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  const PreviousHandler = (event) => {
    event.preventDefault()
    props.setFormNo(2)
  }
  return (
    <section id="section3" className="section3" >
      <form onSubmit={NextHandlerThree}>
        <div className="pg3-mn-otr">
          <div className="pg3-mn-inr">
            <div className="cmn_hdr">
              <h6>Company Documentation</h6>
              <p>Check Documents Available:</p>
              <div className="pg3-mn-otrfld">
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Audited Financial Statements')}
                    value='Audited Financial Statements'
                    onChange={BankCompanyDocumentationHandler}
                  />
                  <label>Audited Financial Statements</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Policies')}
                    value='Policies' onChange={BankCompanyDocumentationHandler} />
                  <label>Policies</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Articles of Formation (Organization or Incorporation)')}
                    value='Articles of Formation (Organization or Incorporation)' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Articles of Formation (Organization or Incorporation)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Operating Agreement')}
                    value='Operating Agreement'
                    onChange={BankCompanyDocumentationHandler}
                  />
                  <label>Operating Agreement</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Contracts with Armored Car Services to Transport Cash')}
                    value='Contracts with Armored Car Services to Transport Cash' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Contracts with Armored Car Services to Transport Cash
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Results of any inspections performed by the State')}
                    value='Results of any inspections performed by the State' onChange={BankCompanyDocumentationHandler} />
                  <label>Results of any inspections performed by the State</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Federal and State Tax Returns (Last 3 Years Available)')}
                    value='Federal and State Tax Returns (Last 3 Years Available)' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Federal and State Tax Returns (Last 3 Years Available)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                   
  checked={Company_Documentation_Bank.includes('Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)')}
                   
  value='Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Compliance Attestation (Agreement with terms and conditions of
                    account and fee schedule, statement that MRB is not in violation
                    of Cole Memo Priorities or FinCEN guidelines)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Proof of vendor due diligence completed for all vendors used')}
                    value='Proof of vendor due diligence completed for all vendors used' onChange={BankCompanyDocumentationHandler}
                  />
                  <label>
                    Proof of vendor due diligence completed for all vendors used
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Most Recent Compliance Audit and Reports')}
                    value='Most Recent Compliance Audit and Reports' onChange={BankCompanyDocumentationHandler}
                  />
                  <label>Most Recent Compliance Audit and Reports</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Sales History from POS Records (90 Days Fully Operational)')}
                    value='Sales History from POS Records (90 Days Fully Operational)' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Sales History from POS Records (90 Days Fully Operational)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Employee Training Manual')}
                    value='Employee Training Manual' onChange={BankCompanyDocumentationHandler} />
                  <label>Employee Training Manual</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Signed Lease Agreement for each Location')}
                    value='Signed Lease Agreement for each Location' onChange={BankCompanyDocumentationHandler} />
                  <label>Signed Lease Agreement for each Location</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Business Recovery Plan')}
                    value='Business Recovery Plan' onChange={BankCompanyDocumentationHandler} />
                  <label>Business Recovery Plan</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Insurance Certificate (summary of coverage)')}
                    value='Insurance Certificate (summary of coverage)' onChange={BankCompanyDocumentationHandler} />
                  <label>Insurance Certificate (summary of coverage)</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('State Licensing')}
                    value='State Licensing' onChange={BankCompanyDocumentationHandler} />
                  <label>State Licensing</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Secretary of State Registration')}
                    value='Secretary of State Registration' onChange={BankCompanyDocumentationHandler} />
                  <label>Secretary of State Registration</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Results of any on-site inspections')}
                    value='Results of any on-site inspections' onChange={BankCompanyDocumentationHandler} />
                  <label>Results of any on-site inspections</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('Financial Projections for Next 1-3 Years')}
                    value='Financial Projections for Next 1-3 Years' onChange={BankCompanyDocumentationHandler} />
                  <label>Financial Projections for Next 1-3 Years</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" checked={Company_Documentation_Bank.includes('List of all vendors utilized including name, DBA, address, contact person, and services/products used')}
                    value='List of all vendors utilized including name, DBA, address, contact person, and services/products used' onChange={BankCompanyDocumentationHandler} />
                  <label>
                    List of all vendors utilized including name, DBA, address,
                    contact person, and services/products used
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('List of all expected customers')}
                    value='List of all expected customers' onChange={BankCompanyDocumentationHandler}
                  />
                  <label>List of all expected customers</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Attestation of Beneficial Ownership')}
                    value='Attestation of Beneficial Ownership' onChange={BankCompanyDocumentationHandler} />
                  <label>Attestation of Beneficial Ownership</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Processing Statements (Last 3 Months)')}
                    value='Processing Statements (Last 3 Months)' onChange={BankCompanyDocumentationHandler}
                  />
                  <label>Processing Statements (Last 3 Months)</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)')}
                    value='Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)'
                    onChange={BankCompanyDocumentationHandler} />
                  <label>
                    Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.includes('California Verification Status of Entity')}
                    value='California Verification Status of Entity' onChange={BankCompanyDocumentationHandler} />
                  <label>California Verification Status of Entity</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx"
                    checked={Company_Documentation_Bank.

 includes('U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons')}
 value='U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons' onChange={BankCompanyDocumentationHandler}
                  />
                  <label>
                    U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='button-container'>
          <button className='btn btn-prev' onClick={PreviousHandler}>Previous</button>
          <button className='btn btn-next' type='submit' >Next</button>
        </div>
      </form>
    </section>

  )
}

export default FormThreeBank