import React, { useState } from "react";
import FormOneBank from "../Components/BankMainPages/FormOneBank";
import FormTwoBank from "../Components/BankMainPages/FormTwoBank";
import FormThreeBank from "../Components/BankMainPages/FormThreeBank";
import FormFourBank from "../Components/BankMainPages/FormFourBank";

const BankPage = () => {
  const [formNo, setFormNo] = useState(1);
  return (
    <section className="mainsection bnk_sctn">
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2>Bank Questions</h2>
        </div>
        <div
          className={`step-${formNo}`}
          id="checkout-progress"
          data-current-step={1}
        >
          <div className="progress-bar">
            <div className="step step-1 active">
              <span> 1</span>
              {/* <div class="fa fa-check opaque"></div> */}
              {/* <div class="step-label">Proposed</div> */}
            </div>
            <div
              className={
                formNo === 2 || formNo > 2
                  ? "step step-2 active"
                  : "step step-2"
              }
            >
              <span> 2</span>
              {/* <div class="fa fa-check opaque"></div> */}
              {/* <div class="step-label">Approved</div> */}
            </div>
            <div
              className={
                formNo === 3 || formNo > 3
                  ? "step step-3 active"
                  : "step step-3"
              }
            >
              <span> 3</span>
              {/* <div class="fa fa-check opaque"></div> */}
              {/* <div class="step-label">Under Implmentation</div> */}
            </div>
            <div
              className={
                formNo === 4 || formNo > 4
                  ? "step step-4 active"
                  : "step step-4"
              }
            >
              <span> 4</span>
              {/* <div class="fa fa-check opaque"></div> */}
              {/* <div class="step-label">Implemented</div> */}
            </div>
            <div
              className={formNo === 5 ? "step step-5 active" : "step step-5"}
            >
              <span> 5</span>
            </div>
            <div className="step step-6" />
          </div>
        </div>
        {formNo === 1 && <FormOneBank setFormNo={setFormNo} />}
        {formNo === 2 && <FormTwoBank setFormNo={setFormNo} />}
        {formNo === 3 && <FormThreeBank setFormNo={setFormNo} />}
        {formNo === 4 && <FormFourBank setFormNo={setFormNo} />}
      </div>
    </section>
  );
};

export default BankPage;
