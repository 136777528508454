import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GetAlloperators_URL } from "../Api/Api";
//Action:-
export const fetchOperators = createAsyncThunk('fetchOperators', async () => {
    // const response = await fetch('https://jsonplaceholder.typicode.com/todos')
    // return response.json()
    return await axios.get(GetAlloperators_URL,{
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('admintoken') }
      })
    
})
const OperatorSlice = createSlice({
    name: 'Operator',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOperators.pending, (state, action) => {
       state.isLoading = true;
       state.data = null;
        });
            builder.addCase(fetchOperators.fulfilled, (state, action) => {
                console.log(action.payload,'action.payload')
                state.isLoading = false;
                state.data = action.payload;
            });

            builder.addCase(fetchOperators.rejected, (state, action) => {
               console.log(action.payload,'action.payload')
                state.isError = true;
            })
    }
})
export default OperatorSlice.reducer;