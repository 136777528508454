import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import BankReducer from './BankSlice';
import OperatorReducer from './OperatorSlice';
import DashboardReducer from './DashboardSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    Bank: BankReducer,
    Operator: OperatorReducer,
    Dashboard:DashboardReducer
  },
})