import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { useDispatch } from "react-redux";
import { nextFour } from "../../Store/userSlice";
import { toast } from "react-toastify";
import { Documents_Available } from "../../Helper/FromValue";
import NumberFormat from "react-number-format";

const FormFour = (props) => {
  const dispatch = useDispatch();
  const [errorState, setErrorstate] = useState();
  const [data, setData] = useState([]);
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  const {
    LegacyCashhandler,
    Legacy_Cash,
    CashManagementHandler,
    Cash_Management,
    TransferExistingBankHandler,
    Transfer_Existing_Bank_Account,
    errors,
  } = useForm();
  useEffect(() => {
    setErrorstate(errors);
  }, [errors]);
  useEffect(() => {
    const finalData = Legacy_Cash.documents_available.map((x) => x.docs_avail);
    console.log(finalData, "800");
    setData([...finalData]);
  }, [Legacy_Cash]);
  console.log(
    Legacy_Cash,
    Cash_Management,
    Transfer_Existing_Bank_Account,
    "letuswatch"
  );
  console.log(Cash_Management, "Cash_Management");
  console.log(errors, "errors");
  const NextHandlerFour = (event) => {
    event.preventDefault();
    console.log(Cash_Management, "1111");
    console.log(Transfer_Existing_Bank_Account, "1112");
    // current_cash_managment
    for (let x in Cash_Management) {
      console.log(String(Cash_Management[x]), "number_vendors");
      if (
        x !== "bank_name" &&
        x !== "reason_to_close" &&
        x !== "vendor_payment_methods" &&
        x !== "thisvalue"
        // x !== "current_cash_managment"
      ) {
        if (String(Cash_Management[x]) === "") {
          errorState[x] = "cannot be empty";

          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      }

      if (Cash_Management.business_acc === "yes") {
        if (x === "bank_name" || x === "thisvalue") {
          if (Cash_Management[x] === "") {
            errorState[x] = "cannot be empty";
            setErrorstate({ ...errorState });
          } else {
            delete errorState[x];
            setErrorstate({ ...errorState });
          }
        }
      } else {
        delete errorState.bank_name;
        delete errorState.thisvalue;
        setErrorstate({ ...errorState });
      }
      // else{
      //   delete errorState[x]
      //       setErrorstate({...errorState})
      // }
      //  else{
      //   errorState[x] = ""
      //  }
    }
    // existing_bank_contact
    for (let x in Transfer_Existing_Bank_Account) {
      if (x !== "existing_bank_contact") {
        if (String(Transfer_Existing_Bank_Account[x]).length === 0) {
          errorState[x] = "cannot be empty";
          setErrorstate({ ...errorState });
        } else {
          delete errorState[x];
          setErrorstate({ ...errorState });
        }
      }
    }

    const res = [
      ...Object.values(Cash_Management),
      ...Object.values(Transfer_Existing_Bank_Account),
    ];

    const finalres = res.map((i) => String(i).length);

    if (Cash_Management.vendor_payment_methods.length === 0) {
      errorState.vendor_payment_methods =
        "Please select atleast one option from vendors payment methods!";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.vendor_payment_methods;
      setErrorstate({ ...errorState });
    }
    console.log(errorState, "errorState");
    // && finalres.every(x => x !== 0)
    if (Object.keys(errorState).length === 0) {
      if (Legacy_Cash.legacy_cash === "yes") {
        if (Legacy_Cash.documents_available.length !== 0) {
          console.log("Done");
          props.setFormNo(5);
          window.scrollTo(0, 0);
          dispatch(
            nextFour({
              Legacy_Cash,
              Cash_Management,
              Transfer_Existing_Bank_Account,
            })
          );
        } else {
          toast.error(
            "Select atleast one option from check documents available!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }
      } else {
        props.setFormNo(5);
        window.scrollTo(0, 0);
        dispatch(
          nextFour({
            Legacy_Cash,
            Cash_Management,
            Transfer_Existing_Bank_Account,
          })
        );
      }
    } else {
      if (Object.keys(errors).length !== 0 && !finalres.every((x) => x !== 0)) {
        toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      if (
        Legacy_Cash.legacy_cash === "yes" &&
        Legacy_Cash.documents_available.length === 0
      ) {
        toast.error(
          "Select atleast one option from check documents available!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      if (Object.keys(errorState).length !== 0) {
        toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      if (errorState.vendor_payment_methods) {
        toast.error(`${errorState.vendor_payment_methods}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  const PreviousHandler = () => {
    props.setFormNo(3);
    window.scrollTo(0, 0);
  };

  return (
    <section id="section4" className="section4">
      <form action="" onSubmit={NextHandlerFour}>
        <div className="lgcy_cash bg_grey">
          <h3 className="hdng_h3">Legacy Cash</h3>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Does your business have legacy cash?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="legacy_cash"
                  checked={Legacy_Cash.legacy_cash === "yes"}
                  onChange={LegacyCashhandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          {Legacy_Cash.legacy_cash === "yes" && (
            <div className="check_form-group">
              <h5 className="fromgroup_hdng">
                Check Documents Available:<span className="red-star">*</span>
              </h5>
              <div className="check_checking">
                <input
                  type="checkbox"
                  id="icheck1"
                  checked={data.includes(
                    "Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                  )}
                  value="Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label htmlFor="icheck1">
                  Quarterly federal, state, and municipal tax returns for each
                  of the last five years, if applicable
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  checked={data.includes(
                    "Financial statements, preferably audited that have been prepared for the business"
                  )}
                  value="Financial statements, preferably audited that have been prepared for the business"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label>
                  Financial statements, preferably audited that have been
                  prepared for the business
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  checked={data.includes(
                    "Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                  )}
                  value="Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label>
                  Point-of-Sale (seed-to-sale) system reports: monthly and
                  annual POS reports showing all wholesale inventory purchases
                  and retail sales transactions
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  checked={data.includes(
                    "State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                  )}
                  value="State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label>
                  State database reporting: reports of amounts reported to the
                  state tracking database for as long as the state database has
                  been active, if applicable
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  checked={data.includes(
                    "Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                  )}
                  value="Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label>
                  Armored courier confirmed cash count: To the extent cash was
                  couriered to a vault or other safekeeping location, obtain
                  confirmed cash count slips from armored courier evidencing
                  amount of cash counted and date of cash pick-up/count
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  checked={data.includes(
                    "Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                  )}
                  value="Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                  name="documents_available"
                  onChange={LegacyCashhandler}
                />
                <label>
                  Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt
                  showing total cash vaulted and custodianship of cash
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="csh_mngmnt bg_grey">
          <h3 className="hdng_h3">Cash Management</h3>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Does the business currently have a bank account?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={Cash_Management.business_acc === "yes"}
                  name="business_acc"
                  onChange={CashManagementHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="parent_forminput_group">
            {Cash_Management.business_acc === "yes" && (
              <>
                {" "}
                <div className="form-input-group">
                  <label htmlFor="">
                    Bank Name:<span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    name="bank_name"
                    value={Cash_Management.bank_name}
                    onChange={CashManagementHandler}
                  />
                  {errorState?.bank_name && (
                    <h3 style={{ color: "red" }}>{errorState.bank_name}</h3>
                  )}
                </div>
                <div
                  className="form-input-group"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <label htmlFor="">
                    Length of Time Account has been Open:
                    <span className="red-star">*</span>
                  </label>
                  {/* <input type="text" name='length_time' value={Cash_Management.length_time} onChange={CashManagementHandler} />
            {
              errorState?.length_time && <h3 style={{ color: 'red' }}>{errorState.length_time}</h3>

            } */}
                  {/* <label style={{ marginTop: '20px' }}>Years:</label> <input style={{ marginLeft: '12px', width: '60px' }} />
                  <label style={{ marginLeft: '20px', marginTop: '20px' }}>Months:</label>  <input style={{ marginLeft: '12px', width: '60px' }} />
                  <label style={{ marginLeft: '20px', marginTop: '20px' }}>Days:</label><input style={{ marginLeft: '12px', width: '60px' }} /> */}
                  <div style={{ display: "flex" }}>
                    {" "}
                    <div className="form-input-group">
                      <input
                        onInput={(e) => {
                          if (e.target.value.length > 3) {
                            e.target.value = e.target.value.slice(0, 3);
                          }
                        }}
                        style={{ width: "100%" }}
                        type="number"
                        name="thisvalue"
                        value={Cash_Management.thisvalue}
                        onChange={CashManagementHandler}
                        className="form-control"
                        // defaultValue={10}
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.thisvalue && (
                        <h3 style={{ color: "red" }}>{errorState.thisvalue}</h3>
                      )}
                    </div>
                    <div className="form-input-group">
                      <select
                        name="daysormonths"
                        style={{
                          width: "150px",
                          cursor: "pointer",
                          marginLeft: "1rem",
                        }}
                        onChange={CashManagementHandler}
                      >
                        <option
                          selected={
                            Cash_Management.daysormonths === "Years"
                              ? true
                              : false
                          }
                        >
                          Years
                        </option>
                        <option
                          selected={
                            Cash_Management.daysormonths === "Months"
                              ? true
                              : false
                          }
                        >
                          Months
                        </option>
                        <option
                          selected={
                            Cash_Management.daysormonths === "Days"
                              ? true
                              : false
                          }
                        >
                          Days
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-input-group full">
                  <label htmlFor="">
                    If account was closed, reason for account closure:
                  </label>
                  <input
                    type="text"
                    name="reason_to_close"
                    value={Cash_Management.reason_to_close}
                    onChange={CashManagementHandler}
                  />
                  {errorState?.reason_to_close && (
                    <h3 style={{ color: "red" }}>
                      {errorState.reason_to_close}
                    </h3>
                  )}
                </div>
              </>
            )}
            {/* <div className="form-group">
              <h5 className="fromgroup_hdng">
                Payroll service used for employees?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="payroll_service"
                    checked={Cash_Management.payroll_service === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Employees paid in cash?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="paid_cash"
                    checked={Cash_Management.paid_cash === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Payroll cards offered?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="payroll_accepted"
                    checked={Cash_Management.payroll_accepted === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Taxes paid in cash?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="taxes_cash"
                    checked={Cash_Management.taxes_cash === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div> */}
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Penalties for paying taxes paid in cash?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="penalty_cash"
                    checked={Cash_Management.penalty_cash === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Current on tax payments with the state?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="tax_payment"
                    checked={Cash_Management.tax_payment === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Number of vendors paid monthly:
                <span className="red-star">*</span>
              </label>
              <NumberFormat
                maxLength={12}
                value={Cash_Management.number_vendors}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={CashManagementHandler}
                name="number_vendors"
              />
              {errorState?.number_vendors && (
                <h3 style={{ color: "red" }}>{errorState.number_vendors}</h3>
              )}
            </div>
            <div className="form-input-group">
              <label>
                Method(s) by which vendors are paid:
                <span className="red-star">*</span>
              </label>
              <div className="frm_radio_prnt">
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="ACH"
                    checked={Cash_Management.vendor_payment_methods.includes(
                      "ACH"
                    )}
                    name="vendor_payment_methods"
                    onChange={CashManagementHandler}
                    id="ACH1"
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="ACH1" className="radio-button">
                    {" "}
                    ACH
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Check"
                    checked={Cash_Management.vendor_payment_methods.includes(
                      "Check"
                    )}
                    name="vendor_payment_methods"
                    onChange={CashManagementHandler}
                    id="check1"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="check1" className="radio-button">
                    {" "}
                    Check
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Wire"
                    checked={Cash_Management.vendor_payment_methods.includes(
                      "Wire"
                    )}
                    name="vendor_payment_methods"
                    onChange={CashManagementHandler}
                    id="wire1"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="wire1" className="radio-button">
                    {" "}
                    Wire
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Cash"
                    checked={Cash_Management.vendor_payment_methods.includes(
                      "Cash"
                    )}
                    name="vendor_payment_methods"
                    onChange={CashManagementHandler}
                    id="cash1"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="cash1" className="radio-button">
                    {" "}
                    Cash
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Any vendors located outside of the U.S.?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="international_vendor"
                    checked={Cash_Management.international_vendor === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Is the business able to receive electronic payments?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="electronic_payment"
                    checked={Cash_Management.electronic_payment === "yes"}
                    onChange={CashManagementHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                If the business-only operates in cash, describe current cash
                management practices:<span className="red-star">*</span>
              </label>
              <textarea
                style={{
                  // width:'1200px'
                  width: "60rem",
                  paddingTop: "10px",
                }}
                type="text"
                name="current_cash_managment"
                value={Cash_Management.current_cash_managment}
                onChange={CashManagementHandler}
              ></textarea>
              {errorState?.current_cash_managment && (
                <h3 style={{ color: "red" }}>
                  {errorState.current_cash_managment}
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className="csh_mngmnt bg_grey">
          <h3 className="hdng_h3">Transfer from Existing Bank Account</h3>
          {/* <div className="form-group">
            <h5 className="fromgroup_hdng">
              Does the business currently have a bank account?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input type="checkbox" name='business_acc' onChange={CashManagementHandler} />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div> */}
          <div className="parent_forminput_group">
            <div className="form-input-group">
              <label htmlFor="">
                Name of financial institution funds being transferred from:
                <span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="financial_institution"
                value={Transfer_Existing_Bank_Account.financial_institution}
                onChange={TransferExistingBankHandler}
              />
              {errorState?.financial_institution && (
                <h3 style={{ color: "red" }}>
                  {errorState.financial_institution}
                </h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">Bank contact:</label>
              {/* <input type="number" name='existing_bank_contact' value={Transfer_Existing_Bank_Account.existing_bank_contact} onChange={TransferExistingBankHandler}
              /> */}
              <NumberFormat
                value={Transfer_Existing_Bank_Account.existing_bank_contact}
                className="form-control"
                format={"(###) ###-####"}
                // mask="*"
                onChange={TransferExistingBankHandler}
                name="existing_bank_contact"
              />

              {errorState?.existing_bank_contact && (
                <h3 style={{ color: "red" }}>
                  {errorState.existing_bank_contact}
                </h3>
              )}
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Name on bank account:<span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="existing_bank_name"
                value={Transfer_Existing_Bank_Account.existing_bank_name}
                onChange={TransferExistingBankHandler}
              />
              {errorState?.existing_bank_name && (
                <h3 style={{ color: "red" }}>
                  {errorState.existing_bank_name}
                </h3>
              )}
            </div>

            <div className="form-input-group">
              <label htmlFor="">
                Source of original deposit to previous bank account:
                <span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="original_deposite"
                value={Transfer_Existing_Bank_Account.original_deposite}
                onChange={TransferExistingBankHandler}
              />
              {errorState?.original_deposite && (
                <h3 style={{ color: "red" }}>{errorState.original_deposite}</h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Can source of deposit at previous bank be verified?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="prev_bank_verified"
                    checked={
                      Transfer_Existing_Bank_Account.prev_bank_verified ===
                      "yes"
                    }
                    onChange={TransferExistingBankHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>

            <div className="form-input-group full">
              <label htmlFor="">
                Reason for account closure:<span className="red-star">*</span>
              </label>
              <input
                type="text"
                name="reason_closure"
                value={Transfer_Existing_Bank_Account.reason_closure}
                onChange={TransferExistingBankHandler}
              />
              {errorState?.reason_closure && (
                <h3 style={{ color: "red" }}>{errorState.reason_closure}</h3>
              )}
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Was previous bank aware account was for a cannabis-related
                business?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="prev_bank_aware"
                    checked={
                      Transfer_Existing_Bank_Account.prev_bank_aware === "yes"
                    }
                    onChange={TransferExistingBankHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormFour;
