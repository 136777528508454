import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { nextThree } from "../../Store/userSlice";
import { MdIndeterminateCheckBox } from "react-icons/md";
const FormThree = (props) => {
  const information = useSelector(
    (state) => state.user.operatorDetailsFormThree
  );
  console.log(information, "information");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  const {
    Company_Documentation,
    CompanyDocumentationhandler,
    setCompany_Documentation,
    errors,
    mainCheckBox,
    setMainCheckBox,
  } = useForm();
  console.log(Company_Documentation, "Company_Documentation");
  const NextHandlerThree = (event) => {
    event.preventDefault();
    if (Company_Documentation.length === 0) {
      toast.error("Please choose atleast one option!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      dispatch(
        nextThree({
          payloadOne: Company_Documentation,
          payloadTwo: mainCheckBox,
        })
      );
      props.setFormNo(4);
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    const mytars = document.getElementsByName("pg-bx");
    console.log(mytars, "123333");
  }, []);
  const PreviousHandler = () => {
    props.setFormNo(2);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const finaldata = Company_Documentation.map((u) => u.documentation);
    console.log(finaldata, "finalData");
    setData(finaldata);
  }, [Company_Documentation]);
  console.log(data, "ghklio");
  useEffect(() => {
    const tar = document.getElementsByName("pg-bx");
    console.log(tar, "900");
  }, []);
  useEffect(() => {
    if (Company_Documentation.length < 25 && Company_Documentation.length > 0) {
      if (mainCheckBox === "yes") {
        setMainCheckBox("intermediate");
      }
    }
    if (Company_Documentation.length === 25) {
      setMainCheckBox("yes");
    }
  }, [Company_Documentation]);
  return (
    <section id="section3" className="section3">
      <form onSubmit={NextHandlerThree}>
        <div className="pg3-mn-otr">
          <div className="pg3-mn-inr">
            <div className="cmn_hdr">
              <h6>
                Company Documentation<span className="red-star">*</span>
              </h6>
              {mainCheckBox === "intermediate" ? (
                <>
                  <MdIndeterminateCheckBox
                    onClick={() => {
                      setMainCheckBox("no");
                    }}
                  />{" "}
                  Select All
                </>
              ) : (
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name=""
                    checked={mainCheckBox === "yes" ? true : false}
                    // checked={data.includes('Audited Financial Statements')}
                    onChange={(e) => {
                      // Company_Documentation.push({documentation:'Policies'},{documentation:'Operating Agreement'})

                      const tars = document.getElementsByName("pg-bx");
                      console.log(tars, "900");
                      if (e.target.checked) {
                        setMainCheckBox("yes");
                        setCompany_Documentation([
                          ...Company_Documentation,
                          { documentation: "Policies" },
                          { documentation: "Operating Agreement" },
                          { documentation: "Audited Financial Statements" },

                          {
                            documentation:
                              "Articles of Formation (Organization or Incorporation)",
                          },
                          {
                            documentation:
                              "Contracts with Armored Car Services to Transport Cash",
                          },

                          {
                            documentation:
                              "Results of any inspections performed by the State",
                          },
                          {
                            documentation:
                              "Federal and State Tax Returns (Last 3 Years Available)",
                          },
                          {
                            documentation:
                              "Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)",
                          },
                          {
                            documentation:
                              "Proof of vendor due diligence completed for all vendors used",
                          },
                          {
                            documentation:
                              "Most Recent Compliance Audit and Reports",
                          },

                          {
                            documentation:
                              "Sales History from POS Records (90 Days Fully Operational)",
                          },

                          {
                            documentation:
                              "Signed Lease Agreement for each Location",
                          },
                          { documentation: "Business Recovery Plan" },
                          {
                            documentation:
                              "Insurance Certificate (summary of coverage)",
                          },
                          { documentation: "State Licensing" },
                          { documentation: "Secretary of State Registration" },
                          {
                            documentation: "Results of any on-site inspections",
                          },
                          {
                            documentation:
                              "Financial Projections for Next 1-3 Years",
                          },

                          {
                            documentation:
                              "List of all vendors utilized including name, DBA, address, contact person, and services/products used",
                          },

                          { documentation: "List of all expected customers" },

                          {
                            documentation:
                              "Attestation of Beneficial Ownership",
                          },
                          {
                            documentation:
                              "Processing Statements (Last 3 Months)",
                          },
                          {
                            documentation:
                              "Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)",
                          },
                          {
                            documentation:
                              "California Verification Status of Entity",
                          },
                          {
                            documentation:
                              "U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons",
                          },
                        ]);
                        for (let c = 0; c < tars.length; c++) {
                          tars[c].checked = true;
                        }
                      } else {
                        for (let c = 0; c < tars.length; c++) {
                          tars[c].checked = false;
                          setCompany_Documentation([]);
                          setMainCheckBox("no");
                        }
                      }
                    }}
                  />
                  <label>Select All</label>
                </div>
              )}
              <p>Check Documents Available:</p>
              <div className="pg3-mn-otrfld">
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Audited Financial Statements"
                    checked={data.includes("Audited Financial Statements")}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Audited Financial Statements</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Policies"
                    onChange={CompanyDocumentationhandler}
                    checked={data.includes("Policies")}
                  />
                  <label>Policies</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Articles of Formation (Organization or Incorporation)"
                    checked={data.includes(
                      "Articles of Formation (Organization or Incorporation)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Articles of Formation (Organization or Incorporation)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Operating Agreement"
                    checked={data.includes("Operating Agreement")}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Operating Agreement</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Contracts with Armored Car Services to Transport Cash"
                    checked={data.includes(
                      "Contracts with Armored Car Services to Transport Cash"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Contracts with Armored Car Services to Transport Cash
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Results of any inspections performed by the State"
                    checked={data.includes(
                      "Results of any inspections performed by the State"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Results of any inspections performed by the State
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Federal and State Tax Returns (Last 3 Years Available)"
                    checked={data.includes(
                      "Federal and State Tax Returns (Last 3 Years Available)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Federal and State Tax Returns (Last 3 Years Available)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                    checked={data.includes(
                      "Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Compliance Attestation (Agreement with terms and conditions
                    of account and fee schedule, statement that MRB is not in
                    violation of Cole Memo Priorities or FinCEN guidelines)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Proof of vendor due diligence completed for all vendors used"
                    checked={data.includes(
                      "Proof of vendor due diligence completed for all vendors used"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Proof of vendor due diligence completed for all vendors used
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    checked={data.includes(
                      "Most Recent Compliance Audit and Reports"
                    )}
                    value="Most Recent Compliance Audit and Reports"
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Most Recent Compliance Audit and Reports</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Sales History from POS Records (90 Days Fully Operational)"
                    checked={data.includes(
                      "Sales History from POS Records (90 Days Fully Operational)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Sales History from POS Records (90 Days Fully Operational)
                  </label>
                </div>
                {/* <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" value='Employee Training Manual'
                    checked={data.includes('Employee Training Manual')}
                    onChange={CompanyDocumentationhandler} />
                  <label>Employee Training Manual</label>
                </div> */}
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Signed Lease Agreement for each Location"
                    checked={data.includes(
                      "Signed Lease Agreement for each Location"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Signed Lease Agreement for each Location</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Business Recovery Plan"
                    checked={data.includes("Business Recovery Plan")}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Business Recovery Plan</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Insurance Certificate (summary of coverage)"
                    checked={data.includes(
                      "Insurance Certificate (summary of coverage)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Insurance Certificate (summary of coverage)</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    checked={data.includes("State Licensing")}
                    value="State Licensing"
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>State Licensing</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Secretary of State Registration"
                    checked={data.includes("Secretary of State Registration")}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Secretary of State Registration</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Results of any on-site inspections"
                    checked={data.includes(
                      "Results of any on-site inspections"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Results of any on-site inspections</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Financial Projections for Next 1-3 Years"
                    checked={data.includes(
                      "Financial Projections for Next 1-3 Years"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Financial Projections for Next 1-3 Years</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                    checked={data.includes(
                      "List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    List of all vendors utilized including name, DBA, address,
                    contact person, and services/products used
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="List of all expected customers"
                    checked={data.includes("List of all expected customers")}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>List of all expected customers</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Attestation of Beneficial Ownership"
                    checked={data.includes(
                      "Attestation of Beneficial Ownership"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Attestation of Beneficial Ownership</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Processing Statements (Last 3 Months)"
                    checked={data.includes(
                      "Processing Statements (Last 3 Months)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>Processing Statements (Last 3 Months)</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                    checked={data.includes(
                      "Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    Balance Sheets, Cash Flow Statements, and P/L Statements
                    (Last 3 Years)
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="California Verification Status of Entity"
                    checked={data.includes(
                      "California Verification Status of Entity"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>California Verification Status of Entity</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="pg-bx"
                    value="U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                    checked={data.includes(
                      "U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                    )}
                    onChange={CompanyDocumentationhandler}
                  />
                  <label>
                    U.S. Government issue ID/Driver’s License/Military ID/State
                    ID or U.S. Passport for Administrators, Beneficial Owners,
                    Officers, Directors, and Control Persons
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormThree;
