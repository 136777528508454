import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { nextTwoBank } from "../../Store/userSlice";
import NumberFormat from "react-number-format";
import CurrencyInput from "../../Helper/CurrencyInput";

const FormTwoBank = (props) => {
  const [errorState, setErrorstate] = useState({});
  const dispatch = useDispatch();
  const {
    errosBank,
    Operational_details_Bank,
    Other_Operational_Details_Bank,
    BankOperationalDetailsHandler,
    BankOtherOperationaldetailsHandler,
    Anticipated_Transaction_Activity_Bank,
    BankAnticipatedTransactionActivityHandler,
  } = useForm();
  console.log(
    typeof Number(
      Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min.slice(1)
    ),
    "9000"
  );
  console.log(
    Anticipated_Transaction_Activity_Bank.frequency_cash_deposits,
    "2057257"
  );
  console.log(Operational_details_Bank, "Operational_details_Bank");
  console.log(errorState, "88");
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  useEffect(() => {
    setErrorstate(errosBank);
  }, [errosBank]);

  const NextHandlerTwo = (event) => {
    console.log(
      Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min,
      "5675678"
    );
    event.preventDefault();
    //Crb max min checking:-
    if (
      Number(Operational_details_Bank.accept_CRB_min) >
      Number(Operational_details_Bank.accept_CRB_max)
    ) {
      errorState.accept_CRBErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.accept_CRBErr;
      setErrorstate({ ...errorState });
    }

    //Num of locations max min error checking:-
    if (
      Number(Operational_details_Bank.num_locs_min) >
      Number(Operational_details_Bank.num_locs_max)
    ) {
      errorState.num_locsErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.num_locsErr;
      setErrorstate({ ...errorState });
    }

    //Num of transactions max-min checking:-
    if (
      Number(Operational_details_Bank.transactions_Per_month_min) >
      Number(Operational_details_Bank.transactions_Per_month_max)
    ) {
      errorState.transactions_Per_monthErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.transactions_Per_monthErr;
      setErrorstate({ ...errorState });
    }
    //managed_square_feet max-min error checking:-managed_square_feet_min
    if (
      Number(Operational_details_Bank.managed_square_feet_min) >
      Number(Operational_details_Bank.managed_square_feet_max)
    ) {
      errorState.managed_square_feetErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.managed_square_feetErr;
      setErrorstate({ ...errorState });
    }
    //number_beneficial_owner max min error checking:-number_beneficial_ownerErr
    if (
      Number(Other_Operational_Details_Bank.number_beneficial_owner_min) >
      Number(Other_Operational_Details_Bank.number_beneficial_owner_max)
    ) {
      errorState.number_beneficial_ownerErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.number_beneficial_ownerErr;
      setErrorstate({ ...errorState });
    }
    //anticipated transaction activity min max error checking:-
    // console.log(Number(Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min.slice(1).replaceAll(',','')),'error98908')
    // console.log( Number(Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max.slice(1).replaceAll(',','')),'error98908')
    if (
      Number(
        Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min
          .slice(1)
          .replaceAll(",", "")
      ) >
      Number(
        Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max
          .slice(1)
          .replaceAll(",", "")
      )
    ) {
      errorState.amount_Initial_DepositErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.amount_Initial_DepositErr;
      setErrorstate({ ...errorState });
    }

    if (
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_min
          .slice(1)
          .replaceAll(",", "")
      ) >
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_max
          .slice(1)
          .replaceAll(",", "")
      )
    ) {
      errorState.estimated_total_amount_monthly_depositErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.estimated_total_amount_monthly_depositErr;
      setErrorstate({ ...errorState });
    }

    if (
      Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits === "yes"
    ) {
      if (
        Number(
          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min
            .slice(1)
            .replaceAll(",", "")
        ) >
        Number(
          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max
            .slice(1)
            .replaceAll(",", "")
        )
      ) {
        errorState.amount_cash_depositsErr =
          "Maximum value must be greater than the minimum value";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.amount_cash_depositsErr;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.amount_cash_depositsErr;
      setErrorstate({ ...errorState });
    }

    if (
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_min
      ) >
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_max
      )
    ) {
      errorState.estimated_total_num_monthly_depositErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.estimated_total_num_monthly_depositErr;
      setErrorstate({ ...errorState });
    }

    // estimated_spendErr
    if (
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_spend_min
          .slice(1)
          .replaceAll(",", "")
      ) >
      Number(
        Anticipated_Transaction_Activity_Bank.estimated_spend_max
          .slice(1)
          .replaceAll(",", "")
      )
    ) {
      errorState.estimated_spendErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.estimated_spendErr;
      setErrorstate({ ...errorState });
    }
    //anticipate_cash_deposits_min
    if (
      Number(
        Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits_min
      ) >
      Number(Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits_max)
    ) {
      errorState.anticipate_cash_depositsErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.anticipate_cash_depositsErr;
      setErrorstate({ ...errorState });
    }
    //anticipate_cash_depositsErr
    if (
      Number(
        Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits_min
      ) >
      Number(Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits_max)
    ) {
      errorState.anticipate_cash_depositsErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.anticipate_cash_depositsErr;
      setErrorstate({ ...errorState });
    }

    //frequency_cash_deposits_min
    if (
      Number(
        Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_min
      ) >
      Number(Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_max)
    ) {
      errorState.frequency_cash_depositsErr =
        "Maximum value must be greater than the minimum value";
      setErrorstate({ ...errorState });
    } else {
      delete errorState.frequency_cash_depositsErr;
      setErrorstate({ ...errorState });
    }

    if (Anticipated_Transaction_Activity_Bank.cash_pick_ups === "yes") {
      if (
        Number(
          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min
        ) >
        Number(
          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max
        )
      ) {
        errorState.estimated_cash_pick_upsErr =
          "Maximum value must be greater than the minimum value";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.estimated_cash_pick_upsErr;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.estimated_cash_pick_upsErr;
      setErrorstate({ ...errorState });
    }
    // let x = false;
    // if ((Number(Operational_details_Bank.num_locs_min) < Number(Operational_details_Bank.num_locs_max)) &&
    //   (Number(Operational_details_Bank.transactions_Per_month_min) < Number(Operational_details_Bank.transactions_Per_month_max)) && (Number(Operational_details_Bank.managed_square_feet_min) < Number(Operational_details_Bank.managed_square_feet_max)) && (Number(Other_Operational_Details_Bank.number_beneficial_owner_min) < Number(Other_Operational_Details_Bank.number_beneficial_owner_max))) {
    //   x = true;
    // }

    for (let x in Operational_details_Bank) {
      if (String(Operational_details_Bank[x]).length === 0) {
        if (x !== "types_customers") {
          errorState[x] = "Can't be empty!";

          setErrorstate({ ...errorState });
        }
      }
    }
    for (let x in Other_Operational_Details_Bank) {
      if (String(Other_Operational_Details_Bank[x]).length === 0) {
        errorState[x] = "Can't be empty!";
        setErrorstate({ ...errorState });
      }
      // else{
      //   let newObj = omit(errors, x);
      // setErrorstate({...errorState})
      // }
    }
    for (let x in Anticipated_Transaction_Activity_Bank) {
      console.log(x, "91");
      if (
        Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
        "yes"
      ) {
        if (
          Number(
            Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max
              ?.slice(1)
              .replaceAll(",", "")
          ) <
          Number(
            Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min
              ?.slice(1)
              .replaceAll(",", "")
          )
        ) {
          errorState.amount_cash_withdrawalsErr =
            "Maximum value must be greater than minimum value!";
          setErrorstate({ ...errorState });
        } else {
          delete errorState.amount_cash_withdrawalsErr;
          setErrorstate({ ...errorState });
        }

        if (
          Number(
            Anticipated_Transaction_Activity_Bank.monthly_payroll_max
              .slice(1)
              .replaceAll(",", "")
          ) <
          Number(
            Anticipated_Transaction_Activity_Bank.monthly_payroll_min
              .slice(1)
              .replaceAll(",", "")
          )
        ) {
          errorState.monthly_payrollErr =
            "Maximum value must be greater than the minimum value!";
          setErrorstate({ ...errorState });
        } else {
          delete errorState.monthly_payrollErr;
          setErrorstate({ ...errorState });
        }

        if (x === "amount_cash_withdrawals_min") {
          if (
            Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min ===
            ""
          ) {
            errorState.amount_cash_withdrawals_min = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.amount_cash_withdrawals_min;
            setErrorstate({ ...errorState });
          }
        } else if (x === "amount_cash_withdrawals_max") {
          if (
            Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max ===
            ""
          ) {
            errorState.amount_cash_withdrawals_max = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.amount_cash_withdrawals_max;
            setErrorstate({ ...errorState });
          }
        } else if (x === "monthly_payroll_max") {
          if (
            Anticipated_Transaction_Activity_Bank.monthly_payroll_max === ""
          ) {
            errorState.monthly_payroll_max = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.monthly_payroll_max;
            setErrorstate({ ...errorState });
          }
        } else if (x === "monthly_payroll_min") {
          if (
            Anticipated_Transaction_Activity_Bank.monthly_payroll_min === ""
          ) {
            errorState.monthly_payroll_min = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.monthly_payroll_min;
            setErrorstate({ ...errorState });
          }
        }
      } else {
        delete errorState.amount_cash_withdrawals_min;
        delete errorState.amount_cash_withdrawals_max;
        delete errorState.monthly_payroll_min;
        delete errorState.monthly_payroll_max;
        delete errorState.monthly_payrollErr;
        delete errorState.amount_cash_withdrawalsErr;
        setErrorstate({ ...errorState });
      }
      if (Anticipated_Transaction_Activity_Bank.cash_pick_ups === "yes") {
        if (
          Number(
            Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max
              .slice(1)
              .replaceAll(",", "")
          ) <
          Number(
            Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min
              .slice(1)
              .replaceAll(",", "")
          )
        ) {
          errorState.estimated_cash_pick_upsErr =
            "Maximum value must be greater than the minimum value!";
          setErrorstate({ ...errorState });
        } else {
          delete errorState.estimated_cash_pick_upsErr;
          setErrorstate({ ...errorState });
        }

        if (x === "estimated_cash_pick_ups_min") {
          if (
            Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min ===
            ""
          ) {
            errorState.estimated_cash_pick_ups_min = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.estimated_cash_pick_ups_min;
            setErrorstate({ ...errorState });
          }
        } else if (x === "estimated_cash_pick_ups_max") {
          if (
            Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max ===
            ""
          ) {
            errorState.estimated_cash_pick_ups_max = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.estimated_cash_pick_ups_max;
            setErrorstate({ ...errorState });
          }
        }
      } else {
        console.log("seerty56");
        delete errorState.estimated_cash_pick_ups_min;
        delete errorState.estimated_cash_pick_ups_max;
        delete errorState.estimated_cash_pick_upsErr;
        setErrorstate({ ...errorState });
      }

      if (
        x !== "amount_cash_withdrawals_min" &&
        x !== "amount_cash_withdrawals_max" &&
        x !== "amount_cash_deposits_min" &&
        x !== "amount_cash_deposits_min" &&
        x !== "estimated_cash_pick_ups_max" &&
        x !== "amount_cash_withdrawals_min" &&
        x !== "frequency_cash_withdrawals" &&
        x !== "frequency_cash_pick_ups" &&
        x !== "frequency_cash_deposits" &&
        String(Anticipated_Transaction_Activity_Bank[x]).length === 0
      ) {
        errorState[x] = "Can't be empty!";
        setErrorstate({ ...errorState });
      }
    }

    if (
      Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits === "yes"
    ) {
      if (
        Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min === ""
      ) {
        errorState.amount_cash_deposits_min = "Can't be empty!";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.amount_cash_deposits_min;
        setErrorstate({ ...errorState });
      }
      if (
        Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max === ""
      ) {
        errorState.amount_cash_deposits_max = "Can't be empty!";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.amount_cash_deposits_max;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.amount_cash_deposits_max;
      delete errorState.amount_cash_deposits_min;

      setErrorstate({ ...errorState });
    }

    if (
      Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
      "yes"
    ) {
      if (
        Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals
          .length === 0
      ) {
        errorState.frequency_cash_withdrawalsErr = "Please select atleast one!";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.frequency_cash_withdrawalsErr;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.frequency_cash_withdrawalsErr;
      setErrorstate({ ...errorState });
    }
    if (Anticipated_Transaction_Activity_Bank.cash_pick_ups === "yes") {
      if (
        Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.length ===
        0
      ) {
        errorState.frequency_cash_pick_upsErr = "Please select atleast one!";
        setErrorstate({ ...errorState });
      } else {
        delete errorState.frequency_cash_pick_upsErr;
        setErrorstate({ ...errorState });
      }
    } else {
      delete errorState.frequency_cash_pick_upsErr;
      setErrorstate({ ...errorState });
    }
    const res = [
      ...Object.values(Operational_details_Bank),
      ...Object.values(Other_Operational_Details_Bank),
      ...Object.values(Anticipated_Transaction_Activity_Bank),
    ];

    const finalres = res.map((i) => String(i).length);
    console.log(errorState, "errorState");

    // && finalres.every(x => x !== 0)
    // console.log(x,'890')
    if (Object.keys(errorState).length === 0) {
      props.setFormNo(3);

      dispatch(
        nextTwoBank({
          Operational_details_Bank,
          Other_Operational_Details_Bank,
          Anticipated_Transaction_Activity_Bank,
        })
      );
    } else {
      // if (!x) {
      //   toast.error('check the min-max conditions!', {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //   });
      // }
      if (errorState.frequency_cash_withdrawalsErr) {
        toast.error(
          "Please select atleast one option from frequency_cash_withdrawals!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      if (errorState.frequency_cash_pick_upsErr) {
        toast.error(
          "Please select atleast one option from frequency_cash_pickups!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      if (Operational_details_Bank.types_customers.length === 0) {
        toast.error(
          "Please select atleast one option from Types of customers accepted!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      toast.error("Please fill all the fields correctly!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const PreviousHandler = (event) => {
    event.preventDefault();
    props.setFormNo(1);
  };
  return (
    <section id="section2" className="section2">
      <form action="" onSubmit={NextHandlerTwo}>
        <div className="ope_dtls bg_grey">
          <p className="hdng_p">Operational Details</p>
          <div className="form-group">
            <h5 className="fromgroup_hdng">
              Products purchased from providers?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={
                    Operational_details_Bank.products_purchased_providers ===
                    "yes"
                  }
                  name="products_purchased_providers"
                  onChange={BankOperationalDetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group">
            <h5 className="fromgroup_hdng">Licesened Providers Accepted?</h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={Operational_details_Bank.licensed_provider === "yes"}
                  name="licensed_provider"
                  onChange={BankOperationalDetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group">
            <div className="bnk_add_grp">
              <p>How many accounts per CRB accepted?</p>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Minimum:</label>
                <input
                  type="number"
                  className="form-control"
                  name="accept_CRB_min"
                  onInput={(e) => {
                    if (e.target.value.length > 2) {
                      e.target.value = e.target.value.slice(0, 2);
                    }
                  }}
                  value={Operational_details_Bank.accept_CRB_min}
                  onChange={BankOperationalDetailsHandler}
                />

                {errorState?.accept_CRB_min && (
                  <p style={{ color: "red" }}>{errorState.accept_CRB_min}</p>
                )}
              </div>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Maximum:</label>
                <NumberFormat
                  style={{ width: "20rem" }}
                  maxLength={19}
                  value={Operational_details_Bank.accept_CRB_max}
                  thousandSeparator={","}
                  className="form-control"
                  onChange={BankOperationalDetailsHandler}
                  name="accept_CRB_max"
                />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                {errorState?.accept_CRB_max && (
                  <p style={{ color: "red" }}>{errorState.accept_CRB_max}</p>
                )}
              </div>
              {errorState.accept_CRBErr && (
                <p style={{ color: "red" }}>{errorState.accept_CRBErr}</p>
              )}
            </div>
          </div>
          <div className="parent_forminput_group">
            <div className="form-group">
              <div className="bnk_add_grp">
                <p>Number of Locations Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={2}
                    name="num_locs_min"
                    onInput={(e) => {
                      if (e.target.value.length > 2) {
                        e.target.value = e.target.value.slice(0, 2);
                      }
                    }}
                    value={Operational_details_Bank.num_locs_min}
                    onChange={BankOperationalDetailsHandler}
                  />
                  {/* <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  <br/> */}
                  {errorState?.num_locs_min && (
                    <p style={{ color: "red" }}>{errorState.num_locs_min}</p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <NumberFormat
                    style={{ width: "20rem" }}
                    maxLength={19}
                    value={Operational_details_Bank.num_locs_max}
                    // format={"$##,###,###,###"}
                    // 78,979,797,979,797
                    thousandSeparator={","}
                    // decimalSeparator={"."}
                    // decimalScale={2}
                    className="form-control"
                    onChange={BankOperationalDetailsHandler}
                    name="num_locs_max"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.num_locs_max && (
                    <p style={{ color: "red" }}>{errorState.num_locs_max}</p>
                  )}
                </div>
              </div>
              {errorState.num_locsErr && (
                <p style={{ color: "red" }}>{errorState.num_locsErr}</p>
              )}
            </div>

            <div className="form-group">
              <div className="bnk_add_grp">
                <p>Number of Transactions Per Month Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input
                    type="number"
                    onInput={(e) => {
                      if (e.target.value.length > 2) {
                        e.target.value = e.target.value.slice(0, 2);
                      }
                    }}
                    name="transactions_Per_month_min"
                    value={Operational_details_Bank.transactions_Per_month_min}
                    onChange={BankOperationalDetailsHandler}
                    className="form-control"
                    defaultValue={2}
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.transactions_Per_month_min && (
                    <p style={{ color: "red" }}>
                      {errorState.transactions_Per_month_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <NumberFormat
                    style={{ width: "20rem" }}
                    maxLength={19}
                    value={Operational_details_Bank.transactions_Per_month_max}
                    // format={"$##,###,###,###"}
                    // 78,979,797,979,797
                    thousandSeparator={","}
                    // decimalSeparator={"."}
                    // decimalScale={2}
                    className="form-control"
                    onChange={BankOperationalDetailsHandler}
                    name="transactions_Per_month_max"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.transactions_Per_month_max && (
                    <p style={{ color: "red" }}>
                      {errorState.transactions_Per_month_max}
                    </p>
                  )}
                </div>
              </div>
              {errorState.transactions_Per_monthErr && (
                <p style={{ color: "red" }}>
                  {errorState.transactions_Per_monthErr}
                </p>
              )}
            </div>
            {/* <div className="form-group w100">
              <div className="bnk_add_grp">
                <p>Total Managed Square Feet Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={2}
                    name="managed_square_feet_min"
                    onInput={(e) => {
                      if (e.target.value.length > 2) {
                        e.target.value = e.target.value.slice(0, 2);
                      }
                    }}
                    value={Operational_details_Bank.managed_square_feet_min}
                    onChange={BankOperationalDetailsHandler}
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.managed_square_feet_min && (
                    <p style={{ color: "red" }}>
                      {errorState.managed_square_feet_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <NumberFormat
                    style={{ width: "20rem" }}
                    maxLength={19}
                    value={Operational_details_Bank.managed_square_feet_max}
                    // format={"$##,###,###,###"}
                    // 78,979,797,979,797
                    thousandSeparator={","}
                    // decimalSeparator={"."}
                    // decimalScale={2}
                    className="form-control"
                    onChange={BankOperationalDetailsHandler}
                    name="managed_square_feet_max"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.managed_square_feet_max && (
                    <p style={{ color: "red" }}>
                      {errorState.managed_square_feet_max}
                    </p>
                  )}
                </div>
                {errorState.managed_square_feetErr && (
                  <p style={{ color: "red" }}>
                    {errorState.managed_square_feetErr}
                  </p>
                )}
              </div>
            </div> */}
            <div className="form-input-group">
              <h5 className="fromgroup_hdng">Facility Owned or Leased?</h5>
              <div className="frm_radio_prnt">
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="Owned"
                    checked={
                      Operational_details_Bank.facility === "Owned"
                        ? true
                        : false
                    }
                    onChange={BankOperationalDetailsHandler}
                    id="Owned"
                    name="facility"
                    // checked={Operational_Details.types_customers === "Medical"}
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="Owned" className="radio-button">
                    Owned
                  </label>
                </div>
                <div className="form_radio_group">
                  <input
                    type="radio"
                    value="Leased"
                    checked={
                      Operational_details_Bank.facility === "Leased"
                        ? true
                        : false
                    }
                    onChange={BankOperationalDetailsHandler}
                    id="Leased"
                    name="facility"
                    // checked={Operational_Details.types_customers === "Recreational"}
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="Leased" className="radio-button">
                    Leased
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <h5 className="fromgroup_hdng">Leased Facility Accepted?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input type="checkbox"
                    checked={Operational_details_Bank.facility_leased === 'yes'}
                    name='facility_leased' onChange={BankOperationalDetailsHandler} />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div> */}
            {/* <div className="form-group">
            
            <div className="">
            <label className="fromgroup_hdng">Types of Customers Accepted: </label>
              <div className="pg3-mn-inrfld">
                <input type="checkbox"  name="types_customers" value='Medical' 
                checked={Operational_details_Bank.types_customers === 'Medical'}
                onChange={BankOperationalDetailsHandler}
                />
                
                <label htmlFor="" className="radio-button">
                  {" "}
                  Medical
                </label>
              </div>
              <div className="pg3-mn-inrfld">
                <input
                  type="checkbox"
                 
                  name="types_customers" value='Recreational' 
                onChange={BankOperationalDetailsHandler}
                />
                
                <label htmlFor="" className="radio-button">
                  Recreational
                </label>
              </div>
            </div>
          </div> */}
            {/* <div className="form-group">
              <div className="pg1-prt-2">
                <div className="pg1-prt-2-txt">
                  <div className="cmn_hdr">

                    <div className="form-input-group">
                      <div className="frm_radio_prnt">
                        <label className="">Types of Customers Accepted:</label>
                        <div className="pg1-prt-2-prt-otrfld">
                          <div className="pg3-mn-inrfld">
                            <input type="checkbox" name="types_customers"
                              checked={Operational_details_Bank.types_customers.includes('Medical')}
                              value='Medical' onChange={BankOperationalDetailsHandler}
                            />
                            <label>Medical</label>
                          </div>
                          <div className="pg3-mn-inrfld">
                            <input type="checkbox" name="types_customers"
                              checked={Operational_details_Bank.types_customers.includes('Recreational')}
                              value='Recreational' onChange={BankOperationalDetailsHandler}
                            />
                            <label>Recreational</label>
                          </div>




                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="form-input-group">
              <label>Types of Customers Accepted:</label>
              <br />
              <div className="pg1-prt-2-prt-otrfld">
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="types_customers"
                    checked={Operational_details_Bank.types_customers.includes(
                      "Medical"
                    )}
                    value="Medical"
                    onChange={BankOperationalDetailsHandler}
                  />
                  <label>Medical</label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    name="types_customers"
                    checked={Operational_details_Bank.types_customers.includes(
                      "Recreational"
                    )}
                    value="Recreational"
                    onChange={BankOperationalDetailsHandler}
                  />
                  <label>Recreational</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg_grey ooop_dtls">
          <p className="hdng_p">Other Operational Details</p>
          <div className="form-group wmcontent">
            <h5 className="fromgroup_hdng wmcontent">
              Principals of other entities that conducts marijuana-related
              business in other states accepted?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={
                    Other_Operational_Details_Bank.principal_business === "yes"
                  }
                  name="principal_business"
                  onChange={BankOtherOperationaldetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group wmcontent">
            <h5 className="fromgroup_hdng wmcontent">
              Beneficial Owners (anyone with stake of 25% or more in the
              business) Accepted?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={
                    Other_Operational_Details_Bank.beneficial_owners === "yes"
                  }
                  name="beneficial_owners"
                  onChange={BankOtherOperationaldetailsHandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          <div className="form-group">
            <div className="bnk_add_grp">
              <p>Number of Beneficial Owners Accepted:</p>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Minimum:</label>
                <input
                  type="number"
                  className="form-control"
                  defaultValue={2}
                  name="number_beneficial_owner_min"
                  onInput={(e) => {
                    if (e.target.value.length > 2) {
                      e.target.value = e.target.value.slice(0, 2);
                    }
                  }}
                  value={
                    Other_Operational_Details_Bank.number_beneficial_owner_min
                  }
                  onChange={BankOtherOperationaldetailsHandler}
                />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                {errorState?.number_beneficial_owner_min && (
                  <p style={{ color: "red" }}>
                    {errorState.number_beneficial_owner_min}
                  </p>
                )}
              </div>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Maximum:</label>
                <NumberFormat
                  style={{ width: "20rem" }}
                  maxLength={19}
                  value={
                    Other_Operational_Details_Bank.number_beneficial_owner_max
                  }
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  className="form-control"
                  onChange={BankOtherOperationaldetailsHandler}
                  name="number_beneficial_owner_max"
                />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                {errorState?.number_beneficial_owner_max && (
                  <p style={{ color: "red" }}>
                    {errorState.number_beneficial_owner_max}
                  </p>
                )}
              </div>
              {errorState?.number_beneficial_ownerErr && (
                <p style={{ color: "red" }}>
                  {errorState.number_beneficial_ownerErr}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="bg_grey bnk_atactivity">
          <p className="hdng_p">Anticipated Transaction Activity</p>
          <div className="parent_forminput_group">
            <div className="form-group">
              <div className="bnk_add_grp">
                <p>Amount of Initial Deposit Accepted (USD):</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min
                    }
                    type="text"
                    name="amount_Initial_Deposit_min"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.amount_Initial_Deposit_min && (
                    <p style={{ color: "red" }}>
                      {errorState.amount_Initial_Deposit_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={20}
                    value={
                      Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max
                    }
                    type="text"
                    name="amount_Initial_Deposit_max"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.amount_Initial_Deposit_max && (
                    <p style={{ color: "red" }}>
                      {errorState.amount_Initial_Deposit_max}
                    </p>
                  )}
                </div>
                {errorState?.amount_Initial_DepositErr && (
                  <p style={{ color: "red" }}>
                    {errorState.amount_Initial_DepositErr}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="bnk_add_grp">
                <p>
                  Estimated Total Amount of Monthly Deposits (USD) Accepted:
                </p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_min
                    }
                    type="text"
                    name="estimated_total_amount_monthly_deposit_min"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_total_amount_monthly_deposit_min && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_total_amount_monthly_deposit_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={20}
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_max
                    }
                    type="text"
                    name="estimated_total_amount_monthly_deposit_max"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_total_amount_monthly_deposit_max && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_total_amount_monthly_deposit_max}
                    </p>
                  )}
                </div>
                {errorState?.estimated_total_amount_monthly_depositErr && (
                  <p style={{ color: "red" }}>
                    {errorState.estimated_total_amount_monthly_depositErr}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="bnk_add_grp">
                <p>Estimated Total Number of Monthly Deposits Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={2}
                    onInput={(e) => {
                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3);
                      }
                    }}
                    name="estimated_total_num_monthly_deposit_min"
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_min
                    }
                    onChange={BankAnticipatedTransactionActivityHandler}
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_total_num_monthly_deposit_min && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_total_num_monthly_deposit_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <NumberFormat
                    style={{ width: "20rem" }}
                    maxLength={19}
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_max
                    }
                    // format={"$##,###,###,###"}
                    // 78,979,797,979,797
                    thousandSeparator={","}
                    // decimalSeparator={"."}
                    // decimalScale={2}
                    className="form-control"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    name="estimated_total_num_monthly_deposit_max"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_total_num_monthly_deposit_max && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_total_num_monthly_deposit_max}
                    </p>
                  )}
                </div>
                {errorState?.estimated_total_num_monthly_depositErr && (
                  <p style={{ color: "red" }}>
                    {errorState.estimated_total_num_monthly_depositErr}
                  </p>
                )}
              </div>
            </div>

            <div className="form-group wmcontent w100">
              <h5 className="fromgroup_hdng wmcontent">
                Cash Deposits Accepted:
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    checked={
                      Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits ===
                      "yes"
                    }
                    name="anticipate_cash_deposits"
                    onChange={BankAnticipatedTransactionActivityHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits ===
              "yes" && (
              <>
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Amount of Monthly Cash Deposits (USD) Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={9}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min
                        }
                        type="text"
                        name="amount_cash_deposits_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_cash_deposits_min && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_cash_deposits_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max
                        }
                        type="text"
                        name="amount_cash_deposits_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_cash_deposits_max && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_cash_deposits_max}
                        </p>
                      )}
                    </div>
                    {errorState?.amount_cash_depositsErr && (
                      <p style={{ color: "red" }}>
                        {errorState.amount_cash_depositsErr}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="form-group">
              <div className="bnk_add_grp">
                <p>Frequency of Cash Deposits Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input type="number" className="form-control" defaultValue={2}
                    onInput={(e) => {

                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3)
                      }
                    }}
                    name='frequency_cash_deposits_min' value={Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_min} onChange={BankAnticipatedTransactionActivityHandler}
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>

                  {
                    errorState?.frequency_cash_deposits_min && <p style={{ color: 'red' }}>{errorState.frequency_cash_deposits_min}</p>

                  }
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  < NumberFormat
                  style={{ width: '20rem' }}
                  maxLength={19}
                  value={Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_max}
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  className='form-control'
                  onChange={BankAnticipatedTransactionActivityHandler} name='frequency_cash_deposits_max'
                />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {
                    errorState?.frequency_cash_deposits_max && <p style={{ color: 'red' }}>{errorState.frequency_cash_deposits_max}</p>

                  }
                </div>
                {
                    errorState?.frequency_cash_depositsErr && <p style={{ color: 'red' }}>{errorState.frequency_cash_depositsErr}</p>

                  }
              </div>
            </div> */}
                <div className="form-input-group">
                  <label>Frequency of Cash Deposits Accepted:</label>
                  <div className="frm_radio_prnt">
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Daily"
                        checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                          "Daily"
                        )}
                        name="frequency_cash_deposits"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="ACH1"
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="ACH1" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Weekly"
                        checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                          "Weekly"
                        )}
                        name="frequency_cash_deposits"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="check1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="check1" className="radio-button">
                        {" "}
                        Weekly
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Bi-Weekly"
                        checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                          "Bi-Weekly"
                        )}
                        name="frequency_cash_deposits"
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="wire1" className="radio-button">
                        {" "}
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Monthly"
                        checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                          "Monthly"
                        )}
                        name="frequency_cash_deposits"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="cash1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="cash1" className="radio-button">
                        {" "}
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="form-group w100">
              <div className="bnk_add_grp">
                <p>Estimated Spend or Withdrawals Monthly (USD) Accepted:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={9}
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_spend_min
                    }
                    type="text"
                    name="estimated_spend_min"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_spend_min && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_spend_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <CurrencyInput
                    placeholder="$0.00"
                    maxLength={20}
                    value={
                      Anticipated_Transaction_Activity_Bank.estimated_spend_max
                    }
                    type="text"
                    name="estimated_spend_max"
                    onChange={BankAnticipatedTransactionActivityHandler}
                    className="form-control"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.estimated_spend_max && (
                    <p style={{ color: "red" }}>
                      {errorState.estimated_spend_max}
                    </p>
                  )}
                </div>
                {errorState?.estimated_spendErr && (
                  <p style={{ color: "red" }}>
                    {errorState.estimated_spendErr}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group wmcontent">
              <h5 className="fromgroup_hdng wmcontent">
                Cash Withdrawals Accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    checked={
                      Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
                      "yes"
                    }
                    name="anticipate_cash_withdrawals"
                    onChange={BankAnticipatedTransactionActivityHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
              "yes" && (
              <>
                <div className="form-group w100">
                  <div className="bnk_add_grp">
                    <p>Amount of Monthly Cash Withdrawals (USD) Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min
                        }
                        type="text"
                        name="amount_cash_withdrawals_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_cash_withdrawals_min && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_cash_withdrawals_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max
                        }
                        type="text"
                        name="amount_cash_withdrawals_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_cash_withdrawals_max && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_cash_withdrawals_max}
                        </p>
                      )}
                    </div>
                    {errorState?.amount_cash_withdrawalsErr && (
                      <p style={{ color: "red" }}>
                        {errorState.amount_cash_withdrawalsErr}
                      </p>
                    )}
                  </div>
                  {/* 
                <div className="form-input-group">
                  <label>Frequency of Cash Withdrawals Accepted: </label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input type="radio"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Daily'}
                        name='frequency_cash_withdrawals' value='Daily' onChange={BankAnticipatedTransactionActivityHandler}
                        id="daily3" />
                      
                      <label htmlFor="ACH1" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input type="radio"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Weekly'}
                        name='frequency_cash_withdrawals' value='Weekly' onChange={BankAnticipatedTransactionActivityHandler} />
                  
                      <label htmlFor="check1" className="radio-button">
                        {" "}
                        Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Bi-Weekly'}
                        name='frequency_cash_withdrawals' value='Bi-Weekly' onChange={BankAnticipatedTransactionActivityHandler}
                      />

                      <label htmlFor="check1" className="radio-button">
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input type="radio"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Monthly'}
                        name='frequency_cash_withdrawals' value='Monthly' onChange={BankAnticipatedTransactionActivityHandler} />
                     
                      <label htmlFor="check1" className="radio-button">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div> */}
                  <div className="form-input-group">
                    <label>Frequency of Cash Withdrawals Accepted:</label>
                    <div className="frm_radio_prnt">
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          value="Daily"
                          checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                            "Daily"
                          )}
                          name="frequency_cash_withdrawals"
                          onChange={BankAnticipatedTransactionActivityHandler}
                          id="ACH1"
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="ACH1" className="radio-button">
                          {" "}
                          Daily
                        </label>
                      </div>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          value="Weekly"
                          checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                            "Weekly"
                          )}
                          name="frequency_cash_withdrawals"
                          onChange={BankAnticipatedTransactionActivityHandler}
                          id="check1"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="check1" className="radio-button">
                          {" "}
                          Weekly
                        </label>
                      </div>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          value="Bi-Weekly"
                          id="wire1"
                          checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                            "Bi-Weekly"
                          )}
                          name="frequency_cash_withdrawals"
                          onChange={BankAnticipatedTransactionActivityHandler}
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="wire1" className="radio-button">
                          {" "}
                          Bi-Weekly
                        </label>
                      </div>
                      <div className="pg3-mn-inrfld">
                        <input
                          type="checkbox"
                          value="Monthly"
                          checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                            "Monthly"
                          )}
                          name="frequency_cash_withdrawals"
                          onChange={BankAnticipatedTransactionActivityHandler}
                          id="cash1"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="cash1" className="radio-button">
                          {" "}
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group w100">
                  <div className="form-group">
                    <div className="bnk_add_grp">
                      <p>Average Expected Monthly Payroll (USD) Accepted:</p>
                      <div className="pg1-prt-2-lwr-inrfld bnk1a">
                        <label>Minimum:</label>
                        <CurrencyInput
                          style={{ width: "12rem" }}
                          placeholder="$0.00"
                          maxLength={9}
                          value={
                            Anticipated_Transaction_Activity_Bank.monthly_payroll_min
                          }
                          type="text"
                          name="monthly_payroll_min"
                          onChange={BankAnticipatedTransactionActivityHandler}
                          className="form-control"
                        />
                        <a href="javascript:void(0);" className="nd3-a">
                          <i className="fal fa-plus" />
                        </a>
                        {errorState?.monthly_payroll_min && (
                          <p style={{ color: "red" }}>
                            {errorState.monthly_payroll_min}
                          </p>
                        )}
                      </div>
                      <div
                        className="pg1-prt-2-lwr-inrfld bnk1a"
                        style={{ marginLeft: "0rem", width: "10rem" }}
                      >
                        <label>Maximum:</label>
                        <CurrencyInput
                          style={{ width: "20rem" }}
                          placeholder="$0.00"
                          maxLength={20}
                          value={
                            Anticipated_Transaction_Activity_Bank.monthly_payroll_max
                          }
                          type="text"
                          name="monthly_payroll_max"
                          onChange={BankAnticipatedTransactionActivityHandler}
                          className="form-control"
                        />
                        <a href="javascript:void(0);" className="nd3-a">
                          <i className="fal fa-plus" />
                        </a>
                        {errorState?.monthly_payroll_max && (
                          <p style={{ color: "red" }}>
                            {errorState.monthly_payroll_max}
                          </p>
                        )}
                      </div>
                      {errorState?.monthly_payrollErr && (
                        <p style={{ color: "red" }}>
                          {errorState.monthly_payrollErr}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="form-group w100">
              <h5 className="fromgroup_hdng">Cash Pick-Ups Available?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="cash_pick_ups"
                    checked={
                      Anticipated_Transaction_Activity_Bank.cash_pick_ups ===
                      "yes"
                    }
                    onChange={BankAnticipatedTransactionActivityHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Anticipated_Transaction_Activity_Bank.cash_pick_ups === "yes" && (
              <>
                <div className="form-input-group">
                  <label>Frequency of Cash Pick-Ups Accepted:</label>
                  <div className="frm_radio_prnt">
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Daily"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                          "Daily"
                        )}
                        name="frequency_cash_pick_ups"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="ACH1"
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="ACH1" className="radio-button">
                        {" "}
                        Daily
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Weekly"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                          "Weekly"
                        )}
                        name="frequency_cash_pick_ups"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="check1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="check1" className="radio-button">
                        {" "}
                        Weekly
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Bi-Weekly"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                          "Bi-Weekly"
                        )}
                        name="frequency_cash_pick_ups"
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="wire1" className="radio-button">
                        {" "}
                        Bi-Weekly
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Monthly"
                        checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                          "Monthly"
                        )}
                        name="frequency_cash_pick_ups"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        id="cash1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="cash1" className="radio-button">
                        {" "}
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Estimated Pick-Up Amount (USD) Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={9}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min
                        }
                        type="text"
                        name="estimated_cash_pick_ups_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_cash_pick_ups_min && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_cash_pick_ups_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max
                        }
                        type="text"
                        name="estimated_cash_pick_ups_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_cash_pick_ups_max && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_cash_pick_ups_max}
                        </p>
                      )}
                    </div>
                    {errorState?.estimated_cash_pick_upsErr && (
                      <p style={{ color: "red" }}>
                        {errorState.estimated_cash_pick_upsErr}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div>
      </form>
    </section>
  );
};

export default FormTwoBank;
