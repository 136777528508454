import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OperatorSingleApplication_URL } from "../../Api/Api";
import useForm from "../../CustomHook/useForm";
import { useScrollTrigger } from "@mui/material";
import NumberFormat from "react-number-format";
import CurrencyInput from "../../Helper/CurrencyInput";
import CreatableSelect from "react-select/creatable";
import { IconContext } from "react-icons";
import { AiFillDelete } from "react-icons/ai";
import { RiFileAddFill } from "react-icons/ri";
import { MdIndeterminateCheckBox } from "react-icons/md";

const OperatorApplicationDetails = () => {
  const { application_Id } = useParams();
  const [data, setData] = useState([]);
  const OPTIONS = ["Credit", "Debit", "ACH", "PIN Debit", "Cashless ATM"];

  const Options = OPTIONS.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });
  const [mainCheckBox, setMainCheckBox] = useState(false);
  console.log(application_Id, "application_Id");
  const [sameValue, setSameValue] = useState("");
  const errorState = "";
  const customErr = "";
  const USASTATES = [
    "Alabama(AL)",
    "Alaska (AK)",
    "Arizona (AZ)",
    " Arkansas (AR)",
    " California (CA)",
    "Colorado (CO)",
    "Connecticut (CT)",
    " Delaware (DE)",
    "Florida (FL)",
    "Georgia (GA)",
    "Hawaii (HI)",
    "Idaho (ID)",
    "Illinois (IL)",
    "Indiana (IN)",
    "Iowa (IA)",
    "Kansas (KS)",
    "Kentucky (KY)",
    "Louisiana (LA)",
    " Maine (ME)",
    "Maryland (MD)",
    "Massachusetts (MA)",
    "Michigan (MI)",
    "Minnesota (MN)",
    "Mississippi (MS)",
    " Missouri (MO)",
    "Montana (MT)",
    "Nebraska (NE)",
    "Nevada (NV)",
    "New Hampshire (NH)",
    "New Jersey (NJ)",
    "NewMexico (NM)",
    "NewYork (NY)",
    "North Carolina (NC)",
    "North Dakota (ND)",
    "Ohio (OH)",
    "Oklahoma (OK)",
    "Oregon (OR)",
    "Pennsylvania (PA)",
    "RhodeIsland (RI)",
    " South Carolina (SC)",
    "South Dakota (SD)",
    "Tennessee (TN)",
    "Texas (TX)",
    " Utah (UT)",
    "Vermont (VT)",
    "Virginia (VA)",
    "Washington (WA)",
    " West Virginia (WV)",
    "Wisconsin (WI)",
    "Wyoming (WY)",
  ];

  const StateOptions = USASTATES.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });

  //servicesWanted input field and change-handler:-
  const [servicesWanted, setServicesWanted] = useState([]);
  const ServicesWantedHandler = (event) => {
    console.log(event.target.value);
    if (event.target.checked) {
      setServicesWanted([...servicesWanted, event.target.value]);
    } else {
      const data = servicesWanted.filter(
        (service) => service !== event.target.value
      );
      setServicesWanted([...data]);
    }
  };

  //Account Purpose input field and change-handler:-
  const [accountPurpose, setAccountPurpose] = useState([]);
  const [accountPurposeInput, setAccountPurposeInput] = useState("");

  const AccountPurposeHandler = (event) => {
    // accountPurposeInput
    if (event.target.name === "accountPurposeInput") {
      setAccountPurposeInput(event.target.value);
    } else {
      if (event.target.checked) {
        setAccountPurpose([...accountPurpose, event.target.value]);
      } else {
        const data = accountPurpose.filter(
          (service) => service !== event.target.value
        );
        setAccountPurpose([...data]);
      }
    }
  };

  //acc_need_time and changehandler:-
  const [acc_need_time, setAcc_need_time] = useState({});
  const AccountNeedTimehandler = (event) => {
    console.log(event.target.value, "8112354");
    // Validate(event, event.target.name, event.target.value, errors, setErrors);

    setAcc_need_time({
      ...acc_need_time,
      [event.target.name]: event.target.value,
    });
  };

  //risk_classification field and change handler:-
  const [risk_classification, setRisk_classification] = useState("");
  const RiskClassificationHandler = (event) => {
    console.log(event.target.value, "risky");
    setRisk_classification(event.target.value);
  };

  //Legal_Business field and changehandler:-
  const [Legal_Business, setLegal_Business] = useState({});
  const LegalBusinessHandler = (event) => {
    console.log(event.target.value, "event");
    if (event.target.type === "checkbox") {
      if (event.target.name === "pg-bx") {
        if (event.target.checked) {
          const data = [...Legal_Business.licensing_Type, event.target.value];
          setLegal_Business({ ...Legal_Business, licensing_Type: data });
        } else {
          const data = Legal_Business.licensing_Type.filter(
            (i) => i !== event.target.value
          );
          setLegal_Business({ ...Legal_Business, licensing_Type: data });
        }
      } else {
        if (event.target.checked) {
          setLegal_Business({
            ...Legal_Business,
            [event.target.name]: "yes",
          });
        } else {
          setLegal_Business({
            ...Legal_Business,
            [event.target.name]: "no",
          });
        }
      }
    } else {
      //To stop default events
      event.persist();
      console.log(event.target.name, event.target.value, "targetsssss");
      let name = event.target.name;
      let val = event.target.value;

      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      //Let's set these values in state

      setLegal_Business({
        ...Legal_Business,
        [name]: val,
      });
    }
  };
  //LicenseNumber field and change handler:-
  const [LicenseNumber, setLicenseNumber] = useState({});
  const LicenseNumberHandler = (event) => {
    setLicenseNumber({
      ...LicenseNumber,
      [event.target.name]: event.target.value,
    });
  };
  //Primary_Information field and change handler:-
  const [Primary_Information, setPrimary_Information] = useState({});
  const PrimaryInformationHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: "yes",
        });
      } else {
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: "no",
        });
      }
    } else {
      // primary_contact_name
      if (event.target.name === "primary_contact_name") {
        const reg = /^([^0-9$%*@!&#^{}()/;+.-=<>?,""''_`~|]*)$/;
        if (reg.test(event.currentTarget.value)) {
          //  Validate (
          //     event,
          //     "primary_contact_name",
          //     event.target.value,
          //     errors,
          //     setErrors
          //   );
          setPrimary_Information({
            ...Primary_Information,
            primary_contact_name: event.target.value,
          });
        }
      } else {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, "seethevalue");
        // calling the custom validate function
        // Validate(event, name, val, errors, setErrors);
        setPrimary_Information({
          ...Primary_Information,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  //Form two:
  //Operational Details and change Handler:-
  const [Operational_Details, setOperational_Details] = useState({});
  const OperationalDetailshandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.name !== "pg-bx") {
        if (event.target.checked) {
          setOperational_Details({
            ...Operational_Details,
            [event.target.name]: "yes",
          });
        } else {
          setOperational_Details({
            ...Operational_Details,
            [event.target.name]: "no",
          });
        }
      } else {
        if (event.target.checked) {
          const myData = [
            ...Operational_Details.types_customers,
            event.target.value,
          ];
          setOperational_Details({
            ...Operational_Details,
            types_customers: myData,
          });
        } else {
          const myData = Operational_Details.types_customers.filter(
            (i) => i !== event.target.value
          );
          setOperational_Details({
            ...Operational_Details,
            types_customers: myData,
          });
        }
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, "6725737");
      console.log(name, "name");
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setOperational_Details({
        ...Operational_Details,
        [event.target.name]: event.target.value,
      });
    }
  };
  //Other Operational Details and Change Handler:-
  const [Other_Operational_Details, setOther_Operational_Details] = useState(
    {}
  );
  const OtherOperationDetailsHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setOther_Operational_Details({
          ...Other_Operational_Details,
          [event.target.name]: "yes",
        });
      } else {
        setOther_Operational_Details({
          ...Other_Operational_Details,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      setOther_Operational_Details({
        ...Other_Operational_Details,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Additional_Locs and change handler:-
  const [Additional_Locs, setAdditional_Locs] = useState([]);
  const AdditionalLocshandler = (event, index) => {
    console.log(event.target.name, index, "handle-index");
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        // Additional_Locs[index].deposit_acc_need = "yes";
        const Obj = { ...Additional_Locs[index] };
        Obj.deposit_acc_need = "yes";
        Additional_Locs[index] = { ...Obj };
        // setAdditional_Locs({
        //   ...Additional_Locs, [event.target.name]: 'yes'
        // })
        setAdditional_Locs([...Additional_Locs]);
      } else {
        const Obj = { ...Additional_Locs[index] };
        Obj.deposit_acc_need = "no";
        Additional_Locs[index] = { ...Obj };
        // setAdditional_Locs({
        //   ...Additional_Locs, [event.target.name]: 'no'
        // })
        setAdditional_Locs([...Additional_Locs]);
      }
    } else {
      // event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, "val12");
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors);
      const data = JSON.parse(JSON.stringify(Additional_Locs));
      // console.log(data[index].loc_name,'868tg')
      // console.log(data[index][event.target.name],'llppprrtr')
      console.log(data, "my-data");
      data[index][name] = val;
      console.log(data, "datapp6p6");

      // setAdditional_Locs({
      //   ...Additional_Locs, [event.target.name]: event.target.value
      // })
      setAdditional_Locs([...data]);
    }
  };
  //Anticipated_Transaction_Activity and change handler:-
  const [
    Anticipated_Transaction_Activity,
    setAnticipated_Transaction_Activity,
  ] = useState({});
  const AnticipatedTransactionActivityHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setAnticipated_Transaction_Activity({
          ...Anticipated_Transaction_Activity,
          [event.target.name]: "yes",
        });
      } else {
        setAnticipated_Transaction_Activity({
          ...Anticipated_Transaction_Activity,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      // calling the custom validate function
      // Validate(event, name, val, errors, setErrors)
      if (name === "amount_cash_deposits" || name === "estimated_spend") {
        if (
          Anticipated_Transaction_Activity.anticipate_cash_deposits === "yes"
        ) {
          // Validate(event, name, val, errors, setErrors);
        }
      }
      setAnticipated_Transaction_Activity({
        ...Anticipated_Transaction_Activity,
        [event.target.name]: event.target.value,
      });
    }
  };

  //form three:-
  //company documentation and change handler:-
  const [Company_Documentation, setCompany_Documentation] = useState([]);
  const CompanyDocumentationhandler = (event) => {
    console.log("firedd");
    if (event.target.checked) {
      setCompany_Documentation([...Company_Documentation, event.target.value]);
    } else {
      const data = Company_Documentation.filter(
        (term) => term.documentation !== event.target.value
      );
      setCompany_Documentation([...data]);
    }
  };
  //form four :-
  const [Legacy_Cash, setLegacy_Cash] = useState({});
  const [Cash_Management, setCash_Management] = useState({});
  const [Transfer_Existing_Bank_Account, setTransfer_Existing_Bank_Account] =
    useState({});
  //form five:-
  const [Electronic_Payments_Settlement, setElectronic_Payments_Settlement] =
    useState({});
  const [ATM_Machine, setATM_Machine] = useState({});
  const [Accounting, setAccounting] = useState({});
  const [Compliance_Details, setCompliance_Details] = useState({});
  const [Following_Services, setFollowing_Services] = useState([]);
  const {
    // servicesWanted,
    // setServicesWanted,
    // ServicesWantedHandler,
    // acc_need_time,
    // setAcc_need_time,
    // RiskClassificationHandler,
    // risk_classification,
    // LegalBusinessHandler,
    // Legal_Business,
    // setLegal_Business,
    // PrimaryInformationHandler,
    // Primary_Information,
    errors,
    // AccountNeedTimehandler,
    // LicenseNumberHandler,
    // LicenseNumber,
    // accountPurpose,
    // setAccountPurpose,
    // AccountPurposeHandler,
    // accountPurposeInput,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get(`${OperatorSingleApplication_URL}/${application_Id}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "the-responsee");
        setServicesWanted(res.data.servicesWanted);
        for (let x of res.data.accountPurpose) {
          if (
            ![
              "Process Payroll",
              "Day-to-day Expensive",
              "Reserve",
              "Tax",
              "Savings",
            ].includes(x)
          ) {
            setAccountPurpose([
              ...res.data.accountPurpose.filter((i) => i !== x),
              "Others",
            ]);
            setAccountPurposeInput(x);
            break;
          } else {
            setAccountPurpose(res.data.accountPurpose);
          }
        }

        acc_need_time.value = res.data.acc_need_time.input;
        acc_need_time.daysormonths = res.data.acc_need_time.unit;
        setAcc_need_time({ ...acc_need_time });
        setRisk_classification(res.data.risk_classification);
        const data = res.data.Legal_Business.license.map(
          (i) => i.licensing_Type
        );
        setLegal_Business({ ...res.data.Legal_Business, licensing_Type: data });
        const lic_num = {};
        const tar = res.data.Legal_Business.license.map((o) => {
          lic_num[o.licensing_Type] = o.license_Num;
        });
        console.log(lic_num, "lic_num");
        setLicenseNumber(lic_num);
        setPrimary_Information(res.data.Primary_Information);
        setOperational_Details(res.data.Operational_Details);
        setOther_Operational_Details(res.data.Other_Operational_Details);
        setAdditional_Locs(res.data.Additional_Locs);
        setAnticipated_Transaction_Activity(
          res.data.Anticipated_Transaction_Activity
        );
        setCompany_Documentation(res.data.Company_Documentation);
        setLegacy_Cash(res.data.Legacy_Cash);
        setCash_Management(res.data.Cash_Management);
        setTransfer_Existing_Bank_Account(res.data.Transfer_Existing_Bank);
        setElectronic_Payments_Settlement(
          res.data.Electronic_Payments_Settlement
        );
        setATM_Machine(res.data.ATM_Machine);
        setAccounting(res.data.Accounting);
        setCompliance_Details(res.data.Compliance_Details);
        setFollowing_Services(res.data.Interested_Services);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  useEffect(() => {
    const finaldata = Company_Documentation.map((u) => u.documentation);
    console.log(finaldata, "finalData");
    setData(finaldata);
  }, [Company_Documentation]);
  console.log(data, "ghklio");
  useEffect(() => {
    const tar = document.getElementsByName("pg-bx");
    console.log(tar, "900");
  }, []);

  useEffect(() => {
    if (Company_Documentation.length < 25 && Company_Documentation.length > 0) {
      if (mainCheckBox === "yes") {
        setMainCheckBox("intermediate");
      }
    }
    if (Company_Documentation.length === 25) {
      setMainCheckBox("yes");
    }
  }, [Company_Documentation]);
  return (
    <div className="mainsection bnk_sctn">
      <section id="section1" className="section1">
        <form>
          <div className="pg1-mn-otr">
            <div className="pg1-mn-inr">
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <div className="pg3-mn-otrfld">
                      <div className="check__in__bx">
                        <h6>
                          Services You Want:
                          <span className="red-star">*</span>
                        </h6>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            value="Business Checking"
                            checked={servicesWanted.includes(
                              "Business Checking"
                            )}
                            onChange={ServicesWantedHandler}
                          />
                          <label>Business Checking</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes(
                              "Interest Bearing Accounts"
                            )}
                            onChange={ServicesWantedHandler}
                            value="Interest Bearing Accounts"
                          />
                          <label>Interest Bearing Accounts</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes("Debit Cards")}
                            onChange={ServicesWantedHandler}
                            value="Debit Cards"
                          />
                          <label>Debit Cards</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes("ACH")}
                            onChange={ServicesWantedHandler}
                            value="ACH"
                          />
                          <label>ACH</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes("Wires")}
                            onChange={ServicesWantedHandler}
                            value="Wires"
                          />
                          <label>Wires</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes(
                              "Discount for Social Equity"
                            )}
                            onChange={ServicesWantedHandler}
                            value="Discount for Social Equity"
                          />
                          <label>Discount for Social Equity</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes(
                              "Accounts for Employees"
                            )}
                            onChange={ServicesWantedHandler}
                            value="Accounts for Employees"
                          />
                          <label>Accounts for Employees</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes("Payroll")}
                            onChange={ServicesWantedHandler}
                            value="Payroll"
                          />
                          <label>Payroll</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={servicesWanted.includes("401K")}
                            onChange={ServicesWantedHandler}
                            value="401K"
                          />
                          <label>401K</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <div className="pg3-mn-otrfld">
                      <div className="check__in__bx">
                        <h6>
                          Account purpose:
                          <span className="red-star">*</span>
                        </h6>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            value="Process Payroll"
                            checked={accountPurpose?.includes(
                              "Process Payroll"
                            )}
                            onChange={AccountPurposeHandler}
                          />
                          <label>Process Payroll</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={accountPurpose?.includes(
                              "Day-to-day Expensive"
                            )}
                            onChange={AccountPurposeHandler}
                            value="Day-to-day Expensive"
                          />
                          <label>Day-to-day Expensive</label>
                        </div>
                      </div>

                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={accountPurpose?.includes("Reserve")}
                            onChange={AccountPurposeHandler}
                            value="Reserve"
                          />
                          <label>Reserve</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={accountPurpose?.includes("Tax")}
                            onChange={AccountPurposeHandler}
                            value="Tax"
                          />
                          <label>Tax</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={accountPurpose?.includes("Savings")}
                            onChange={AccountPurposeHandler}
                            value="Savings"
                          />
                          <label>Savings</label>
                        </div>
                      </div>
                      <div className="check__in__bx">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={accountPurpose?.includes("Others")}
                            onChange={AccountPurposeHandler}
                            value="Others"
                          />
                          <label>Others</label>
                        </div>
                        {accountPurpose?.includes("Others") && (
                          <input
                            name="accountPurposeInput"
                            disabled
                            onChange={AccountPurposeHandler}
                            value={accountPurposeInput}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <div className="pg3-mn-otrfld">
                      <div className="check__in__bx">
                        <h6>How fast do you need a bank account?</h6>
                        {/* <div style={{display:'flex'}}>
              <div >
              <h4>Months</h4><br/>
             <select 
             name='months'
             style={{width:'150px',height:'42px',cursor:'pointer'}} onChange={AccountNeedTimehandler}>
              <option selected={acc_need_time.months === '0' ? true : false}>0</option>
              <option selected={acc_need_time.months === '1' ? true : false}>1</option>
              <option selected={acc_need_time.months === '2' ? true : false}>2</option>
              <option selected={acc_need_time.months === '3' ? true : false}>3</option>
              <option selected={acc_need_time.months === '4' ? true : false}>4</option>
              <option selected={acc_need_time.months === '5' ? true : false}>5</option>
              <option selected={acc_need_time.months === '6' ? true : false}>6</option>
              <option selected={acc_need_time.months === '7' ? true : false}>7</option>
              <option selected={acc_need_time.months === '8' ? true : false}>8</option>
              <option selected={acc_need_time.months === '9' ? true : false}>9</option>
              <option selected={acc_need_time.months === '10' ? true : false}>10</option>
              <option selected={acc_need_time.months === '11' ? true : false}>11</option>
              <option selected={acc_need_time.months === '12' ? true : false}>12</option>
             </select>
              </div>
            <div style={{marginLeft:'2rem'}}> <h4>Days</h4><br/>
             <select 
             name='days'
             style={{width:'150px',height:'42px'}} onChange={AccountNeedTimehandler}>
             <option selected={acc_need_time.days === '0' ? true : false}>0</option>
              <option selected={acc_need_time.days === '1' ? true : false}>1</option>
              <option selected={acc_need_time.days === '2' ? true : false}>2</option>
              <option selected={acc_need_time.days === '3' ? true : false}>3</option>
              <option selected={acc_need_time.days === '4' ? true : false}>4</option>
              <option selected={acc_need_time.days === '5' ? true : false}>5</option>
              <option selected={acc_need_time.days === '6' ? true : false}>6</option>
              <option selected={acc_need_time.days === '7' ? true : false}>7</option>
              <option selected={acc_need_time.days === '9' ? true : false}>8</option>
              <option selected={acc_need_time.days === '10' ? true : false}>9</option>
              <option selected={acc_need_time.days === '11' ? true : false}>10</option>
              <option selected={acc_need_time.days === '12' ? true : false}>11</option>
              <option selected={acc_need_time.days === '13' ? true : false}>13</option>
              <option selected={acc_need_time.days === '14' ? true : false}>14</option>
              <option selected={acc_need_time.days === '15' ? true : false}>15</option>
              <option selected={acc_need_time.days === '16' ? true : false}>16</option>
              <option selected={acc_need_time.days === '17' ? true : false}>17</option>
              <option selected={acc_need_time.days === '18' ? true : false}>18</option>
              <option selected={acc_need_time.days === '19' ? true : false}>19</option>
              <option selected={acc_need_time.days === '20' ? true : false}>20</option>
              <option selected={acc_need_time.days === '21' ? true : false}>21</option>
              <option selected={acc_need_time.days === '22' ? true : false}>22</option>
              <option selected={acc_need_time.days === '23' ? true : false}>23</option>
              <option selected={acc_need_time.days === '24' ? true : false}>24</option>
              <option selected={acc_need_time.days === '25' ? true : false}>25</option>
              <option selected={acc_need_time.days === '26' ? true : false}>26</option>
              <option selected={acc_need_time.days === '27' ? true : false}>27</option>
              <option selected={acc_need_time.days === '28' ? true : false}>28</option>
              <option selected={acc_need_time.days === '29' ? true : false}>29</option>
              <option selected={acc_need_time.days === '30' ? true : false}>30</option>
             </select></div>
             </div> */}
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div className="pg1-prt-2-inrfld">
                            <input
                              id="acc_need_time"
                              disabled
                              onInput={(e) => {
                                if (e.target.value.length > 3) {
                                  e.target.value = e.target.value.slice(0, 3);
                                }
                              }}
                              type="number"
                              name="value"
                              value={acc_need_time.value}
                              onChange={AccountNeedTimehandler}
                              className="form-control"
                              // defaultValue={10}
                            />
                            <span className="red-star">*</span>
                            <a href="javascript:void(0);" className="nd3-a">
                              <i className="fal fa-plus" />
                            </a>
                            {errorState?.value && (
                              <p style={{ color: "red" }}>{errorState.value}</p>
                            )}
                          </div>
                          <div className="pg1-prt-2-inrfld">
                            <select
                              disabled
                              name="daysormonths"
                              className="form-control"
                              onChange={AccountNeedTimehandler}
                            >
                              <option
                                selected={
                                  acc_need_time.daysormonths === "Years"
                                    ? true
                                    : false
                                }
                              >
                                Years
                              </option>
                              <option
                                selected={
                                  acc_need_time.daysormonths === "Months"
                                    ? true
                                    : false
                                }
                              >
                                Months
                              </option>
                              <option
                                selected={
                                  acc_need_time.daysormonths === "Days"
                                    ? true
                                    : false
                                }
                              >
                                Days
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <h6>
                      Select one that applies to your business:
                      {/* <span className="red-star">*</span> */}
                    </h6>

                    <div className="pg1-prt-1-inrfld">
                      <input
                        type="radio"
                        disabled
                        name="risk_classification"
                        checked={risk_classification === "option1"}
                        value="option1"
                        onChange={RiskClassificationHandler}
                      />
                      <label>
                        My business touches marijuana at any point from seed to
                        sale and my income relies strictly on the marijuana
                        industry. This includes any business or farm that
                        manufactures, processes, or distributes illegal hemp.
                      </label>
                    </div>
                    <div className="pg1-prt-1-inrfld">
                      <input
                        type="radio"
                        disabled
                        name="risk_classification"
                        checked={risk_classification === "option2"}
                        value="option2"
                        onChange={RiskClassificationHandler}
                      />
                      <label>
                        My business activity focuses on providing products and
                        services specifically to MarijuanaRelated Businesses and
                        the cannabis industry as a whole. My business revenue is
                        expected to come from cannabis-related activities.
                      </label>
                    </div>
                    <div className="pg1-prt-1-inrfld">
                      <input
                        type="radio"
                        disabled
                        name="risk_classification"
                        checked={risk_classification === "option3"}
                        value="option3"
                        onChange={RiskClassificationHandler}
                      />
                      <label>
                        My business activity is not specifically focused on
                        providing services to Marijuana-Related Businesses or
                        the cannabis industry, but rather such services are
                        incidental to their overall business and revenue.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-2">
                <div className="pg1-prt-2-txt">
                  <div className="cmn_hdr">
                    <h6>Legal Business Information</h6>
                    <div className="pg1-prt-2-otrfld">
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Legal Business Name:
                          <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="name"
                          // value={Legal_Business.name}
                          value={Legal_Business["name"]}
                          onChange={LegalBusinessHandler}
                          // required minlength="3"
                        />

                        {errorState?.name && (
                          <p style={{ color: "red" }}>{errorState.name}</p>
                        )}
                      </div>

                      <div className="pg1-prt-2-inrfld">
                        <label>Other DBAs:</label>
                        <input
                          type="text"
                          disabled
                          name="dba"
                          value={Legal_Business.dba}
                          onChange={LegalBusinessHandler}
                          className="form-control"
                        />
                        {errorState?.dba && (
                          <p style={{ color: "red" }}>{errorState.dba}</p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Physical Address:<span className="red-star">*</span>
                        </label>
                        {/* <input
                        type="text"
                        name="physical_Address"
                        onFocus={() => setFocus("physical_Address")}
                        value={Legal_Business.physical_Address}
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                        <textarea
                          type="text"
                          disabled
                          name="physical_Address"
                          value={Legal_Business.physical_Address}
                          style={{ height: "10rem", width: "40rem" }}
                          onChange={LegalBusinessHandler}
                          className="form-control"
                          textarea
                        />
                        {errorState?.physical_Address && (
                          <p style={{ color: "red" }}>
                            {errorState.physical_Address}
                          </p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <div className="pg1__inr">
                          <label>Mailing Address:</label>
                          <span className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              disabled
                              name="samevalue"
                              value="Secretary of State Registration"
                              checked={sameValue === "yes"}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSameValue("yes");
                                } else {
                                  setSameValue("no");
                                }
                              }}
                            />
                            <label>Same as Physical Address</label>
                          </span>
                        </div>
                        {/* <input
                        type="text"
                        onFocus={() => setFocus("mailing_Address")}
                        name="mailing_Address"
                        value={
                          sameValue === "yes"
                            ? Legal_Business.physical_Address
                            : Legal_Business.mailing_Address
                        }
                        style={{ height: "10rem", width: "40rem" }}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                        <textarea
                          type="text"
                          disabled
                          name="mailing_Address"
                          value={
                            sameValue === "yes"
                              ? Legal_Business.physical_Address
                              : Legal_Business.mailing_Address
                          }
                          style={{ height: "10rem", width: "40rem" }}
                          onChange={LegalBusinessHandler}
                          className="form-control"
                          textarea
                        />
                        {errorState?.mailing_Address && (
                          <p style={{ color: "red" }}>
                            {errorState.mailing_Address}
                          </p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Federal EIN:<span className="red-star">*</span>
                        </label>
                        {/* Legal_Business.federal_EIN.substr(0,2) + '-' + Legal_Business.federal_EIN.substr(3, 6)  */}
                        {/* <input
                        type="number"
                        onFocus={() => setFocus("federal_EIN")}
                        onInput={(e) => {
                          if (e.target.value.length > 9) {
                            e.target.value = e.target.value.slice(0, 9);
                          }
                        }}
                        name="federal_EIN"
                        value={Legal_Business.federal_EIN}
                        onChange={LegalBusinessHandler}
                        className="form-control"
                      /> */}
                        <NumberFormat
                          disabled
                          value={Legal_Business?.federal_EIN}
                          className="form-control"
                          format={"##-#######"}
                          // mask="*"
                          onChange={LegalBusinessHandler}
                          name="federal_EIN"
                        />
                        {errorState?.federal_EIN && (
                          <p style={{ color: "red" }}>
                            {errorState.federal_EIN}
                          </p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Cannabis License Number:
                          <span className="red-star">*</span>
                        </label>
                        <input
                          disabled
                          type="text"
                          name="cannabis_License_Number"
                          //     onInput={(e)=>{

                          //       if(e.target.value.length > 12){
                          //        e.target.value = e.target.value.slice(0,12)
                          //       }
                          //  }}
                          value={Legal_Business.cannabis_License_Number}
                          onChange={LegalBusinessHandler}
                          className="form-control"
                        />
                        {errorState?.cannabis_License_Number && (
                          <p style={{ color: "red" }}>
                            {errorState.cannabis_License_Number}
                          </p>
                        )}
                      </div>
                      {/* <div className="pg1-prt-2-inrfld nd2">
                      <label>Licensing Type:</label>
                      <div className="pg1-prt-2-prt-otrfld">
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Cultivation")}
                            value="Cultivation"
                            checked={
                              Legal_Business.licensing_Type === "Cultivation"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Cultivation</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Manufacturer")}
                            value="Manufacturer"
                            checked={
                              Legal_Business.licensing_Type === "Manufacturer"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Manufacturer</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Distribution")}
                            value="Distribution"
                            checked={
                              Legal_Business.licensing_Type === "Distribution"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Distribution</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Testing Lab")}
                            value="Testing Lab"
                            checked={
                              Legal_Business.licensing_Type === "Testing Lab"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Testing Lab</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Dispensary")}
                            value="Dispensary"
                            checked={
                              Legal_Business.licensing_Type === "Dispensary"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Dispensary</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Delivery")}
                            value="Delivery"
                            checked={
                              Legal_Business.licensing_Type === "Delivery"
                            }
                            onChange={LegalBusinessHandler}
                          />
                          <label>Delivery</label>
                        </div>
                        <div className="pg1-prt-2-prtfld">
                          <input
                            type="radio"
                            name="licensing_Type"
                            onFocus={() => setFocus("Other")}
                            value="Other"
                            checked={Legal_Business.licensing_Type === "Other"}
                            onChange={LegalBusinessHandler}
                          />
                          <label>Other</label>
                        </div>
                      </div>
                    </div> */}
                      <div className="pg3-mn-otrfld">
                        <div className="check__in__bx">
                          <h6>
                            Licensing Type:<span className="red-star">*</span>
                          </h6>
                          <div className="pg3-mn-inrfld">
                            <input
                              disabled
                              type="checkbox"
                              name="pg-bx"
                              value="Cultivation"
                              checked={Legal_Business?.licensing_Type?.includes(
                                "Cultivation"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Cultivation</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "Cultivation"
                            ) && (
                              <>
                                <input
                                  name="Cultivation"
                                  disabled
                                  value={LicenseNumber?.Cultivation}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}

                            {errorState?.Cultivation && (
                              <p style={{ color: "red" }}>
                                {errorState.Cultivation}
                              </p>
                            )}
                          </div>
                          <div className="pg3-mn-inrfld">
                            <input
                              disabled
                              type="checkbox"
                              name="pg-bx"
                              value="Manufacturer"
                              checked={Legal_Business?.licensing_Type?.includes(
                                "Manufacturer"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Manufacturer</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "Manufacturer"
                            ) && (
                              // <input
                              //   name="Manufacturer"
                              //   value={LicenseNumber?.Manufacturer}
                              //   onChange={LicenseNumberHandler}
                              // />
                              <>
                                <input
                                  name="Manufacturer"
                                  disabled
                                  value={LicenseNumber?.Manufacturer}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}

                            {errorState?.Manufacturer && (
                              <p style={{ color: "red" }}>
                                {errorState.Manufacturer}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              disabled
                              type="checkbox"
                              name="pg-bx"
                              value="Distribution"
                              checked={Legal_Business?.licensing_Type?.includes(
                                "Distribution"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Distribution</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "Distribution"
                            ) && (
                              <>
                                <input
                                  name="Distribution"
                                  disabled
                                  value={LicenseNumber?.Distribution}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}
                            {errorState?.Distribution && (
                              <p style={{ color: "red" }}>
                                {errorState.Distribution}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              disabled
                              value="Testing Lab"
                              checked={Legal_Business?.licensing_Type?.includes(
                                "Testing Lab"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Testing Lab</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "Testing Lab"
                            ) && (
                              <>
                                {" "}
                                <input
                                  name="Testing Lab"
                                  disabled
                                  value={
                                    LicenseNumber &&
                                    LicenseNumber["Testing Lab"]
                                  }
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}
                            {errorState && errorState["Testing Lab"] && (
                              <p style={{ color: "red" }}>
                                {errorState["Testing Lab"]}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              disabled
                              checked={Legal_Business?.licensing_Type?.includes(
                                "ACH"
                              )}
                              onChange={LegalBusinessHandler}
                              value="ACH"
                            />
                            <label>ACH</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "ACH"
                            ) && (
                              <>
                                <input
                                  name="ACH"
                                  disabled
                                  value={LicenseNumber?.ACH}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}
                            {errorState?.ACH && (
                              <p style={{ color: "red" }}>{errorState?.ACH}</p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              disabled
                              value="Dispensary"
                              checked={Legal_Business?.licensing_Type?.includes(
                                "Dispensary"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Dispensary</label>
                            {Legal_Business?.licensing_Type?.includes(
                              "Dispensary"
                            ) && (
                              <>
                                <input
                                  name="Dispensary"
                                  disabled
                                  value={LicenseNumber?.Dispensary}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}
                            {errorState?.Dispensary && (
                              <p style={{ color: "red" }}>
                                {errorState?.Dispensary}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              disabled
                              name="pg-bx"
                              value="Delivery"
                              checked={Legal_Business.licensing_Type?.includes(
                                "Delivery"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Delivery</label>
                            {Legal_Business.licensing_Type?.includes(
                              "Delivery"
                            ) && (
                              <>
                                {" "}
                                <input
                                  name="Delivery"
                                  disabled
                                  value={LicenseNumber?.Delivery}
                                  onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                              </>
                            )}
                            {errorState?.Delivery && (
                              <p style={{ color: "red" }}>
                                {errorState?.Delivery}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              disabled
                              name="pg-bx"
                              value="Other"
                              checked={Legal_Business.licensing_Type?.includes(
                                "Other"
                              )}
                              onChange={LegalBusinessHandler}
                            />
                            <label>Other</label>
                            {Legal_Business.licensing_Type?.includes(
                              "Other"
                            ) && (
                              <div>
                                <input
                                  placeholder="Type licensing type.."
                                  name="other"
                                  disabled
                                  value={Legal_Business?.other}
                                  onChange={LegalBusinessHandler}
                                  // onChange={LicenseNumberHandler}
                                />
                                <span className="red-star">*</span>
                                <br />
                                <br />
                                <>
                                  <input
                                    name="Other"
                                    disabled
                                    value={LicenseNumber?.Other}
                                    onChange={LicenseNumberHandler}
                                  />
                                  <span className="red-star">*</span>
                                </>
                                {errorState?.Other && (
                                  <p style={{ color: "red" }}>
                                    {errorState?.Other}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pg1-prt-2-inrfld nd3">
                        <div className="pg1-prt-2-lwr-otrfld">
                          {Legal_Business.licensing_Type === "Other" && (
                            <div className="pg1-prt-2-lwr-inrfld">
                              <label>Other:</label>
                              <input
                                type="text"
                                disabled
                                className="form-control"
                                name="other"
                                value={Legal_Business.other}
                                onChange={LegalBusinessHandler}
                              />
                              {errorState?.other && (
                                <p style={{ color: "red" }}>
                                  {errorState.other}
                                </p>
                              )}
                            </div>
                          )}
                          <div className="pg1-prt-2-lwr-inrfld">
                            <label>
                              No of Employees:
                              <span className="red-star">*</span>
                            </label>
                            <NumberFormat
                              disabled
                              style={{ width: "14rem", height: "5rem" }}
                              className="form-control"
                              maxLength={12}
                              value={Legal_Business?.num_employees}
                              // format={"$##,###,###,###"}
                              // 78,979,797,979,797
                              thousandSeparator={","}
                              // decimalSeparator={"."}
                              // decimalScale={2}
                              onChange={LegalBusinessHandler}
                              name="num_employees"
                            />
                            <a href="javascript:void(0);" className="nd3-a">
                              <i className="fal fa-plus" />
                            </a>
                            {errorState?.num_employees && (
                              <p style={{ color: "red" }}>
                                {errors.num_employees}
                              </p>
                            )}
                          </div>
                          {Legal_Business.accepted_states && (
                            <div className="pg1-prt-2-lwr-inrfld bnk1b">
                              <label>
                                List All States of Operation (State that the
                                business grows, processes, or distributes
                                marijuana-related products):
                                <span className="red-star">*</span>
                              </label>
                              <CreatableSelect
                                isDisabled
                                name="accepted_states"
                                defaultValue={Legal_Business?.accepted_states?.map(
                                  (i) => {
                                    return {
                                      value: i,
                                      label: i,
                                      color: "#0052CC",
                                    };
                                  }
                                )}
                                isMulti
                                // options={StateOptions}
                                onChange={(val) => {
                                  console.log(val, "states");
                                  const data = val.map((item) => item.value);
                                  //  setAcceptedStates([...data])

                                  setLegal_Business({
                                    ...Legal_Business,
                                    accepted_states: data,
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>
                          Does your business have any foreign operations?
                        </label>
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              disabled
                              type="checkbox"
                              name="foreign_operations"
                              checked={
                                Legal_Business.foreign_operations === "yes"
                              }
                              onChange={LegalBusinessHandler}
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-3">
                <div className="pg1-prt-3-txt">
                  <div className="cmn_hdr">
                    <h6>Primary Contact Information:</h6>
                    <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Primary Contact Name:
                          <span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          disabled
                          name="primary_contact_name"
                          value={Primary_Information.primary_contact_name}
                          onChange={PrimaryInformationHandler}
                          className="form-control"
                        />
                        {errorState?.primary_contact_name && (
                          <p style={{ color: "red" }}>
                            {errorState.primary_contact_name}
                          </p>
                        )}
                      </div>
                      {/* <div className="pg1-prt-2-inrfld">
                    <label>Date of Birth:</label>
                    <input type="text" name="dob"
                      value={Primary_Information.dob}
                      onChange={PrimaryInformationHandler} className="form-control" />
                    {
                      errorState?.dob && <p style={{ color: 'red' }}>{errorState?.dob}</p>

                    }
                  </div> */}
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Date of Birth:<span className="red-star">*</span>
                        </label>
                        <input
                          disabled
                          type="date"
                          name="dob"
                          value={Primary_Information.dob}
                          onChange={PrimaryInformationHandler}
                          className="form-control"
                        />
                        {/* <DatePicker className="form-control" style={{width:'20rem'}}
                     dateFormat="MM-dd-yyyy"
                     selected={startDate}
                     onChange={selectDateHandler}
                     minDate={today}
                     todayButton={"Today"}
                     /> */}
                        {errorState?.dob && (
                          <p style={{ color: "red" }}>{errorState?.dob}</p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Address:<span className="red-star">*</span>
                        </label>
                        <input
                          type="text"
                          disabled
                          id="ui-tar"
                          name="primary_address"
                          style={{
                            height: "10rem",
                            width: "30rem",
                            paddingTop: "0px",
                          }}
                          value={Primary_Information.primary_address}
                          onChange={PrimaryInformationHandler}
                          className="form-control"
                        />
                        {errorState?.primary_address && (
                          <p style={{ color: "red" }}>
                            {errorState.primary_address}
                          </p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          E-mail:<span className="red-star">*</span>
                        </label>
                        <input
                          type="email"
                          disabled
                          name="mail"
                          id="e-mail"
                          style={{ width: "30rem" }}
                          value={Primary_Information.mail}
                          onChange={PrimaryInformationHandler}
                          className="form-control"
                        />
                        {errorState?.mail && (
                          <p style={{ color: "red" }}>{errorState.mail}</p>
                        )}
                      </div>
                      <div className="pg1-prt-2-inrfld">
                        <label>
                          Primary Phone No.<span className="red-star">*</span>
                        </label>
                        {/* <input type="tel" name="primary_Phone"
                      value={Primary_Information.primary_Phone}
                      onChange={PrimaryInformationHandler} className="form-control" />
                    <br /><br /> */}
                        <NumberFormat
                          disabled
                          value={Primary_Information?.primary_Phone}
                          className="form-control"
                          format={"(###) ###-####"}
                          // mask="*"
                          type="text"
                          onChange={PrimaryInformationHandler}
                          name="primary_Phone"
                        />
                        {/* <input
                        type="text"
                        name="primary_Phone"
                        onFocus={() => setFocus("primary_Phone")}
                        value={Primary_Information.primary_Phone}
                        onChange={PrimaryInformationHandler}
                        className="form-control"
                      /> */}
                        {errorState?.primary_Phone && (
                          <p style={{ color: "red" }}>
                            {errorState.primary_Phone}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                      <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                        <label>
                          Ownership in business applying for account?
                        </label>
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              disabled
                              type="checkbox"
                              name="ownership"
                              checked={Primary_Information.ownership === "yes"}
                              onChange={PrimaryInformationHandler}
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      {Primary_Information.ownership === "yes" && (
                        <div className="pg1-prt-2-inrfld">
                          <label>
                            Ownership Percentage
                            <span className="red-star">*</span>
                          </label>
                          <input
                            type="number"
                            disabled
                            name="ownership_Percentage"
                            onInput={(e) => {
                              if (e.target.value.length > 2) {
                                e.target.value = e.target.value.slice(0, 2);
                              }
                            }}
                            value={Primary_Information.ownership_Percentage}
                            onChange={PrimaryInformationHandler}
                            className="form-control"
                          />
                          <h6
                            style={{
                              float: "right",
                              marginRight: "30rem",
                              width: "20px",
                              marginTop: "1rem",
                            }}
                          >
                            %
                          </h6>
                          {errorState?.ownership_Percentage && (
                            <p style={{ color: "red" }}>
                              {errorState.ownership_Percentage}
                            </p>
                          )}
                        </div>
                      )}
                      <div className="pg1-prt-2-inrfld nd2">
                        <label>U.S. Citizenship?</label>
                        <div className="pg1-prt-2-prt-otrfld">
                          <div className="pg1-prt-2-prtfld">
                            <input
                              type="radio"
                              name="us_Citizenship"
                              value="yes"
                              disabled
                              checked={
                                Primary_Information.us_Citizenship === "yes"
                              }
                              onChange={PrimaryInformationHandler}
                            />
                            <label>Yes</label>
                          </div>
                          <div className="pg1-prt-2-prtfld">
                            <input
                              type="radio"
                              disabled
                              name="us_Citizenship"
                              value="no"
                              checked={
                                Primary_Information.us_Citizenship === "no"
                              }
                              onChange={PrimaryInformationHandler}
                            />
                            <label>No</label>
                          </div>

                          {/* {Primary_Information.us_Citizenship === "no" && (
                          <p style={{ color: "red" }}>
                            Continue filling up the form and contact GBS admin
                            later.
                          </p>
                        )} */}
                        </div>
                      </div>
                      <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                        <label>
                          Authority to sign on behalf of the business?
                        </label>
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              disabled
                              name="authority_sign"
                              checked={
                                Primary_Information.authority_sign === "yes"
                              }
                              onChange={PrimaryInformationHandler}
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="button-container">
            <button className='btn btn-prev' onClick={PreviousHandler}>Previous</button>
            <button className="btn btn-next" type="submit">
              Next
            </button>
          </div> */}
        </form>
      </section>
      <section id="section2" className="section2">
        <form action="">
          <div className="ope_dtls bg_grey">
            <h3 className="hdng_h3">Operational Details</h3>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Are any marijuana-related products purchased from providers?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    disabled
                    name="products_purchased_providers"
                    onChange={OperationalDetailshandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Licensed Provider?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    disabled
                    type="checkbox"
                    name="licensed_provider"
                    onChange={OperationalDetailshandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                How many Bank accounts do you need?
                <span className="red-star">*</span>
              </label>
              <NumberFormat
                maxLength={2}
                disabled
                value={Operational_Details?.acc_need}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OperationalDetailshandler}
                name="acc_need"
              />
              {errorState?.acc_need && (
                <h3 style={{ color: "red" }}>{errorState.acc_need}</h3>
              )}
            </div>
            <div className="parent_forminput_group">
              <div className="form-input-group">
                <label htmlFor="">
                  Number of Locations:<span className="red-star">*</span>
                </label>
                <NumberFormat
                  maxLength={2}
                  disabled
                  value={Operational_Details?.num_locs}
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  onChange={OperationalDetailshandler}
                  name="num_locs"
                />
                {errorState?.num_locs && (
                  <h3 style={{ color: "red" }}>{errorState.num_locs}</h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Average No. of Transactions Per Month:
                  <span className="red-star">*</span>
                </label>
                {/* <input type='number'
               onInput={(e)=>{
                 
                 if(e.target.value.length > 8){
                  e.target.value = e.target.value.slice(0,8)
                 }
            }}
                value={Operational_Details.transactions_Per_month}
                name='transactions_Per_month' onChange={OperationalDetailshandler} /> */}
                <NumberFormat
                  disabled
                  maxLength={6}
                  value={Operational_Details?.transactions_Per_month}
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  onChange={OperationalDetailshandler}
                  name="transactions_Per_month"
                />
                {errorState?.transactions_Per_month && (
                  <h3 style={{ color: "red" }}>
                    {errorState.transactions_Per_month}
                  </h3>
                )}
              </div>
              {/* <div className="form-input-group">
              <label htmlFor="">Total Managed Square Feet:</label>
              
              <NumberFormat
                maxLength={19}
                value={Operational_Details.managed_square_feet}
                // format={"$#########"}
                thousandSeparator={","}
                decimalSeparator={"."}
                decimalScale={2}
                onChange={OperationalDetailshandler}
                name="managed_square_feet"
              />
              {errorState?.managed_square_feet && (
                <h3 style={{ color: "red" }}>
                  {errorState.managed_square_feet}
                </h3>
              )}
            </div> */}
              <div className="form-input-group">
                <label htmlFor="">
                  Average Purchase Amount Per Sale(USD):
                  <span className="red-star">*</span>
                </label>

                <CurrencyInput
                  disabled
                  placeholder="$0.00"
                  maxLength={9}
                  value={Operational_Details.purchase_amount_per_sale}
                  type="text"
                  name="purchase_amount_per_sale"
                  onChange={OperationalDetailshandler}
                />
                <br />
                {errorState?.purchase_amount_per_sale && (
                  <h3 style={{ color: "red" }}>
                    {errorState.purchase_amount_per_sale}
                  </h3>
                )}
                {/* </div> */}
                {/* <input type="text" 
                          value={USDollar.format(Operational_Details.purchase_amount_per_sale)}
                          // defaultValue={Number(Operational_Details.purchase_amount_per_sale).toFixed(2)}
                          // defaultValue={'$'+Number(Operational_Details.purchase_amount_per_sale).toFixed(2)}
                            onChange={OperationalDetailshandler} name='purchase_amount_per_sale'  />
                            {
                      errorState?.purchase_amount_per_sale && <h3 style={{ color: 'red' }}>{errorState.purchase_amount_per_sale}</h3>

                    } */}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Number of Plants: <span className="red-star">*</span>
                </label>
                <NumberFormat
                  disabled
                  maxLength={19}
                  value={Operational_Details?.num_plants}
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  onChange={OperationalDetailshandler}
                  name="num_plants"
                />
                {errorState?.num_plants && (
                  <h3 style={{ color: "red" }}>{errorState.num_plants}</h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Average Quantity Per Sale:<span className="red-star">*</span>
                </label>

                <NumberFormat
                  disabled
                  maxLength={19}
                  value={
                    Operational_Details?.average_quantity_per_sale.quantityInput
                  }
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}
                  onChange={OperationalDetailshandler}
                  name="average_quantity_per_sale"
                />
                {errorState?.average_quantity_per_sale && (
                  <h3 style={{ color: "red" }}>
                    {errorState.average_quantity_per_sale}
                  </h3>
                )}
                <select
                  disabled
                  style={{
                    height: "5rem",
                    width: "20%",
                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                  name="unit"
                  onChange={OperationalDetailshandler}
                  className="form-control"
                >
                  <option
                    value="Gram"
                    selected={
                      Operational_Details.average_quantity_per_sale
                        .quantityUnit === "Gram"
                    }
                  >
                    Gram
                  </option>
                  <option
                    value="Kilogram"
                    selected={
                      Operational_Details.average_quantity_per_sale
                        .quantityUnit === "Kilogram"
                    }
                  >
                    Kilogram
                  </option>
                  <option
                    value="Pound"
                    selected={
                      Operational_Details.average_quantity_per_sale
                        .quantityUnit === "Pound"
                    }
                  >
                    Pound
                  </option>
                </select>
              </div>

              <div className="form-input-group">
                <div className="iform-group">
                  <h5 className="fromgroup_hdng">Facility Owned or Leased?</h5>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        disabled
                        value="Owned"
                        checked={
                          Operational_Details.facility === "Owned"
                            ? true
                            : false
                        }
                        onChange={OperationalDetailshandler}
                        id="Owned"
                        name="facility"
                        // checked={Operational_Details.types_customers === "Medical"}
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="Owned" className="radio-button">
                        Owned
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        disabled
                        value="Leased"
                        checked={
                          Operational_Details.facility === "Leased"
                            ? true
                            : false
                        }
                        onChange={OperationalDetailshandler}
                        id="Leased"
                        name="facility"
                        // checked={Operational_Details.types_customers === "Recreational"}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Leased" className="radio-button">
                        Leased
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {Operational_Details.facility === "Leased" && (
                <div className="form-input-group">
                  <label htmlFor="">Lease Term:</label>
                  <input
                    type="text"
                    disabled
                    name="lease_term"
                    value={Operational_Details.lease_term}
                    onChange={OperationalDetailshandler}
                  />
                  {errorState?.lease_term && (
                    <h3 style={{ color: "red" }}>{errorState.lease_term}</h3>
                  )}
                </div>
              )}
              <div className="form-input-group">
                <label htmlFor="">
                  {" "}
                  Estimated Gross Monthly Income(USD):
                  <span className="red-star">*</span>
                </label>
                {/* <IconContext.Provider value={{ color: "", size:'3rem',marginTop:'4rem'}}> <PiCurrencyDollar/></IconContext.Provider> */}
                {/* <input type="number"
                value={Operational_Details.egmi}
                defaultValue={'$' + Number(Operational_Details.egmi).toFixed(2)}
                onChange={OperationalDetailshandler} name='egmi' /> */}
                <CurrencyInput
                  disabled
                  placeholder="$0.00"
                  maxLength={9}
                  value={Operational_Details.egmi}
                  type="text"
                  name="egmi"
                  onChange={OperationalDetailshandler}
                />

                {errorState?.egmi && (
                  <h3 style={{ color: "red" }}>{errorState.egmi}</h3>
                )}
              </div>
              <div className="form-input-group">
                <label>
                  {" "}
                  Types of Customers to Be Served:
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="pg3-mn-otrfld">
                  <div className="check__in__bx">
                    <div className="pg3-mn-inrfld">
                      <input
                        disabled
                        type="checkbox"
                        name="pg-bx"
                        value="Medical"
                        onChange={OperationalDetailshandler}
                        checked={Operational_Details?.types_customers?.includes(
                          "Medical"
                        )}
                        id="medical"
                      />
                      <label>Medical</label>
                    </div>
                  </div>
                  <div className="check__in__bx">
                    <div className="pg3-mn-inrfld">
                      <input
                        disabled
                        type="checkbox"
                        name="pg-bx"
                        value="Recreational"
                        onChange={OperationalDetailshandler}
                        checked={Operational_Details?.types_customers?.includes(
                          "Recreational"
                        )}
                        id="recreational"
                      />
                      <label>Recreational</label>
                    </div>
                  </div>
                  {errorState?.types_customers &&
                    Operational_Details?.types_customers?.length === 0 && (
                      <h3 style={{ color: "red" }}>
                        {errorState?.types_customers}
                      </h3>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="other_ope_dtls bg_grey">
            <h3 className="hdng_h3">Other Operational Details</h3>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Are any of the principals of the CRB (e.g., officer, directors,
                or significant investors) principals of any other entity that
                conducts marijuana-related business in any other state?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    disabled
                    type="checkbox"
                    checked={
                      Other_Operational_Details.principal_business === "yes"
                    }
                    name="principal_business"
                    onChange={OtherOperationDetailsHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does the CRB have any Beneficial Owners? (anyone with a stake of
                10% or more in the CRB)
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    disabled
                    type="checkbox"
                    checked={
                      Other_Operational_Details.beneficial_owners === "yes"
                    }
                    name="beneficial_owners"
                    onChange={OtherOperationDetailsHandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-input-group">
              <label htmlFor="">
                Number of Beneficial Owners:<span className="red-star">*</span>{" "}
              </label>
              {/* <NumberFormat
                disabled
                maxLength={12}
                value={Other_Operational_Details?.number_beneficial_owner}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                onChange={OtherOperationDetailsHandler}
                name="number_beneficial_owner"
              /> */}
              {errorState?.number_beneficial_owner && (
                <h3 style={{ color: "red" }}>
                  {errorState.number_beneficial_owner}
                </h3>
              )}
            </div>
          </div>
          <div className="additional_location bg_grey">
            <h3 className="hdng_h3">Additional Locations</h3>
            <br />
            {Additional_Locs.map((addloc, index) => {
              return (
                <>
                  <div className="form-input-group">
                    <label> Location {index + 1}</label>
                  </div>
                  <div className="parent_forminput_group">
                    <div className="form-input-group">
                      <label htmlFor="">
                        Location Name:<span className="red-star">*</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={Additional_Locs[index].loc_name}
                        name="loc_name"
                        onChange={(event) => {
                          AdditionalLocshandler(event, index);
                        }}
                      />
                      {customErr[index]?.loc_name && (
                        <h3 style={{ color: "red" }}>
                          {customErr[index]?.loc_name}
                        </h3>
                      )}
                    </div>
                    <div className="form-input-group">
                      <label htmlFor="">
                        License No:<span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        maxLength={12}
                        // onInput={(e) => {
                        //   if (e.target.value.length > 8) {
                        //     e.target.value = e.target.value.slice(0, 8);
                        //   }
                        // }}
                        value={Additional_Locs[index].license}
                        name="license"
                        onChange={(event) => {
                          AdditionalLocshandler(event, index);
                        }}
                      />
                      {customErr[index]?.license && (
                        <h3 style={{ color: "red" }}>
                          {customErr[index].license}
                        </h3>
                      )}
                      {/* {
                      Additional_Locs[index].license === '' ? showErr : ''
                    } */}
                    </div>
                    <div className="form-input-group">
                      <label htmlFor="">
                        Address:<span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={Additional_Locs[index].loc_address}
                        name="loc_address"
                        onChange={(event) => {
                          AdditionalLocshandler(event, index);
                        }}
                      />
                      {customErr[index]?.loc_address && (
                        <h3 style={{ color: "red" }}>
                          {customErr[index].loc_address}
                        </h3>
                      )}
                      {/* {
                      Additional_Locs[index].loc_address === '' ? showErr : ''
                    } */}
                    </div>
                    <div className="form-input-group">
                      <div className="iform-group">
                        <h5 className="fromgroup_hdng">
                          Deposit Account Needed:
                        </h5>
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              disabled
                              type="checkbox"
                              name="deposit_acc_need"
                              checked={
                                Additional_Locs[index]?.deposit_acc_need ===
                                "yes"
                              }
                              onChange={(event) => {
                                AdditionalLocshandler(event, index);
                              }}
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* <div
                    style={{
                      marginTop: "10px",
                      float: "right",
                      backgroundColor: "#fff",
                      color: "#4e4e4e",
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: "#ba8c4a", size: "4rem" }}
                    >
                      <div
                        onClick={() => {
                          const data = [...Additional_Locs];
                          data.splice(index, 1);
                          setAdditional_Locs([...data]);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <AiFillDelete />
                      </div>
                    </IconContext.Provider>
                  </div> */}
                  <br />
                  <br />
                  <br />
                </>
              );
            })}
            {/* <div
              style={{
                marginTop: "10px",
                float: "right",
                backgroundColor: "#fff",
                color: "#4e4e4e",
              }}
            >
              <IconContext.Provider value={{ color: "#ba8c4a", size: "5rem" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    let data = [
                      ...Additional_Locs,
                      {
                        loc_name: "",
                        license: "",
                        deposit_acc_need: "no",
                        loc_address: "",
                      },
                    ];
                    setAdditional_Locs(data);
                  }}
                >
                  <RiFileAddFill />
                </div>
              </IconContext.Provider>
            </div> */}
          </div>
          <div className="Atactivity bg_grey">
            <h3 className="hdng_h3">Anticipated Transaction Activity</h3>
            <div className="parent_forminput_group">
              <div className="form-input-group">
                <label htmlFor="">
                  Amount of Initial Deposit (USD):
                  <span className="red-star">*</span>
                </label>
                {/* <input type="number" name='amount_Initial_Deposit'
                value={Anticipated_Transaction_Activity.amount_Initial_Deposit}
                defaultValue={'$' + Number(Anticipated_Transaction_Activity.amount_Initial_Deposit).toFixed(2)}
                onChange={AnticipatedTransactionActivityHandler}
              /> */}
                {/* <IconContext.Provider value={{ color: "", size:'3rem',marginLeft:''}}> <PiCurrencyDollar/></IconContext.Provider>
                 */}
                <CurrencyInput
                  disabled
                  placeholder="$0.00"
                  maxLength={9}
                  value={
                    Anticipated_Transaction_Activity.amount_Initial_Deposit
                  }
                  type="text"
                  name="amount_Initial_Deposit"
                  onChange={AnticipatedTransactionActivityHandler}
                />
                {errorState?.amount_Initial_Deposit && (
                  <h3 style={{ color: "red" }}>
                    {errorState.amount_Initial_Deposit}
                  </h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Source of Initial Deposit:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="source_Initial_Deposit"
                  value={
                    Anticipated_Transaction_Activity.source_Initial_Deposit
                  }
                  onChange={AnticipatedTransactionActivityHandler}
                />
                {errorState?.source_Initial_Deposit && (
                  <h3 style={{ color: "red" }}>
                    {errorState.source_Initial_Deposit}
                  </h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Estimated Total Amount of Monthly Deposits (USD):
                  <span className="red-star">*</span>
                </label>
                {/* <IconContext.Provider value={{ color: "", size:'3rem',marginLeft:''}}> <PiCurrencyDollar/></IconContext.Provider> */}
                {/* <input type="number" name='estimated_total_amount_monthly_deposit'
                value={Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit}
                defaultValue={'$' + Number(Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit).toFixed(2)}
                onChange={AnticipatedTransactionActivityHandler} /> */}
                <CurrencyInput
                  disabled
                  placeholder="$0.00"
                  maxLength={9}
                  value={
                    Anticipated_Transaction_Activity.estimated_total_amount_monthly_deposit
                  }
                  type="text"
                  name="estimated_total_amount_monthly_deposit"
                  onChange={AnticipatedTransactionActivityHandler}
                />
                {errorState?.estimated_total_amount_monthly_deposit && (
                  <h3 style={{ color: "red" }}>
                    {errorState.estimated_total_amount_monthly_deposit}
                  </h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Estimated Total Number of Deposits Per Month:
                  <span className="red-star">*</span>
                </label>
                {/* <input type="number" name='estimated_total_num_monthly_deposit'
              
                value={Anticipated_Transaction_Activity.estimated_total_num_monthly_deposit}
                onChange={AnticipatedTransactionActivityHandler} /> */}
                {/* <NumberFormat
                  maxLength={10}
                  disabled
                  value={
                    Anticipated_Transaction_Activity?.estimated_total_num_monthly_deposit
                  }
                  // format={"$#########"}
                  thousandSeparator={","}
                  decimalSeparator={"."}
                  decimalScale={2}
                  onChange={AnticipatedTransactionActivityHandler}
                  name="estimated_total_num_monthly_deposit"
                /> */}
                {errorState?.estimated_total_num_monthly_deposit && (
                  <h3 style={{ color: "red" }}>
                    {errorState.estimated_total_num_monthly_deposit}
                  </h3>
                )}
              </div>
              <div className="iform-group">
                <h5 className="fromgroup_hdng">
                  Anticipate making cash deposits?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="anticipate_cash_deposits"
                      checked={
                        Anticipated_Transaction_Activity.anticipate_cash_deposits ===
                        "yes"
                          ? true
                          : false
                      }
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Anticipated_Transaction_Activity.anticipate_cash_deposits ===
                "yes" && (
                <>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Amount of Monthly Cash Deposits (USD):
                      <span className="red-star">*</span>
                    </label>
                    <CurrencyInput
                      disabled
                      placeholder="$0.00"
                      maxLength={9}
                      value={
                        Anticipated_Transaction_Activity.amount_cash_deposits
                      }
                      type="text"
                      name="amount_cash_deposits"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    {errorState?.amount_cash_deposits && (
                      <h3 style={{ color: "red" }}>
                        {errorState.amount_cash_deposits}
                      </h3>
                    )}
                  </div>
                  <div className="form-input-group">
                    <label> Frequency of Cash Deposits:</label>
                    <div className="frm_radio_prnt">
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          name="frequency_cash_deposits"
                          value="Daily"
                          onChange={AnticipatedTransactionActivityHandler}
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_deposits ===
                            "Daily"
                          }
                          id="daily"
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="daily" className="radio-button">
                          {" "}
                          Daily
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Weekly"
                          onChange={AnticipatedTransactionActivityHandler}
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_deposits ===
                            "Weekly"
                          }
                          id="weekly"
                          name="frequency_cash_deposits"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="weekly" className="radio-button">
                          {" "}
                          Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Bi-Weekly"
                          onChange={AnticipatedTransactionActivityHandler}
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_deposits ===
                            "Bi-Weekly"
                          }
                          id="Bi-Weekly"
                          name="frequency_cash_deposits"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Bi-Weekly" className="radio-button">
                          Bi-Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Monthly"
                          onChange={AnticipatedTransactionActivityHandler}
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_deposits ===
                            "Monthly"
                          }
                          name="frequency_cash_deposits"
                          id="Monthly"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Monthly" className="radio-button">
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Estimated Spend or Withdrawals Monthly (USD):
                      <span className="red-star">*</span>
                    </label>
                    <CurrencyInput
                      disabled
                      placeholder="$0.00"
                      maxLength={9}
                      value={Anticipated_Transaction_Activity.estimated_spend}
                      type="text"
                      name="estimated_spend"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    {errorState?.estimated_spend && (
                      <h3 style={{ color: "red" }}>
                        {errorState.estimated_spend}
                      </h3>
                    )}
                  </div>
                </>
              )}
              <div className="iform-group">
                <h5 className="fromgroup_hdng">
                  Anticipate making cash withdrawals?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      disabled
                      type="checkbox"
                      onChange={AnticipatedTransactionActivityHandler}
                      checked={
                        Anticipated_Transaction_Activity.anticipate_cash_withdrawals ===
                        "yes"
                          ? true
                          : false
                      }
                      name="anticipate_cash_withdrawals"
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Anticipated_Transaction_Activity.anticipate_cash_withdrawals ===
                "yes" && (
                <>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Amount of Monthly Cash Withdrawals (USD):
                      <span className="red-star">*</span>
                    </label>
                    <CurrencyInput
                      disabled
                      placeholder="$0.00"
                      maxLength={9}
                      value={
                        Anticipated_Transaction_Activity.amount_cash_withdrawals
                      }
                      type="text"
                      name="amount_cash_withdrawals"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    {errorState?.amount_cash_withdrawals && (
                      <h3 style={{ color: "red" }}>
                        {errorState.amount_cash_withdrawals}
                      </h3>
                    )}
                  </div>
                  <div className="form-input-group">
                    <label> Frequency of Cash Withdrawals:</label>
                    <div className="frm_radio_prnt">
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Daily"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                            "Daily"
                          }
                          name="frequency_cash_withdrawals"
                          onChange={AnticipatedTransactionActivityHandler}
                          id="daily2"
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="daily2" className="radio-button">
                          {" "}
                          Daily
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          id="weekly2"
                          value="Weekly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                            "Weekly"
                          }
                          name="frequency_cash_withdrawals"
                          onChange={AnticipatedTransactionActivityHandler}
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="weekly2" className="radio-button">
                          Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          id="Bi-Weekly2"
                          value="Bi-Weekly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                            "Bi-Weekly"
                          }
                          name="frequency_cash_withdrawals"
                          onChange={AnticipatedTransactionActivityHandler}
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Bi-Weekly2" className="radio-button">
                          Bi-Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          id="Monthly2"
                          value="Monthly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_withdrawals ===
                            "Monthly"
                          }
                          name="frequency_cash_withdrawals"
                          onChange={AnticipatedTransactionActivityHandler}
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Monthly2" className="radio-button">
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Average Expected Monthly Payroll (USD):
                      <span className="red-star">*</span>
                    </label>
                    <CurrencyInput
                      disabled
                      placeholder="$0.00"
                      maxLength={9}
                      value={Anticipated_Transaction_Activity.monthly_payroll}
                      type="text"
                      name="monthly_payroll"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    {errorState?.monthly_payroll && (
                      <h3 style={{ color: "red" }}>
                        {errorState.monthly_payroll}
                      </h3>
                    )}
                  </div>
                </>
              )}
              <div className="iform-group">
                <h5 className="fromgroup_hdng">Cash pick-ups required?</h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="cash_pick_ups"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Anticipated_Transaction_Activity.cash_pick_ups === "yes" && (
                <>
                  <div className="form-input-group">
                    <label>Frequency of Cash Pick-Ups:</label>
                    <div className="frm_radio_prnt">
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          id="daily3"
                          value="Daily"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                            "Daily"
                          }
                          name="frequency_cash_pick_ups"
                          onChange={AnticipatedTransactionActivityHandler}
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="daily3" className="radio-button">
                          {" "}
                          Daily
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Weekly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                            "Weekly"
                          }
                          name="frequency_cash_pick_ups"
                          onChange={AnticipatedTransactionActivityHandler}
                          id="weekly3"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="weekly3" className="radio-button">
                          Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Bi-Weekly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                            "Bi-Weekly"
                          }
                          name="frequency_cash_pick_ups"
                          onChange={AnticipatedTransactionActivityHandler}
                          id="Bi-Weekly3"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Bi-Weekly3" className="radio-button">
                          Bi-Weekly
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input
                          type="radio"
                          disabled
                          value="Monthly"
                          checked={
                            Anticipated_Transaction_Activity.frequency_cash_pick_ups ===
                            "Monthly"
                          }
                          name="frequency_cash_pick_ups"
                          onChange={AnticipatedTransactionActivityHandler}
                          id="Monthly3"
                        />
                        {/* <span class="radio"></span> */}
                        <label htmlFor="Monthly3" className="radio-button">
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label htmlFor="">
                      Estimated Pick-Up Amount (USD):
                      <span className="red-star">*</span>
                    </label>
                    <CurrencyInput
                      disabled
                      placeholder="$0.00"
                      maxLength={9}
                      value={
                        Anticipated_Transaction_Activity.estimated_cash_pick_ups
                      }
                      type="text"
                      name="estimated_cash_pick_ups"
                      onChange={AnticipatedTransactionActivityHandler}
                    />
                    {errorState?.estimated_cash_pick_ups && (
                      <h3 style={{ color: "red" }}>
                        {errorState.estimated_cash_pick_ups}
                      </h3>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </section>
      <section id="section3" className="section3">
        <form>
          <div className="pg3-mn-otr">
            <div className="pg3-mn-inr">
              <div className="cmn_hdr">
                <h6>
                  Company Documentation<span className="red-star">*</span>
                </h6>
                {mainCheckBox === "intermediate" ? (
                  <>
                    <MdIndeterminateCheckBox
                      disabled
                      onClick={() => {
                        setMainCheckBox("no");
                      }}
                    />{" "}
                    Select All
                  </>
                ) : (
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name=""
                      checked={mainCheckBox === "yes" ? true : false}
                      // checked={Company_Documentation.includes('Audited Financial Statements')}
                      onChange={(e) => {
                        // Company_Documentation.push({documentation:'Policies'},{documentation:'Operating Agreement'})

                        const tars = document.getElementsByName("pg-bx");
                        console.log(tars, "900");
                        if (e.target.checked) {
                          setMainCheckBox("yes");
                          setCompany_Documentation([
                            ...Company_Documentation,
                            { documentation: "Policies" },
                            { documentation: "Operating Agreement" },
                            { documentation: "Audited Financial Statements" },

                            {
                              documentation:
                                "Articles of Formation (Organization or Incorporation)",
                            },
                            {
                              documentation:
                                "Contracts with Armored Car Services to Transport Cash",
                            },

                            {
                              documentation:
                                "Results of any inspections performed by the State",
                            },
                            {
                              documentation:
                                "Federal and State Tax Returns (Last 3 Years Available)",
                            },
                            {
                              documentation:
                                "Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)",
                            },
                            {
                              documentation:
                                "Proof of vendor due diligence completed for all vendors used",
                            },
                            {
                              documentation:
                                "Most Recent Compliance Audit and Reports",
                            },

                            {
                              documentation:
                                "Sales History from POS Records (90 Days Fully Operational)",
                            },

                            {
                              documentation:
                                "Signed Lease Agreement for each Location",
                            },
                            { documentation: "Business Recovery Plan" },
                            {
                              documentation:
                                "Insurance Certificate (summary of coverage)",
                            },
                            { documentation: "State Licensing" },
                            {
                              documentation: "Secretary of State Registration",
                            },
                            {
                              documentation:
                                "Results of any on-site inspections",
                            },
                            {
                              documentation:
                                "Financial Projections for Next 1-3 Years",
                            },

                            {
                              documentation:
                                "List of all vendors utilized including name, DBA, address, contact person, and services/products used",
                            },

                            { documentation: "List of all expected customers" },

                            {
                              documentation:
                                "Attestation of Beneficial Ownership",
                            },
                            {
                              documentation:
                                "Processing Statements (Last 3 Months)",
                            },
                            {
                              documentation:
                                "Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)",
                            },
                            {
                              documentation:
                                "California Verification Status of Entity",
                            },
                            {
                              documentation:
                                "U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons",
                            },
                          ]);
                          for (let c = 0; c < tars.length; c++) {
                            tars[c].checked = true;
                          }
                        } else {
                          for (let c = 0; c < tars.length; c++) {
                            tars[c].checked = false;
                            setCompany_Documentation([]);
                            setMainCheckBox("no");
                          }
                        }
                      }}
                    />
                    <label>Select All</label>
                  </div>
                )}
                <p>Check Documents Available:</p>
                <div className="pg3-mn-otrfld">
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Audited Financial Statements"
                      checked={Company_Documentation.includes(
                        "Audited Financial Statements"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Audited Financial Statements</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Policies"
                      onChange={CompanyDocumentationhandler}
                      checked={Company_Documentation.includes("Policies")}
                    />
                    <label>Policies</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Articles of Formation (Organization or Incorporation)"
                      checked={Company_Documentation.includes(
                        "Articles of Formation (Organization or Incorporation)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Articles of Formation (Organization or Incorporation)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Operating Agreement"
                      checked={Company_Documentation.includes(
                        "Operating Agreement"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Operating Agreement</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Contracts with Armored Car Services to Transport Cash"
                      checked={Company_Documentation.includes(
                        "Contracts with Armored Car Services to Transport Cash"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Contracts with Armored Car Services to Transport Cash
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Results of any inspections performed by the State"
                      checked={Company_Documentation.includes(
                        "Results of any inspections performed by the State"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Results of any inspections performed by the State
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Federal and State Tax Returns (Last 3 Years Available)"
                      checked={Company_Documentation.includes(
                        "Federal and State Tax Returns (Last 3 Years Available)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Federal and State Tax Returns (Last 3 Years Available)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                      checked={Company_Documentation.includes(
                        "Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Compliance Attestation (Agreement with terms and
                      conditions of account and fee schedule, statement that MRB
                      is not in violation of Cole Memo Priorities or FinCEN
                      guidelines)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Proof of vendor due diligence completed for all vendors used"
                      checked={Company_Documentation.includes(
                        "Proof of vendor due diligence completed for all vendors used"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Proof of vendor due diligence completed for all vendors
                      used
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      checked={Company_Documentation.includes(
                        "Most Recent Compliance Audit and Reports"
                      )}
                      value="Most Recent Compliance Audit and Reports"
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Most Recent Compliance Audit and Reports</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Sales History from POS Records (90 Days Fully Operational)"
                      checked={Company_Documentation.includes(
                        "Sales History from POS Records (90 Days Fully Operational)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Sales History from POS Records (90 Days Fully Operational)
                    </label>
                  </div>
                  {/* <div className="pg3-mn-inrfld">
                  <input type="checkbox" name="pg-bx" value='Employee Training Manual'
                    checked={Company_Documentation.includes('Employee Training Manual')}
                    onChange={CompanyDocumentationhandler} />
                  <label>Employee Training Manual</label>
                </div> */}
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Signed Lease Agreement for each Location"
                      checked={Company_Documentation.includes(
                        "Signed Lease Agreement for each Location"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Signed Lease Agreement for each Location</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Business Recovery Plan"
                      checked={Company_Documentation.includes(
                        "Business Recovery Plan"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Business Recovery Plan</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Insurance Certificate (summary of coverage)"
                      checked={Company_Documentation.includes(
                        "Insurance Certificate (summary of coverage)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Insurance Certificate (summary of coverage)</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      checked={Company_Documentation.includes(
                        "State Licensing"
                      )}
                      value="State Licensing"
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>State Licensing</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Secretary of State Registration"
                      checked={Company_Documentation.includes(
                        "Secretary of State Registration"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Secretary of State Registration</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Results of any on-site inspections"
                      checked={Company_Documentation.includes(
                        "Results of any on-site inspections"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Results of any on-site inspections</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Financial Projections for Next 1-3 Years"
                      checked={Company_Documentation.includes(
                        "Financial Projections for Next 1-3 Years"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Financial Projections for Next 1-3 Years</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                      checked={Company_Documentation.includes(
                        "List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      List of all vendors utilized including name, DBA, address,
                      contact person, and services/products used
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="List of all expected customers"
                      checked={Company_Documentation.includes(
                        "List of all expected customers"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>List of all expected customers</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Attestation of Beneficial Ownership"
                      checked={Company_Documentation.includes(
                        "Attestation of Beneficial Ownership"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Attestation of Beneficial Ownership</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Processing Statements (Last 3 Months)"
                      checked={Company_Documentation.includes(
                        "Processing Statements (Last 3 Months)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>Processing Statements (Last 3 Months)</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                      checked={Company_Documentation.includes(
                        "Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      Balance Sheets, Cash Flow Statements, and P/L Statements
                      (Last 3 Years)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="California Verification Status of Entity"
                      checked={Company_Documentation.includes(
                        "California Verification Status of Entity"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>California Verification Status of Entity</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      name="pg-bx"
                      value="U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                      checked={Company_Documentation.includes(
                        "U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                      )}
                      onChange={CompanyDocumentationhandler}
                    />
                    <label>
                      U.S. Government issue ID/Driver’s License/Military
                      ID/State ID or U.S. Passport for Administrators,
                      Beneficial Owners, Officers, Directors, and Control
                      Persons
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <section id="section4" className="section4">
        <form action="">
          <div className="lgcy_cash bg_grey">
            <h3 className="hdng_h3">Legacy Cash</h3>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does your business have legacy cash?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    disabled
                    name="legacy_cash"
                    checked={Legacy_Cash.legacy_cash === "yes"}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            {Legacy_Cash.legacy_cash === "yes" && (
              <div className="check_form-group">
                <h5 className="fromgroup_hdng">
                  Check Documents Available:<span className="red-star">*</span>
                </h5>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    id="icheck1"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                    )}
                    value="Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                    name="documents_available"
                  />
                  <label htmlFor="icheck1">
                    Quarterly federal, state, and municipal tax returns for each
                    of the last five years, if applicable
                  </label>
                </div>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "Financial statements, preferably audited that have been prepared for the business"
                    )}
                    value="Financial statements, preferably audited that have been prepared for the business"
                    name="documents_available"
                  />
                  <label>
                    Financial statements, preferably audited that have been
                    prepared for the business
                  </label>
                </div>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                    )}
                    value="Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                    name="documents_available"
                  />
                  <label>
                    Point-of-Sale (seed-to-sale) system reports: monthly and
                    annual POS reports showing all wholesale inventory purchases
                    and retail sales transactions
                  </label>
                </div>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                    )}
                    value="State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                    name="documents_available"
                    o
                  />
                  <label>
                    State database reporting: reports of amounts reported to the
                    state tracking database for as long as the state database
                    has been active, if applicable
                  </label>
                </div>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                    )}
                    value="Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                    name="documents_available"
                  />
                  <label>
                    Armored courier confirmed cash count: To the extent cash was
                    couriered to a vault or other safekeeping location, obtain
                    confirmed cash count slips from armored courier evidencing
                    amount of cash counted and date of cash pick-up/count
                  </label>
                </div>
                <div className="check_checking">
                  <input
                    type="checkbox"
                    disabled
                    checked={Legacy_Cash.documents_available.includes(
                      "Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                    )}
                    value="Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                    name="documents_available"
                  />
                  <label>
                    Safekeeping receipt (vaulted cash): Obtain Safekeeping
                    Receipt showing total cash vaulted and custodianship of cash
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="csh_mngmnt bg_grey">
            <h3 className="hdng_h3">Cash Management</h3>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Does the business currently have a bank account?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    disabled
                    checked={Cash_Management.business_acc === "yes"}
                    name="business_acc"
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="parent_forminput_group">
              {Cash_Management.business_acc === "yes" && (
                <>
                  {" "}
                  <div className="form-input-group">
                    <label htmlFor="">
                      Bank Name:<span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      name="bank_name"
                      disabled
                      value={Cash_Management.bank_name}
                    />
                    {errorState?.bank_name && (
                      <h3 style={{ color: "red" }}>{errorState.bank_name}</h3>
                    )}
                  </div>
                  <div
                    className="form-input-group"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <label htmlFor="">
                      Length of Time Account has been Open:
                      <span className="red-star">*</span>
                    </label>

                    <div style={{ display: "flex" }}>
                      {" "}
                      <div className="form-input-group">
                        <input
                          disabled
                          onInput={(e) => {
                            if (e.target.value.length > 3) {
                              e.target.value = e.target.value.slice(0, 3);
                            }
                          }}
                          style={{ width: "100%" }}
                          type="number"
                          name="thisvalue"
                          value={Cash_Management.thisvalue}
                          className="form-control"
                        />
                        <a href="javascript:void(0);" className="nd3-a">
                          <i className="fal fa-plus" />
                        </a>
                        {errorState?.thisvalue && (
                          <h3 style={{ color: "red" }}>
                            {errorState.thisvalue}
                          </h3>
                        )}
                      </div>
                      <div className="form-input-group">
                        <select
                          name="daysormonths"
                          disabled
                          style={{
                            width: "150px",
                            cursor: "pointer",
                            marginLeft: "1rem",
                          }}
                        >
                          <option
                            selected={
                              Cash_Management.daysormonths === "Years"
                                ? true
                                : false
                            }
                          >
                            Years
                          </option>
                          <option
                            selected={
                              Cash_Management.daysormonths === "Months"
                                ? true
                                : false
                            }
                          >
                            Months
                          </option>
                          <option
                            selected={
                              Cash_Management.daysormonths === "Days"
                                ? true
                                : false
                            }
                          >
                            Days
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-input-group full">
                    <label htmlFor="">
                      If account was closed, reason for account closure:
                    </label>
                    <input
                      type="text"
                      disabled
                      name="reason_to_close"
                      value={Cash_Management.reason_to_close}
                    />
                    {errorState?.reason_to_close && (
                      <h3 style={{ color: "red" }}>
                        {errorState.reason_to_close}
                      </h3>
                    )}
                  </div>
                </>
              )}

              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Penalties for paying taxes paid in cash?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="penalty_cash"
                      checked={Cash_Management.penalty_cash === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Current on tax payments with the state?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="tax_payment"
                      checked={Cash_Management.tax_payment === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Number of vendors paid monthly:
                  <span className="red-star">*</span>
                </label>
                {/* <NumberFormat
                  disabled
                  maxLength={12}
                  value={Cash_Management?.number_vendors}
                  thousandSeparator={","}
                  name="number_vendors"
                /> */}
                {errorState?.number_vendors && (
                  <h3 style={{ color: "red" }}>{errorState.number_vendors}</h3>
                )}
              </div>
              <div className="form-input-group">
                <label>
                  Method(s) by which vendors are paid:
                  <span className="red-star">*</span>
                </label>
                <div className="frm_radio_prnt">
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      value="ACH"
                      checked={Cash_Management?.vendor_payment_methods?.includes(
                        "ACH"
                      )}
                      name="vendor_payment_methods"
                      id="ACH1"
                    />

                    <label htmlFor="ACH1" className="radio-button">
                      {" "}
                      ACH
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      value="Check"
                      checked={Cash_Management?.vendor_payment_methods?.includes(
                        "Check"
                      )}
                      name="vendor_payment_methods"
                      id="check1"
                    />

                    <label htmlFor="check1" className="radio-button">
                      {" "}
                      Check
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      disabled
                      value="Wire"
                      checked={Cash_Management?.vendor_payment_methods?.includes(
                        "Wire"
                      )}
                      name="vendor_payment_methods"
                      id="wire1"
                    />

                    <label htmlFor="wire1" className="radio-button">
                      {" "}
                      Wire
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      value="Cash"
                      disabled
                      checked={Cash_Management?.vendor_payment_methods?.includes(
                        "Cash"
                      )}
                      name="vendor_payment_methods"
                      id="cash1"
                    />

                    <label htmlFor="cash1" className="radio-button">
                      {" "}
                      Cash
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Any vendors located outside of the U.S.?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="international_vendor"
                      checked={Cash_Management.international_vendor === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is the business able to receive electronic payments?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="electronic_payment"
                      checked={Cash_Management.electronic_payment === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  If the business-only operates in cash, describe current cash
                  management practices:<span className="red-star">*</span>
                </label>
                <textarea
                  disabled
                  style={{
                    width: "60rem",
                    paddingTop: "10px",
                  }}
                  type="text"
                  name="current_cash_managment"
                  value={Cash_Management.current_cash_managment}
                ></textarea>
                {errorState?.current_cash_managment && (
                  <h3 style={{ color: "red" }}>
                    {errorState.current_cash_managment}
                  </h3>
                )}
              </div>
            </div>
          </div>
          <div className="csh_mngmnt bg_grey">
            <h3 className="hdng_h3">Transfer from Existing Bank Account</h3>

            <div className="parent_forminput_group">
              <div className="form-input-group">
                <label htmlFor="">
                  Name of financial institution funds being transferred from:
                  <span className="red-star">*</span>
                </label>
                <input
                  disabled
                  type="text"
                  name="financial_institution"
                  value={Transfer_Existing_Bank_Account?.financial_institution}
                />
                {errorState?.financial_institution && (
                  <h3 style={{ color: "red" }}>
                    {errorState.financial_institution}
                  </h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">Bank contact:</label>

                {/* <NumberFormat
                  disabled
                  value={Transfer_Existing_Bank_Account?.existing_bank_contact}
                  className="form-control"
                  format={"(###) ###-####"}
                  // mask="*"

                  name="existing_bank_contact"
                /> */}

                {errorState?.existing_bank_contact && (
                  <h3 style={{ color: "red" }}>
                    {errorState.existing_bank_contact}
                  </h3>
                )}
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Name on bank account:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="existing_bank_name"
                  value={Transfer_Existing_Bank_Account?.existing_bank_name}
                />
                {errorState?.existing_bank_name && (
                  <h3 style={{ color: "red" }}>
                    {errorState.existing_bank_name}
                  </h3>
                )}
              </div>

              <div className="form-input-group">
                <label htmlFor="">
                  Source of original deposit to previous bank account:
                  <span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="original_deposite"
                  value={Transfer_Existing_Bank_Account.original_deposite}
                />
                {errorState?.original_deposite && (
                  <h3 style={{ color: "red" }}>
                    {errorState.original_deposite}
                  </h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Can source of deposit at previous bank be verified?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      disabled
                      type="checkbox"
                      name="prev_bank_verified"
                      checked={
                        Transfer_Existing_Bank_Account.prev_bank_verified ===
                        "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>

              <div className="form-input-group full">
                <label htmlFor="">
                  Reason for account closure:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="reason_closure"
                  value={Transfer_Existing_Bank_Account.reason_closure}
                />
                {errorState?.reason_closure && (
                  <h3 style={{ color: "red" }}>{errorState.reason_closure}</h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Was previous bank aware account was for a cannabis-related
                  business?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="prev_bank_aware"
                      checked={
                        Transfer_Existing_Bank_Account.prev_bank_aware === "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <section
        id="section5"
        className="section5"
        // style={{ display: "block" }}
      >
        <form>
          {/* Electronic Payments Settlement */}
          <div className="epaysettle bg_grey">
            <h3 className="hdng_h3">
              Electronic Payments Settlement | Merchant Processing
            </h3>
            <div className="outr_finptgrp">
              <div className="form-input-group">
                <label htmlFor="">
                  Number of Locations:<span className="red-star">*</span>
                </label>
                {/* <NumberFormat
                  disabled
                  maxLength={12}
                  value={Electronic_Payments_Settlement?.settlement_num_loc}
                  // format={"$##,###,###,###"}
                  // 78,979,797,979,797
                  thousandSeparator={","}
                  // decimalSeparator={"."}
                  // decimalScale={2}

                  name="settlement_num_loc"
                /> */}
                {errorState?.settlement_num_loc && (
                  <h3 style={{ color: "red" }}>
                    {errorState.settlement_num_loc}
                  </h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is the provider a registered MSB?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      name="msb"
                      disabled
                      type="checkbox"
                      checked={Electronic_Payments_Settlement.msb === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the provider have a money transmitter license in the
                  state(s) of operations?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      name="money_transmitted_license"
                      type="checkbox"
                      disabled
                      checked={
                        Electronic_Payments_Settlement.money_transmitted_license ===
                        "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Electronic_Payments_Settlement.types_payments && (
                <div className="form-input-group">
                  <label htmlFor="">
                    Type(s) of payments methods provided:
                    <span className="red-star">*</span>
                  </label>
                  <br />

                  <CreatableSelect
                    isDisabled
                    name="types_payments"
                    defaultValue={Electronic_Payments_Settlement?.types_payments?.map(
                      (i) => {
                        return {
                          value: i,
                          label: i,
                          color: "#0052CC",
                        };
                      }
                    )}
                    isMulti
                    options={Options}
                    onChange={(val) => {
                      console.log(val, "states");
                      const data = val.map((item) => item.value);
                      //  setAcceptedStates([...data])
                      console.log(data, "567");
                      setElectronic_Payments_Settlement({
                        ...Electronic_Payments_Settlement,
                        types_payments: data,
                      });
                    }}
                  />
                </div>
              )}
              <div className="form-group">
                <h5 className="fromgroup_hdng">Mobile delivery application?</h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="mobile_app"
                      checked={
                        Electronic_Payments_Settlement.mobile_app === "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  POS name:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="pos"
                  value={Electronic_Payments_Settlement.pos}
                />
                {errorState?.pos && (
                  <h3 style={{ color: "red" }}>{errorState.pos}</h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the provider allow acceptance of credit card payments?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      name="credit_card"
                      type="checkbox"
                      disabled
                      checked={
                        Electronic_Payments_Settlement.credit_card === "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is the acquiring bank aware they are sponsoring a
                  cannabis-related transaction?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="bank_aware"
                      checked={
                        Electronic_Payments_Settlement.bank_aware === "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Name of merchant processor:<span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="merchant_processor"
                  value={Electronic_Payments_Settlement.merchant_processor}
                />
                {errorState?.merchant_processor && (
                  <h3 style={{ color: "red" }}>
                    {errorState.merchant_processor}
                  </h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is the solution a “cashless ATM” or “Point-of-Banking”
                  terminal?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="terminal"
                      checked={
                        Electronic_Payments_Settlement.terminal === "yes"
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
            </div>
          </div>
          {/* ATM Machine Transactions */}
          <div className="atmmtrancast bg_grey">
            <h3 className="hdng_h3">ATM Machine Transactions</h3>
            <div className="outr_finptgrp">
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Does the business own or lease the ATM(s) on the premises?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      checked={ATM_Machine.atm_own === "yes"}
                      name="atm_own"
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-input-group">
                <label htmlFor="">
                  Location of ATM (e.g., lobby or inside storefront):
                  <span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  value={ATM_Machine.atm_loc}
                  name="atm_loc"
                  disabled
                />
                {errorState?.atm_loc && (
                  <h3 style={{ color: "red" }}>{errorState.atm_loc}</h3>
                )}
              </div>
              <div className="pflex">
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Does the business own or lease multiple ATMs?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        name="atm_multiple"
                        checked={ATM_Machine.atm_multiple === "yes"}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Does the business fill the ATM(s) itself?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        name="atm_fill"
                        checked={ATM_Machine.atm_fill === "yes"}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is the ATM filled by a third-party?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      disabled
                      name="atm_third_party"
                      checked={ATM_Machine.atm_third_party === "yes"}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="pflex">
                <div className="form-input-group c">
                  <label htmlFor="">
                    Name of company or armored service that fills ATM(s)
                    <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    value={ATM_Machine.atm_fill_company}
                    name="atm_fill_company"
                  />
                  {errorState?.atm_fill_company && (
                    <h3 style={{ color: "red" }}>
                      {errorState.atm_fill_company}
                    </h3>
                  )}
                </div>
                <div className="form-input-group c">
                  <label htmlFor="">
                    How much currency are ATM(s) filled with weekly?(USD)
                    <span className="red-star">*</span>
                  </label>
                  {/* <input type="text"
                  // value={ATM_Machine.atm_currency} 
                  defaultValue={Number(ATM_Machine.atm_currency).toFixed(2)}
                  onChange={ATMMachineTransactionsChangeHandler} name='atm_currency' /> */}
                  <CurrencyInput
                    disabled
                    placeholder="$0.00"
                    maxLength={20}
                    value={ATM_Machine.atm_currency}
                    type="text"
                    name="atm_currency"
                  />
                  {errorState?.atm_currency && (
                    <h3 style={{ color: "red" }}>{errorState.atm_currency}</h3>
                  )}
                </div>
              </div>
              {/* <div className="form-input-group">
            <label htmlFor="">
              Does ATM accept or dispense cryptocurrency?
            </label>
            <input type="text" value={ATM_Machine.atm_crypto} onChange={ATMMachineTransactionsChangeHandler} name='atm_crypto' />
            {
                      errorState?.atm_crypto && <h3 style={{ color: 'red' }}>{errorState.atm_crypto}</h3>

                    }
          </div> */}
              <br />
              <div className="form-input-group">
                <label htmlFor="">
                  Does ATM accept or dispense cryptocurrency?
                </label>
                <div className="frm_radio_prnt">
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      disabled
                      value="yes"
                      checked={ATM_Machine.atm_crypto === "yes"}
                      id="Accept"
                      name="atm_crypto"
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Accept" className="radio-button">
                      Yes
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      disabled
                      value="no"
                      checked={ATM_Machine.atm_crypto === "no"}
                      name="atm_crypto"
                      id="Dispense"
                    />
                    {/* <span class="radio"></span> */}
                    <label htmlFor="Dispense" className="radio-button">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="pflex">
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Does the ATM(s) accept deposits?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        name="atm_deposite"
                        checked={ATM_Machine.atm_deposite === "yes"}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Does the ATM(s) dispense receipts rather than cash?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        checked={ATM_Machine.atm_receipt === "yes"}
                        name="atm_receipt"
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Accounting */}
          <div className="accounting bg_grey">
            <h3 className="hdng_h3">Accounting</h3>
            <div className="outr_finptgrp">
              <div className="form-input-group">
                <label htmlFor="">
                  Does the business utilize an accounting system?
                  <span className="red-star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  name="accounting_system"
                  value={Accounting.accounting_system}
                />
                {errorState?.accounting_system && (
                  <h3 style={{ color: "red" }}>
                    {errorState.accounting_system}
                  </h3>
                )}
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Is accounting handled internally or externally through an
                  accountant/firm?
                </h5>
                <div className="form-input-group">
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        disabled
                        value="External"
                        checked={Accounting.accountant === "External"}
                        id="External"
                        name="accountant"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="External" className="radio-button">
                        External
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        disabled
                        value="Internal"
                        checked={Accounting.accountant === "Internal"}
                        name="accountant"
                        id="Internal"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Internal" className="radio-button">
                        Internal
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {Accounting.accountant === "External" && (
                <div className="form-input-group">
                  <label htmlFor="">
                    Accountant/Firm Name:<span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    name="firm_name"
                    value={Accounting.firm_name}
                  />
                  {errorState?.firm_name && (
                    <h3 style={{ color: "red" }}>{errorState.firm_name}</h3>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Compliance Details */}
          {Compliance_Details && (
            <div className="com_dtails bg_grey">
              <h3 className="hdng_h3">Compliance Details</h3>
              <div className="outr_finptgrp">
                <div className="pflex">
                  <div className="form-group">
                    <h5 className="fromgroup_hdng">
                      Has the business ever cited for non-compliance?
                    </h5>
                    <div className="switch_box">
                      <h4>no</h4>
                      <label className="switch2">
                        <input
                          type="checkbox"
                          disabled
                          checked={Compliance_Details.non_compliance === "yes"}
                          name="non_compliance"
                        />
                        <span className="slider round" />
                      </label>
                      <h4>yes</h4>
                    </div>
                  </div>
                  {Compliance_Details.non_compliance === "yes" && (
                    <div className="form-input-group c">
                      <label htmlFor="">
                        If yes, please provide a description:
                        <span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={Compliance_Details.compliance_desc}
                        name="compliance_desc"
                      />
                      {errorState?.compliance_desc && (
                        <h3 style={{ color: "red" }}>
                          {errorState.compliance_desc}
                        </h3>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Business employs in-house Compliance Officer
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        name="compliance_officer"
                        checked={
                          Compliance_Details.compliance_officer === "yes"
                        }
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="pflex">
                  <div className="form-group">
                    <h5 className="fromgroup_hdng">
                      Business engaged a third-party compliance partner.
                    </h5>
                    <div className="switch_box">
                      <h4>no</h4>
                      <label className="switch2">
                        <input
                          type="checkbox"
                          disabled
                          name="compliance_partner"
                          checked={
                            Compliance_Details.compliance_partner === "yes"
                          }
                        />
                        <span className="slider round" />
                      </label>
                      <h4>yes</h4>
                    </div>
                  </div>
                  {Compliance_Details.compliance_partner === "yes" && (
                    <div className="form-input-group c">
                      <label htmlFor="">
                        If yes, please provide name of entity and services
                        provided:
                        <span className="red-star">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={Compliance_Details.compliance_partner_name}
                        name="compliance_partner_name"
                      />
                      {errorState?.compliance_partner_name && (
                        <h3 style={{ color: "red" }}>
                          {errorState.compliance_partner_name}
                        </h3>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Busines has auditor/compliance group to conduct on-site
                    inspections:
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        disabled
                        name="onsite_inspection"
                        checked={
                          Compliance_Details?.onsite_inspection === "yes"
                        }
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-input-group">
                  <label htmlFor="">
                    Date of most recently completed on-site inspection:
                    <span className="red-star">*</span>
                  </label>
                  <input
                    type="date"
                    disabled
                    value={Compliance_Details?.compliance_date}
                    className="form-control"
                    name="compliance_date"
                  />
                  {errorState?.compliance_date && (
                    <h3 style={{ color: "red" }}>
                      {errorState.compliance_date}
                    </h3>
                  )}
                </div>
                <div className="form-input-group w100">
                  <label>
                    Does the onsite inspection support the structure, security,
                    operations, staff, and stated purpose of the business and
                    intended activity with the Bank?
                  </label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="yes"
                        id="yes1"
                        disabled
                        name="compliance_support"
                        checked={
                          Compliance_Details.compliance_support === "yes"
                        }
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="yes1" className="radio-button">
                        {" "}
                        Yes
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="no"
                        id="no1"
                        disabled
                        name="compliance_support"
                        checked={Compliance_Details.compliance_support === "no"}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="no1" className="radio-button">
                        {" "}
                        No
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="unsure"
                        id="unsure1"
                        disabled
                        name="compliance_support"
                        checked={
                          Compliance_Details.compliance_support === "unsure"
                        }
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="unsure1" className="radio-button">
                        Unsure
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-input-group">
                  <label htmlFor="">Frequency of compliance audits:</label>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Quaterly"
                        disabled
                        id="yes1"
                        name="frequency_compliance"
                        checked={
                          Compliance_Details.frequency_compliance ===
                          "Quarterly"
                        }
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="yes1" className="radio-button">
                        {" "}
                        Quaterly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Bi-Monthly"
                        disabled
                        id="no1"
                        name="frequency_compliance"
                        checked={
                          Compliance_Details.frequency_compliance ===
                          "Bi-Monthly"
                        }
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="no1" className="radio-button">
                        {" "}
                        Bi-Monthly
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Annually"
                        id="unsure1"
                        disabled
                        name="frequency_compliance"
                        checked={
                          Compliance_Details.frequency_compliance === "Annually"
                        }
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="unsure1" className="radio-button">
                        Annually
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pflex">
                  <div className="form-group">
                    <h5 className="fromgroup_hdng">
                      Compliance Training conducted?
                    </h5>
                    <div className="switch_box">
                      <h4>no</h4>
                      <label className="switch2">
                        <input
                          type="checkbox"
                          disabled
                          checked={
                            Compliance_Details.compliance_trainning === "yes"
                          }
                          name="compliance_trainning"
                        />
                        <span className="slider round" />
                      </label>
                      <h4>yes</h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <h5 className="fromgroup_hdng">
                      Standard Operating Procedures in place?
                    </h5>
                    <div className="switch_box">
                      <h4>no</h4>
                      <label className="switch2">
                        <input
                          type="checkbox"
                          disabled
                          checked={
                            Compliance_Details.operating_procedures === "yes"
                          }
                          name="operating_procedures"
                        />
                        <span className="slider round" />
                      </label>
                      <h4>yes</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  Are you interested in any of the following services */}
          <div className="fservc bg_grey">
            <h3 className="hdng_h3">
              Are you interested in any of the following services:
              <span className="red-star">*</span>
            </h3>
            <div className="check_form-group">
              <div className="check_checking">
                <input
                  type="checkbox"
                  id="icheck2"
                  name="pg-bx"
                  disabled
                  checked={Following_Services?.includes(
                    "Payment Processing - Credit Card"
                  )}
                  value="Payment Processing - Credit Card"
                />
                <label htmlFor="icheck2">
                  Payment Processing - Credit Card
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes(
                    "Payment Processing - Debit Card"
                  )}
                  value="Payment Processing - Debit Card"
                />
                <label> Payment Processing - Debit Card </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes(
                    "Payment Processing - PIN Debit"
                  )}
                  value="Payment Processing - PIN Debit"
                />
                <label> Payment Processing - PIN Debit </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  disabled
                  name="pg-bx"
                  checked={Following_Services.includes(
                    "Payment Processing - Cashless ATM"
                  )}
                  value="Payment Processing - Cashless ATM"
                />
                <label> Payment Processing - Cashless ATM </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes(
                    "Payment Processing - ACH Processing"
                  )}
                  value="Payment Processing - ACH Processing"
                />
                <label> Payment Processing - ACH Processing </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes(
                    "Payment Processing - Crypto Payments"
                  )}
                  value="Payment Processing - Crypto Payments"
                />
                <label> Payment Processing - Crypto Payments </label>
              </div>
              {/* <div className="check_checking">
              <input type="checkbox" />
              <label> Payment Processing - Crypto Payments </label>
            </div> */}
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("Lending Services")}
                  value="Lending Services"
                />
                <label> Lending Services </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("Insurance Services")}
                  value="Insurance Services"
                />
                <label> Insurance Services </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("Payroll")}
                  value="Payroll"
                />
                <label> Payroll </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("Benefits")}
                  value="Benefits"
                />
                <label> Benefits </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("401K")}
                  value="401K"
                />
                <label> 401K </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("Human Resources")}
                  value="Human Resources"
                />
                <label> Human Resources </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes(
                    "Billboard / Outdoor Advertising"
                  )}
                  value="Billboard / Outdoor Advertising"
                />
                <label> Billboard / Outdoor Advertising </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="pg-bx"
                  disabled
                  checked={Following_Services.includes("ERC Processing")}
                  value="ERC Processing"
                />
                <label> ERC Processing </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  disabled
                  name="pg-bx"
                  checked={Following_Services.includes(
                    "Compliance complitency"
                  )}
                  value="Compliance complitency"
                />
                <label> Compliance complitency </label>
              </div>
            </div>
          </div>
        </form>
      </section>
      <div className="button-container">
        <button className="btn btn-next" type="">
          Save
        </button>
      </div>
    </div>
  );
};

export default OperatorApplicationDetails;
