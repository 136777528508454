import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { current } from '@reduxjs/toolkit'
import { Get_BankDashboard_URL, Get_OperatorDashboard_URL } from "../Api/Api";

//Action:-
export const fetchDashBoardDataBank  = createAsyncThunk('fetchDashBoardDataBank', async () => {
    
return  await axios.get(Get_BankDashboard_URL, {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    })
    
})

export const fetchDashBoardDataOperator  = createAsyncThunk('fetchDashBoardDataOperator', async () => {
    
return await axios.get(Get_OperatorDashboard_URL, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
        
    })



const DashboardSlice = createSlice(
    {
        name: 'Dashboard',
        initialState: {
            isLoading: false,
            data: null,
            isError: false,
            message: null
        },
        extraReducers: {
            [fetchDashBoardDataBank.pending]: (state, action) => {
                state.isLoading = true;
                state.data = null;
            },
            [fetchDashBoardDataBank.fulfilled]: (state, action) => {
                console.log(action.payload, 'action.payload')
                state.isLoading = false;
                state.data = action?.payload?.data;
            },

            [fetchDashBoardDataBank.rejected]: (state, action) => {
                console.log(action.payload, 'action.payload23')
                state.isError = true;
            },
            [fetchDashBoardDataOperator.pending]: (state, action) => {
                state.isLoading = true;
                state.data = null;
            },
            [fetchDashBoardDataOperator.fulfilled]: (state, action) => {
                console.log(action.payload, 'action.payloadOp')
                state.isLoading = false;
                state.data = action?.payload?.data;
            },

            [fetchDashBoardDataOperator.rejected]: (state, action) => {
                console.log(action.payload, 'action.payload23')
                state.isError = true;
            },

            
            
        }
    }
)

export default DashboardSlice.reducer;
// export {BankSlice,RandomSlice}