import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GetAllBank_Approved_Operators_URL } from '../../Api/Api'
import { useSearchParams } from 'react-router-dom'

const ApprovedApplications = () => {
    const [approvedApplications,setApprovedApplications] =useState([])
    useEffect(()=>{
  axios.get(`${GetAllBank_Approved_Operators_URL}`,{
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }).then(res=>{
    console.log(res.data,'approvedlist')
    setApprovedApplications(res.data)
  }).catch(err=>{
    console.log(err,'error')
  })
    },[])
  return (
    <div className='mainsection bnk_sctn'>
    <div className="bank_content">
 <div className="cmn_hdr">
   <h2 style={{fontWeight:'bold',fontSize:'16rem'}}>Approved Applications List</h2>
 </div>
 <table>
   <tbody>
     <tr>
       {/* <th>Name</th> */}
       <th>Application ID</th>
 
       <th>Approved On</th>
     </tr>
    {
     approvedApplications && approvedApplications.map((application,index)=>{
       return  <tr>
       {/* <td>Application {index + 1}</td> */}
       <td>{application.application_Id}</td>
      
       <td>
         {/* <div className="status">
           <div className="status__light">
             <div className="status__light__ring" />
             <div className="status__light__led" />
           </div>
           <div className="status__message">{}</div>
         </div> */}
         {application.proposalApprovedOn}
       </td>
     </tr>
     
     })
    }
   </tbody>
 </table>
</div>
   </div>
  )
}

export default ApprovedApplications