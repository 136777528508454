import React from 'react'

const BusinessSetupDetails = () => {
  return (
    <div className="">
                <div className="content" style={{ borderRadius: '20px' }}>
                  <h4>Business Set-Up</h4>
                  <div className="">
                    <div className="">
               
                      <div className="cmn_hdr">
                  {/* <h6>Company Documentation</h6> */}
                  <div className='business_box'><h6>Services Offered:</h6>
                    <div className="pg3-mn-otrfld">
                    
                 
                    <table> 
                    <tbody>
                             <tr>
                             <th>Service</th>
                              <th>Price</th>
                              <th>Select type</th>
                             </tr>
                            </tbody>   
                       
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Business Checking</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                                 <br/> <br/>
                                 <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />%
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
                         <label>Per Month</label>
                         <label>Per Deposite</label>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Interest Bearing Accounts</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />%
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
               <label>Paid By Bank</label>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Debit Cards</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
              <div className="">
               <label>Onetime</label>
              </div>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>ACH</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
              <div className="">
             
               <div className="">
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="Per"
                    // checked={Cash_Management_Bank.vendor_payment_methods === 'ACH'}
                    // name={service.service}
                    value='Per Month'
                    //  onChange={(event)=>{TypeChangehandler(event,service.service)}}
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="Per Month" className="radio-button">
                    {" "}
                   Per Month
                  </label>
                  <br/><br/>
                  
                </div>
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="onetime"
                    // checked={modifiedServices.map(x=>x.type).includes(service.type)}
                     defaultChecked
                    // name={service.service}
                    value='Per ACH' 
                    // onChange={(event)=>{TypeChangehandler(event,service.service)}}
                     />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="Per ACH" className="radio-button">
                    {" "}
                    Per ACH
                  </label>
                </div>
                
              </div>
              </div>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Wires</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
              <div className="">
             
               <div className="">
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="Per"
                    // checked={Cash_Management_Bank.vendor_payment_methods === 'ACH'}
                    // name={service.service}
                    value='Per Month'
                    //  onChange={(event)=>{TypeChangehandler(event,service.service)}}
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="Per Month" className="radio-button">
                    {" "}
                   Per Month
                  </label>
                  <br/><br/>
                  
                </div>
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="onetime"
                    // checked={modifiedServices.map(x=>x.type).includes(service.type)}
                     defaultChecked
                    // name={service.service}
                    value='Per Wire' 
                    // onChange={(event)=>{TypeChangehandler(event,service.service)}}
                     />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="Per Wire" className="radio-button">
                    {" "}
                    Per Wire
                  </label>
                </div>
                
              </div>
              </div>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Accounts For Employees</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
              <div className="">
             
               <div className="">
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="Per"
                    // checked={Cash_Management_Bank.vendor_payment_methods === 'ACH'}
                    // name={service.service}
                    value='Per Month'
                    //  onChange={(event)=>{TypeChangehandler(event,service.service)}}
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="Per Month" className="radio-button">
                    {" "}
                   Per Month
                  </label>
                  <br/><br/>
                  
                </div>
                <div className="form_radio_group">
                  <input type="radio" defaultValue="" id="onetime"
                    // checked={modifiedServices.map(x=>x.type).includes(service.type)}
                     defaultChecked
                    // name={service.service}
                    value=' Per Account' 
                    // onChange={(event)=>{TypeChangehandler(event,service.service)}}
                     />
                  {/* <span class="radio"></span> */}
                  <label htmlFor=" Per Account" className="radio-button">
                    {" "}
                    Per Account
                  </label>
                </div>
                
              </div>
              </div>
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Cash Pick Up Services</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" >
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />%
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
       
             
               <div className="">
                <label>Per Amount</label>
              </div>
            
            </div>
            
            </td>
                            </tr>
                            <tr>
                              <td>  <div className="pg3-mn-inrfld" >
                               <input type="checkbox" name="pg-bx"
                                // defaultValue={service.service}
                            //    checked={modifiedServices.map(x=>x.service).includes(service.service)}
                            //     onChange={(event)=>{BusinessSetUpDataChangeHandler(event)}}
                              />
                              <label>Legacy Cash</label>
    
                           </div></td>
                            
                               <td><div className='price_box' >
                               {/* <h5>Price</h5> */}
                              <div className="" style={{display:'flex'}}>
                                <input type="number" 
                            //   defaultValue={ValueDeterminant(service.service)}
                            //   disabled={!modifiedServices.map(x=>x.service).includes(service.service)}
                                 placeholder="price" required
                                //   name={service.service} 
                                //   onChange={(event)=>{PriceChangeHandler(event)}}
                                 />
                                 %
                               </div>
                               {/* <h3 style={{ color: 'red' }}>{errorState}</h3> */}
                             </div></td>
                             <td>
                              <div className="form-input-group">
           
       
             
               <div className="">
                <label>Per Transaction</label>
              </div>
            
            </div>
            
            </td>
                            </tr>
                      </table>
                    
                    </div>
                  </div>
                  <br />
                  
                </div>
                    </div>

                  </div>
                  <div className="footer">
                    {/* <button  className='notification_btn' style={{marginLeft:"40%"}}
                      onClick={() => {
                        setInd(services.length)
                        setNewEntry(services.length)
                        setServices([...services, {}])
                      }}
                    >Add New</button> */}
                    <button className='notification_btn'
                    //   onClick={() => {
                        
                        
                    //     const finalData = modifiedServices.map((a)=>{
                    //       for(let x in names){
                    //         if(a.service === x){
                    //           a.price = names[x]
                    //         }
                    //       }
                    //        return a;
                    //     })


                    //     console.log(finalData, 'finalData')
                    //     console.log(types,'finalData')
                    //     console.log(targetVal,'finalData')
                    //     const keys = Object.keys(targetVal)
                    //     console.log(keys,'finalData')
                    //     const test = finalData.map((c,index)=>{
                    //           if(keys.includes(c.service)){
                    //             let i = keys.indexOf(c.service)
                              
                    //             //  c.type =  `${types[keys[index]]} + ${targetVal[keys[index]]}`
                    //             if(types[keys[i]] === 'Per'){
                    //               c.type = `Per ${targetVal[keys[i]]}`
                    //             }
                    //           }else{
                    //             c.type = 'onetime'
                    //           }
                    //           return c
                    //     })
                    //     console.log(test,'datatosendtoapi')
                    //     // if (finalData.length !==0 && finalData.every(o=>o.price !== ('' || undefined))) {

                    //     //   axios.post(Fill_BusinessSetUp_URL, {
                    //     //     servicesOffered: finalData
                    //     //   }, {
                    //     //     headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                    //     //   }).then(res => {
      
                    //     //     console.log(res, '70000')
                    //     //     swal('Saved!', 'Set Up updated succesfully', 'success')
                    //     //   })
      
                    //     // } 
                    //     // if(finalData.length ===0){
                         
                    //     //   swal('Oops!', 'Please choose atleast one!', 'error', {
                    //     //     buttons: {
      
                    //     //       // roll: {
                    //     //       //   text:'Ok',
                    //     //       //   className:'deny-button-class',
                    //     //       // },
                    //     //       // roll: {
                    //     //       //   text: "Do a barrell roll!",
                    //     //       //   value: "roll",
                    //     //       // },
                    //     //     },
                    //     //   });
                    //     // }
                    //     // if(!finalData.every(o=>o.price !== ('' || undefined))){
                    //     //   swal('Oops!', 'Amount Can,t be empty!', 'error', {
                    //     //     buttons: {
      
                    //     //       // roll: {
                    //     //       //   text:'Ok',
                    //     //       //   className:'deny-button-class',
                    //     //       // },
                    //     //       // roll: {
                    //     //       //   text: "Do a barrell roll!",
                    //     //       //   value: "roll",
                    //     //       // },
                    //     //     },})
                    //     // }
      
      
      

                    //   }}
                      
                      color="primary" >
                      Submit
                    </button>
                    {' '}

                  </div>
                </div>
              </div>
  )
}

export default BusinessSetupDetails