import React from 'react'
import '../Modal/Modal.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import { ResetPassword_URL } from '../../Api/Api'
import swal from 'sweetalert'

const ResetPassword = () => {
    const [resetpassword,setResetPassword] = useState({
        password : '',role:''
    })
    const navigate = useNavigate()
    const {resetToken} = useParams()
    console.log(resetToken,'fromhere')
    const ResetPasswordFunction =()=>{
        console.log('hit')
    
    }
    const ResetPasswordChangeHandler = (event)=>{
          setResetPassword({
            ...resetpassword,[event.target.name]: event.target.value
          })
    }
  return (
    <div className='mainsection bnk_sctn'>
    <div className='modal'
    >
      <div className="content-passwordreset" style={{ borderRadius: '20px' }}>
        <h4>Reset Password</h4>
        <div className="">
          <div className="">
            <div className="cmn_hdr" style={{ marginLeft: '30%' }}>
              <div className="inpt_bx">
                <label>Enter New Password: &nbsp;</label>
                <input type="password"
                 onChange={ResetPasswordChangeHandler}
                  name='password' placeholder="Password" required />
              </div>
              <br />
                <div className="inpt_bx">


                  <div className="form-input-group">
                    <label>Choose role:</label>
                    <div className="frm_radio_prnt">
                      <div className="form_radio_group">
                        <input type="radio" defaultValue="" id="ACH1"
                         onChange={ResetPasswordChangeHandler}
                          name="role"
                          value='bank'
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="ACH1" className="radio-button">
                          {" "}
                          Bank
                        </label>
                      </div>
                      <div className="form_radio_group">
                        <input type="radio" defaultValue="" id="ACH1"
                         onChange={ResetPasswordChangeHandler}

                          name="role"
                          value='operator'
                        />
                        {/* <span class="radio"></span>  */}
                        <label htmlFor="ACH1" className="radio-button">
                          {" "}
                          Operator
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
          </div>

        </div>
        <div className="footer">
          {/* <button type='submit' className='notification_btn ' style={{marginLeft:'28%'}}
             
              color="primary" >
              Change
            </button>{' '} &nbsp; */}
          <button type='' className='notification_btn ' 
            onClick={()=>{
                console.log('click')
                axios.put(`${ResetPassword_URL}/${resetToken}`,{
                    password: resetpassword.password
                },{
                    params : {
                        role: resetpassword.role
                    }
                }).then(res=>{
                    swal('Done',`${res.data.message}`,'success')
                    navigate('/login')
                    console.log(res,'788888')
                }).catch(err=>{
                    swal('Done',`${err.response.data.message}`,'error')
                    if(resetpassword.password !== '' && resetpassword.role !== ''){
                        navigate('/login')
                    }
                    console.log(err,'6712')
                })
            }}
            color="primary" >
           Reset
          </button>{' '}
        </div>
      </div>
    </div>
  </div>
  )
}

export default ResetPassword