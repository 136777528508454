import React from 'react'
import '../Modal/Modal.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import { Operaor_BusinessSetUp_URL } from '../../Api/Api'
import swal from 'sweetalert'
const ServicesWanted = () => {
    const navigate = useNavigate()
const [servicesWanted,setServicesWanted] = useState([])
    const Submitserviceswanted =()=>{
        console.log(servicesWanted,'check7777')
        axios.post(Operaor_BusinessSetUp_URL,{
          servicesWanted : servicesWanted
        },{
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).then(res=>{
          console.log(res.data,'servicesWanted')
          swal('Added',`${res.data.message}`,'success')
          localStorage.setItem('access', true)
          setTimeout(()=>{
            window.location.reload()
          },[2000])
        }).catch(err=>{
          swal('Oops!',`${err.response.data.message}`,'error')
        })
    }
    const ChangeHandler =(event)=>{
      if(event.target.checked){
        const data = [...servicesWanted,event.target.value]
        setServicesWanted([...data])
      }else{
        const data = servicesWanted.filter(x=> x !== event.target.value)
        setServicesWanted([...data])
      }
    }
  return (
    <div className='mainsection bnk_sctn'>
      <div className='modal'
      >
        <div className="content-passwordreset" style={{ borderRadius: '20px' }}>
         
         
              <div className="cmn_hdr" style={{ marginLeft: '30%' }}>
              <div className='business_box'><h6>Services Wanted:</h6>
                    <div className="pg3-mn-otrfld">
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld" >
                          <input type="checkbox" name="pg-bx" value='Business Checking'
                            // checked={businessSetUp.servicesOffered.includes('Business Checking')}
                            onChange={ChangeHandler}
                          />
                          <label>Business Checking</label>

                        </div>
                        
                      </div>
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx"
                            // checked={businessSetUp.servicesOffered.includes('Interest Bearing Accounts')}
                            onChange={ChangeHandler}
                            value='Interest Bearing Accounts' />
                          <label>Interest Bearing Accounts</label>

                        </div>
                       
                      </div>
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx" 
                          // checked={businessSetUp.servicesOffered.includes('Debit Cards')}
                          onChange={ChangeHandler}
                            value='Debit Cards'
                          />
                          <label>
                            Debit Cards
                          </label>

                        </div>
                        
                      </div>
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx"
                          //  checked={businessSetUp.servicesOffered.includes('ACH')}
                          onChange={ChangeHandler}
                            value='ACH'
                          />
                          <label>ACH</label>

                        </div>
                        
                      </div>
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx"
                          //  checked={businessSetUp.servicesOffered.includes('Wires')}
                          onChange={ChangeHandler}
                            value='Wires'
                          />
                          <label>
                            Wires
                          </label>

                        </div>
                        
                      </div>
                      <div className='check__in__bx'>

                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx" 
                          // checked={businessSetUp.servicesOffered.includes('Discount for Social Equity')}
                          onChange={ChangeHandler}
                            value='Discount for Social Equity'
                          />
                          <label>
                            Discount for Social Equity
                          </label>

                        </div>
                        
                      </div>
                      <div className='check__in__bx'>
                        <div className="pg3-mn-inrfld">
                          <input type="checkbox" name="pg-bx" 
                          // checked={businessSetUp.servicesOffered.includes('Accounts for Employees')}
                          onChange={ChangeHandler}
                            value='Accounts for Employees'
                          />
                          <label>
                            Accounts for Employees
                          </label>

                        </div>
                        
                      </div>
                    </div>
                  </div>
              </div>
            
          <div className="footer" style={{marginLeft:'30%'}}>
            {/* <button type='submit' className='notification_btn ' style={{marginLeft:'28%'}}
               
                color="primary" >
                Change
              </button>{' '} &nbsp; */}
            <button type='' className='notification_btn ' 
              onClick={Submitserviceswanted}
              color="primary" >
              Submit
            </button>{' '}

            <button type='' className='notification_btn ' 
              onClick={()=>{
                localStorage.clear()
                navigate('/login')
            }}
              color="primary" >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesWanted