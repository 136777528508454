import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { OperatorApprove_Proposal_URL, OperatorDecline_Proposal_URL, OperatorSingleProposalGet_URL } from '../../Api/Api'
import { useState } from 'react'
import swal from 'sweetalert'

const SeeServicesOffered = () => {
    const {applicationId,proposalId} = useParams()
    console.log(applicationId,proposalId,'proposalId')
    const navigate = useNavigate()
    const [services,setServices] = useState()
    const [isLoading,setIsLoading]=useState(false)
    const [approved,setApproved] = useState(true)
    useEffect(()=>{
    axios.get(`${OperatorSingleProposalGet_URL}/${applicationId}/${proposalId}`,{
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      }).then((res)=>{
        console.log(res.data,'7777')
        setServices(res.data.servicesOffered)
        setApproved(res.data.proposalAccepted)
    }).catch((err)=>{
        console.log(err,'err')
    })
    },[])
    const ApproveProposal =()=>{
        swal({
            title: 'Are you sure you want to approve the proposal?',
            buttons: {
                confirm: 'YES',
                cancel: 'NO'
            },
            type: "success"
      
        }).then((okay) => {
            if (okay) {
              setIsLoading(true)
                axios.get(`${OperatorApprove_Proposal_URL}/${applicationId}/${proposalId}`,{
                  headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                }).then(res=>{
                  setIsLoading(false)
                  swal('Done!',`${res.data.message}`,'success')
                }).catch(err=>{
                  console.log(err)
                  swal('Oops!',`${err.response.data.message},'error`)
                })
                 
                 
                
            }else{
              // setActivitystatus('No')
            }
        })
    }
    const DeclineProposal =()=>{
        swal({
            title: 'Are you sure you want to decline the proposal?',
            buttons: {
                confirm: 'YES',
                cancel: 'NO'
            },
            type: "success"
      
        }).then((okay) => {
            if (okay) {
              
                axios.get(`${OperatorDecline_Proposal_URL}/${applicationId}/${proposalId}`,{
                  headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                }).then(res=>{
                  swal('Done!',`${res.data.message}`,'success')
                }).catch(err=>{
                  console.log(err,'error')
                  swal('Oops!',`${err.response.data.message}`)
                })
                 
                 
                
            }else{
              // setActivitystatus('No')
            }
        })
    }
  return (
    <div className='mainsection bnk_sctn'>
        <div className="bank_content">
        
       {  !approved && <div className="cmn_hdr">
    <h2  style={{marginBottom:'1px'}}>Services Offered</h2>
    <button style={{ float: 'right' }} className='notification_btn' type="button" 
              onClick={DeclineProposal}
              >Decline</button>
        <button style={{ marginLeft:'90rem'}} className='notification_btn' type="button" 
              onClick={ApproveProposal}
              >Approve</button>
  </div>}
  <br/>
  <table>
    <tbody>
      <tr>
        {/* <th>Name</th> */}
        <th>Service</th>
        <th>Price</th>
        
      </tr>
     {
      services && services.map((service,index)=>{
        return  <tr>
        {/* <td>Application {index + 1}</td> */}
        <td>{service.service}</td>
        <td>${service.price}</td>
        
      </tr>
      
      })
     }
    </tbody>
  </table>
        </div>
        <br/>
        <button style={{ marginLeft:'60rem'}} className='notification_btn' type="button" 
              onClick={()=>{
                navigate(-1)
              }}
              >Go back</button>
    </div>
  )
}

export default SeeServicesOffered