import React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OperatorApplicationsList_URL } from "../../Api/Api";
import { useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsEyeFill, BsToggleOn } from "react-icons/bs";
const ProposalsReceived = () => {
  const [myApplications, setMyApplications] = useState([]);
  useEffect(() => {
    axios
      .get(OperatorApplicationsList_URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        console.log(response.data, "myApplicationsproposal");
        setMyApplications(response.data);
      })
      .catch((err) => console.log(err, "err"));
  }, []);
  return (
    <div className="mainsection bnk_sctn">
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2>Offers List</h2>
          <br />
        </div>
        <div className="outr_table">
          {" "}
          <table>
            <tbody>
              <tr>
                {/* <th>Name</th> */}
                {/* <th>Application ID</th> */}
                <th>DBA</th>
                <th>Created at</th>
                <th> No. of Proposals Received</th>
                <th>See Details</th>
              </tr>
              {myApplications &&
                myApplications.map((application, index) => {
                  return (
                    <tr>
                      {/* <td>Application {index + 1}</td> */}
                      {/* <td>{application.application_Id}</td> */}
                      <td>{application.dba}</td>
                      <td>{application.createdAt}</td>
                      <td>{application.proposal}</td>
                      <td>
                        <div className="status">
                          {/* <div className="status__light">
                      <div className="status__light__ring" />
                      <div className="status__light__led" />
                    </div>
                    
                    <div className="status__message">Send Request</div> */}
                          {/* <button className='notification_btn'
                   style={{borderRadius:'10rem'}}
                   >SEND  <IoMdSend/></button> */}
                          {/* <IoMdSend/> */}
                          <div
                            style={{
                              marginLeft: "2rem",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to={`/offers/${application.application_Id}`}
                            >
                              <BsEyeFill />
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProposalsReceived;
