import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBank_Profile } from "../Api/Api";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import swal from "sweetalert";
import { CleanUpStore } from "../Store/userSlice";

const CommonHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [targetRef]);
  function handleClickOutside(event) {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      // alert("You clicked outside of me!");
      setShow(false);
    }
  }

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState();
  // useEffect(() => {
  //   setIsLoading(true)
  //   axios.get(GetBank_Profile, {
  //     headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  //   }).then(res => {
  //     setIsLoading(false)
  //     console.log(res.data, 'profiledata')
  //     setUserInfo(res.data)
  //   })
  // }, [])
  const location = useLocation();
  return (
    <div className="rghtTp_hdr">
      <div className="srch_br">
        <button className="srchBtn">
          <img src={require("../Assets/images/search_btn.png")} alt="" />
        </button>
        <input className="srch_cntnt" type="text" />
      </div>
      <div className="rghtTP_RghtOtr">
        {/* <div className="ntfctn_bl">
      <a href="javascript:void(0);">
        <img src="./images/bell.png" alt="" />
      </a>
    </div> */}
        <div className="prflDtl">
          <div className="img_otr">
            {/* <img src={require("../Assets/images/prfl_pic.png")} alt="" /> */}
            {/* <img src={userInfo && userInfo.bankLogo} alt="" /> */}
            <img src={localStorage.getItem("logo")} alt="" />
          </div>
          <p>
            &nbsp;{" "}
            {location.pathname.includes("admin")
              ? "ADMIN"
              : localStorage.getItem("name")}
          </p>
          <div className="prflDrpDwn">
            <div className="drop-down">
              <div className="selected">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  {userInfo && userInfo.bankName}
                </a>
              </div>
              {show && (
                <div className="options" ref={targetRef}>
                  <ul style={{ borderRadius: "10px" }}>
                    {/* <li>
                <a href="javascript:void(0);">Log In</a>
              </li> */}
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/profile")}
                      >
                        Profile
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Settings</a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          // Swal.fire({
                          //     title: 'Are you sure you want to log out?',
                          //     showDenyButton: true,
                          //     // showCancelButton: true,
                          //     confirmButtonText: 'YES',
                          //     confirmButtonColor:'#ba8c4a',
                          //     denyButtonText: 'NO',
                          //     // className:'confirm-button-class'
                          //     denyButtonColor:'gray',
                          //     customClass:{
                          //          actions: 'my-actions',
                          //         // cancelButton: 'cancel-button-class',
                          //         confirmButton: 'confirm-button-class',
                          //         denyButton: 'deny-button-class',
                          //     },

                          //     // }
                          //   }).then((result) => {
                          //     if (result.isConfirmed) {
                          //        localStorage.clear()
                          //       navigate('/login')
                          //     } else if (result.isDenied) {
                          //     //   Swal.fire('Changes are not saved', '', 'info')
                          //     }
                          //   })

                          swal({
                            title: "Are you sure you want to log out?",
                            className: "custom-content",
                            buttons: {
                              confirm: {
                                text: "YES",
                                className: "sweet-warning",
                              },
                              cancel: "NO",
                              // confirmButtonColor: '#8CD4F5',
                            },
                            type: "success",
                          }).then((okay) => {
                            if (okay) {
                              localStorage.clear();
                              sessionStorage.clear();
                              window.location.reload();
                              // dispatch(CleanUpStore())
                              navigate("/login");
                            } else {
                              // setActivitystatus('No')
                            }
                          });
                        }}
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
