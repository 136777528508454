import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  OperatorProposalbankDetails_URL,
  OperatorProposalsListGet_URL,
  OperatorSingleApplication_URL,
} from "../../Api/Api";
import { BsEyeFill } from "react-icons/bs";
import { FcApproval, FcDisapprove, FcFinePrint } from "react-icons/fc";
import { GiSandsOfTime } from "react-icons/gi";
import { IconContext } from "react-icons";
import swal from "@sweetalert/with-react";
import { FadeLoader } from "react-spinners";
import { ThreeDots } from "react-loader-spinner";
const Proposals = () => {
  const [proposals, setProposals] = useState([]);
  const { applicationId } = useParams();
  const [show, setShow] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  console.log(state, "from-state");
  const targetRef = useRef(null);
  console.log(targetRef, "targetRef");
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [targetRef]);
  function handleClickOutside(event) {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      // alert("You clicked outside of me!");
      setShow(false);
    }
  }
  console.log(applicationId, "applicationId");
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${OperatorProposalsListGet_URL}/${applicationId}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "targetproposallist");
        setProposals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const ShowBankDetails = (eligibility, proposalId) => {
    if (eligibility) {
      swal({
        content: (
          <div className="normal-swal">
            {/* <IconContext.Provider ><FadeLoader color={'#b07f35'}/></IconContext.Provider> */}
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#b07f35"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ),
        buttons: false,
        //  className:'normal-swal'
      });
      axios
        .get(
          `${OperatorProposalbankDetails_URL}/${applicationId}/${proposalId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data, "1954729357");
          swal({
            buttons: {
              confirm: { text: "OK", className: "sweet-warning-custom" },
            },
            content: (
              <table>
                <tr>
                  <th>Name</th>
                  <th>Contact Number</th>
                  <th>Address</th>
                  <th>Zipcode</th>
                  <th>E-Mail</th>
                </tr>
                <tr>
                  <td>{res.data?.bankName}</td>
                  <td>{res.data?.bankContactNumber}</td>
                  <td>{res.data?.bankAddress}</td>
                  <td>{res.data?.bankZip}</td>
                  <td>{res.data?.email}</td>
                </tr>
              </table>
            ),
            className: "custom-content",
          });
        })
        .catch((err) => console.log(err, "myerror"));
    } else {
      swal({
        title: "You haven't accepted the proposal yet!",
        className: "custom-content",
        buttons: {
          confirm: { text: "ACCEPT", className: "sweet-warning" },
          cancel: "NO",
          // confirmButtonColor: '#8CD4F5',
        },
        type: "error",
      }).then((okay) => {
        if (okay) {
          navigate(`/proposals/${applicationId}/${proposalId}`);
        }
      });
    }
  };
  return (
    <div className="mainsection bnk_sctn">
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2>Offers List</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              float: "right",
            }}
          >
            {/* <p>{header}</p> */}
            <div className="calendar" ref={targetRef}>
              <h3 className="srange" onClick={() => setShow(!show)}>
                Filter Application By Date
              </h3>
              {show && (
                <DateRange
                  id="daterange"
                  onChange={(item) => {
                    console.log(item.selection.startDate, "datee");
                    console.log(item.selection.endDate, "datee");
                    const startDate = new Date(
                      item.selection.startDate
                    ).toISOString();
                    const endDate = new Date(
                      item.selection.endDate
                    ).toISOString();
                    // console.log(
                    //   item.selection.startDate.toString().split(" "),
                    //   "startdate"
                    // );
                    // const resultStartDate = item.selection.startDate
                    //   .toString()
                    //   .split(" ");
                    // const resultEndDate = item.selection.endDate
                    //   .toString()
                    //   .split(" ");
                    // console.log(resultStartDate, resultEndDate, "8888888");
                    // const startDate = `${dateMod[resultStartDate[1]]}-${
                    //   resultStartDate[2]
                    // }-${resultStartDate[3]}`;
                    // const endDate = `${dateMod[resultEndDate[1]]}-${
                    //   resultEndDate[2]
                    // }-${resultEndDate[3]}`;
                    // console.log(startDate, "8888888");
                    // console.log(endDate, "8888888");
                    // {
                    //   params: {
                    //     startDate: startDate;
                    //     endDate: endDate;
                    //   }
                    // }
                    if (startDate !== endDate) {
                      setShow(false);
                    }
                    axios
                      .get(`${OperatorProposalsListGet_URL}/${applicationId}`, {
                        params: {
                          startDate: startDate,
                          endDate: endDate,
                        },
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                      .then((res) => {
                        console.log(res.data, "date-filteration");
                        setProposals(res.data);
                      })
                      .catch((err) => {
                        console.log(err, "error");
                      });

                    setState([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
              )}
            </div>
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              {/* <th>Name</th> */}
              <th>Name</th>
              <th>Send at</th>
              <th>Proposal Acceptance Status</th>
              <th>See Offered Services</th>
              <th>Account Opened</th>
              <th>Bank Details</th>
            </tr>
            {proposals &&
              proposals?.map((proposal, index) => {
                return (
                  <tr>
                    {/* <td>Application {index + 1}</td> */}
                    <td>Proposal {proposals.length - index}</td>
                    <td>
                      {new Date(proposal.proposalSentOn).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    <td>
                      {proposal.proposalAccepted ? (
                        <IconContext.Provider
                          value={{ color: "blue", size: "32px" }}
                        >
                          <div>
                            <FcApproval />{" "}
                          </div>
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider
                          value={{ color: "orange", size: "32px" }}
                        >
                          <div>
                            <GiSandsOfTime />{" "}
                          </div>
                        </IconContext.Provider>
                      )}
                    </td>
                    <td>
                      <div className="status">
                        {/* <div className="status__light">
                      <div className="status__light__ring" />
                      <div className="status__light__led" />
                    </div>
                    
                    <div className="status__message">Send Request</div> */}
                        {/* <button className='notification_btn'
                   style={{borderRadius:'10rem'}}
                   >SEND  <IoMdSend/></button> */}
                        {/* <IoMdSend/> */}
                        <div
                          style={{
                            marginLeft: "2rem",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        >
                          <Link
                            style={{ color: "black" }}
                            to={`/proposals/${applicationId}/${proposal.proposalID}`}
                          >
                            <BsEyeFill />
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>{String(proposal.accountOpened)}</td>
                    <td>
                      <button
                        // disabled={!proposal.proposalAccepted}
                        style={{ border: "none", cursor: "pointer" }}
                        onClick={() => {
                          ShowBankDetails(
                            proposal.proposalAccepted,
                            proposal.proposalID
                          );
                        }}
                      >
                        {" "}
                        <IconContext.Provider
                          value={{ color: "red", size: "32px" }}
                        >
                          <FcFinePrint />
                        </IconContext.Provider>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Proposals;
