import { omit } from 'lodash'
const BankValidator =(event, name, value,  errosBank, setErrorsBank)=>{
    console.log(event, name, value,'mytargets')
    switch(name){
        // For Banking Form:-
        case 'value':
            if (value === '') {
              
              setErrorsBank({
                  ...errosBank, value: "Can't be empty!"
              })
          }

          else {
              // set the error state empty or remove the error for username input

              //omit function removes/omits the value from given object and returns a new object
              let newObj = omit(errosBank, "value");
              setErrorsBank(newObj);

          }
          break;
        case 'accepted_states':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                num_employees: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "num_employees");
            setErrorsBank(newObj);
    
        }
        break;
        case 'accept_CRB_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                accept_CRB_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "accept_CRB_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'accept_CRB_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                accept_CRB_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "accept_CRB_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'num_employees_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                num_employees_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "num_employees_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'num_employees_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                num_employees_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "num_employees_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'other':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                other: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "other");
            setErrorsBank(newObj);
    
        }
        break;
        case 'ownership_Percentage_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                ownership_Percentage_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "ownership_Percentage_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'ownership_Percentage_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                ownership_Percentage_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "ownership_Percentage_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'num_locs_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                num_locs_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "num_locs_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'num_locs_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                num_locs_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "num_locs_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'transactions_Per_month_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                transactions_Per_month_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "transactions_Per_month_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'transactions_Per_month_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                transactions_Per_month_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "transactions_Per_month_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'managed_square_feet_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                managed_square_feet_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "managed_square_feet_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'managed_square_feet_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                managed_square_feet_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "managed_square_feet_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'number_beneficial_owner_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                number_beneficial_owner_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "number_beneficial_owner_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'number_beneficial_owner_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                number_beneficial_owner_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "number_beneficial_owner_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_Initial_Deposit_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_Initial_Deposit_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_Initial_Deposit_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_Initial_Deposit_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_Initial_Deposit_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_Initial_Deposit_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'frequency_cash_withdrawals':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                frequency_cash_withdrawals: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "frequency_cash_withdrawals");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_total_amount_monthly_deposit_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_total_amount_monthly_deposit_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_total_amount_monthly_deposit_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_total_amount_monthly_deposit_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_total_amount_monthly_deposit_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_total_amount_monthly_deposit_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_total_num_monthly_deposit_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_total_num_monthly_deposit_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_total_num_monthly_deposit_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_total_num_monthly_deposit_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_total_num_monthly_deposit_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_total_num_monthly_deposit_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'anticipate_cash_deposits_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                anticipate_cash_deposits_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "anticipate_cash_deposits_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'anticipate_cash_deposits_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                anticipate_cash_deposits_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "anticipate_cash_deposits_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_cash_deposits_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_cash_deposits_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_cash_deposits_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_cash_deposits_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_cash_deposits_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_cash_deposits_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'frequency_cash_deposits_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                frequency_cash_deposits_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "frequency_cash_deposits_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'frequency_cash_deposits_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                frequency_cash_deposits_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "frequency_cash_deposits_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_spend_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_spend_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_spend_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_spend_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_spend_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_spend_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_cash_withdrawals_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_cash_withdrawals_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_cash_withdrawals_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'amount_cash_withdrawals_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                amount_cash_withdrawals_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "amount_cash_withdrawals_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'monthly_payroll_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                monthly_payroll_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "monthly_payroll_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'monthly_payroll_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                monthly_payroll_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "monthly_payroll_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_cash_pick_ups_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_cash_pick_ups_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_cash_pick_ups_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'estimated_cash_pick_ups_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                estimated_cash_pick_ups_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "estimated_cash_pick_ups_max");
            setErrorsBank(newObj);
    
        }
        break;
        case 'length_time':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                length_time: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "length_time");
            setErrorsBank(newObj);
    
        }
        break;
        case 'number_vendors_min':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                number_vendors_min: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "number_vendors_min");
            setErrorsBank(newObj);
    
        }
        break;
        case 'number_vendors_max':
    
        // we will set the error state
        if (value.length === 0) {
            setErrorsBank({
                ...errosBank,
                number_vendors_max: "Can't be empty!"
            })
        }
    
    
    
    
        else {
            // set the error state empty or remove the error for username input
    
            //omit function removes/omits the value from given object and returns a new object
            let newObj = omit(errosBank, "number_vendors_max");
            setErrorsBank(newObj);
    
        }
        break;
        default:
            break;
    }
    return errosBank;
}
export {BankValidator}