import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range';
import { FcOpenedFolder } from "react-icons/fc";
import { CSVLink, CSVDownload } from "react-csv";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Admin_Report_URL } from '../../Api/Api';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { IoSendSharp } from "react-icons/io5";
import OutsideClickHandler from 'react-outside-click-handler';
import swal from '@sweetalert/with-react';
import { BsDisplay } from 'react-icons/bs';
import { ThreeDots } from 'react-loader-spinner';
import { IconButton } from '@mui/material';
import { IconContext } from 'react-icons';

import ReactHTMLTableToExcel from "export-html-table-to-excel";

const Reports = () => {
  const [bank, setBank] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ])

  const [msg, setMsg] = useState('')


  useEffect(() => {
    setIsLoading(true)
    axios.get(`${Admin_Report_URL}`, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('admintoken') }
    })
      .then((res) => {
        console.log(res.data, 'res')
        setBank(res.data)
        setIsLoading(false)
      })
      .catch((err) => console.log(err))
  }, [])

  const dateMod = {
    Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07',
    Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
  }

  const searchBank = (e) => {
    setIsLoading(true)
    setSearch(e.target.value)
    axios.get(`${Admin_Report_URL}`, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('admintoken') }
    })
      .then((res) => {
        if (e.target.value === '') {
          setBank([...res.data])
        } else {
          const resultBank = res.data.filter((item) => item.bankName.toLowerCase().includes(e.target.value.toLowerCase()))
          console.log(resultBank, 'filtered')
          setBank([...resultBank])

        }
        setIsLoading(false)
      })
  }

  return (
    <div className='mainsection bnk_sctn'>
      <div className='bank_content'>
        <div className='cmn_hdr' >

          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2>Reports</h2>
            <br /><br />
            <div className="srch_br">
              <input className="srch_cntnt" type="text" style={{ height: '6rem' }} value={search} placeholder='Search by bank name...' onChange={(e) => searchBank(e)} />
            </div><br />
            <div>
              <p>{msg} </p>
            </div>

            <div ><ReactHTMLTableToExcel id="test-table-xls-button"
             
             className="download-table-xls-button"
             table="table-to-xls"
             filename="tablexls"
             sheet="tablexls"
             
             
             buttonText="Download">
            
           </ReactHTMLTableToExcel></div>
            <div className='calendar'>
              <h3 className='srange' onClick={() => setShow(!show)}>Filter Application by Date</h3>

              <OutsideClickHandler onOutsideClick={() => setShow(false)}>
                {show &&
                  <DateRange
                    id='daterange'
                    // editableDateInputs={true}
                    onChange={(item) => {
                      setState([item.selection])
                      console.log(item.selection.startDate.toString().split(' '), "startdate");
                      const resultStartDate = item.selection.startDate.toString().split(' ')
                      console.log(resultStartDate, 'resultStartDate')
                      const resultEndDate = item.selection.endDate.toString().split(' ')
                      console.log(resultEndDate, 'resultEndDate')
                      const startDate = `${dateMod[resultStartDate[1]]}-${resultStartDate[2]}-${resultStartDate[3]}`
                      console.log(startDate, 'zzzstartDate001')
                      const endDate = `${dateMod[resultEndDate[1]]}-${resultEndDate[2]}-${resultEndDate[3]}`
                      console.log(endDate, 'zzzendDate002')

                      if (startDate !== endDate) {
                        setShow(false)
                        setMsg(`From ${startDate} to ${endDate}`)
                        setSearch('')
                      }


                      axios.get(`${Admin_Report_URL}`, {
                        params: {
                          startDate: startDate, endDate: endDate
                        },
                        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('admintoken') }
                      })
                        .then((res) => {
                          console.log(res.data, 'resReport')
                          setBank(res.data)
                        })
                        .catch((err) => console.log(err))

                    }}

                    // moveRangeOnFirstSelection={false}
                    ranges={state}
                  />}
              </OutsideClickHandler>
            </div></div>
        </div>
        <br /><br />
        <div className='outr_table'>
          <table id="table-to-xls">
            <tbody>
              <tr>
                <th>Date</th>
                <th>Bank Name</th>
                {/* <th>Email</th>
                <th>Bank Contact Number</th> */}
                <th >Application ID</th>
                <th>Stage</th>
                <th>Price</th>
                {/* <th>Stage Count</th> */}
                {/* <th>Total Payment</th> */}
                <th>Receipt</th>
                <th>Send payment due to alert</th>
                <th >Send payment received notification</th>
              </tr>
              {
                (!isLoading && bank) ?
                  bank.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{data.date}</td>
                        <td>{data.bankName}</td>
                        {/* <td>{data.email}</td>
                      <td>{data.bankContactNumber}</td> */}
                        <td >{data.application_Id}</td>
                        <td>{data.stage}</td>
                        <td>{data.price}</td>
                        {/* <td>{data.stageCount}</td>
                      <td>{data.price * data.stageCount}</td> */}
                        <td>
                          <FaCloudDownloadAlt size='30px' />
                        </td>
                        <td>
                          <IoSendSharp size='30px' />
                        </td>
                        <td >
                          <IoSendSharp size='30px' />
                        </td>
                      </tr>
                    )
                  })
                  : <ThreeDots
                    height="60"
                    width="60"
                    radius="9"
                    color="#b07f35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
              }
            </tbody>
          </table>

        </div>


      </div>
    </div>
  )
}

export default Reports