import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Bank_ApplyChargeOnViewApplication_URL,
  Bank_ProfileUpdate,
  GetAll_Matching_Operators_URL,
} from "../../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import { BsEyeFill, BsToggleOn } from "react-icons/bs";
import { RiToggleLine } from "react-icons/ri";
import { IoMdSend } from "react-icons/io";
import swal from "@sweetalert/with-react";

import { FadeLoader } from "react-spinners";
import { ThreeDots } from "react-loader-spinner";
const MatchingOperators = () => {
  console.log(
    typeof localStorage.getItem("matchedApplicationPercentage"),
    "percentage"
  );
  const [matchedApplicants, setMatchedApplicants] = useState([]);
  const [api, setApi] = useState(false);
  const [matchedApplicationStatus, setMatchedApplicationStatus] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showdata, setShowData] = useState("Yes");
  const navigate = useNavigate();

  const profileData = {
    component: "Profile page",
    content: "Data located in profile component",
    timestamp: Date.now(),
  };

  function Cheking(admninstatus) {
    // console.log( formFilled,'8888888880')
    console.log(String(admninstatus), "888888881");
    // if(String(admninstatus) === 'false'){
    //   return "Sorry! Admin has not activated your active status!"
    // }else{
    //   if(formFilled === 'true'){
    //     return "Your match active setting is off. Do you want to turn it on?"
    //   }else{
    //     return "You have not filled up the settings yet. Fill up this now?"
    //   }
    // }

    if (String(localStorage.getItem("formFilled")) === "false") {
      return {
        title: "You have not filled up the settings yet. Fill up this now?",
        buttonText: "FILL UP NOW",
      };
    } else {
      if (String(admninstatus) === "false" || admninstatus === undefined) {
        return {
          title: "Sorry! Your account is still under process!",
          buttonText: "OK",
        };
      } else {
        if (String(localStorage.getItem("isActive")) === "false") {
          return {
            title:
              "Your match active setting is off. Do you want to turn it on?",
            buttonText: "TURN ON",
          };
        }
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(GetAll_Matching_Operators_URL, {
        params: {
          percentage: localStorage.getItem("matchedApplicationPercentage"),
        },

        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "matchedops");
        setMatchedApplicationStatus(res.data.bankActiveStatus);
        if (Array.isArray(res.data.matchRestult)) {
          setIsLoading(false);
          setMatchedApplicants(res.data.matchRestult);
        } else {
          setMessage(res.data.message);
          swal({
            title: `${Cheking(res.data.bankActiveStatus).title}`,
            className: "custom-content",
            buttons: {
              // confirm: {text:`${String(localStorage.getItem('formFilled')) === 'true'    ? 'GO TO SETTINGS' : 'FILL UP'}`,className:'sweet-warning'},
              confirm: {
                text: `${Cheking(res.data.bankActiveStatus).buttonText}`,
                className: "sweet-warning",
              },
              cancel: "NO",

              // confirmButtonColor: '#8CD4F5',
            },
            type: "success",
          }).then((okay) => {
            if (okay) {
              if (String(localStorage.getItem("formFilled")) === "false") {
                navigate("/fillup-bank");
              } else {
                if (res.data.bankActiveStatus === false) {
                } else {
                  // navigate('/my-settings')
                  swal({
                    title:
                      "Receive application status will be changed. Are you sure to continue?",
                    buttons: {
                      confirm: { text: "YES", className: "sweet-warning" },
                      cancel: "NO",
                    },
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      setIsLoading(true);

                      axios
                        .put(
                          Bank_ProfileUpdate,
                          {
                            isActive:
                              localStorage.getItem("isActive") === "true"
                                ? false
                                : true,
                          },
                          {
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          }
                        )
                        .then((res) => {
                          console.log(res.data, "afterupdatebankform");
                          // setActivitystatus(res.data.newProfile.isActive)
                          localStorage.setItem(
                            "isActive",
                            res.data.newProfile.isActive
                          );
                          setIsLoading(false);
                          setApi(!api);
                          swal("Updated!", `Form Status Updated!`, "success");
                        });
                    } else {
                      // setActivitystatus('No')
                    }
                  });
                }
              }
            } else {
              // setActivitystatus('No')
            }
          });
        }
      })
      .catch((err) => {
        swal({
          title: "You havn't filled the bank settings yet!",
          className: "custom-content",
          buttons: {
            confirm: { text: "FILL UP", className: "sweet-warning" },
            cancel: "NO",
            // confirmButtonColor: '#8CD4F5',
          },
          type: "success",
        }).then((okay) => {
          if (okay) {
            navigate("/fillup-bank");
            //  window.location.reload()
          } else {
            // setActivitystatus('No')
          }
        });
        // swal('Oops',"You havn't filled the bank settings yet",'error')
        console.log(err?.response?.data?.message, "error");
      });
  }, [api]);
  const ChargesOnViewApplication = (id) => {
    // `/matching-operators/${id}`
    swal({
      title:
        "Charges will be applied on view application. Are you sure to continue?",
      className: "custom-content",
      buttons: {
        confirm: { text: "YES", className: "sweet-warning" },
        cancel: "NO",
        // confirmButtonColor: '#8CD4F5',
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        axios.post(
          Bank_ApplyChargeOnViewApplication_URL,
          {
            id: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        // dispatch(CleanUpStore())
        navigate(`/matching-operators/${id}`);
      } else {
        // setActivitystatus('No')
      }
    });
  };
  return (
    <div className="mainsection bnk_sctn">
      {/* <div style={{marginLeft:'80%'}}>
           <div className="switch_box">
        
                          <label className="switch2">
                            <input 
                            style={{}}
                            type="checkbox" checked={showdata === 'Yes'} onChange={(event)=>
                            {
                              if(event.target.checked){
                                setShowData('Yes')
                              }else{
                                setShowData('No')
                              }
                            }}
                              name='products_purchased_providers'
                              />
                            <span className="slider round" />
                          </label>
                          { showdata === 'Yes' ? <h4>Turn Off</h4> : <h4>Turn On</h4> }
                        </div>
                        </div> */}

      <div className="bank_content">
        <div className="cmn_hdr">
          <h2
          // style={{ fontWeight: 'bold', fontSize: '16rem' }}
          >
            Matched Applications List
          </h2>
          <div style={{ display: "flex", marginLeft: "60%" }}>
            <p>Filter by pecentage: &nbsp; </p>
            <select
              className=""
              style={{ width: "30%" }}
              onChange={(event) => {
                if (event.target.value !== "Filter Applications") {
                  axios
                    .get(GetAll_Matching_Operators_URL, {
                      params: {
                        percentage: Number(event.target.value),
                      },
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .then((res) => {
                      setMatchedApplicants([...res.data.matchRestult]);
                    })
                    .catch((err) => console.log(err, "error"));
                }
              }}
            >
              {/* <option value="Filter Applications" selected={localStorage.getItem('matchedApplicationPercentage') ? 'Filter Applications' : false}>Filter Applications</option> */}
              <option
                value={10}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 10
                    ? true
                    : false
                }
              >
                10
              </option>
              <option
                value={20}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 20
                    ? true
                    : false
                }
              >
                20
              </option>
              <option
                value={30}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 30
                    ? true
                    : false
                }
              >
                30
              </option>
              <option
                value={40}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 40
                    ? true
                    : false
                }
              >
                40
              </option>
              <option
                value={50}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 50
                    ? true
                    : false
                }
              >
                50
              </option>
              <option
                value={60}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 60
                    ? true
                    : false
                }
              >
                60
              </option>
              <option
                value={70}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 70
                    ? true
                    : false
                }
              >
                70
              </option>
              <option
                value={80}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 80
                    ? true
                    : false
                }
              >
                80
              </option>
              <option
                value={90}
                selected={
                  Number(
                    localStorage.getItem("matchedApplicationPercentage")
                  ) === 90
                    ? true
                    : false
                }
              >
                90
              </option>
            </select>
          </div>
        </div>
        <br />
        <br />
        <div className="outr_table">
          {" "}
          <table>
            <tbody>
              <tr>
                {/* <th>Applicants</th> */}
                <th>Business Type</th>
                {/* <th>Services</th> */}
                <th>Services Wanted</th>
                <th>Application ID</th>
                <th>Matched Percentage</th>

                <th>View</th>
              </tr>
              {!isLoading && matchedApplicants ? (
                matchedApplicants.map((applicant, index) => {
                  console.log(applicant, "myApplicant");
                  return (
                    <tr>
                      <td>
                        <ul>
                          {applicant?.licensingType.map((i) => (
                            <li>{i}</li>
                          ))}
                        </ul>
                      </td>
                      {/* <td>{applicant?.interestedServices?.filter(x=> x.includes('Payment Processing')).map(i=> i.slice(21)).concat(applicant.interestedServices.filter(y=> !(y.includes('Payment Processing')))).toString()}</td> */}
                      <td>{applicant?.servicesWanted.toString()}</td>
                      <td>
                        <Link
                          style={{ color: "black" }}
                          to={`/matching-operators/${applicant.application_Id}`}
                        >
                          {applicant.application_Id}
                        </Link>
                      </td>
                      {/* <td>{applicant.application_Id}</td> */}
                      <td style={{ fontWeight: "bold" }}>
                        {applicant.matchedPercentage}%
                      </td>

                      <td>
                        <div className="status">
                          {/* <div className="status__light">
                      <div className="status__light__ring" />
                      <div className="status__light__led" />
                    </div>
                    
                    <div className="status__message">Send Request</div> */}
                          {/* <button className='notification_btn'
                   style={{borderRadius:'10rem'}}
                   >SEND  <IoMdSend/></button> */}
                          {/* <IoMdSend/> */}
                          <div
                            style={{
                              marginLeft: "2rem",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              onClick={() => {
                                ChargesOnViewApplication(
                                  applicant.application_Id
                                );
                              }}
                              // to={`/matching-operators/${applicant.application_Id}`}
                            >
                              <BsEyeFill />
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div
                  // className='mainsection bnk_sctn bank_content'
                  style={{ marginLeft: "50rem" }}
                >
                  <ThreeDots
                    height="60"
                    width="60"
                    radius="9"
                    color="#b07f35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
              {!Array.isArray(matchedApplicants) && <h3>No Match.</h3>}
              {/* {message && <h2>Your match active setting is off. Do you want to turn it on?</h2>} */}
              {/* {(matchedApplicationStatus === false || matchedApplicationStatus === undefined) && <h2>Sorry! Admin has not activated your active status!</h2>} */}
              {matchedApplicants.length !== 0 &&
                Cheking(matchedApplicationStatus)?.title}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MatchingOperators;
