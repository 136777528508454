import "./App.css";
import "./Assets/css/custom.css";
import "./Assets/css/responsive.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from "./Pages/Homepage";
import Login from "./Components/Authentication/Login";
import Signup from "./Components/Authentication/Signup";
import { useEffect, useRef, useState } from "react";
import BankPage from "./Pages/BankPage";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import Test from "./Test";

import CommonHeader from "./Components/CommonHeader";
import Sidebar from "./Components/Sidebar";
import BankDashboard from "./Components/BankMainPages/BankDashboard";
import OperatorDashboard from "./Components/OperatorPages/OperatorDashboard";
import MatchingBanks from "./Components/OperatorPages/MatchingBanks";
import MatchingOperators from "./Components/BankMainPages/MatchingOperators";
import OperatorSettings from "./Components/OperatorPages/OperatorSettings";
import BankSettings from "./Components/BankMainPages/BankSettings";
import OperatorQueries from "./Components/OperatorPages/OperatorQueries";
import BankQueries from "./Components/BankMainPages/BankQueries";
import OperatorApplications from "./Components/OperatorPages/OperatorApplications";
import BankApplications from "./Components/BankMainPages/BankApplications";
import ProfilePage from "./Pages/ProfilePage";
import MatchedOperatorDetails from "./Components/BankMainPages/MatchedOperatorDetails";
import AcceptedApplications from "./Components/BankMainPages/AcceptedApplications";
import BankProposals from "./Components/BankMainPages/BankProposals";
import RegistrationSuccess from "./Components/OperatorPages/RegistrationSuccess";
import ForgetPassword from "./Components/Authentication/ForgetPassword";
import ResetPassword from "./Components/Authentication/ResetPassword";
import ProposalsReceived from "./Components/OperatorPages/ProposalsReceived";
import Proposals from "./Components/OperatorPages/Proposals";
import SeeServicesOffered from "./Components/OperatorPages/SeeServicesOffered";
import ApprovedApplications from "./Components/BankMainPages/ApprovedApplications";
import MySettingsDetails from "./Components/BankMainPages/MySettingsDetails";
import AdminLogin from "./Components/Authentication/AdminLogin";
import BankListing from "./Components/AdminPages/BankListing";
import OperatorListing from "./Components/AdminPages/OperatorListing";
import { AdminAsUser_LogIn_URL } from "./Api/Api";
import axios from "axios";
import swal from "sweetalert";
import TransactionListing from "./Components/AdminPages/TransactionListing";
import TotalCharges from "./Components/OperatorPages/TotalCharges";
import BankProposalToOperator from "./Components/BankMainPages/BankProposalToOperator";
import Reports from "./Components/AdminPages/Reports";
import ViewedApplications from "./Components/OperatorPages/ViewedApplications";
import OperatorApplicationDetails from "./Components/OperatorPages/OperatorApplicationDetails";
import DeclinedApplications from "./Components/BankMainPages/DeclinedApplications";
import useSize from "./CustomHook/useSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaBars } from "react-icons/fa";
import LandingPage from "./LandingPage";

function App() {
  const [showmodal, setshowmodal] = useState(false);
  const [width, setWidth] = useState("");
  function hidemodal() {
    setshowmodal(false);
    console.log("clicked");
  }
  console.log(localStorage.getItem("token"), "678979");

  const userData = useSelector((state) => state);
  console.log(userData.user.role, "userdata");
  const navigate = useNavigate();
  const { resetToken } = useParams();
  console.log(resetToken, "resetToken");
  const location = useLocation();
  console.log(location?.pathname.split("/"), "from-admin");
  // console.log(location.pathname.slice(1),'showms')
  // console.log(location.pathname.split('/').at(-1), 'locc')
  //   useEffect(()=>{
  //     axios.post(AdminAsUser_LogIn_URL,{
  //       email: location?.pathname.split('/')[2], role:location?.pathname.split('/')[3]
  //     },{
  //       params : {
  //         role: 'admin'
  //       },
  //       headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
  //     }).then(res=>{
  //       console.log(res.data,'logintargetdata')
  //       localStorage.setItem('token',res.data.token)
  //       localStorage.setItem('role',res.data.role)
  //       localStorage.setItem('name',res.data.user.bankName)
  // navigate('/bank-dashboard')
  //     //   window.location.reload()

  //     //   const URL = `http://localhost:3001/bank-dashboard/${res.data.user._id}/bank`;
  //       // navigate({to: `${URL}`})
  //       // window.location.href =`${URL}`
  //     //   window.open(`${URL}`,'_blank')
  //     }).catch(err=>{
  //       console.log(err,'error')
  //     })
  //   },[location])
  useEffect(() => {
    const customTitle = location.pathname.slice(1);
    console.log(customTitle, "customTitle");
    document.title = `Guardian Banking Solutions | ${customTitle}`;
    if (!localStorage.getItem("token")) {
      console.log(location.pathname, "location.pathname");
      if (
        location.pathname !== "/admin-transaction-listing" &&
        location.pathname !== "/admin-bank-listing" &&
        location.pathname !== "/operator-listing" &&
        location.pathname !== "/Admin-login" &&
        location.pathname !== "/" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/forget-password" &&
        location.pathname !==
          `/resetpassword/${location.pathname.split("/").at(-1)}`
      ) {
        navigate("/login");
      }
      // else{
      //   console.log('happening')

      // }
    } else {
      // if (localStorage.getItem('role') === 'operator') {
      //   navigate('/home')
      // }
      // else
      // { navigate('/bank') }

      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/signup" ||
        location.pathname === "/forget-password"
      ) {
        if (localStorage.getItem("role") === "operator") {
          navigate("/operator-dashboard");
          // navigate('/operator-applications')
        } else {
          navigate("/bank-dashboard");
        }
      }
    }
    if (localStorage.getItem("admintoken")) {
      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/signup" ||
        location.pathname === "/Admin-login"
      ) {
        navigate("/admin-bank-listing");
      } else if (location.pathname === "/reports") {
        navigate("/reports");
      }
    } else {
      if (
        location.pathname === "/admin-bank-listing" ||
        location.pathname === "/operator-listing" ||
        location.pathname === "/admin-transaction-listing" ||
        location.pathname === "/reports"
      ) {
        navigate("/Admin-login");
      }
    }
  }, [location.pathname, localStorage.getItem("role")]);
  console.log(sessionStorage.getItem("token"), "from-session");
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const size = useSize();
  useEffect(() => {
    if (size.width <= 768) {
      setWidth("show");
    }
  }, [size]);
  console.log(size, "1221321313");
  console.log(location.pathname, "thispath");
  // useEffect(() => {
  //   if (
  //     localStorage.getItem("role") !== "bank" &&
  //     location.pathname === "/bank-dashboard"
  //   ) {
  //     navigate("/operator-dashboard");
  //   }
  // }, []);
  return (
    <div>
      {(localStorage.getItem("token") ||
        localStorage.getItem("admintoken")) && (
        <>
          <Sidebar />
          <section className="mainsection bnk_sctn">
            {/* {size.width <= 768 ? <Sidebar /> : <CommonHeader />} */}
            <CommonHeader />
          </section>
        </>
      )}

      <Routes>
        {!localStorage.getItem("token") &&
          !localStorage.getItem("admintoken") && (
            // <Route path="/" name="Signup Page" element={<Signup />} />
            <Route path="/signup" name="Signup Page" element={<Signup />} />
          )}
        {!localStorage.getItem("token") &&
          !localStorage.getItem("admintoken") && (
            // <Route path="/" name="Signup Page" element={<Signup />} />
            <Route path="/" name="Landing Page" element={<LandingPage />} />
          )}
        {!localStorage.getItem("token") && (
          <Route path="/login" name="Login Page" element={<Login />} />
        )}
        {!localStorage.getItem("admintoken") &&
          !localStorage.getItem("token") && (
            <Route
              path="/Admin-login"
              name="Admin Login Page"
              element={<AdminLogin />}
            />
          )}
        {!localStorage.getItem("token") && (
          <Route
            path="/forget-password"
            name="Forget Password Page"
            element={<ForgetPassword />}
          />
        )}
        {localStorage.getItem("role") === "operator" && (
          <Route
            path="/activation/:activeToken"
            name="Successfull Registration Page"
            element={<RegistrationSuccess />}
          />
        )}
        {!localStorage.getItem("token") && (
          <Route
            path="/resetpassword/:resetToken"
            name="Reset Password Page"
            element={<ResetPassword />}
          />
        )}

        {localStorage.getItem("token") && (
          <>
            {" "}
            {/* common */}
            <Route path="/profile" name="Home Page" element={<ProfilePage />} />
            {/* for operator */}
            {localStorage.getItem("role") === "operator" && (
              <>
                <Route path="/home" name="Home Page" element={<Homepage />} />

                <Route
                  path="/operator-dashboard"
                  name="Operator Dashboard"
                  element={<OperatorDashboard />}
                />
                <Route
                  path="/matching-banks"
                  name="Matching Banks"
                  element={<MatchingBanks />}
                />
                <Route
                  path="/operator-settings"
                  name="Operator Settings"
                  element={<OperatorSettings />}
                />
                <Route
                  path="/operator-queries"
                  name="Operator Queries"
                  element={<OperatorQueries />}
                />
                <Route
                  path="/operator-applications"
                  name="Operator Applications"
                  element={<OperatorApplications />}
                />
                <Route
                  path="/operator-applications/:application_Id"
                  name="Operator Application Details"
                  element={<OperatorApplicationDetails />}
                />
                <Route
                  path="/offers-received"
                  name="Offers Received"
                  element={<ProposalsReceived />}
                />
                <Route
                  path="/offers/:applicationId"
                  name="Proposals"
                  element={<Proposals />}
                />
                <Route
                  path="/proposals/:applicationId/:proposalId"
                  name="See Services Offered"
                  element={<SeeServicesOffered />}
                />
              </>
            )}
            {/* for bank */}
            {/* <Route path="/dashboard" name="Home Page" element={<Homepage />} /> */}
            {localStorage.getItem("role") === "bank" && (
              <>
                <Route
                  path="/fillup-bank"
                  name="Bank Page"
                  element={<BankPage />}
                />
                {/* <Route path="/profile" name="Home Page" element={<ProfilePage />} /> */}
                <Route
                  path="/bank-dashboard"
                  name="Bank Dashboard"
                  element={<BankDashboard />}
                />
                {localStorage.getItem("role") === "bank" && (
                  <Route
                    path="/matching-operators"
                    name="Matching Operators"
                    element={<MatchingOperators />}
                  />
                )}{" "}
                <Route
                  path="/matching-operators/:id"
                  name="Matched Operator Details"
                  element={<MatchedOperatorDetails />}
                />
                <Route
                  path="/accepted-applications"
                  name="Accepted Applications"
                  element={<AcceptedApplications />}
                />
                <Route
                  path="/declined-applications"
                  name="Declined Applications"
                  element={<DeclinedApplications />}
                />
                <Route
                  path="/approved-applications"
                  name="Approved Applications Details"
                  element={<ApprovedApplications />}
                />
                <Route
                  path="/accepted-applications/:id"
                  name="Accepted Applications Details"
                  element={<MatchedOperatorDetails />}
                />
                <Route
                  path="/bank-settings"
                  name="Bank Settings"
                  element={<BankSettings />}
                />
                {/* <Route path="/bank-proposals/:id" name="Bank Proposals" element={<BankProposals/>} /> */}
                <Route
                  path="/bank-proposals/:id"
                  name="Bank Proposals"
                  element={<BankProposalToOperator />}
                />
                <Route
                  path="/bank-queries"
                  name="bank Queries"
                  element={<BankQueries />}
                />
                <Route
                  path="/my-settings"
                  name="Bank Applications"
                  element={<BankApplications />}
                />
                {/* <Route path="/total-charges" name="Total Charges" element={<TotalCharges/>} /> */}
                <Route
                  path="/viewed-applications"
                  name="Total Charges"
                  element={<ViewedApplications />}
                />
                <Route
                  path="/settings-details"
                  name="Bank Settings Details"
                  element={<MySettingsDetails />}
                />
                <Route path="/test" name="Bank Page" element={<Test />} />
              </>
            )}
          </>
        )}
        {localStorage.getItem("admintoken") &&
          localStorage.getItem("role") === "admin" && (
            <Route
              path="/admin-bank-listing"
              name="Bank Listing Page"
              element={<BankListing />}
            />
          )}
        {localStorage.getItem("admintoken") &&
          localStorage.getItem("role") === "admin" && (
            <Route
              path="/operator-listing"
              name="operator Listing Page"
              element={<OperatorListing />}
            />
          )}
        {localStorage.getItem("admintoken") &&
          localStorage.getItem("role") === "admin" && (
            <Route
              path="/admin-transaction-listing"
              name="Transaction Listing Page"
              element={<TransactionListing />}
            />
          )}
        {localStorage.getItem("admintoken") &&
          localStorage.getItem("role") === "admin" && (
            <Route path="/reports" name="Reports Page" element={<Reports />} />
          )}
      </Routes>
    </div>
  );
}

export default App;
