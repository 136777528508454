import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ModalCustom } from "../Modal/ModalCustom";
import axios from "axios";
import {
  Fill_BusinessSetUp,
  Fill_BusinessSetUp_URL,
  Get_BankDashboard_URL,
} from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashBoardDataBank } from "../../Store/DashboardSlice";
import { FcCheckmark } from "react-icons/fc";
import { ImCross } from "react-icons/im";
import { GiSandsOfTime } from "react-icons/gi";
import { IconContext } from "react-icons";
const BankDashboard = () => {
  const dispatch = useDispatch();
  const accessdata = useSelector((state) => state.user.access);
  console.log(accessdata, "accessdata");
  const [names, setNames] = useState({});
  const [access, setAccess] = useState(localStorage.getItem("access"));
  const [errorState, setErrorState] = useState("");
  const navigate = useNavigate();
  const [businessSetUp, setBusinessSetUp] = useState({
    servicesOffered: [],
  });
  const { data, isLoading, isError, message } = useSelector(
    (state) => state.Dashboard
  );
  console.log(data, "6666");
  const BusinessSetUpDataChangeHandler = (event, i) => {
    console.log(event.target.value, i, "seethis");

    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        const data = [
          ...businessSetUp.servicesOffered,
          {
            service: event.target.value,
          },
        ];
        setBusinessSetUp({
          ...businessSetUp,
          servicesOffered: data,
        });
      } else {
        const data = businessSetUp.servicesOffered.filter(
          (x) => x.service !== event.target.value
        );
        setBusinessSetUp({
          ...businessSetUp,
          servicesOffered: data,
        });
      }
    } else {
      console.log(businessSetUp.servicesOffered, "target");
      // setBusinessSetUp(businessSetUp)
    }
  };
  const PriceChangeHandler = (event, i) => {
    setNames({ ...names, [event.target.name]: event.target.value });
  };
  // const toggle = () => setOpen(!open);
  console.log(localStorage.getItem("access"), "678");
  // useEffect(()=>{

  // axios.get(Get_BankDashboard_URL, {
  //   headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  // }).then(res=>{console.log(res,'890')}).catch(err=>console.log(err,'error'))
  // },[])
  useEffect(() => {
    dispatch(fetchDashBoardDataBank());
  }, []);
  return (
    <div className="mainsection bnk_sctn">
      <>
        <div className="dshboard_cntnt">
          <div className="parent_top_cntnt_outr">
            <div className="parent_top_cntnt">
              <div className="tptc_total_bx">
                <h4>Total Accepted Applications</h4>
                <span className="balance">
                  {data?.numAcceptedApplications}{" "}
                </span>
                {/* <h6>{data?.numAcceptedApplications} (last record)</h6> */}
              </div>
              <div className="tptc_total_bx">
                <h4>Number of Applications in underwriting process</h4>
                <span className="balance">{data?.numUnderwriting}</span>
                {/* <h6>{data?.numUnderwriting} (last record)</h6> */}
              </div>
              <div className="tptc_total_bx">
                <h4>Number of Total accounts opened</h4>
                <span className="balance">{data?.numAccountOpen} </span>
                {/* <h6>{data?.numAccountOpen} (last record)</h6> */}
              </div>
            </div>
          </div>
          <div className="parent_btm-cntnt_outr">
            <div className="prnt_btm_cntnt">
              <h3 className="prbtc_outr">
                List of Accepted Applications Top 10
              </h3>
              <div className="prnt_btm_search">
                {/* <div className="search-container">
                <form action="">
                  <input
                    type="text"
                    placeholder="Search by Customer"
                    name="search"
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
              <div className="prnt_btn">
                <a href="javascript:void(0);" className="cmn_btn">
                  Demo btn
                </a>
              </div> */}
              </div>
              <div className="prnt_btm_table">
                <table>
                  <tbody>
                    <tr>
                      <th>Application ID</th>
                      {/* <th>Created At</th> */}
                      <th>Bank Accept</th>
                      <th>Bank Accepted On</th>
                      {/* <th>Services Offered</th> */}
                      <th>Proposal Sent</th>
                      <th>Proposal Sent On</th>
                      <th>Proposal Accepted</th>
                      <th>Proposal Accepted On</th>
                      <th>Underwriting</th>
                      <th>Underwriting Process</th>
                      <th>Account Opened</th>
                    </tr>

                    {data &&
                      data.acceptedApplications?.map((application, index) => {
                        return (
                          <tr>
                            <td>{application.application_Id}</td>
                            <td>
                              {application.bankAccept ? (
                                <FcCheckmark />
                              ) : (
                                <ImCross />
                              )}
                            </td>
                            <td>{application.bankAcceptedOn}</td>
                            <td>
                              {application.proposalSent ? (
                                <FcCheckmark />
                              ) : (
                                <IconContext.Provider
                                  value={{ color: "orange", size: "32px" }}
                                >
                                  <div>
                                    <GiSandsOfTime />{" "}
                                  </div>
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{application.proposalSentOn}</td>
                            <td>
                              {application.proposalAccepted ? (
                                <FcCheckmark />
                              ) : (
                                <IconContext.Provider
                                  value={{ color: "orange", size: "32px" }}
                                >
                                  <div>
                                    <GiSandsOfTime />{" "}
                                  </div>
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{application.proposalAcceptedOn}</td>
                            <td>
                              {application.underwriting ? (
                                <FcCheckmark />
                              ) : (
                                <IconContext.Provider
                                  value={{ color: "orange", size: "32px" }}
                                >
                                  <div>
                                    <GiSandsOfTime />{" "}
                                  </div>
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{application.underwritingProcess}</td>
                            <td>
                              {application.accountOpened ===
                              "Account Opened" ? (
                                <FcCheckmark />
                              ) : application.accountOpened === "Declined" ? (
                                <>❌</>
                              ) : (
                                "Waiting"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*Header End*/}
        {/* Back to top button */}
        <a href="javascript:void(0);" id="backToTop">
          <i className="fa fa-solid fa-arrow-up" />
        </a>
        {/* Back to top button */}
        {/* Jquery  */}
        {/* WOW JS */}
        {/* Font Awesome JS */}
        {/* Icon Scout */}
        {/* AOS JS */}
        {/* Bootstrap JS */}
        {/*  */}
        {/* Custom JS */}
      </>
    </div>
    // <div className='mainsection bnk_sctn'>Welcome to Dashboard ❤</div>
  );
};

export default BankDashboard;
