import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import useSize from "../CustomHook/useSize";

const Sidebar = () => {
  const [applicationDropdown, setApplicationDropdown] = useState(false);
  const [approvedApplicationDropdown, setApprovedApplicationDropdown] =
    useState(false);
  const location = useLocation();
  console.log(location.pathname.includes("dashboard"), "location");
  const [active, setActive] = useState("Dashboard");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const Activehandler = (section) => {
    setActive(section);
  };
  // const size = useSize()
  // useEffect(()=>{
  //   if(size.width <= 768 ){

  //   }
  // },[])
  return (
    <>
      <div className="rspnsv_hdr">
        <div className="logo">
          <a href="javascript:void(0);">
            <img src={require("../Assets/images/bank_logo.png")} alt="" />
          </a>
        </div>
        <div id="toggle_bar">
          <a
            onClick={() => {
              setShow(!show);
            }}
            style={{ cursor: "pointer" }}
          >
            <FaBars />
          </a>
        </div>
      </div>
      <div
        className="dash_board"
        id="target"
        style={show ? { display: "block" } : {}}
      >
        <div className="logo">
          <a href="javascript:void(0);">
            <img src={require("../Assets/images/bank_logo.png")} alt="" />
          </a>
        </div>
        <nav className="nav_bar">
          <ul>
            <li className="nav_list">
              <a
                className={
                  // active ===  'Dashboard'
                  location.pathname.includes("dashboard")
                    ? "nav-menu active"
                    : "nav-menu "
                }
                // href="javascript:void"
              >
                <div className="img_otr">
                  <img src={require("../Assets/images/icon_1.png")} alt="" />
                </div>
                <p
                  onClick={() => {
                    setShow(false);
                    if (localStorage.getItem("role") === "operator") {
                      navigate("/operator-dashboard");
                    } else if (localStorage.getItem("role") === "bank") {
                      navigate("/bank-dashboard");
                    }
                    Activehandler("Dashboard");
                  }}
                >
                  Dashboard
                </p>
              </a>
            </li>
            {/* {
        localStorage.getItem('role') === 'operator' && <li className="nav_list">
        <a className={location.pathname === '/matching-banks' ? "nav-menu active" : "nav-menu "}>
          <div className="img_otr">
            <img src={require("../Assets/images/icon_2.png")} alt="" />
          </div>
          <p onClick={()=>{
            navigate('/matching-banks')
            Activehandler('matching Banks')}}>matching Banks</p>
        </a>
      </li>
      } */}
            {localStorage.getItem("role") === "bank" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname === "/matching-operators"
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_2.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      navigate("/matching-operators");
                      Activehandler("matching Operators");
                      setShow(false);
                    }}
                  >
                    matching Applications
                  </p>
                </a>
              </li>
            )}
            {localStorage.getItem("role") === "bank" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname === "/viewed-applications"
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_2.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      navigate("/viewed-applications");
                      Activehandler("total charges");
                      setShow(false);
                    }}
                  >
                    Viewed Applications
                  </p>
                </a>
              </li>
            )}
            {localStorage.getItem("role") === "operator" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname === "/operator-applications"
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_2.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      navigate("/operator-applications");
                      Activehandler("matching Operators");
                      setShow(false);
                    }}
                  >
                    My Applications
                  </p>
                </a>
              </li>
            )}
            {localStorage.getItem("role") === "bank" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname.includes("accepted-applications")
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_4.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                      // Activehandler('Settings')
                      navigate("/accepted-applications");
                      setShow(false);
                    }}
                  >
                    Accepted Applications
                  </p>
                </a>
              </li>
            )}
            {localStorage.getItem("role") === "bank" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname.includes("declined-applications")
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_4.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                      // Activehandler('Settings')
                      navigate("/declined-applications");
                      setShow(false);
                    }}
                  >
                    Declined Applications
                  </p>
                </a>
              </li>
            )}
            {/* {localStorage.getItem('role') === 'bank' && <li className="nav_list">
        <a className={location.pathname.includes('approved-applications') ? "nav-menu active" : "nav-menu "} >
          <div className="img_otr">
            <img 
        
            src={require("../Assets/images/icon_4.png")} alt="" />
          </div>
          <p onClick={()=>{
            // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
            // Activehandler('Settings')
            navigate('/approved-applications')
            }}>Approved Applications</p>
        </a>
      </li>} */}
            {localStorage.getItem("role") === "operator" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname.includes("home")
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_4.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                      // Activehandler('Settings')
                      navigate("/home");
                      setShow(false);
                    }}
                  >
                    Create Application
                  </p>
                </a>
              </li>
            )}
            {/* <li className="nav_list">
        <a className={location.pathname.includes('queries') ? "nav-menu active" : "nav-menu "} >
          <div className="img_otr">
            <img src={require("../Assets/images/icon_3.png")} alt="" />
          </div>
          <p onClick={()=>{
            if(localStorage.getItem('role') === 'bank'){
              navigate('/bank-queries')
            }else{
              navigate('/operator-queries')
            }
            Activehandler('Queries')}}>Queries</p>
        </a>
      </li> */}
            {/* <li className="nav_list" id="AprvApp">
        <a className={active === 'Approved Applications' ? "nav-menu active" : "nav-menu "} >
          <div className="img_otr">
            <img src={require("../Assets/images/icon_4.png")} alt="" />
          </div>
          <p onClick={()=>{
            Activehandler('Approved Applications')
            setApprovedApplicationDropdown(!approvedApplicationDropdown)
          }}>Approved Applications</p>
        </a>
       {
        approvedApplicationDropdown &&  <div className="drop_dwn_cntnt drop_dwn_cntnt_1">
        <button className="drpdwnLink grdn__btn" type='button'>
           DROPDOWN
        </button>
        <button className="drpdwnLink active grdn__btn" type='button'>
           DROPDOWN
        </button>
      </div>
       }
      </li> */}
            {localStorage.getItem("role") === "bank" && (
              <li className="nav_list" id="M_App">
                <a
                  className={
                    // active === 'Applications'
                    location.pathname.includes("my-settings") ||
                    location.pathname.includes("fillup-bank")
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_5.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      if (
                        String(localStorage.getItem("formFilled")) === "true"
                      ) {
                        navigate("/my-settings");
                      } else {
                        navigate("/fillup-bank");
                      }
                      Activehandler("Applications");
                      setApplicationDropdown(!applicationDropdown);
                      setShow(false);
                    }}
                  >
                    Bank Settings
                  </p>
                </a>

                {/* {
        applicationDropdown &&
         <div className="drop_dwn_cntnt drop_dwn_cntnt_2">
        
       {
       String(localStorage.getItem('formFilled')) ===  'false' &&  
       <button className="drpdwnLink active grdn__btn" onClick={()=>{
          
        if(localStorage.getItem('role') === 'bank'){

          navigate('/bank')
        }else{
          navigate('/home')
        }
         
       }}>
         Fill up Settings
       </button>
       }
      </div>
       } */}
              </li>
            )}
            <li className="nav_list">
              <a
                className={
                  location.pathname.includes("operator-settings") ||
                  location.pathname.includes("bank-settings")
                    ? "nav-menu active"
                    : "nav-menu "
                }
              >
                <div className="img_otr">
                  <img src={require("../Assets/images/icon_6.png")} alt="" />
                </div>
                <p
                  onClick={() => {
                    if (localStorage.getItem("role") === "bank") {
                      navigate("/bank-settings");
                    } else if (localStorage.getItem("role") === "operator") {
                      navigate("/operator-settings");
                    }
                    Activehandler("Settings");
                    setShow(false);
                  }}
                >
                  Settings
                </p>
              </a>
            </li>
            {localStorage.getItem("role") === "operator" && (
              <li className="nav_list">
                <a
                  className={
                    location.pathname.includes("offers-received")
                      ? "nav-menu active"
                      : "nav-menu "
                  }
                >
                  <div className="img_otr">
                    <img src={require("../Assets/images/icon_4.png")} alt="" />
                  </div>
                  <p
                    onClick={() => {
                      // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                      // Activehandler('Settings')
                      navigate("/offers-received");
                      setShow(false);
                    }}
                  >
                    Offers Received
                  </p>
                </a>
              </li>
            )}
            {/* { (String(localStorage.getItem('formFilled')) === 'false' && localStorage.getItem('role') === 'operator') && <li className="nav_list">
        <a className={location.pathname.includes('fillup-bank') ? "nav-menu active" : "nav-menu "} >
          <div className="img_otr">
            <img src={require("../Assets/images/icon_6.png")} alt="" />
          </div>
          <p onClick={()=>{
           
            // if(localStorage.getItem('role') === 'bank'){navigate('/fillup-bank')}
            // else{navigate('/home')}
            // Activehandler('Settings')
            }}>Bank Settings</p>
        </a>
      </li>} */}
            {localStorage.getItem("admintoken") && (
              <>
                <li className="nav_list">
                  <a
                    className={
                      location.pathname.includes("bank-listing")
                        ? "nav-menu active"
                        : "nav-menu "
                    }
                  >
                    <div className="img_otr">
                      <img
                        src={require("../Assets/images/icon_4.png")}
                        alt=""
                      />
                    </div>
                    <p
                      onClick={() => {
                        // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                        // Activehandler('Settings')
                        navigate("/admin-bank-listing");
                        setShow(false);
                      }}
                    >
                      Banks
                    </p>
                  </a>
                </li>
                <li className="nav_list">
                  <a
                    className={
                      location.pathname.includes("operator-listing")
                        ? "nav-menu active"
                        : "nav-menu "
                    }
                  >
                    <div className="img_otr">
                      <img
                        src={require("../Assets/images/icon_4.png")}
                        alt=""
                      />
                    </div>
                    <p
                      onClick={() => {
                        // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                        // Activehandler('Settings')
                        navigate("/operator-listing");
                        setShow(false);
                      }}
                    >
                      Operators
                    </p>
                  </a>
                </li>
                <li className="nav_list">
                  <a
                    className={
                      location.pathname.includes("transaction-listing")
                        ? "nav-menu active"
                        : "nav-menu "
                    }
                  >
                    <div className="img_otr">
                      <img
                        src={require("../Assets/images/icon_4.png")}
                        alt=""
                      />
                    </div>
                    <p
                      onClick={() => {
                        // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                        // Activehandler('Settings')
                        navigate("/admin-transaction-listing");
                        setShow(false);
                      }}
                    >
                      Transaction Details
                    </p>
                  </a>
                </li>
                <li className="nav_list">
                  <a
                    className={
                      location.pathname.includes("reports")
                        ? "nav-menu active"
                        : "nav-menu "
                    }
                  >
                    <div className="img_otr">
                      <img
                        src={require("../Assets/images/icon_4.png")}
                        alt=""
                      />
                    </div>
                    <p
                      onClick={() => {
                        // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                        // Activehandler('Settings')
                        navigate("/reports");
                        setShow(false);
                      }}
                    >
                      Reports
                    </p>
                  </a>
                </li>
              </>
            )}
            <li className="nav_list">
              <a
                className={
                  location.pathname.includes("profile")
                    ? "nav-menu active"
                    : "nav-menu "
                }
              >
                <div className="img_otr">
                  <img src={require("../Assets/images/icon_4.png")} alt="" />
                </div>
                <p
                  onClick={() => {
                    // if(localStorage.getItem('role') === 'bank'){navigate('/bank-settings')}else{navigate('/operator-settings')}
                    // Activehandler('Settings')
                    if (localStorage.getItem("role") !== "admin") {
                      navigate("/profile");
                      setShow(false);
                    }
                  }}
                >
                  My Profile
                </p>
              </a>
            </li>

            {/* <li className="nav_list">
        <a className="nav-menu" >
          <div className="img_otr">
            <img src={require("../Assets/images/icon_7.png")} alt="" />
          </div>
          <p 
          onClick={()=>{
           
             
            
            // Swal.fire({
            //     title: 'Are you sure you want to log out?',
            //     showDenyButton: true,
            //     // showCancelButton: true,
            //     confirmButtonText: 'YES',
            //     confirmButtonColor:'#ba8c4a',
            //     denyButtonText: 'NO',
            //     // className:'confirm-button-class'
            //     denyButtonColor:'gray',
            //     customClass:{
            //          actions: 'my-actions',
            //         // cancelButton: 'cancel-button-class',
            //         confirmButton: 'confirm-button-class',
            //         denyButton: 'deny-button-class',
            //     },
                
               
            //     // }
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //        localStorage.clear()
            //       navigate('/login')
            //     } else if (result.isDenied) {
            //     //   Swal.fire('Changes are not saved', '', 'info')
            //     }
            //   })
              
            swal({
              title: 'Are you sure you want to log out?',
              buttons: {
                  confirm: 'YES',
                  cancel: 'NO'
              },
              type: "success"
        
          }).then((okay) => {
              if (okay) {
                localStorage.clear()
                      navigate('/login')
                  
                   
                   
                  
              }else{
                // setActivitystatus('No')
              }
          })
      }}>Log Out</p>
        </a>
      </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
