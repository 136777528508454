import axios from "axios";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { Link, useNavigate } from "react-router-dom";
import {
  Bank_SignUp_URL,
  Operator_SignUp_URL,
  Sign_Up_URL,
} from "../../Api/Api";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader } from "react-spinners";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
// import "intl-tel-input/build/css/intlTelInput.min.css";

import { toast } from "react-toastify";
// import "react-phone-input-2/lib/bootstrap.css";
// import PhoneInput from 'react-phone-input-2'
const Signup = () => {
  const navigate = useNavigate();

  const [isverified, setIsVerified] = useState(false);
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState("Operator");
  const [register, setRegister] = useState({
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    // address: "",
    // industryType: ""
  });
  const [registerBank, setRegisterBank] = useState({
    bankName: "",
    // bankState: '',
    // bankCity: '',
    // bankZip: '',
    // bankAddress: '',
    // bankType: '',
    // bankLogo: '',
    email: "",
    password: "",
    bankContactNumber: "",
  });
  console.log(phone, "phonewithccode");
  const RegistrationBankHandler = (event) => {
    console.log(event.target.value, "677");
    if (event.target.type === "file") {
      setRegisterBank({
        ...registerBank,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setRegisterBank({
        ...registerBank,
        [event.target.name]: event.target.value,
      });
    }
  };
  console.log(registerBank, "registerBank");
  const RegistrationOperatorHandler = (event) => {
    setRegister({
      ...register,
      [event.target.name]: event.target.value,
    });
  };
  const RegistrationFunction = (event) => {
    console.log(event.target.value, "iop");
    event.preventDefault();

    if (user === "Operator") {
      if (isverified) {
        setIsLoading(true);
        register.phoneNumber = phone;
        axios
          .post(Sign_Up_URL, register, {
            params: {
              role: user.toLowerCase(),
            },
          })
          .then((res) => {
            console.log(res, "123");
            setIsLoading(false);
            // localStorage.setItem('token', res.data.token)
            localStorage.setItem("role", res.data.role);

            // toast('Click the link sent to your mail!')
            Swal.fire("Check  mail", `${res.data.message}`, "success");
            // navigate('/operator-dashboard')
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          })
          .catch((err) => {
            setIsLoading(false);
            Swal.fire("Oops..", `${err.response.data.message}`, "error");
            // console.log(err,'err')
          });
      } else {
        // window.alert('Please Fill Up captcha!')
        toast.error("Please Fill Up captcha!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        swal("Before you proceed please complete the captcha below!");
        console.log("Please Fill Up captchaaaa!");
      }
    } else {
      setIsLoading(true);
      const formdata = new FormData();
      for (const key in registerBank) {
        if (key === "bankContactNumber") {
          formdata.append(key, phone);
        } else {
          formdata.append(key, registerBank[key]);
        }
      }
      axios
        .post(Sign_Up_URL, formdata, {
          params: {
            role: user.toLowerCase(),
          },
        })
        .then((res) => {
          setIsLoading(false);
          console.log(res.data, "signupdata");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("logo", res.data.user.bankLogo);
          localStorage.setItem("name", res.data.user.bankName);
          console.log("access", res.data.user.access);
          localStorage.setItem("access", res.data.user.access);
          localStorage.setItem("formFilled", res.data.user.formFilled);
          localStorage.setItem("isActive", res.data.user.isActive);
          // Swal.fire('Registered', `${res.data.message}`, 'success')
          swal("Registered", `${res.data.message}`, "success", {
            buttons: {},
          });
          navigate("/bank-dashboard");
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire("Oops..", `${err.response.data.message}`, "error");
          // console.log(err,'err')
        });
    }
  };
  //   const RegistrationBankFunction =(event)=>{
  //     event.preventDefault()
  //     setIsLoading(true)

  //         let formdata = new FormData()
  //         for (const key in registerBank) {
  //           if (key !== 'bankLogo') {
  //             formdata.append(`${key}`, registerBank[key]);
  //           } else {
  //             if (typeof registerBank[key] === 'object') {
  //               formdata.append(`${key}`, registerBank[key]);
  //             }
  //           }

  //         }
  //     axios.post(Bank_SignUp_URL,formdata).then(res=>{
  //       setIsLoading(false)
  //        localStorage.setItem('token',res.data.token)
  //       Swal.fire('Registered', `${res.data.message}`, 'success')
  //       navigate('/home')
  //     }).catch(err=>console.log(err,'err'))
  // }
  const RadioHandler = (event) => {
    setUser(event.target.value);
  };
  const onChange = (status, phoneNumber, country, value) => {
    setPhone(value);
  };
  const onCaptchaChange = (value) => {
    console.log(value, "captchaval");
    setIsVerified(true);
  };
  return (
    <>
      <header className="sign_up_header">
        <div className="container">
          <a href="javascript:void(0)" className="logo">
            <img src={require("../../Assets/images/logo.png")} alt="" />
          </a>
        </div>
      </header>
      <main>
        <section className="bnr_sec">
          <div className="container">
            <div className="bnr_hdng">
              <h1>
                Build Your Business <br />
                An Unshakable Foundation.
              </h1>
            </div>
          </div>
          <div className="bnr_img">
            <img src={require("../../Assets/images/mn-bnr-bg.jpg")} alt="" />
          </div>
        </section>
        <div className="form_sec">
          <>
            <div className="frm_hdng">
              <h3>Sign up now</h3>
            </div>
            <div className="sign_up_flx_bx">
              {/* <div className="sgn_up_flx_bx_inr active" id="sgn_up_oprtr">
                <span className="round" />
                <p>Sign up as an Operator</p>
              </div> */}
              <div className="pg1-prt-1-inrfld">
                <input
                  type="radio"
                  name="user"
                  id="sgn_up_oprtr"
                  checked={user === "Operator"}
                  value="Operator"
                  onChange={RadioHandler}
                />
                <label htmlFor="sgn_up_oprtr">Sign up as an Operator</label>
              </div>
              {/* <div className="sgn_up_flx_bx_inr" id="sgn_up_bnk">
                <span className="round" />
                <p>Sign up as a Bank</p>
              </div> */}
              <div className="pg1-prt-1-inrfld">
                <input
                  type="radio"
                  name="user"
                  id="sgn_up_oprtr"
                  checked={user === "Bank"}
                  value="Bank"
                  onChange={RadioHandler}
                />
                <label htmlFor="sgn_up_oprtr">Sign up as a Bank</label>
              </div>
            </div>
            {user === "Operator" && (
              <form onSubmit={RegistrationFunction}>
                <div className="sign_up-otr" id="grdn_sgn_up_oprtr">
                  <div className="sgn_up_cntnt" id="oprtr_cntnt">
                    <div className="inpt_bx">
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        name="email"
                        value={register.email}
                        onChange={RegistrationOperatorHandler}
                      />
                    </div>
                    <div className="inpt_bx">
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        name="password"
                        value={register.password}
                        onChange={RegistrationOperatorHandler}
                      />
                    </div>
                    <div className="inpt_bx">
                      <input
                        type="text"
                        placeholder="Full Name"
                        required
                        name="fullName"
                        value={register.fullName}
                        onChange={RegistrationOperatorHandler}
                      />
                    </div>
                    {/* <div className="inpt_bx">
                    <input type="number" placeholder="Phone Number" required name='phoneNumber'
                      value={register.phoneNumber}
                      onChange={RegistrationOperatorHandler} />
                  </div> */}
                    <div className="inpt_bx">
                      <IntlTelInput
                        type="number"
                        preferredCountries={["us"]}
                        defaultCountry="us"
                        containerClassName="intl-tel-input"
                        // utilsScript= 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.0.2/js/utils.js'

                        // utilsScript={'libphonenumber.js'}
                        inputClassName="controlthis"
                        input
                        placeholder="Phone Number"
                        style={{ margin: "2rem" }}
                        separateDialCode
                        name="phoneNumber"
                        onPhoneNumberChange={onChange}
                        format
                        formatOnInit={true}

                        // onSelectFlag={}
                      />
                      ,
                    </div>
                    {/* <div className="inpt_bx">
                    <input type="text" placeholder="Address" required name='address'
                      value={register.address}
                      onChange={RegistrationOperatorHandler} />
                  </div>
                  <div className="inpt_bx">
                    <select name="industryType" id="" required onChange={RegistrationOperatorHandler}>
                      <option value="Industry Type" >Industry Type</option>
                      <option value="Industry Type 1">Industry Type 1</option>
                      <option value="Industry Type 2">Industry Type 2</option>
                      <option value="Industry Type 3">Industry Type 3</option>
                    </select>
                  </div> */}

                    <div>
                      <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={onCaptchaChange}
                      />
                      ,
                    </div>

                    {
                      <div className="inpt_bx">
                        <input
                          type="submit"
                          value={!isLoading ? "Submit" : "Loading.."}
                          // disabled={!isverified}
                          required=""
                        />
                      </div>
                    }
                    <br />
                    <p>
                      Already registered? &nbsp;
                      <Link
                        to="/login"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Log In
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            )}
            {user === "Bank" && (
              <form onSubmit={RegistrationFunction}>
                <div className="sign_up-otr" id="">
                  <div className="sgn_up_cntnt" id="oprtr_cntnt">
                    <div className="inpt_bx">
                      <input
                        type="text"
                        name="bankName"
                        placeholder="Bank Name"
                        required
                        onChange={RegistrationBankHandler}
                      />
                    </div>
                    {/* <div className="inpt_bx">
                      <input type="text" name="bankState" placeholder="Bank State" required onChange={RegistrationBankHandler} />
                    </div> */}
                    {/* <div className="inpt_bx">
                      <input type="text" name='bankCity' placeholder="Bank City" required onChange={RegistrationBankHandler} />
                    </div> */}
                    {/* <div className="inpt_bx">
                      <input type="text" name='bankZip' placeholder="Zipcode" required onChange={RegistrationBankHandler} />
                    </div> */}
                    {/* <div className="inpt_bx">
                      <input type="text" name='bankAddress' placeholder="Bank Address" required onChange={RegistrationBankHandler} />
                    </div> */}
                    {/* <div className="inpt_bx">
                      <select name="bankType" id="" required onChange={RegistrationBankHandler}>
                        <option value="">Bank Type</option>
                        <option value="Bank Type 1">Bank Type 1</option>
                        <option value="Bank Type 2">Bank Type 2</option>
                        <option value="Bank Type 3">Bank Type 3</option>
                      </select>
                    </div>
                    <div className="inpt_bx">
                      <label className="upld_lgo_lbl" htmlFor="upld_lgo">
                        Upload Bank Logo
                      </label>
                      <input type="file" name='bankLogo' id="upld_lgo"
                        placeholder='Upload Bank Logo'
                         onChange={RegistrationBankHandler} />
                    </div> */}

                    {/* <div className='file_bx'>
                  <label className="" htmlFor="upld_lgo">
                      Upload Bank Logo
                    </label>
                    <input  type="file" id="upld_lgo" name='bankLogo' required onChange={RegistrationBankHandler}/>
                    </div> */}
                    <div className="inpt_bx">
                      <input
                        type="email"
                        name="email"
                        placeholder="Bank Email"
                        required
                        onChange={RegistrationBankHandler}
                      />
                    </div>
                    <div className="inpt_bx">
                      <input
                        type="password"
                        name="password"
                        placeholder="Bank Password"
                        required
                        onChange={RegistrationBankHandler}
                      />
                    </div>
                    <div className="inpt_bx">
                      <IntlTelInput
                        type="number"
                        preferredCountries={["us"]}
                        // containerClassName="intl-tel-input"
                        // utilsScript={'libphonenumber.js'}
                        inputClassName="form-control"
                        placeholder="Bank Contact Number"
                        style={{ margin: "2rem" }}
                        separateDialCode
                        name="bankContactNumber"
                        onPhoneNumberChange={onChange}
                        format
                        formatOnInit={true}
                      />
                      ,
                    </div>
                    {isLoading ? (
                      <div className="inpt_bx">
                        <input
                          type="submit"
                          defaultValue="NEXT"
                          required=""
                          value="Loading.."
                        />
                      </div>
                    ) : (
                      // <div className='mainsection bnk_sctn bank_content' style={{fontWeight:'bold',marginLeft:'48%',fontSize:'3rem'}}><FadeLoader
                      // color={'#b07f35'}
                      // /></div>
                      <div className="inpt_bx">
                        <input type="submit" defaultValue="NEXT" required="" />
                      </div>
                    )}
                    {/* <br /> */}
                    <p>
                      Already registered? &nbsp;
                      <Link
                        to="/login"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Log In
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </>
        </div>
      </main>
      {/* Jquery  */}
      {/* WOW JS */}
      {/* Font Awesome JS */}
      {/* Icon Scout */}
      {/* AOS JS */}
      {/* Bootstrap JS */}
      {/*  */}
      {/* Custom JS */}
    </>
  );
};

export default Signup;
