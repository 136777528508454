import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { toast } from "react-toastify";
import { nextFourBank } from "../../Store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Bank_FillupSettings_URL } from "../../Api/Api";
import swal from "@sweetalert/with-react";
import NumberFormat from "react-number-format";

const FormFourBank = (props) => {
  const mydata = useSelector((state) => state.user);
  console.log(mydata, "78900000");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorState, setErrorstate] = useState(false);
  const {
    Legacy_Cash_Bank,
    BankLegacyCashhandler,
    Cash_Management_Bank,
    BankCashManagementhandler,
    Transfer_Existing_Bank_Bank,
    BankTransferExistingBankhandler,
    errosBank,
  } = useForm();
  console.log(Legacy_Cash_Bank, "100001");
  console.log(Cash_Management_Bank, "100002");
  // console.log(errosBank, 'errosBank')
  console.log(Transfer_Existing_Bank_Bank, "100003");
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  useEffect(() => {
    setErrorstate(errosBank);
  }, [errosBank]);
  const NextHandlerFour = (event) => {
    console.log("HITT");
    if (
      Number(Cash_Management_Bank.number_vendors_max) <
      Number(Cash_Management_Bank.number_vendors_min)
    ) {
      errorState.num_vendors_minmaxErr =
        "Maximum value must be greater than the minimum value!";
      setErrorstate({
        ...errorState,
      });
    } else {
      delete errorState.num_vendors_minmaxErr;
      setErrorstate({
        ...errorState,
      });
    }
    for (let x in Cash_Management_Bank) {
      if (x === "value") {
        if (Cash_Management_Bank.business_acc === "yes") {
          if (Cash_Management_Bank.value === "") {
            console.log("inside");
            errorState.value = "Can't be empty!";
            setErrorstate({ ...errorState });
          } else {
            delete errorState.value;
            setErrorstate({ ...errorState });
          }
        } else {
          delete errorState.value;
          setErrorstate({ ...errorState });
        }
      }
      if (
        x !== "vendor_payment_methods" &&
        x !== "value" &&
        String(Cash_Management_Bank[x]).length === 0
      ) {
        errorState[x] = "Can't be empty!";

        setErrorstate({ ...errorState });
      }
    }

    event.preventDefault();

    const res = [...Object.values(Cash_Management_Bank)];

    const finalres = res.map((i) => String(i).length);
    console.log(errorState, "error-State");

    let x = false;
    if (Legacy_Cash_Bank.legacy_cash === "yes") {
      if (Legacy_Cash_Bank.documents_available.length === 0) {
        x = false;
      } else {
        x = true;
      }
    } else {
      x = true;
    }
    //  && finalres.every(x => x !== 0)
    if (
      Object.keys(errorState).length === 0 &&
      x &&
      Cash_Management_Bank.vendor_payment_methods.length !== 0
    ) {
      // props.setFormNo(3)
      // Swal.fire(
      //   'Done!',
      //   'Your Application has been submitted successfully!',
      //   'success'
      // )
      swal(
        "Great!",
        "Your Bank Settings have been saved successfully!",
        "success",
        {
          buttons: { confirm: { text: "OK", className: "sweet-warning" } },
          className: "",
        }
      );
      dispatch(
        nextFourBank({
          Legacy_Cash_Bank,
          Cash_Management_Bank,
          Transfer_Existing_Bank_Bank,
        })
      );
      //   swal({
      //     title: 'Your Application has been submitted successfully!',
      //     className: 'custom-content',
      //     buttons: {
      //         confirm: {text:'OK',className:'sweet-warning'},
      //         // cancel: 'NO',
      //         // confirmButtonColor: '#8CD4F5',
      //     },
      //     type: "success"

      // })
      console.log(
        Legacy_Cash_Bank,
        Cash_Management_Bank,
        Transfer_Existing_Bank_Bank,
        "890"
      );
      console.log(
        mydata.BankDetailsFormFour.legacy_cash,
        "documents_available"
      );
      console.log(
        mydata.BankDetailsFormFour.documents_available,
        "documents_available"
      );

      let thisdata = 0;
      if (
        mydata.BankDetailsFormOne.acc_creation_time.daysormonths === "Years"
      ) {
        thisdata =
          Number(mydata.BankDetailsFormOne.acc_creation_time.value) * 365;
      } else if (
        mydata.BankDetailsFormOne.acc_creation_time.daysormonths === "Months"
      ) {
        thisdata =
          Number(mydata.BankDetailsFormOne.acc_creation_time.value) * 30;
      } else {
        thisdata = Number(mydata.BankDetailsFormOne.acc_creation_time.value);
      }

      let CurrentBankTime = 0;
      console.log(Cash_Management_Bank.value, "CurrentBankTime");
      console.log(Cash_Management_Bank.daysormonthsoryears, "CurrentBankTime");

      if (Cash_Management_Bank.daysormonthsoryears === "Years") {
        CurrentBankTime = Number(Cash_Management_Bank.value) * 365;
      } else if (Cash_Management_Bank.daysormonthsoryears === "Months") {
        CurrentBankTime = Number(Cash_Management_Bank.value) * 30;
      } else {
        CurrentBankTime = Number(Cash_Management_Bank.value);
      }
      console.log(CurrentBankTime, "CurrentBankTime");
      const Data = mydata?.BankDetailsFormOne?.servicesOffered?.map((t) => {
        return {
          service: t,
          price: Number(mydata?.BankDetailsFormOne?.servicesPrices[t]),
        };
      });
      console.log(Data, "1234567890");
      console.log(
        mydata.BankDetailsFormOne.acc_creation_time.value,
        "6767676767"
      );
      const finalData = {
        servicesOffered: Data,
        // acc_creation_time: thisdata,
        input: Number(mydata.BankDetailsFormOne.acc_creation_time.value),
        unit: mydata.BankDetailsFormOne.acc_creation_time.daysormonths,
        convertedToDays: thisdata,
        risk_classification: mydata.BankDetailsFormOne.risk_classification,
        licensing_Type: mydata.BankDetailsFormOne.licensing_Type,
        other: mydata.BankDetailsFormOne.other,
        num_employees_min: mydata.BankDetailsFormOne.num_employees_min,
        num_employees_max:
          mydata.BankDetailsFormOne.num_employees_max.replaceAll(",", ""),
        accepted_states: mydata.BankDetailsFormOne.accepted_states,
        foreign_operations: mydata.BankDetailsFormOne.foreign_operations,
        ownership_Percentage:
          mydata.BankDetailsFormOne.ownership_Percentage_min,
        // ownership_Percentage_max: mydata.BankDetailsFormOne.ownership_Percentage_max,
        us_Citizenship: mydata.BankDetailsFormOne.us_Citizenship,

        products_purchased_providers:
          mydata.BankDetailsFormTwo.products_purchased_providers,
        licensed_provider: mydata.BankDetailsFormTwo.licensed_provider,
        acc_need_min: mydata.BankDetailsFormTwo.accept_CRB_min.replaceAll(
          ",",
          ""
        ),
        acc_need_max: mydata.BankDetailsFormTwo.accept_CRB_max.replaceAll(
          ",",
          ""
        ),
        num_locs_min: mydata.BankDetailsFormTwo.num_locs_min,
        num_locs_max: mydata.BankDetailsFormTwo.num_locs_max.replaceAll(
          ",",
          ""
        ),
        transactions_Per_month_min:
          mydata.BankDetailsFormTwo.transactions_Per_month_min,
        transactions_Per_month_max:
          mydata.BankDetailsFormTwo.transactions_Per_month_max.replaceAll(
            ",",
            ""
          ),
        // managed_square_feet_min:
        //   mydata.BankDetailsFormTwo.managed_square_feet_min,
        // managed_square_feet_max:
        //   mydata.BankDetailsFormTwo.managed_square_feet_max.replaceAll(",", ""),
        // facility_owned: mydata.BankDetailsFormTwo.facility_owned,
        // facility_leased: mydata.BankDetailsFormTwo.facility_leased,
        facility: mydata.BankDetailsFormTwo.facility,

        types_customers: mydata.BankDetailsFormTwo.types_customers,
        principal_business: mydata.BankDetailsFormTwo.principal_business,
        beneficial_owners: mydata.BankDetailsFormTwo.beneficial_owners,
        number_beneficial_owner_min:
          mydata.BankDetailsFormTwo.number_beneficial_owner_min,
        number_beneficial_owner_max:
          mydata.BankDetailsFormTwo.number_beneficial_owner_max.replaceAll(
            ",",
            ""
          ),
        amount_Initial_Deposit_min:
          mydata.BankDetailsFormTwo.amount_Initial_Deposit_min
            ?.slice(1)
            .replaceAll(",", ""),
        amount_Initial_Deposit_max:
          mydata.BankDetailsFormTwo.amount_Initial_Deposit_max
            ?.slice(1)
            .replaceAll(",", ""),
        estimated_total_amount_monthly_deposit_min:
          mydata.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_min
            .slice(1)
            .replaceAll(",", ""),
        estimated_total_amount_monthly_deposit_max:
          mydata.BankDetailsFormTwo.estimated_total_amount_monthly_deposit_max
            .slice(1)
            .replaceAll(",", ""),
        estimated_total_num_monthly_deposit_min:
          mydata.BankDetailsFormTwo.estimated_total_num_monthly_deposit_min,
        estimated_total_num_monthly_deposit_max:
          mydata.BankDetailsFormTwo.estimated_total_num_monthly_deposit_max.replaceAll(
            ",",
            ""
          ),
        anticipate_cash_deposits:
          mydata.BankDetailsFormTwo.anticipate_cash_deposits,
        amount_cash_deposits_min:
          mydata.BankDetailsFormTwo.amount_cash_deposits_min
            ?.slice(1)
            .replaceAll(",", ""),
        amount_cash_deposits_max:
          mydata.BankDetailsFormTwo.amount_cash_deposits_max
            ?.slice(1)
            .replaceAll(",", ""),
        frequency_cash_deposits:
          mydata.BankDetailsFormTwo.frequency_cash_deposits,
        estimated_spend_min: mydata.BankDetailsFormTwo.amount_cash_deposits_max
          ?.slice(1)
          .replaceAll(",", ""),
        estimated_spend_max: mydata.BankDetailsFormTwo.estimated_spend_max
          ?.slice(1)
          .replaceAll(",", ""),
        anticipate_cash_withdrawals:
          mydata.BankDetailsFormTwo.anticipate_cash_withdrawals,
        amount_cash_withdrawals_min:
          mydata.BankDetailsFormTwo.amount_cash_withdrawals_min
            ?.slice(1)
            .replaceAll(",", ""),
        amount_cash_withdrawals_max:
          mydata.BankDetailsFormTwo.amount_cash_withdrawals_max
            ?.slice(1)
            .replaceAll(",", ""),
        frequency_cash_withdrawals:
          mydata.BankDetailsFormTwo.frequency_cash_withdrawals,
        monthly_payroll_min: mydata.BankDetailsFormTwo.monthly_payroll_min
          ?.slice(1)
          .replaceAll(",", ""),
        monthly_payroll_max: mydata.BankDetailsFormTwo.monthly_payroll_max
          ?.slice(1)
          .replaceAll(",", ""),
        cash_pick_ups: mydata.BankDetailsFormTwo.cash_pick_ups,
        frequency_cash_pick_ups:
          mydata.BankDetailsFormTwo.frequency_cash_pick_ups,
        estimated_cash_pick_ups_min:
          mydata.BankDetailsFormTwo.estimated_cash_pick_ups_min
            ?.slice(1)
            .replaceAll(",", ""),
        estimated_cash_pick_ups_max:
          mydata.BankDetailsFormTwo.estimated_cash_pick_ups_max
            ?.slice(1)
            .replaceAll(",", ""),
        Company_Documentation:
          mydata.BankDetailsFormThree.Company_Documentation,

        legacy_cash: Legacy_Cash_Bank.legacy_cash,
        documents_available: Legacy_Cash_Bank.documents_available,
        business_acc: Cash_Management_Bank.business_acc,
        // length_time: CurrentBankTime,
        lengthTimeInput: Number(Cash_Management_Bank.value),
        lengthTimeUnit: Cash_Management_Bank.daysormonthsoryears,
        lengthTimeConvertedToDays: CurrentBankTime,
        payroll_service: Cash_Management_Bank.payroll_service,
        paid_cash: Cash_Management_Bank.paid_cash,
        payroll_accepted: Cash_Management_Bank.payroll_accepted,
        taxes_cash: Cash_Management_Bank.taxes_cash,
        penalty_cash: Cash_Management_Bank.penalty_cash,
        tax_payment: Cash_Management_Bank.tax_payment,
        number_vendors_min: Cash_Management_Bank.number_vendors_min,
        number_vendors_max: Cash_Management_Bank.number_vendors_max.replaceAll(
          ",",
          ""
        ),
        vendor_payment_methods: Cash_Management_Bank.vendor_payment_methods,
        international_vendor: Cash_Management_Bank.international_vendor,
        prev_bank_verified: Transfer_Existing_Bank_Bank.prev_bank_verified,
        prev_bank_aware: Transfer_Existing_Bank_Bank.prev_bank_aware,
      };
      console.log(finalData, "postthis");
      axios
        .post(Bank_FillupSettings_URL, finalData, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          localStorage.setItem("formFilled", true);
          console.log(res.data, "the result");
          navigate("/matching-operators");
        })
        .catch((err) => console.log(err, "error"));
      navigate("/bank-applications");
      dispatch(
        nextFourBank({
          Legacy_Cash_Bank,
          Cash_Management_Bank,
          Transfer_Existing_Bank_Bank,
        })
      );
    } else {
      if (Cash_Management_Bank.vendor_payment_methods.length === 0) {
        toast.error(
          "Please select atleast one option from vendor payment methods!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      if (!x) {
        toast.error(
          "Please select atleast one option from documents available!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const PreviousHandler = (event) => {
    event.preventDefault();
    props.setFormNo(3);
  };
  return (
    <section id="section4" className="section4">
      <form action="">
        <div className="lgcy_cash bg_grey">
          <p className="hdng_p">Legacy Cash</p>
          <div className="form-group bnk4_frm_grp">
            <h5 className="fromgroup_hdng">
              Does your business have legacy cash?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  name="legacy_cash"
                  checked={Legacy_Cash_Bank.legacy_cash === "yes"}
                  onChange={BankLegacyCashhandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          {Legacy_Cash_Bank.legacy_cash === "yes" && (
            <div className="check_form-group">
              <h5 className="fromgroup_hdng">Check Documents Available:</h5>
              <div className="check_checking">
                <input
                  type="checkbox"
                  id="icheck1"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                  )}
                  value="Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                  onChange={BankLegacyCashhandler}
                />
                <label htmlFor="icheck1">
                  Quarterly federal, state, and municipal tax returns for each
                  of the last five years, if applicable
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "Financial statements, preferably audited that have been prepared for the business"
                  )}
                  value="Financial statements, preferably audited that have been prepared for the business"
                  onChange={BankLegacyCashhandler}
                />
                <label>
                  Financial statements, preferably audited that have been
                  prepared for the business
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                  )}
                  value="Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                  onChange={BankLegacyCashhandler}
                />
                <label>
                  Point-of-Sale (seed-to-sale) system reports: monthly and
                  annual POS reports showing all wholesale inventory purchases
                  and retail sales transactions
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                  )}
                  value="State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                  onChange={BankLegacyCashhandler}
                />
                <label>
                  State database reporting: reports of amounts reported to the
                  state tracking database for as long as the state database has
                  been active, if applicable
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                  )}
                  value="Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                  onChange={BankLegacyCashhandler}
                />
                <label>
                  Armored courier confirmed cash count: To the extent cash was
                  couriered to a vault or other safekeeping location, obtain
                  confirmed cash count slips from armored courier evidencing
                  amount of cash counted and date of cash pick-up/count
                </label>
              </div>
              <div className="check_checking">
                <input
                  type="checkbox"
                  name="documents_available"
                  checked={Legacy_Cash_Bank.documents_available.includes(
                    "Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                  )}
                  value="Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                  onChange={BankLegacyCashhandler}
                />
                <label>
                  Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt
                  showing total cash vaulted and custodianship of cash
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="csh_mngmnt bg_grey">
          <p className="hdng_p">Cash Management</p>
          <div className="parent_forminput_group form-group bnk4">
            <h5 className="fromgroup_hdng">
              Business with no current bank account accepted?
            </h5>
            <div className="switch_box">
              <h4>no</h4>
              <label className="switch2">
                <input
                  type="checkbox"
                  checked={Cash_Management_Bank.business_acc === "yes"}
                  name="business_acc"
                  onChange={BankCashManagementhandler}
                />
                <span className="slider round" />
              </label>
              <h4>yes</h4>
            </div>
          </div>
          {Cash_Management_Bank.business_acc === "yes" && (
            <div className="form-input-group">
              <label htmlFor="">
                Minimum Length of time for current bank account to be open:
              </label>
              <div className="bnk_add_grp" style={{ justifyContent: "start" }}>
                <input
                  type="number"
                  className="form-control"
                  onWheel={(e) => e.target.blur()}
                  defaultValue=""
                  name="value"
                  onInput={(e) => {
                    if (e.target.value.length > 3) {
                      e.target.value = e.target.value.slice(0, 3);
                    }
                  }}
                  value={Cash_Management_Bank.value}
                  onChange={BankCashManagementhandler}
                  style={{ width: "200px" }}
                />
                {errorState?.value && (
                  <p style={{ color: "red" }}>{errorState.value}</p>
                )}

                <select
                  name="daysormonthsoryears"
                  className="form-control"
                  style={{
                    width: "150px",
                    height: "50px",
                    cursor: "pointer",
                    marginLeft: "1rem",
                  }}
                  onChange={BankCashManagementhandler}
                >
                  <option
                    selected={
                      Cash_Management_Bank.daysormonthsoryears === "Years"
                        ? true
                        : false
                    }
                  >
                    Years
                  </option>
                  <option
                    selected={
                      Cash_Management_Bank.daysormonthsoryears === "Months"
                        ? true
                        : false
                    }
                  >
                    Months
                  </option>
                  <option
                    selected={
                      Cash_Management_Bank.daysormonthsoryears === "Days"
                        ? true
                        : false
                    }
                  >
                    Days
                  </option>
                </select>
              </div>
            </div>
          )}
          <div className="parent_forminput_group">
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Use of payroll service accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="payroll_service"
                    checked={Cash_Management_Bank.payroll_service === "yes"}
                    value={Cash_Management_Bank.payroll_service}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Employees paid in cash accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="paid_cash"
                    checked={Cash_Management_Bank.paid_cash === "yes"}
                    value={Cash_Management_Bank.paid_cash}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Use of payroll cards accepted?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="payroll_accepted"
                    checked={Cash_Management_Bank.payroll_accepted === "yes"}
                    value={Cash_Management_Bank.payroll_accepted}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">Taxes paid in cash accepted?</h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="taxes_cash"
                    checked={Cash_Management_Bank.taxes_cash === "yes"}
                    value={Cash_Management_Bank.taxes_cash}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Taxes penalties paid in cash accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="penalty_cash"
                    checked={Cash_Management_Bank.penalty_cash === "yes"}
                    value={Cash_Management_Bank.penalty_cash}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Not current on tax payments with the state accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="tax_payment"
                    checked={Cash_Management_Bank.tax_payment === "yes"}
                    value={Cash_Management_Bank.tax_payment}
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group">
              <div className="bnk_add_grp">
                <p>Number of vendors paid monthly:</p>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Minimum:</label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onInput={(e) => {
                      if (e.target.value.length > 2) {
                        e.target.value = e.target.value.slice(0, 2);
                      }
                    }}
                    name="number_vendors_min"
                    value={Cash_Management_Bank.number_vendors_min}
                    onChange={BankCashManagementhandler}
                    className="form-control"
                    defaultValue={10}
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.number_vendors_min && (
                    <p style={{ color: "red" }}>
                      {errorState.number_vendors_min}
                    </p>
                  )}
                </div>
                <div className="pg1-prt-2-lwr-inrfld bnk1a">
                  <label>Maximum:</label>
                  <NumberFormat
                    style={{ width: "20rem" }}
                    maxLength={19}
                    value={Cash_Management_Bank.number_vendors_max}
                    // format={"$##,###,###,###"}
                    // 78,979,797,979,797
                    thousandSeparator={","}
                    // decimalSeparator={"."}
                    // decimalScale={2}
                    className="form-control"
                    onChange={BankCashManagementhandler}
                    name="number_vendors_max"
                  />
                  <a href="javascript:void(0);" className="nd3-a">
                    <i className="fal fa-plus" />
                  </a>
                  {errorState?.number_vendors_max && (
                    <p style={{ color: "red" }}>
                      {errorState.number_vendors_max}
                    </p>
                  )}
                </div>
              </div>
              {errorState.num_vendors_minmaxErr && (
                <p style={{ color: "red", marginTop: "10rem" }}>
                  {errorState.num_vendors_minmaxErr}
                </p>
              )}
            </div>

            <div className="form-input-group">
              <label>Accepted vendor payment methods:</label>
              <div className="frm_radio_prnt">
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="ACH"
                    checked={Cash_Management_Bank.vendor_payment_methods.includes(
                      "ACH"
                    )}
                    name="vendor_payment_methods"
                    onChange={BankCashManagementhandler}
                    id="ACH1"
                  />
                  {/* <span class="radio"></span>  */}
                  <label htmlFor="ACH1" className="radio-button">
                    {" "}
                    ACH
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Check"
                    checked={Cash_Management_Bank.vendor_payment_methods.includes(
                      "Check"
                    )}
                    name="vendor_payment_methods"
                    onChange={BankCashManagementhandler}
                    id="check1"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="check1" className="radio-button">
                    {" "}
                    Check
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Wire"
                    checked={Cash_Management_Bank.vendor_payment_methods.includes(
                      "Wire"
                    )}
                    name="vendor_payment_methods"
                    onChange={BankCashManagementhandler}
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="wire1" className="radio-button">
                    {" "}
                    Wire
                  </label>
                </div>
                <div className="pg3-mn-inrfld">
                  <input
                    type="checkbox"
                    value="Cash"
                    checked={Cash_Management_Bank.vendor_payment_methods.includes(
                      "Cash"
                    )}
                    name="vendor_payment_methods"
                    onChange={BankCashManagementhandler}
                    id="cash1"
                  />
                  {/* <span class="radio"></span> */}
                  <label htmlFor="cash1" className="radio-button">
                    {" "}
                    Cash
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <h5 className="fromgroup_hdng">
                Use of International vendors accepted:
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    checked={
                      Cash_Management_Bank.international_vendor === "yes"
                    }
                    name="international_vendor"
                    onChange={BankCashManagementhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="transferba bg_grey">
          <p className="hdng_p">Transfer from Existing Bank Account</p>
          <div className="parent_forminput_group">
            <div className="form-group w100">
              <h5 className="fromgroup_hdng">
                Non-verified source of deposit from original bank account
                accpted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="prev_bank_verified"
                    checked={
                      Transfer_Existing_Bank_Bank.prev_bank_verified === "yes"
                    }
                    onChange={BankTransferExistingBankhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
            <div className="form-group w100">
              <h5 className="fromgroup_hdng">
                Previous bank not aware of cannabis-related business accepted?
              </h5>
              <div className="switch_box">
                <h4>no</h4>
                <label className="switch2">
                  <input
                    type="checkbox"
                    name="prev_bank_aware"
                    checked={
                      Transfer_Existing_Bank_Bank.prev_bank_aware === "yes"
                    }
                    onChange={BankTransferExistingBankhandler}
                  />
                  <span className="slider round" />
                </label>
                <h4>yes</h4>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="button-container">
        <button className="btn btn-prev" onClick={PreviousHandler}>
          Previous
        </button>
        <button
          className="btn btn-next"
          type="submit"
          onClick={NextHandlerFour}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default FormFourBank;
