import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashBoardDataOperator } from "../../Store/DashboardSlice";
import { FcCheckmark } from "react-icons/fc";
import { GiSandsOfTime } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const OperatorDashboard = () => {
  const { data, isError, isLoading, message } = useSelector(
    (state) => state.Dashboard
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(data, "data56");
  useEffect(() => {
    dispatch(fetchDashBoardDataOperator());
  }, []);

  return (
    <div className="mainsection bnk_sctn">
      <div className="dshboard_cntnt">
        <div className="parent_top_cntnt_outr">
          <div className="parent_top_cntnt">
            <div className="tptc_total_bx">
              <h4>Total Active Applications</h4>
              <span className="balance">{data?.activeApplications}</span>
              {/* <h6>0 (last record)</h6> */}
            </div>
            <div className="tptc_total_bx">
              <h4>Number of Proposals Received</h4>
              <span className="balance">{data?.numProposalReceived} </span>
              {/* <h6>0 (last record)</h6> */}
            </div>
            <div className="tptc_total_bx">
              <h4>Number of Total accounts opened</h4>
              <span className="balance">{data?.numAccountOpened} </span>
              {/* <h6>0 (last record)</h6> */}
            </div>
          </div>
        </div>
        <div className="parent_btm-cntnt_outr">
          <div className="prnt_btm_cntnt">
            <h3 className="prbtc_outr">
              List of Recent proposals received Top 10
            </h3>
            {/* <div className="prnt_btm_search">
        <div className="search-container">
          <form action="">
            <input type="text" placeholder="Search by Customer" name="search" />
            <button type="submit">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>
        <div className="prnt_btn">
          <a href="javascript:void(0);" className="cmn_btn">
            Demo btn
          </a>
        </div>
      </div> */}
            <div className="prnt_btm_table">
              <table>
                <tbody>
                  <tr>
                    <th>Application ID</th>
                    <th>DBA</th>
                    {/* <th>Proposal ID</th> */}
                    <th>Services Offered</th>
                    <th>Proposal Sent On</th>
                    <th>Proposal Accepted</th>
                    <th>Proposal Accepted On</th>
                    <th>Underwriting</th>
                    <th>Underwriting Process</th>
                    <th>Account Opened</th>
                  </tr>
                  {data &&
                    data?.recentProposalReceived?.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.application_Id}</td>
                          {/* <td>{item.proposalID}</td> */}
                          <td>{item.application_dba}</td>
                          <td>
                            {item.servicesOffered
                              .map((x) => x.service)
                              .join(",")}
                          </td>
                          <td>{item.proposalSentOn}</td>
                          <td>
                            {item.proposalAccepted ? (
                              <FcCheckmark />
                            ) : (
                              <GiSandsOfTime />
                            )}
                          </td>
                          <td>{item.proposalAcceptedOn}</td>
                          <td>
                            {item.underwriting ? <FcCheckmark /> : <>waiting</>}
                          </td>
                          <td>{item.underwritingProcess}</td>
                          <td>{item.accountOpened}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorDashboard;
