import axios from "axios";
import React, { useEffect, useState } from "react";
import { OperatorApplicationsList_URL } from "../../Api/Api";
import { IconContext } from "react-icons";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { GiSandsOfTime } from "react-icons/gi";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const OperatorApplications = () => {
  const [myApplications, setMyApplications] = useState([]);
  useEffect(() => {
    axios
      .get(OperatorApplicationsList_URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        console.log(response.data, "myApplications");
        setMyApplications(response.data);
      })
      .catch((err) => console.log(err, "err"));
  }, []);
  return (
    <div className="mainsection bnk_sctn">
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2>My Applications List</h2>
          <br />
        </div>
        <div className="outr_table">
          {" "}
          <table>
            <tbody>
              <tr>
                {/* <th>Name</th> */}
                <th>Application ID</th>
                <th>DBA</th>
                <th>Created at</th>
                <th>Application Status</th>
                <th>View</th>
              </tr>
              {myApplications &&
                myApplications.map((application, index) => {
                  return (
                    <tr>
                      {/* <td>Application {index + 1}</td> */}
                      <td>{application.application_Id}</td>
                      <td>{application.dba}</td>
                      <td>{application.createdAt}</td>
                      <td>
                        {/* <div className="status">
            <div className="status__light">
              <div className="status__light__ring" />
              <div className="status__light__led" />
            </div>
            <div className="status__message">{application.approve ? 'Accepted' : "Pending"}</div>
          </div> */}
                        {application.approved ? (
                          <IconContext.Provider
                            value={{ color: "blue", size: "3rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <FcApproval /> Approved
                            </div>
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider
                            value={{ color: "orange", size: "3rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <FcHighPriority />
                              Waiting{" "}
                            </div>
                          </IconContext.Provider>
                        )}
                      </td>
                      <td>
                        <div className="status">
                          <div
                            style={{
                              marginLeft: "2rem",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to={`/operator-applications/${application.application_Id}`}
                            >
                              <BsEyeFill />
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OperatorApplications;
