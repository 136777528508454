import axios from "axios";
import React, { useEffect, useRef } from "react";
import {
  BankDeclineAccount,
  BankOpenAccount,
  Bank_GetAll_AcceptedForms_URL,
} from "../../Api/Api";
import { useState } from "react";
import { BsEnvelopeCheckFill, BsEyeFill, BsToggleOn } from "react-icons/bs";
import {
  TbSortAscendingNumbers,
  TbSortDescendingNumbers,
} from "react-icons/tb";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { IconContext } from "react-icons";
import { FcCancel, FcCheckmark, FcOpenedFolder } from "react-icons/fc";
import { GiSandsOfTime } from "react-icons/gi";
import { AiTwotoneBank } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import swal from "sweetalert";
import { ThreeDots } from "react-loader-spinner";
const AcceptedApplications = () => {
  const [sectionName, setSectionName] = useState("inprogress");
  const [toggle, setToggle] = useState(2);
  const [header, setHeader] = useState("");
  const [sorting, serSorting] = useState("Descending");
  const [api, setApi] = useState(false);
  const targetRef = useRef(null);
  console.log(targetRef, "targetRef");
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [targetRef]);
  function handleClickOutside(event) {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      // alert("You clicked outside of me!");
      setShow(false);
    }
  }
  const dateMod = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const [accptedApplications, setAcceptedApplications] = useState([]);
  const [show, setShow] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  console.log(state, "from-state");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(Bank_GetAll_AcceptedForms_URL, {
        params: {
          state: sectionName,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "myformsaccptd");
        // const data = res.data.map(x=> new Date(x.createdAt))
        // data.sort(function(a, b){
        //     return a - b
        // });

        // console.log(data,'datatobern');
        // const newData = data.map(i=>  i.toString().split(' '))
        // console.log(newData,'newData')
        // // const startDate = `${resultStartDate[3]}-${dateMod[resultStartDate[1]]}-${resultStartDate[2]}
        // const finalData = newData.map(o=> `${o[3]}-${dateMod[o[1]]}-${o[2]}`)
        // console.log(finalData,'234')
        // const myData = res.data.map(t=> {
        //   for(let j =0; j< finalData.length ; j ++){
        //       if(t.createdAt === finalData[j]){
        //          return t
        //       }
        //   }
        // })
        // console.log(myData,'890890')
        setIsLoading(false);
        if (sorting === "Descending") {
          setAcceptedApplications([...res.data]);
        } else {
          setAcceptedApplications([...res.data.reverse()]);
        }
        // setAcceptedApplications(res.data)
      })
      .catch((err) => console.log(err, "errooo"));
  }, [api, sectionName]);
  useEffect(() => {
    const newMydata = [...accptedApplications];
    if (sorting === "Descending") {
      setAcceptedApplications([...accptedApplications.reverse()]);
    } else {
      setAcceptedApplications([...accptedApplications.reverse()]);
    }
  }, [sorting]);
  const OpenAccount = (application_Id) => {
    console.log(application_Id, "890");
    swal({
      title: "Are you sure you want to open account?",
      buttons: {
        confirm: "YES",
        cancel: "Cancel",
        deny: "NO",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        axios
          .get(`${BankOpenAccount}/${application_Id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            swal("Congrats!", `${res.data.message}`, "success");
            setApi(!api);
          })
          .catch((err) => {
            console.log(err, "error-open-acc");
            swal("Oops!", `${err.response.data.message}`, "error");
          });
      } else {
        // setActivitystatus('No')
        console.log("Declined!");
      }
    });
  };
  const DeclineAccount = (application_Id) => {
    swal({
      title: "Are you sure you want to decline?",
      buttons: {
        confirm: "YES",
        cancel: "Cancel",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        axios
          .get(`${BankDeclineAccount}/${application_Id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            swal("Sorry!", `${res.data.message}`, "error");
            setApi(!api);
          })
          .catch((err) => {
            console.log(err, "error-open-acc");
            swal("Oops!", `${err.response.data.message}`, "error");
          });
      } else {
        // setActivitystatus('No')
        console.log("Declined!");
      }
    });
  };
  const AccountStatusAction = (application_Id) => {
    swal({
      title: "Are you sure you want to open account?",
      buttons: {
        confirm: "YES",
        cancel: "Cancel",
        decline: "Decline",
      },
      type: "success",
    }).then((okay) => {
      console.log(typeof okay, "7777766");
      if (typeof okay === "boolean") {
        axios
          .get(`${BankOpenAccount}/${application_Id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            swal("Congrats!", `${res.data.message}`, "success");
            setApi(!api);
          })
          .catch((err) => {
            console.log(err, "error-open-acc");
            swal("Oops!", `${err.response.data.message}`, "error");
          });
      } else if (typeof okay === "object") {
        console.log("cancel 7777766");
      } else {
        axios
          .get(`${BankDeclineAccount}/${application_Id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            swal("Sorry!", `${res.data.message}`, "error");
            setApi(!api);
          })
          .catch((err) => {
            console.log(err, "error-open-acc");
            swal("Oops!", `${err.response.data.message}`, "error");
          });
      }
    });
  };
  useEffect(() => {
    console.log(state[0].startDate?.toString().split(" "), "state");
    let target1 = state[0]?.startDate?.toString().split(" ").slice(0, 4);
    let target2 = state[0]?.endDate?.toString().split(" ").slice(0, 4);
    if (target1 && target2) {
      if (
        !(
          target1[1] === target2[1] &&
          target1[2] === target2[2] &&
          target1[3] === target2[3]
        )
      ) {
        setShow(false);
        setHeader(
          `Showing data from ${state[0].startDate
            ?.toString()
            .slice(0, 15)} to ${state[0].endDate?.toString().slice(0, 15)}`
        );
      }
    }
    //  console.log(state.startDate.split('').slice(0,4),'state')
  }, [state]);
  return (
    <div className="mainsection bnk_sctn">
      {/* <div style={{marginLeft:'80%'}}>
           <div className="switch_box">
        
                          <label className="switch2">
                            <input 
                            style={{}}
                            type="checkbox" checked={showdata === 'Yes'} onChange={(event)=>
                            {
                              if(event.target.checked){
                                setShowData('Yes')
                              }else{
                                setShowData('No')
                              }
                            }}
                              name='products_purchased_providers'
                              />
                            <span className="slider round" />
                          </label>
                          { showdata === 'Yes' ? <h4>Turn Off</h4> : <h4>Turn On</h4> }
                        </div>
                        </div> */}

      <div className="bank_content">
        <div className="cmn_hdr">
          <h2
          // style={{ fontWeight: "bold", fontSize: "16rem" }}
          >
            Accepted Applications List
          </h2>
          <br />
          <div className="tabs">
            <div
              className={`${toggle === 2 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(2);
                setSectionName("inprogress");
              }}
            >
              In Progress
            </div>
            <div
              className={`${toggle === 3 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(3);
                setSectionName("open");
              }}
            >
              Opened
            </div>
            <div
              className={`${toggle === 4 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(4);
                setSectionName("decline");
              }}
            >
              Declined
            </div>
            <div
              className={`${toggle === 1 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(1);
                setSectionName("All");
              }}
            >
              All
            </div>
          </div>
          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>{header}</p>
            <div className="calendar" ref={targetRef}>
              <h3 className="srange" onClick={() => setShow(!show)}>
                Filter Application By Date
              </h3>
              {show && (
                <DateRange
                  id="daterange"
                  onChange={(item) => {
                    console.log(item.selection.startDate, "datee");
                    console.log(item.selection.endDate, "datee");
                    const startDate = new Date(
                      item.selection.startDate
                    ).toISOString();
                    const endDate = new Date(
                      item.selection.endDate
                    ).toISOString();
                    // console.log(
                    //   item.selection.startDate.toString().split(" "),
                    //   "startdate"
                    // );
                    // const resultStartDate = item.selection.startDate
                    //   .toString()
                    //   .split(" ");
                    // const resultEndDate = item.selection.endDate
                    //   .toString()
                    //   .split(" ");
                    // console.log(resultStartDate, resultEndDate, "8888888");
                    // const startDate = `${dateMod[resultStartDate[1]]}-${
                    //   resultStartDate[2]
                    // }-${resultStartDate[3]}`;
                    // const endDate = `${dateMod[resultEndDate[1]]}-${
                    //   resultEndDate[2]
                    // }-${resultEndDate[3]}`;
                    // console.log(startDate, "8888888");
                    // console.log(endDate, "8888888");
                    // {
                    //   params: {
                    //     startDate: startDate;
                    //     endDate: endDate;
                    //   }
                    // }
                    if (startDate !== endDate) {
                      setShow(false);
                    }
                    axios
                      .get(Bank_GetAll_AcceptedForms_URL, {
                        params: {
                          startDate: startDate,
                          endDate: endDate,
                          state: sectionName,
                        },
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                      .then((res) => {
                        console.log(res.data, "date-filteration");
                        if (sorting === "Descending") {
                          setAcceptedApplications([...res.data]);
                        } else {
                          setAcceptedApplications([...res.data.reverse()]);
                        }
                      })
                      .catch((err) => {
                        console.log(err, "error");
                      });

                    setState([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        {!isLoading ? (
          <div className="outr_table">
            <table>
              <tbody>
                <tr>
                  {/* <th>Applicants</th> */}
                  <th>Application ID</th>
                  <th>Application Date</th>
                  <th>
                    Acceptance Date{" "}
                    <>
                      {sorting === "Ascending" ? (
                        <span
                          onClick={() => {
                            serSorting("Descending");
                            // setAcceptedApplications(accptedApplications.reverse())
                          }}
                        >
                          <TbSortAscendingNumbers />
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            serSorting("Ascending");
                            // setAcceptedApplications(accptedApplications.reverse())vvv
                          }}
                        >
                          {" "}
                          <TbSortDescendingNumbers />
                        </span>
                      )}
                    </>
                  </th>
                  <th>Proposal Sent</th>
                  <th>Proposal Sent On</th>
                  <th>Accepted</th>
                  <th>Proposal Accepted On</th>
                  <th>Account Status</th>
                  {/* <th>Decline</th> */}
                  <th>View</th>
                </tr>
                {!isLoading ? (
                  accptedApplications !== null &&
                  accptedApplications.map((applicant, index) => {
                    return (
                      <tr>
                        {/* <td><Link to={`/matching-operators/${applicant.application_Id}` } target='_blank'>Applicant {index + 1}</Link></td> */}
                        <td>
                          <Link
                            state={{ category: "accepted applications" }}
                            to={`/accepted-applications/${applicant.application_Id}`}
                          >
                            {applicant.application_Id}
                          </Link>
                        </td>
                        <td>
                          {new Date(applicant.createdAt).toLocaleDateString(
                            "en-US"
                          )}
                        </td>
                        <td>
                          {new Date(
                            applicant.bankAcceptedOn
                          ).toLocaleDateString("en-US")}
                        </td>
                        <td>
                          {applicant.proposalSent ? (
                            <IconContext.Provider
                              value={{ color: "green", size: "32px" }}
                            >
                              <div>
                                <BsEnvelopeCheckFill />{" "}
                              </div>
                            </IconContext.Provider>
                          ) : (
                            <IconContext.Provider
                              value={{ color: "orange", size: "32px" }}
                            >
                              <div>
                                <GiSandsOfTime />{" "}
                              </div>
                            </IconContext.Provider>
                          )}
                        </td>
                        <td>
                          {new Date(
                            applicant.proposalSentOn
                          ).toLocaleDateString("en-US")}
                        </td>
                        <td>
                          {applicant.proposalAccepted ? (
                            <IconContext.Provider
                              value={{ color: "blue", size: "32px" }}
                            >
                              <div>
                                <FcCheckmark />{" "}
                              </div>
                            </IconContext.Provider>
                          ) : (
                            <IconContext.Provider
                              value={{ color: "orange", size: "32px" }}
                            >
                              <div>
                                <GiSandsOfTime />{" "}
                              </div>
                            </IconContext.Provider>
                          )}
                        </td>
                        <td>{applicant.proposalAcceptedOn}</td>
                        {/* <td>{String(applicant.accountOpened)}</td> */}
                        <td>
                          <div style={{ marginLeft: "3rem" }}>
                            {applicant.accountOpened === "Waiting" && (
                              <button
                                onClick={() => {
                                  AccountStatusAction(applicant.application_Id);
                                }}
                                disabled={!applicant.proposalAccepted}
                                style={{
                                  alignItems: "center",
                                  border: "none",
                                  background: "none",
                                  paddingLeft: "",
                                  cursor: "pointer",
                                  width: "40px",
                                  color: "green",
                                  height: "40px",
                                }}
                              >
                                <AiTwotoneBank />
                              </button>
                            )}
                            {applicant.accountOpened === "Account Opened" && (
                              <>Opened &#9989;</>
                            )}
                            {applicant.accountOpened === "Account Declined" && (
                              <>Declined❌</>
                            )}
                          </div>
                          {/* <button className='notification_btn' style={{height:'40px',width:'90%'}}><FcOpenedFolder/></button> */}
                        </td>
                        {/* <td>
                  { applicant.accountOpened !== 'Account Declined' ?
                    <button 
                    disabled={!applicant.proposalAccepted} onClick={()=>{DeclineAccount(applicant.application_Id)}}
                    style={{color: "red",border:'none',background:'none',marginLeft:'1.2rem',cursor:'pointer'}}><IconContext.Provider value={{ color: "", size:'25px'}}><MdCancel/></IconContext.Provider></button>
                    : <>Declined❌</>
                  }
                </td> */}
                        <td>
                          <div className="status">
                            {/* <div className="status__light">
                    <div className="status__light__ring" />
                    <div className="status__light__led" />
                  </div>
                  
                  <div className="status__message">Send Request</div> */}
                            {/* <button className='notification_btn'
                 style={{borderRadius:'10rem'}}
                 >SEND  <IoMdSend/></button> */}
                            {/* <IoMdSend/> */}
                            <div
                              style={{
                                marginLeft: "2rem",
                                fontSize: "2rem",
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                style={{ color: "black" }}
                                state={{ category: "accepted applications" }}
                                to={`/accepted-applications/${applicant.application_Id}`}
                              >
                                <BsEyeFill />
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div
                    className="mainsection bnk_sctn bank_content"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "60%",
                      fontSize: "3rem",
                    }}
                  >
                    <FadeLoader color={"#b07f35"} />
                  </div>
                )}
                {!Array.isArray(accptedApplications) && <h3>No Match.</h3>}
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ marginLeft: "50rem" }}>
            <ThreeDots
              height="60"
              width="60"
              radius="9"
              color="#b07f35"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptedApplications;
