import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextOne, nextFour, nextThree, nextTwo } from "../Store/userSlice";
import formValue from "../Helper/FromValue";
import ValidateForm from "form-validation-react";

import { Textbox } from "react-inputs-validation";
import useForm from "../CustomHook/useForm";
import FormOne from "../Components/OperatorPages/FormOne";
import FormTwo from "../Components/OperatorPages/FormTwo";
import FormThree from "../Components/OperatorPages/FormThree";
import FormFour from "../Components/OperatorPages/FormFour";
import { useNavigate } from "react-router-dom";
import FormFive from "../Components/OperatorPages/FormFive";
import ServicesWanted from "../Components/OperatorPages/ServicesWanted";

const Homepage = () => {
  const dispatch = useDispatch();
  //Final submit function
  const formLogin = () => {
    console.log("Callback function when form is submitted!");
    // console.log("Form Values ", values);
  };

  const formInfo = useSelector((state) => state.user);
  console.log(formInfo, "information");
  const [formNo, setFormNo] = useState(1);
  // // Risk classification
  const [risk_classification, setRisk_classification] = useState(
    formInfo.operatorDetailsFormOne.risk_classification
  );
  // Legal_Business
  // const [Legal_Business, setLegal_Business] = useState({
  //   name: formInfo.operatorDetailsFormOne.name,
  //   dba: formInfo.operatorDetailsFormOne.dba,
  //   physical_Address: formInfo.operatorDetailsFormOne.physical_Address,
  //   mailing_Address: formInfo.operatorDetailsFormOne.mailing_Address,
  //   federal_EIN: formInfo.operatorDetailsFormOne.federal_EIN,
  //   cannabis_License_Number: formInfo.operatorDetailsFormOne.cannabis_License_Number,
  //   licensing_Type: formInfo.operatorDetailsFormOne.licensing_Type,
  //   other: formInfo.operatorDetailsFormOne.other,
  //   num_employees: formInfo.operatorDetailsFormOne.num_employees,
  //   foreign_operations: formInfo.operatorDetailsFormOne.foreign_operations,

  // })
  //Primary Information
  const [Primary_Information, setPrimary_Information] = useState({
    primary_contact_name: formInfo.operatorDetailsFormOne.primary_contact_name,
    dob: formInfo.operatorDetailsFormOne.dob,
    address: formInfo.operatorDetailsFormOne.address,
    mail: formInfo.operatorDetailsFormOne.mail,
    primary_Phone: formInfo.operatorDetailsFormOne.primary_Phone,
    minimum_ownership: formInfo.operatorDetailsFormOne.minimum_ownership,
    authority_sign: formInfo.operatorDetailsFormOne.authority_sign,
    us_Citizenship: formInfo.operatorDetailsFormOne.us_Citizenship,
  });

  // Operational Details:-
  const [Operational_Details, setOperational_Details] = useState({
    products_purchased_providers: "",
    licensed_provider: "",
    num_locs: "",
    transactions_Per_month: "",
    managed_square_feet: "",
    purchase_amount_per_sale: "",
    num_plants: "",
    average_quantity_per_sale: "",
    facility_owned: "",
    facility_leased: "",
    lease_term: "",
    egmi: "",
    types_customers: "",
  });
  //Other Operational Details:-
  const [Other_Operational_Details, setOther_Operational_Details] = useState({
    principal_business: "",
    beneficial_owners: "",
    number_beneficial_owner: "",
  });
  // Additional_Locs :-
  const [Additional_Locs, setAdditional_Locs] = useState({
    loc_name: "",
    license: "",
    deposit_acc_need: "",
    loc_address: "",
  });

  // Anticipated_Transaction_Activity :-
  const [
    Anticipated_Transaction_Activity,
    setAnticipated_Transaction_Activity,
  ] = useState({
    amount_Initial_Deposit: "",
    source_Initial_Deposit: "",
    estimated_total_amount_monthly_deposit: "",
    estimated_total_num_monthly_deposit: "",
    anticipate_cash_deposits: "",
    amount_cash_deposits: "",
    frequency_cash_deposits: "",
    estimated_spend: "",
    anticipate_cash_withdrawals: "",
    amount_cash_withdrawals: "",
    frequency_cash_withdrawals: "",
    monthly_payroll: "",
    cash_pick_ups: "",
    frequency_cash_pick_ups: "",
    estimated_cash_pick_ups: "",
  });

  // Legacy_Cash:-
  const [Legacy_Cash, setLegacy_Cash] = useState({
    legacy_cash: "",
    documents_available: [],
  });

  // Cash Management:-
  const [Cash_Management, setCash_Management] = useState({
    business_acc: "",
    bank_name: "",
    length_time: "",
    reason_to_close: "",
    payroll_service: "",
    paid_cash: "",
    payroll_accepted: "",
    taxes_cash: "",
    penalty_cash: "",
    tax_payment: "",
    number_vendors: "",
    vendor_payment_methods: "",
    international_vendor: "",
    electronic_payment: "",
    current_cash_managment: "",
    international_vendor: "",
    electronic_payment: "",
    current_cash_managment: "",
  });

  console.log(Cash_Management, "Cash_Management");
  // Electronic Payments Settlement

  const [Electronic_Payments_Settlement, setElectronic_Payments_Settlement] =
    useState({
      settlement_num_loc: "",
      msb: "",
      money_transmitted_license: "",
      types_payments: "",
      mobile_app: "",
      pos: "",
      credit_card: "",
      bank_aware: "",
      merchant_processor: "",
      terminal: "",
    });

  // ATM Machine Transactions

  const [ATM_Machine, setATM_Machine] = useState({
    atm_own: "",
    atm_loc: "",
    atm_multiple: "",
    atm_fill: "",
    atm_third_party: "",
    atm_fill_company: "",
    atm_currency: "",
    atm_crypto: "",
    atm_deposite: "",
    atm_receipt: "",
  });

  // Accounting

  const [Accounting, setAccounting] = useState({
    accounting_system: "",
    accountant: "",
    firm_name: "",
  });

  // Compliance_Details

  const [Compliance_Details, setCompliance_Details] = useState({
    non_compliance: "",
    compliance_desc: "",
    compliance_officer: "",
    compliance_partner: "",
    compliance_partner_name: "",
    compliance_group: "",
    onsite_inspection: "",
    compliance_date: "",
    frequency_compliance: "",
    compliance_trainning: "",
  });
  //RiskClassificationHandler Function:-

  // Primary Information Handler Function :-

  // Legal_Business handler Function:-
  // const LegalBusinessHandler = (event) => {
  //   if (event.target.type === 'checkbox') {
  //     if (event.target.checked) {
  //       setLegal_Business({
  //         ...Legal_Business, [event.target.name]: 'yes'
  //       })
  //     } else {
  //       setLegal_Business({
  //         ...Legal_Business, [event.target.name]: 'no'
  //       })
  //     }
  //   } else {
  //     setLegal_Business({
  //       ...Legal_Business, [event.target.name]: event.target.value
  //     })
  //   }
  // }

  // const { LegalBusinessHandler, Legal_Business, errors} = useForm(formLogin);
  // console.log(Legal_Business, 'Legal_Business2')
  // ElectronicPaymentsSettlement Handler function:-

  console.log(Compliance_Details, "Compliance_Details");
  const PreviousHandler = () => {
    setFormNo(formNo - 1);
  };

  const NextHandlerTwo = (event) => {
    event.preventDefault();
    // setFormNo(formNo + 1)
    dispatch(
      nextTwo({
        Operational_Details,
        Other_Operational_Details,
        Additional_Locs,
        Anticipated_Transaction_Activity,
      })
    );
  };
  const NextHandlerThree = (event) => {
    event.preventDefault();
    // setFormNo(formNo + 1)
    dispatch(
      nextThree({
        formno: formNo,
        status: "fired",
      })
    );
  };
  const NextHandlerFour = (event) => {
    event.preventDefault();
    // setFormNo(formNo + 1)
    dispatch(
      nextFour({
        formno: formNo,
        status: "fired",
      })
    );
  };

  const navigate = useNavigate();
  console.log(typeof localStorage.getItem("access"), "666666");
  return (
    <>
      {/* {
      String(localStorage.getItem('access'))  === 'false' && <ServicesWanted/>
     } */}
      <section className="mainsection bnk_sctn">
        <div className="bank_content">
          <div className="cmn_hdr">
            <h2>Operator Questions</h2>
            <br />
          </div>
          <div
            className={`step-${formNo}`}
            id="checkout-progress"
            // data-current-step={1}
          >
            <div className="progress-bar">
              <div className="step step-1 active">
                <span> 1</span>
                {/* <div class="fa fa-check opaque"></div> */}
                {/* <div class="step-label">Proposed</div> */}
              </div>
              <div
                className={
                  formNo === 2 || formNo > 2
                    ? "step step-2 active"
                    : "step step-2"
                }
              >
                <span> 2</span>
                {/* <div class="fa fa-check opaque"></div> */}
                {/* <div class="step-label">Approved</div> */}
              </div>
              <div
                className={
                  formNo === 3 || formNo > 3
                    ? "step step-3 active"
                    : "step step-3"
                }
              >
                <span> 3</span>
                {/* <div class="fa fa-check opaque"></div> */}
                {/* <div class="step-label">Under Implmentation</div> */}
              </div>
              <div
                className={
                  formNo === 4 || formNo > 4
                    ? "step step-4 active"
                    : "step step-4"
                }
              >
                <span> 4</span>
                {/* <div class="fa fa-check opaque"></div> */}
                {/* <div class="step-label">Implemented</div> */}
              </div>
              <div
                className={formNo === 5 ? "step step-5 active" : "step step-5"}
              >
                <span> 5</span>
                {/* <div class="fa fa-check opaque"></div> */}
                {/* <div class="step-label">Implemented</div> */}
              </div>
              <div className="step step-6" />
            </div>
          </div>
          {formNo === 1 && <FormOne setFormNo={setFormNo} />}
          {/* {
             formNo === 5 && 
             <FormFive setFormNo={setFormNo}/>
           } */}
          {formNo === 2 && <FormTwo setFormNo={setFormNo} />}
          {formNo === 3 && <FormThree setFormNo={setFormNo} />}
          {formNo === 4 && <FormFour setFormNo={setFormNo} />}
          {formNo === 5 && <FormFive setFormNo={setFormNo} />}
          {/* <section
             id="section5"
             className="section5"
             style={{ display: "none" }}
           /> */}
          {/* <div className="button-container">
             {
               formNo !== 1 && <div className="btn btn-prev disabled"  onClick={() => {
                 window.scrollTo(0, 0)
                 setFormNo(formNo - 1)
               }}>Previous</div>
             }
             {
               formNo < 5 && <button className="btn btn-next"  onClick={() => {
                
                 window.scrollTo(0, 0)
                 setFormNo(formNo + 1)
                if(formNo === 1){
                 dispatch(nextOne({
                   risk_classification,Legal_Business,Primary_Information
                 }))
                }else if(formNo === 2){
                 dispatch(nextTwo({
                   Operational_Details,Other_Operational_Details,Additional_Locs,Anticipated_Transaction_Activity                           
                 }))
                }else if(formNo === 3){
                    dispatch(nextThree({
                     formno: formNo, status: 'fired'
                    }))
                }else if(formNo === 4){
                 dispatch(nextFour({
                   formno: formNo, status: 'fired'
                  }))
                }
               }}>next</button>
             }
             {
               formNo === 5 && <div className="btn btn-submit" >
                 Submit
               </div>
             }
           </div> */}
        </div>
      </section>
    </>
  );
};

export default Homepage;
