import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BankAcceptForm_URL,
  BankGetMatchedOperatorDetails_URL,
  BankProposalDetails_URL,
  Bank_GetAll_AcceptedForms_URL,
} from "../../Api/Api";
import { useState } from "react";
import swal from "sweetalert";
import { FadeLoader } from "react-spinners";
import { IoMdSend } from "react-icons/io";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import CurrencyInput from "../../Helper/CurrencyInput";

const MatchedOperatorDetails = () => {
  const { state } = useLocation();
  console.log(state, "routerData");
  const { id } = useParams();
  const [isAccepted, setIsAccepted] = useState(false);
  const [accept, setAccept] = useState("No");
  const [sectionName, setSectionName] = useState("All Fields");
  const [toggle, setToggle] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const [matchedDetails, setMatchedDetails] = useState([]);
  const [unmatchedDetails, setUnmatchedDetails] = useState([]);
  const [servicesOffered, setServicesOffered] = useState([]);
  console.log(id, "gotit");

  //See the data Bank have sent proposals:-
  useEffect(() => {
    if (id) {
      console.log("hit api");
      axios
        .get(`${BankProposalDetails_URL}/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          console.log(res, "whetherproposals");
          setServicesOffered(res.data);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      console.log("no id");
    }
  }, []);
  //need all accepted applications data again:-
  useEffect(() => {
    axios
      .get(Bank_GetAll_AcceptedForms_URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "againcallapi");
        const target = res.data.map((x) => x.application_Id);
        if (id) {
          setIsAccepted(target.includes(id));
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BankGetMatchedOperatorDetails_URL}/${id}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "myDetails");
        setDetails(res.data.allFields);
        setMatchedDetails(res.data.matchedFields);
        setUnmatchedDetails(res.data.unmatchedFields);
        setAccept(res.data.bankAccept);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    if (isLoading === true) {
      // return <div className='mainsection bnk_sctn'>Loading..</div>
      swal("Good job!", "You clicked the button", "success");
    }
  }, []);
  const SendButtonFunction = () => {
    swal({
      title: "Are you sure you want to send proposal?",
      buttons: {
        confirm: "YES",
        cancel: "NO",
      },
      type: "success",
    }).then((okay) => {
      if (okay) {
        navigate(`/bank-proposals/${id}`);
      } else {
        // setActivitystatus('No')
      }
    });
  };
  const MatchUnmatchIdentifier = (fieldName) => {
    // console.log(fieldName,'00000')
    const targets = unmatchedDetails.map((field) => {
      let key = Object.keys(field)[0];
      return key;
    });
    console.log(targets, "1111111");

    if (targets.includes(fieldName)) {
      console.log("true", fieldName, "yy");
      return true;
    } else {
      console.log("false", fieldName, "yy");
      return false;
    }
  };
  useEffect(() => {
    axios
      .get(`${BankProposalDetails_URL}/${id}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "12314141");
        setServicesOffered(res.data);
      })
      .catch((err) => {
        console.log(err, "seeerror67");
      });
  }, []);
  const OtherCheckedDeterminer = () => {
    const Default_LicenseTypes = [
      "Cultivation",
      "Manufacturer",
      "Distribution",
      "Testing Lab",
      "ACH",
      "Dispensary",
      "Delivery",
    ];
    const determiner = details.Legal_Business.license.map(
      (o) => o.licensing_Type
    );
    console.log(determiner, "seee");

    let res;
    for (let i of determiner) {
      console.log(i, "see");
      console.log(Default_LicenseTypes.includes(i), "see");
      if (!Default_LicenseTypes.includes(i)) {
        res = i;

        break;
      }
    }
    console.log(res, "seee");
    return res;
  };
  useEffect(() => {
    details && OtherCheckedDeterminer();
  }, [details]);
  return (
    <>
      {!isLoading && details ? (
        <div className="mainsection bnk_sctn">
          <br />
          <div className="tabs">
            <div
              className={`${toggle === 1 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(1);
                setSectionName("All Fields");
              }}
            >
              All Fields
            </div>
            <div
              className={`${toggle === 2 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(2);
                setSectionName("Matched Fields");
              }}
            >
              Matched Fields
            </div>
            <div
              className={`${toggle === 3 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(3);
                setSectionName("Unmatched Fields");
              }}
            >
              Unmatched Fields
            </div>
            {state?.category === "accepted applications" && (
              <div
                className={`${toggle === 4 ? "tab active-tab" : "tab"}`}
                onClick={() => {
                  setToggle(4);
                  setSectionName("Services Offered");
                }}
              >
                Services Offered
              </div>
            )}
          </div>
          <>
            {sectionName === "All Fields" && (
              <div className="bank_content">
                {/* { !details.bankAccept && <div style={{marginLeft:'80%'}}>
           <div style={{display:'flex'}}>
           <div className="switch_box">
        
        <label className="switch2">
          <input 
          style={{}}
          type="checkbox" checked={accept} onChange={(event)=>
          {
            if(event.target.checked){
              setAccept(true)
            }else{
              setAccept(false)
            }
          }}
            name='products_purchased_providers'
            />
            
          <span className="slider round" />
        </label>
        { accept === 'Yes' ? <h4>Decline</h4> : <h4>Accept</h4> }
      </div>
    { accept && <button className='notification_btn'
                  style={{marginLeft:'2rem',width:'40%',height:'1%',borderRadius:'2rem',display:'flex'}}
                  >SEND <IoMdSend/></button> }
                   
      </div>
           </div>} */}
                {state?.category !== "accepted applications" ? (
                  <>
                    {isAccepted ? (
                      <div style={{ float: "right" }}>
                        Accepted <FcCheckmark />
                      </div>
                    ) : (
                      <div className="" style={{ float: "right" }}>
                        <button
                          className="notification_btn"
                          type="button"
                          onClick={() => {
                            swal({
                              title:
                                "Are you sure you want to accept the application?",
                              buttons: {
                                confirm: "YES",
                                cancel: "NO",
                              },
                              type: "success",
                            }).then((okay) => {
                              if (okay) {
                                axios
                                  .post(
                                    BankAcceptForm_URL,
                                    {
                                      id: id,
                                    },
                                    {
                                      headers: {
                                        Authorization:
                                          "Bearer " +
                                          localStorage.getItem("token"),
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    if (
                                      res.data.message ===
                                      `Application ID ${id} has already been accepted, Please go to Accepted Applications`
                                    ) {
                                      // swal('Oops!', `${res.data.message}`, 'error')
                                      swal({
                                        title:
                                          "This application has already been accepted!",
                                        buttons: {
                                          confirm: "Send proposal",
                                          cancel: "NO",
                                        },
                                        type: "success",
                                      }).then((okay) => {
                                        if (okay) {
                                          navigate(`/bank-proposals/${id}`);
                                        } else {
                                          // setActivitystatus('No')
                                        }
                                      });
                                    } else {
                                      navigate(`/bank-proposals/${id}`);
                                    }
                                  });
                              } else {
                                // setActivitystatus('No')
                              }
                            });
                          }}
                        >
                          Accept
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <button
                    style={{ float: "right" }}
                    className="notification_btn"
                    type="button"
                    onClick={SendButtonFunction}
                  >
                    Send
                  </button>
                )}
                <h2
                  style={{
                    fontWeight: "bold",
                    marginLeft: "40%",
                    fontSize: "3rem",
                  }}
                >
                  All Details
                </h2>

                <br />
                <section id="section1" className="section1">
                  <form>
                    <div className="pg1-mn-otr">
                      <div className="pg1-mn-inr">
                        <div className="pg1-prt-1">
                          <div className="pg1-prt-1-txt">
                            <div className="cmn_hdr">
                              <h6>
                                Risk Classification:
                                {MatchUnmatchIdentifier(
                                  "risk_classification"
                                ) ? (
                                  <span> ❌</span>
                                ) : (
                                  // <>&#10060;</>
                                  <span>✅</span>
                                  // <FcCheckmark />
                                )}
                              </h6>

                              <div className="pg1-prt-1-inrfld">
                                <input
                                  type="radio"
                                  name="risk_classification"
                                  checked={
                                    details.risk_classification === "Option1"
                                  }
                                  disabled
                                  // value='option1'
                                />
                                <label>
                                  My business touches marijuana at any point
                                  from seed to sale and my income relies
                                  strictly on the marijuana industry. This
                                  includes any business or farm that
                                  manufactures, processes, or distributes
                                  illegal hemp.
                                </label>
                              </div>
                              <div className="pg1-prt-1-inrfld">
                                <input
                                  type="radio"
                                  name="risk_classification"
                                  checked={
                                    details.risk_classification === "Option2"
                                  }
                                  disabled
                                  // value='option2'
                                />
                                <label>
                                  My business activity focuses on providing
                                  products and services specifically to
                                  MarijuanaRelated Businesses and the cannabis
                                  industry as a whole. My business revenue is
                                  expected to come from cannabis-related
                                  activities.
                                </label>
                              </div>
                              <div className="pg1-prt-1-inrfld">
                                <input
                                  type="radio"
                                  name="risk_classification"
                                  checked={
                                    details.risk_classification === "Option3"
                                  }
                                  // value='option3'
                                  disabled
                                />
                                <label>
                                  My business activity is not specifically
                                  focused on providing services to
                                  Marijuana-Related Businesses or the cannabis
                                  industry, but rather such services are
                                  incidental to their overall business and
                                  revenue.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pg1-prt-2">
                          <div className="pg1-prt-2-txt">
                            <div className="cmn_hdr">
                              <h6>Legal Business Information</h6>
                              <div className="pg1-prt-2-otrfld">
                                <div className="pg1-prt-2-inrfld">
                                  <label>Legal Business Name:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    name="name"
                                    value={details.Legal_Business.name}

                                    // required minlength="3"
                                  />
                                </div>

                                <div className="pg1-prt-2-inrfld">
                                  <label>Other DBAs:</label>
                                  <input
                                    type="text"
                                    name="dba"
                                    value={details.Legal_Business.dba}
                                    disabled
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Physical Address:</label>
                                  <input
                                    type="text"
                                    name="physical_Address"
                                    value={
                                      details.Legal_Business.physical_Address
                                    }
                                    disabled
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Mailing Address:</label>
                                  <input
                                    type="text"
                                    name="mailing_Address"
                                    disabled
                                    value={
                                      details.Legal_Business.mailing_Address
                                    }
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Federal EIN:</label>
                                  <input
                                    type="text"
                                    name="federal_EIN"
                                    disabled
                                    value={details.Legal_Business.federal_EIN}
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Cannabis License Number:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="cannabis_License_Number"
                                    disabled
                                    value={
                                      details.Legal_Business
                                        .cannabis_License_Number
                                    }
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>State:</label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    name="accepted_states"
                                    disabled
                                    value={
                                      details.Legal_Business.accepted_states[0]
                                    }
                                  />
                                  {MatchUnmatchIdentifier("accepted_states") ? (
                                    <span> ❌</span>
                                  ) : (
                                    // <>&#10060;</>
                                    <span>✅</span>
                                    // <FcCheckmark />
                                  )}
                                </div>
                                <br />
                                <div className="pg1-prt-2-inrfld">
                                  <div className="check__in__bx">
                                    <label>Licensing Type:</label>
                                    {MatchUnmatchIdentifier("license") ? (
                                      <span> ❌</span>
                                    ) : (
                                      // <>&#10060;</>
                                      <span>✅</span>
                                      // <FcCheckmark />
                                    )}
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Cultivation"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          .includes("Cultivation")}
                                      />
                                      <label>Cultivation</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        .includes("Cultivation") && (
                                        // <input
                                        //   name="Manufacturer"
                                        //   value={LicenseNumber?.Manufacturer}
                                        //   onChange={LicenseNumberHandler}
                                        // />
                                        <>
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business?.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Cultivation"
                                              )?.license_Num
                                            }
                                            type="text"
                                            name="Cultivation"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Manufacturer"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          .includes("Manufacturer")}
                                      />
                                      <label>Dispensary</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        .includes("Manufacturer") && (
                                        // <input
                                        //   name="Manufacturer"
                                        //   value={LicenseNumber?.Manufacturer}
                                        //   onChange={LicenseNumberHandler}
                                        // />
                                        <>
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business?.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Manufacturer"
                                              )?.license_Num
                                            }
                                            type="text"
                                            name="Manufacturer"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="check__in__bx">
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Distribution"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          .includes("Distribution")}
                                        // onChange={LegalBusinessHandler}
                                      />
                                      <label>Distribution</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        .includes("Distribution") && (
                                        <>
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business?.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Distribution"
                                              )?.license_Num
                                            }
                                            type="text"
                                            name="Distribution"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="check__in__bx">
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Testing Lab"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          .includes("Testing Lab")}
                                      />
                                      <label>Testing Lab</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        .includes("Testing Lab") && (
                                        <>
                                          {" "}
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business?.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Testing Lab"
                                              )?.license_Num
                                            }
                                            type="text"
                                            name="Testing Lab"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="check__in__bx">
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          ?.includes("ACH")}
                                        value="ACH"
                                      />
                                      <label>ACH</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        ?.includes("ACH") && (
                                        <>
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business.license.find(
                                                (i) =>
                                                  i.licensing_Type === "ACH"
                                              )?.license_Num
                                            }
                                            type="text"
                                            name="ACH"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="check__in__bx">
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Dispensary"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          ?.includes("Dispensary")}
                                      />
                                      <label>Dispensary</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        ?.includes("Dispensary") && (
                                        <>
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Dispensary"
                                              ).license_Num
                                            }
                                            type="text"
                                            name="Dispensary"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="check__in__bx">
                                    <div className="pg3-mn-inrfld">
                                      <input
                                        type="checkbox"
                                        name="pg-bx"
                                        disabled
                                        value="Delivery"
                                        checked={details.Legal_Business.license
                                          .map((i) => i.licensing_Type)
                                          .includes("Delivery")}
                                      />
                                      <label>Delivery</label>
                                      {details.Legal_Business.license
                                        .map((i) => i.licensing_Type)
                                        .includes("Delivery") && (
                                        <>
                                          {" "}
                                          <CurrencyInput
                                            placeholder="$0.00"
                                            maxLength={9}
                                            disabled
                                            value={
                                              details.Legal_Business.license.find(
                                                (i) =>
                                                  i.licensing_Type ===
                                                  "Delivery"
                                              ).LicenseNumber
                                            }
                                            type="text"
                                            name="Delivery"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {OtherCheckedDeterminer() && (
                                    <div className="check__in__bx">
                                      <div className="pg3-mn-inrfld">
                                        <input
                                          type="checkbox"
                                          name="pg-bx"
                                          value="Other"
                                          disabled
                                          checked={
                                            OtherCheckedDeterminer()
                                              ? true
                                              : false
                                          }
                                        />
                                        <label>
                                          {OtherCheckedDeterminer()}
                                        </label>
                                        {OtherCheckedDeterminer() && (
                                          <div>
                                            {/* <input
                                            placeholder="Type licensing type.."
                                            name="other"
                                            value={details.Legal_Business}
                                            onChange={LegalBusinessHandler}
                                            // onChange={LicenseNumberHandler}
                                          />
                                          <span className="red-star">*</span>
                                          <br />
                                          <br /> */}
                                            <>
                                              <CurrencyInput
                                                placeholder="$0.00"
                                                maxLength={9}
                                                disabled
                                                value={
                                                  details.Legal_Business.license.find(
                                                    (p) =>
                                                      p.licensing_Type ===
                                                      OtherCheckedDeterminer()
                                                  ).license_Num
                                                }
                                                type="text"
                                                name="Other"
                                              />
                                            </>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="pg1-prt-2-inrfld nd3">
                                  <div className="pg1-prt-2-lwr-otrfld">
                                    <div className="pg1-prt-2-lwr-inrfld">
                                      <label>Other:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="other"
                                        disabled
                                        value={details.Legal_Business.other}
                                      />
                                      {MatchUnmatchIdentifier("other") ? (
                                        <>❌</>
                                      ) : (
                                        // <FcCheckmark />
                                        <div style={{ float: "right" }}>
                                          &#9989;
                                        </div>
                                      )}
                                    </div>
                                    <div className="pg1-prt-2-lwr-inrfld">
                                      <label>No of Employees:</label>
                                      <input
                                        type="number"
                                        name="num_employees"
                                        disabled
                                        value={
                                          details.Legal_Business.num_employees
                                        }
                                        className="form-control"
                                        // defaultValue={10}
                                      />
                                      <a
                                        href="javascript:void(0);"
                                        className="nd3-a"
                                      >
                                        <i className="fal fa-plus" />
                                      </a>
                                      {MatchUnmatchIdentifier(
                                        "num_employees"
                                      ) ? (
                                        <>❌</>
                                      ) : (
                                        // <FcCheckmark />
                                        <div style={{ float: "right" }}>
                                          &#9989;
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Does your business have any foreign
                                    operations?
                                  </label>
                                  {MatchUnmatchIdentifier(
                                    "foreign_operations"
                                  ) ? (
                                    <>❌</>
                                  ) : (
                                    // <FcCheckmark />
                                    <div style={{ float: "right" }}>
                                      &#9989;
                                    </div>
                                  )}
                                  <div className="switch_box">
                                    <h4>no</h4>
                                    <label className="switch2">
                                      <input
                                        type="checkbox"
                                        name="foreign_operations"
                                        disabled
                                        checked={
                                          details.Legal_Business
                                            .foreign_operations === "yes"
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                    <h4>yes</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pg1-prt-3">
                          <div className="pg1-prt-3-txt">
                            <div className="cmn_hdr">
                              <h6>Primary Contact Information:</h6>
                              <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                                <div className="pg1-prt-2-inrfld">
                                  <label>Primary Contact Name:</label>
                                  <input
                                    type="text"
                                    name="primary_contact_name"
                                    disabled
                                    value={
                                      details.Primary_Information
                                        .primary_contact_name
                                    }
                                    className="form-control"
                                  />
                                </div>

                                <div className="pg1-prt-2-inrfld">
                                  <label>Date of Birth:</label>
                                  <input
                                    type="text"
                                    name="dob"
                                    disabled
                                    value={details.Primary_Information.dob}
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Address:</label>
                                  <input
                                    type="text"
                                    name="primary_address"
                                    disabled
                                    value={
                                      details.Primary_Information
                                        .primary_address
                                    }
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>E-mail:</label>
                                  <input
                                    type="email"
                                    name="mail"
                                    disabled
                                    value={details.Primary_Information.mail}
                                    className="form-control"
                                  />
                                </div>
                                <div className="pg1-prt-2-inrfld">
                                  <label>Primary Phone #</label>
                                  <input
                                    type="tel"
                                    name="primary_Phone"
                                    disabled
                                    value={
                                      details.Primary_Information.primary_Phone
                                    }
                                    className="form-control"
                                  />
                                  <br />
                                  <br />
                                </div>
                              </div>
                              <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                                <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                                  <label>
                                    Ownership in business applying for account?
                                  </label>
                                  {MatchUnmatchIdentifier("ownership") ? (
                                    <>❌</>
                                  ) : (
                                    // <FcCheckmark />
                                    <div style={{ float: "right" }}>
                                      &#9989;
                                    </div>
                                  )}
                                  <div className="switch_box">
                                    <h4>no</h4>
                                    <label className="switch2">
                                      <input
                                        type="checkbox"
                                        disabled
                                        name="minimum_ownership"
                                        checked={
                                          details.Primary_Information
                                            .ownership === "yes"
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                    <h4>yes</h4>
                                  </div>
                                </div>
                                <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                                  <label>U.S. Citizenship?</label>
                                  {MatchUnmatchIdentifier("us_Citizenship") ? (
                                    <>❌</>
                                  ) : (
                                    // <FcCheckmark />
                                    <div style={{ float: "right" }}>
                                      &#9989;
                                    </div>
                                  )}
                                  <div className="switch_box">
                                    <h4>no</h4>
                                    <label className="switch2">
                                      <input
                                        type="checkbox"
                                        disabled
                                        name="us_Citizenship"
                                        checked={
                                          details.Primary_Information
                                            .us_Citizenship === "yes"
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                    <h4>yes</h4>
                                  </div>
                                </div>
                                <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                                  <label>
                                    Authority to sign on behalf of the business?
                                  </label>
                                  {MatchUnmatchIdentifier("authority_sign") ? (
                                    <>❌</>
                                  ) : (
                                    // <FcCheckmark />
                                    <div style={{ float: "right" }}>
                                      &#9989;
                                    </div>
                                  )}
                                  <div className="switch_box">
                                    <h4>no</h4>
                                    <label className="switch2">
                                      <input
                                        type="checkbox"
                                        disabled
                                        name="authority_sign"
                                        checked={
                                          details.Primary_Information
                                            .authority_sign === "yes"
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                    <h4>yes</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section id="section2" className="section2">
                  <form action="">
                    <div className="ope_dtls bg_grey">
                      <h3 className="hdng_h3">Operational Details</h3>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">
                          Are any marijuana-related products purchased from
                          providers?
                        </h5>{" "}
                        {MatchUnmatchIdentifier(
                          "products_purchased_providers"
                        ) ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              checked={
                                details.Operational_Details
                                  .products_purchased_providers === "yes"
                              }
                              name="products_purchased_providers"
                              disabled
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">Licensed Provider?</h5>
                        {MatchUnmatchIdentifier("licensed_provider") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              checked={
                                details.Operational_Details
                                  .licensed_provider === "yes"
                              }
                              name="licensed_provider"
                              disabled
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="parent_forminput_group">
                        <div className="form-input-group">
                          <label htmlFor="">Number of Locations:</label>
                          <input
                            type="number"
                            disabled
                            value={details.Operational_Details.num_locs}
                            name="num_locs"
                          />
                          {MatchUnmatchIdentifier("num_locs") ? (
                            <>❌</>
                          ) : (
                            // <FcCheckmark />
                            <div style={{ float: "right" }}>&#9989;</div>
                          )}
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Average # of Transactions Per Month:
                          </label>
                          <input
                            disabled
                            value={
                              details.Operational_Details.transactions_Per_month
                            }
                            name="transactions_Per_month"
                          />
                          {MatchUnmatchIdentifier("transactions_Per_month") ? (
                            <>❌</>
                          ) : (
                            // <FcCheckmark />
                            <div style={{ float: "right" }}>&#9989;</div>
                          )}
                        </div>
                        {/* <div className="form-input-group">
                          <label htmlFor="">Total Managed Square Feet:</label>
                          <input
                            type="number"
                            disabled
                            value={
                              details.Operational_Details.managed_square_feet
                            }
                            name="managed_square_feet"
                          />
                        </div> */}
                        {/* {MatchUnmatchIdentifier("managed_square_feet") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )} */}
                        <div className="form-input-group">
                          <label htmlFor="">
                            Average Purchase Amount Per Sale:
                          </label>
                          <input
                            type="number"
                            disabled
                            value={
                              details.Operational_Details
                                .purchase_amount_per_sale
                            }
                            name="purchase_amount_per_sale"
                          />
                        </div>
                        {MatchUnmatchIdentifier("purchase_amount_per_sale") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="form-input-group">
                          <label htmlFor="">Number of Plants: </label>
                          <input
                            type="number"
                            disabled
                            value={details.Operational_Details.num_plants}
                            name="num_plants"
                          />
                        </div>
                        {MatchUnmatchIdentifier("num_plants") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="form-input-group">
                          <label htmlFor="">Average Quantity Per Sale:</label>
                          <input
                            type="number"
                            disabled
                            value={`${details.Operational_Details.average_quantity_per_sale.convertedToGram}`}
                            name="average_quantity_per_sale"
                          />
                        </div>
                        {MatchUnmatchIdentifier("average_quantity_per_sale") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="form-input-group">
                          <div className="iform-group">
                            <h5 className="fromgroup_hdng">
                              Facility Owned or Leased?
                            </h5>
                            {MatchUnmatchIdentifier("facility") ? (
                              <>❌</>
                            ) : (
                              // <FcCheckmark />
                              <div style={{ float: "right" }}>&#9989;</div>
                            )}
                            <div className="frm_radio_prnt">
                              <div className="form_radio_group">
                                <input
                                  type="radio"
                                  disabled
                                  value="Owned"
                                  checked={
                                    details.Operational_Details.facility ===
                                    "Owned"
                                  }
                                  id="Owned"
                                  name="facility"
                                  // checked={Operational_Details.types_customers === "Medical"}
                                />
                                {/* <span class="radio"></span>  */}
                                <label htmlFor="Owned" className="radio-button">
                                  Owned
                                </label>
                              </div>
                              <div className="form_radio_group">
                                <input
                                  type="radio"
                                  disabled
                                  value="Leased"
                                  checked={
                                    details.Operational_Details.facility ===
                                    "Leased"
                                  }
                                  id="Leased"
                                  name="facility"
                                  // checked={Operational_Details.types_customers === "Recreational"}
                                />
                                {/* <span class="radio"></span> */}
                                <label
                                  htmlFor="Leased"
                                  className="radio-button"
                                >
                                  Leased
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-input-group">
                          <div className="iform-group">
                            <h5 className="fromgroup_hdng">
                              Is Facility Leased?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="facility_leased"
                                  disabled
                                  checked={
                                    details.Operational_Details
                                      .facility_owned === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                        </div> */}
                        <br />
                        <div className="form-input-group">
                          <label htmlFor="">Lease Term:</label>
                          {MatchUnmatchIdentifier("lease_term") ? (
                            <>❌</>
                          ) : (
                            // <FcCheckmark />
                            <div style={{ float: "right" }}>&#9989;</div>
                          )}
                          <input
                            type="string"
                            name="lease_term"
                            disabled
                            value={details.Operational_Details.lease_term}
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            {" "}
                            Estimated Gross Monthly Income:
                            {MatchUnmatchIdentifier("egmi") ? (
                              <>❌</>
                            ) : (
                              // <FcCheckmark />
                              <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <input
                            type="number"
                            disabled
                            value={details.Operational_Details.egmi}
                            name="egmi"
                          />
                        </div>
                        <div className="form-input-group">
                          <label> Types of Customers to Be Served:</label>
                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Medical"
                                id="medical"
                                name="types_customers"
                                disabled
                                checked={
                                  details.Operational_Details
                                    .types_customers === "Medical"
                                }
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="medical" className="radio-button">
                                Medical
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Recreational"
                                id="recreational"
                                name="types_customers"
                                disabled
                                checked={
                                  details.Operational_Details
                                    .types_customers === "Recreational"
                                }
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="recreational"
                                className="radio-button"
                              >
                                Recreational
                              </label>
                            </div>
                          </div>
                          {MatchUnmatchIdentifier("types_customers") ? (
                            <>❌</>
                          ) : (
                            // <FcCheckmark />
                            <div style={{ float: "right" }}>&#9989;</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="other_ope_dtls bg_grey">
                      <h3 className="hdng_h3">Other Operational Details</h3>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">
                          Are any of the principals of the CRB (e.g., officer,
                          directors, or significant investors) principals of any
                          other entity that conducts marijuana-related business
                          in any other state?
                        </h5>
                        {MatchUnmatchIdentifier("principal_business") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              name="principal_business"
                              disabled
                              checked={
                                details.Other_Operational_Details
                                  .principal_business === "yes"
                              }
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">
                          Does the CRB have any Beneficial Owners? (anyone with
                          a stake of 25% or more in the CRB)
                        </h5>
                        {MatchUnmatchIdentifier("beneficial_owners") ? (
                          <>❌</>
                        ) : (
                          // <FcCheckmark />
                          <div style={{ float: "right" }}>&#9989;</div>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              name="beneficial_owners"
                              disabled
                              checked={
                                details.Other_Operational_Details
                                  .beneficial_owners === "yes"
                              }
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="form-input-group">
                        <label htmlFor="">
                          Number of Beneficial Owners:{" "}
                          {MatchUnmatchIdentifier("number_beneficial_owner") ? (
                            <>❌</>
                          ) : (
                            // <FcCheckmark />
                            <>✅</>
                          )}
                        </label>
                        <input
                          type="number"
                          disabled
                          value={
                            details.Other_Operational_Details
                              .number_beneficial_owner
                          }
                          name="number_beneficial_owner"
                        />
                      </div>
                    </div>
                    <div className="additional_location bg_grey">
                      <h3 className="hdng_h3">Additional Locations</h3>
                      <div className="parent_forminput_group">
                        <div className="form-input-group">
                          <label htmlFor="">Location Name:</label>
                          <input
                            type="text"
                            disabled
                            value={details.Additional_Locs?.loc_name}
                            name="loc_name"
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">License #:</label>
                          <input
                            type="number"
                            disabled
                            value={details.Additional_Locs?.license}
                            name="license"
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">Address:</label>
                          <input
                            type="text"
                            disabled
                            value={details.Additional_Locs?.loc_address}
                            name="loc_address"
                          />
                        </div>
                        <div className="form-input-group">
                          <div className="iform-group">
                            <h5 className="fromgroup_hdng">
                              Deposit Account Needed:
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="deposit_acc_need"
                                  disabled
                                  checked={
                                    details.Additional_Locs
                                      ?.deposit_acc_need === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Atactivity bg_grey">
                      <h3 className="hdng_h3">
                        Anticipated Transaction Activity
                      </h3>
                      <div className="parent_forminput_group">
                        <div className="form-input-group">
                          <label htmlFor="">
                            Amount of Initial Deposit (USD):
                            {MatchUnmatchIdentifier(
                              "amount_Initial_Deposit"
                            ) ? (
                              <span>❌</span>
                            ) : (
                              // <FcCheckmark />
                              <span>✅</span>
                            )}
                          </label>
                          <input
                            type="number"
                            name="amount_Initial_Deposit"
                            value={
                              details.Anticipated_Transaction_Activity
                                .amount_Initial_Deposit
                            }
                            disabled
                          />
                        </div>

                        <div className="form-input-group">
                          <label htmlFor="">
                            Source of Initial Deposit:
                            {MatchUnmatchIdentifier(
                              "source_Initial_Deposit"
                            ) ? (
                              <span>❌</span>
                            ) : (
                              // <FcCheckmark />
                              <span>✅</span>
                            )}
                          </label>
                          <input
                            type="text"
                            name="source_Initial_Deposit"
                            value={
                              details.Anticipated_Transaction_Activity
                                .source_Initial_Deposit
                            }
                            disabled
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Estimated Total Amount of Monthly Deposits (USD):
                            {MatchUnmatchIdentifier(
                              "estimated_total_amount_monthly_deposit"
                            ) ? (
                              <span>❌</span>
                            ) : (
                              // <FcCheckmark />
                              <span>✅</span>
                            )}
                          </label>
                          <input
                            type="number"
                            name="estimated_total_amount_monthly_deposit"
                            disabled
                            value={
                              details.Anticipated_Transaction_Activity
                                .estimated_total_amount_monthly_deposit
                            }
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Estimated Total Number of Monthly Deposits:
                            {MatchUnmatchIdentifier(
                              "estimated_total_num_monthly_deposit"
                            ) ? (
                              <span>❌</span>
                            ) : (
                              // <FcCheckmark />
                              <span>✅</span>
                            )}
                          </label>
                          <input
                            type="number"
                            name="estimated_total_num_monthly_deposit"
                            disabled
                            value={
                              details.Anticipated_Transaction_Activity
                                .estimated_total_num_monthly_deposit
                            }
                          />
                        </div>
                        <div className="iform-group">
                          <h5 className="fromgroup_hdng">
                            Anticipate making cash deposits?
                            {MatchUnmatchIdentifier(
                              "anticipate_cash_deposits"
                            ) ? (
                              <>❌</>
                            ) : (
                              // <FcCheckmark />
                              <>✅</>
                            )}
                          </h5>

                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="anticipate_cash_deposits"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .anticipate_cash_deposits === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Amount of Monthly Cash Deposits (USD):
                            {MatchUnmatchIdentifier("amount_cash_deposits") ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>

                          <input
                            type="number"
                            name="amount_cash_deposits"
                            disabled
                            value={
                              details.Anticipated_Transaction_Activity
                                .amount_cash_deposits
                            }
                          />
                        </div>
                        <div className="form-input-group">
                          <label>
                            {" "}
                            Frequency of Cash Deposits:
                            {MatchUnmatchIdentifier(
                              "frequency_cash_deposits"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>

                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                name="frequency_cash_deposits"
                                value="Daily"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_deposits === "Daily"
                                }
                                id="daily"
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="daily" className="radio-button">
                                {" "}
                                Daily
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_deposits === "Weekly"
                                }
                                id="weekly"
                                name="frequency_cash_deposits"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="weekly" className="radio-button">
                                {" "}
                                Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value=" Bi-Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_deposits === "Bi-Weekly"
                                }
                                id="Bi-Weekly"
                                name="frequency_cash_deposits"
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="Bi-Weekly"
                                className="radio-button"
                              >
                                Bi-Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Monthly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_deposits === "Monthly"
                                }
                                name="frequency_cash_deposits"
                                id="Monthly"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="Monthly" className="radio-button">
                                Monthly
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-input-group">
                          <label htmlFor="">
                            Estimated Spend or Withdrawals Monthly (USD):
                            {MatchUnmatchIdentifier("estimated_spend") ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <input
                            type="number"
                            value={
                              details.Anticipated_Transaction_Activity
                                .estimated_spend
                            }
                            disabled
                            name="estimated_spend"
                          />
                        </div>

                        <div className="iform-group">
                          <h5 className="fromgroup_hdng">
                            Anticipate making cash withdrawals?
                            {MatchUnmatchIdentifier(
                              "anticipate_cash_withdrawals"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </h5>

                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .anticipate_cash_withdrawals === "yes"
                                }
                                name="anticipate_cash_withdrawals"
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Amount of Monthly Cash Withdrawals (USD):{" "}
                            {MatchUnmatchIdentifier(
                              "amount_cash_withdrawals"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <input
                            type="number"
                            value={
                              details.Anticipated_Transaction_Activity
                                .amount_cash_withdrawals
                            }
                            disabled
                            name="amount_cash_withdrawals"
                          />
                        </div>
                        <div className="form-input-group">
                          <label>
                            {" "}
                            Frequency of Cash Withdrawals:{" "}
                            {MatchUnmatchIdentifier(
                              "frequency_cash_withdrawals"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Daily"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_withdrawals === "Daily"
                                }
                                name="frequency_cash_withdrawals"
                                id="daily2"
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="daily2" className="radio-button">
                                {" "}
                                Daily
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                id="weekly2"
                                value="Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_withdrawals === "Weekly"
                                }
                                name="frequency_cash_withdrawals"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="weekly2" className="radio-button">
                                Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                id="Bi-Weekly2"
                                value="Bi-Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_withdrawals === "Bi-Weekly"
                                }
                                name="frequency_cash_withdrawals"
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="Bi-Weekly2"
                                className="radio-button"
                              >
                                Bi-Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                id="Monthly2"
                                value="Monthly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_withdrawals === "Monthly"
                                }
                                name="frequency_cash_withdrawals"
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="Monthly2"
                                className="radio-button"
                              >
                                Monthly
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Average Expected Monthly Payroll (USD):
                            {MatchUnmatchIdentifier("monthly_payroll") ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <input
                            type="number"
                            disabled
                            value={
                              details.Anticipated_Transaction_Activity
                                .monthly_payroll
                            }
                            name="monthly_payroll"
                          />
                        </div>
                        <div className="iform-group">
                          <h5 className="fromgroup_hdng">
                            Cash pick-ups required?
                            {MatchUnmatchIdentifier("cash_pick_ups") ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </h5>

                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="cash_pick_ups"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .cash_pick_ups === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label>
                            Frequency of Cash Pick-Ups:
                            {MatchUnmatchIdentifier(
                              "frequency_cash_pick_ups"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                id="daily3"
                                value="Daily"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_pick_ups === "Daily"
                                }
                                name="frequency_cash_pick_ups"
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="daily3" className="radio-button">
                                {" "}
                                Daily
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_pick_ups === "Weekly"
                                }
                                name="frequency_cash_pick_ups"
                                id="weekly3"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="weekly3" className="radio-button">
                                Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Bi-Weekly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_pick_ups === "Bi-Weekly"
                                }
                                name="frequency_cash_pick_ups"
                                id="Bi-Weekly3"
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="Bi-Weekly3"
                                className="radio-button"
                              >
                                Bi-Weekly
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Monthly"
                                disabled
                                checked={
                                  details.Anticipated_Transaction_Activity
                                    .frequency_cash_pick_ups === "Monthly"
                                }
                                name="frequency_cash_pick_ups"
                                id="Monthly3"
                              />
                              {/* <span class="radio"></span> */}
                              <label
                                htmlFor="Monthly3"
                                className="radio-button"
                              >
                                Monthly
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Estimated Pick-Up Amount (USD):
                            {MatchUnmatchIdentifier(
                              "estimated_cash_pick_ups"
                            ) ? (
                              <span> ❌</span>
                            ) : (
                              <span>✅</span>
                              // <div style={{ float: "right" }}>&#9989;</div>
                            )}
                          </label>
                          <input
                            type="number"
                            value={
                              details.Anticipated_Transaction_Activity
                                .estimated_cash_pick_ups
                            }
                            disabled
                            name="estimated_cash_pick_ups"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section id="section3" className="section3">
                  <form>
                    <div className="pg3-mn-otr">
                      <div className="pg3-mn-inr">
                        <div className="cmn_hdr">
                          {MatchUnmatchIdentifier("Company_Documentation") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <h6>Company Documentation</h6>
                          <p>Check Documents Available:</p>
                          <div className="pg3-mn-otrfld">
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Audited Financial Statements"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Audited Financial Statements"
                                )}
                              />
                              <label>Audited Financial Statements</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Policies"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Policies"
                                )}
                              />
                              <label>Policies</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Articles of Formation (Organization or Incorporation)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Articles of Formation (Organization or Incorporation)"
                                )}
                              />
                              <label>
                                Articles of Formation (Organization or
                                Incorporation)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Operating Agreement"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Operating Agreement"
                                )}
                              />
                              <label>Operating Agreement</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Contracts with Armored Car Services to Transport Cash"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Contracts with Armored Car Services to Transport Cash"
                                )}
                              />
                              <label>
                                Contracts with Armored Car Services to Transport
                                Cash
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Results of any inspections performed by the State"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Results of any inspections performed by the State"
                                )}
                              />
                              <label>
                                Results of any inspections performed by the
                                State
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Federal and State Tax Returns (Last 3 Years Available)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Federal and State Tax Returns (Last 3 Years Available)"
                                )}
                              />
                              <label>
                                Federal and State Tax Returns (Last 3 Years
                                Available)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Compliance Attestation (Agreement with terms and
                              conditions of account and fee schedule, statement that
                              MRB is not in violation of Cole Memo Priorities or
                              FinCEN guidelines)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Compliance Attestation (Agreement with terms andconditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                                )}
                              />
                              <label>
                                Compliance Attestation (Agreement with terms and
                                conditions of account and fee schedule,
                                statement that MRB is not in violation of Cole
                                Memo Priorities or FinCEN guidelines)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Proof of vendor due diligence completed for all vendors
                              used"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  " Proof of vendor due diligence completed for all vendors used"
                                )}
                              />
                              <label>
                                Proof of vendor due diligence completed for all
                                vendors used
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Most Recent Compliance Audit and Reports"
                                )}
                                value="Most Recent Compliance Audit and Reports"
                              />
                              <label>
                                Most Recent Compliance Audit and Reports
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Sales History from POS Records (90 Days Fully
                              Operational)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Sales History from POS Records (90 Days Fully Operational)"
                                )}
                              />
                              <label>
                                Sales History from POS Records (90 Days Fully
                                Operational)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Employee Training Manual"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Employee Training Manual"
                                )}
                              />
                              <label>Employee Training Manual</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Signed Lease Agreement for each Location"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Signed Lease Agreement for each Location"
                                )}
                              />
                              <label>
                                Signed Lease Agreement for each Location
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Business Recovery Plan"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Business Recovery Plan"
                                )}
                              />
                              <label>Business Recovery Plan</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Insurance Certificate (summary of coverage)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Insurance Certificate (summary of coverage)"
                                )}
                              />
                              <label>
                                Insurance Certificate (summary of coverage)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="State Licensing"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "State Licensing)"
                                )}
                              />
                              <label>State Licensing</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Secretary of State Registration"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Secretary of State Registration"
                                )}
                              />
                              <label>Secretary of State Registration</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Results of any on-site inspections"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Results of any on-site inspections"
                                )}
                              />
                              <label>Results of any on-site inspections</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Financial Projections for Next 1-3 Years"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Financial Projections for Next 1-3 Years"
                                )}
                              />
                              <label>
                                Financial Projections for Next 1-3 Years
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value=" List of all vendors utilized including name, DBA,
                              address, contact person, and services/products used"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                                )}
                              />
                              <label>
                                List of all vendors utilized including name,
                                DBA, address, contact person, and
                                services/products used
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="List of all expected customers"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "List of all expected customers"
                                )}
                              />
                              <label>List of all expected customers</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Attestation of Beneficial Ownership"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Attestation of Beneficial Ownership"
                                )}
                              />
                              <label>Attestation of Beneficial Ownership</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Processing Statements (Last 3 Months)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Processing Statements (Last 3 Months)"
                                )}
                              />
                              <label>
                                Processing Statements (Last 3 Months)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="Balance Sheets, Cash Flow Statements, and P/L Statements
                              (Last 3 Years)"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "Balance Sheets, Cash Flow Statements, and Statements (Last 3 Years)"
                                )}
                              />
                              <label>
                                Balance Sheets, Cash Flow Statements, and P/L
                                Statements (Last 3 Years)
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="California Verification Status of Entity"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "California Verification Status of Entity"
                                )}
                              />
                              <label>
                                California Verification Status of Entity
                              </label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="pg-bx"
                                value="U.S. Government issue ID/Driver’s License/Military
                              ID/State ID or U.S. Passport for Administrators,
                              Beneficial Owners, Officers, Directors, and Control
                              Persons"
                                disabled
                                checked={details.Company_Documentation.includes(
                                  "U.S. Government issue ID/Driver’s License/MilitaryID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                                )}
                              />
                              <label>
                                U.S. Government issue ID/Driver’s
                                License/Military ID/State ID or U.S. Passport
                                for Administrators, Beneficial Owners, Officers,
                                Directors, and Control Persons
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section id="section4" className="section4">
                  <form action="">
                    <div className="lgcy_cash bg_grey">
                      <h3 className="hdng_h3">Legacy Cash</h3>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">
                          Does your business have legacy cash?
                        </h5>
                        {MatchUnmatchIdentifier("legacy_cash") ? (
                          <>❌</>
                        ) : (
                          <>✅</>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              name="legacy_cash"
                              disabled
                              checked={
                                details.Legacy_Cash.legacy_cash === "yes"
                              }
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="check_form-group">
                        <h5 className="fromgroup_hdng">
                          Check Documents Available:
                        </h5>
                        {MatchUnmatchIdentifier("documents_available") ? (
                          <>❌</>
                        ) : (
                          <>✅</>
                        )}
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            id="icheck1"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                            )}
                            value="Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                            name="documents_available"
                          />
                          <label htmlFor="icheck1">
                            Quarterly federal, state, and municipal tax returns
                            for each of the last five years, if applicable
                          </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "Financial statements, preferably audited that have been prepared for the business"
                            )}
                            value="Financial statements, preferably audited that have been prepared for the business"
                            name="documents_available"
                          />
                          <label>
                            Financial statements, preferably audited that have
                            been prepared for the business
                          </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                            )}
                            value="Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                            name="documents_available"
                          />
                          <label>
                            Point-of-Sale (seed-to-sale) system reports: monthly
                            and annual POS reports showing all wholesale
                            inventory purchases and retail sales transactions
                          </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                            )}
                            value="State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                            name="documents_available"
                          />
                          <label>
                            State database reporting: reports of amounts
                            reported to the state tracking database for as long
                            as the state database has been active, if applicable
                          </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                            )}
                            value="Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                            name="documents_available"
                          />
                          <label>
                            Armored courier confirmed cash count: To the extent
                            cash was couriered to a vault or other safekeeping
                            location, obtain confirmed cash count slips from
                            armored courier evidencing amount of cash counted
                            and date of cash pick-up/count
                          </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Legacy_Cash.documents_available.includes(
                              "Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                            )}
                            value="Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                            name="documents_available"
                          />
                          <label>
                            Safekeeping receipt (vaulted cash): Obtain
                            Safekeeping Receipt showing total cash vaulted and
                            custodianship of cash
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="csh_mngmnt bg_grey">
                      <h3 className="hdng_h3">Cash Management</h3>
                      <div className="form-group">
                        <h5 className="fromgroup_hdng">
                          Does the business currently have a bank account?
                        </h5>
                        {MatchUnmatchIdentifier("business_acc") ? (
                          <>❌</>
                        ) : (
                          <>✅</>
                        )}
                        <div className="switch_box">
                          <h4>no</h4>
                          <label className="switch2">
                            <input
                              type="checkbox"
                              name="business_acc"
                              disabled
                              checked={
                                details.Cash_Management.business_acc === "yes"
                              }
                            />
                            <span className="slider round" />
                          </label>
                          <h4>yes</h4>
                        </div>
                      </div>
                      <div className="parent_forminput_group">
                        <div className="form-input-group">
                          <label htmlFor="">Bank Name:</label>
                          <input
                            type="text"
                            name="bank_name"
                            value={details.Cash_Management.bank_name}
                            disabled
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Length of Time Account has been Open:
                          </label>
                          <input
                            type="text"
                            name="length_time"
                            value={
                              details.Cash_Management.length_time
                                .lengthTimeConvertedToDays
                            }
                            disabled
                          />
                          {MatchUnmatchIdentifier("length_time") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                        </div>
                        <div className="form-input-group full">
                          <label htmlFor="">
                            If account was closed, reason for account closure:
                          </label>
                          <input
                            type="text"
                            name="reason_to_close"
                            disabled
                            value={details.Cash_Management.reason_to_close}
                          />
                        </div>
                        {/* <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Payroll service used for employees?
                          </h5>
                          {MatchUnmatchIdentifier("payroll_service") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="payroll_service"
                                disabled
                                checked={
                                  details.Cash_Management.payroll_service ===
                                  "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Employees paid in cash?
                          </h5>
                          {MatchUnmatchIdentifier("paid_cash") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="paid_cash"
                                disabled
                                checked={
                                  details.Cash_Management.paid_cash === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Payroll cards offered?
                          </h5>
                          {MatchUnmatchIdentifier("payroll_accepted") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="payroll_accepted"
                                disabled
                                checked={
                                  details.Cash_Management.payroll_accepted ===
                                  "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Taxes paid in cash?
                          </h5>
                          {MatchUnmatchIdentifier("taxes_cash") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="taxes_cash"
                                disabled
                                checked={
                                  details.Cash_Management.taxes_cash === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div> */}
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Penalties for paying taxes paid in cash?
                          </h5>
                          {MatchUnmatchIdentifier("penalty_cash") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="penalty_cash"
                                disabled
                                checked={
                                  details.Cash_Management.penalty_cash === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Current on tax payments with the state?
                          </h5>
                          {MatchUnmatchIdentifier("tax_payment") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="tax_payment"
                                disabled
                                checked={
                                  details.Cash_Management.tax_payment === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Number of vendors paid monthly:
                          </label>
                          <input
                            type="number"
                            name="number_vendors"
                            disabled
                            value={details.Cash_Management.number_vendors}
                          />
                          {MatchUnmatchIdentifier("number_vendors") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                        </div>
                        <div className="form-input-group">
                          <label>Method(s) by which vendors are paid:</label>
                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="ACH"
                                disabled
                                checked={
                                  details.Cash_Management
                                    .vendor_payment_methods === "ACH"
                                }
                                name="vendor_payment_methods"
                                id="ACH1"
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="ACH1" className="radio-button">
                                {" "}
                                ACH
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Check"
                                disabled
                                checked={
                                  details.Cash_Management
                                    .vendor_payment_methods === "Check"
                                }
                                name="vendor_payment_methods"
                                id="check1"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="check1" className="radio-button">
                                {" "}
                                Check
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Wire"
                                disabled
                                checked={
                                  details.Cash_Management
                                    .vendor_payment_methods === "Wire"
                                }
                                name="vendor_payment_methods"
                                id="wire1"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="wire1" className="radio-button">
                                {" "}
                                Wire
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Cash"
                                disabled
                                checked={
                                  details.Cash_Management
                                    .vendor_payment_methods === "Cash"
                                }
                                name="vendor_payment_methods"
                                id="cash1"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="cash1" className="radio-button">
                                {" "}
                                Cash
                              </label>
                            </div>
                          </div>
                          {MatchUnmatchIdentifier("vendor_payment_methods") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Any vendors located outside of the U.S.?
                          </h5>
                          {MatchUnmatchIdentifier("international_vendor") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="international_vendor"
                                disabled
                                checked={
                                  details.Cash_Management
                                    .international_vendor === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is the business able to receive electronic payments?
                          </h5>
                          {MatchUnmatchIdentifier("electronic_payment") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="electronic_payment"
                                disabled
                                checked={
                                  details.Cash_Management.electronic_payment ===
                                  "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            If the businessonly operates in cash, describe
                            current cash management practices:
                          </label>
                          <input
                            style={{
                              // width:'1200px'
                              width: "125rem",
                            }}
                            type="text"
                            name="current_cash_managment"
                            disabled
                            value={
                              details.Cash_Management.current_cash_managment
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="csh_mngmnt bg_grey">
                      <h3 className="hdng_h3">
                        Transfer from Existing Bank Account
                      </h3>
                      {/* <div className="form-group">
          <h5 className="fromgroup_hdng">
            Does the business currently have a bank account?
          </h5>
          <div className="switch_box">
            <h4>no</h4>
            <label className="switch2">
              <input type="checkbox" name='business_acc' onChange={CashManagementHandler} />
              <span className="slider round" />
            </label>
            <h4>yes</h4>
          </div>
        </div> */}
                      <div className="parent_forminput_group">
                        <div className="form-input-group">
                          <label htmlFor="">
                            Name of financial institution funds being
                            transferred from:
                          </label>
                          <input
                            type="text"
                            name="financial_institution"
                            disabled
                            value={
                              details.Transfer_Existing_Bank
                                .financial_institution
                            }
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">Bank contact:</label>
                          <input
                            type="text"
                            name="existing_bank_contact"
                            disabled
                            value={
                              details.Transfer_Existing_Bank
                                .existing_bank_contact
                            }
                          />
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">Name on bank account:</label>
                          <input
                            type="text"
                            name="existing_bank_name"
                            disabled
                            value={
                              details.Transfer_Existing_Bank.existing_bank_name
                            }
                          />
                        </div>

                        <div className="form-input-group">
                          <label htmlFor="">
                            Source of original deposit to previous bank account:
                          </label>
                          <input
                            type="text"
                            name="original_deposite"
                            disabled
                            value={
                              details.Transfer_Existing_Bank.original_deposite
                            }
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Can source of deposit at previous bank be verified?
                          </h5>
                          {MatchUnmatchIdentifier("prev_bank_verified") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="prev_bank_verified"
                                disabled
                                checked={
                                  details.Transfer_Existing_Bank
                                    .prev_bank_verified === "yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>

                        <div className="form-input-group full">
                          <label htmlFor="">Reason for account closure:</label>
                          <input
                            type="text"
                            name="reason_closure"
                            disabled
                            value={
                              details.Transfer_Existing_Bank.reason_closure
                            }
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Was previous bank aware account was for a
                            cannabis-related business?
                          </h5>
                          {MatchUnmatchIdentifier("prev_bank_aware") ? (
                            <>❌</>
                          ) : (
                            <>✅</>
                          )}
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="prev_bank_aware"
                                checked={
                                  details.Transfer_Existing_Bank
                                    .prev_bank_aware === "yes"
                                }
                                disabled
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section
                  id="section5"
                  className="section5"
                  // style={{ display: "block" }}
                >
                  <form>
                    {/* Electronic Payments Settlement */}
                    <div className="epaysettle bg_grey">
                      <h3 className="hdng_h3">
                        Electronic Payments Settlement | Merchant Processing
                      </h3>
                      <div className="outr_finptgrp">
                        <div className="form-input-group">
                          <label htmlFor="">Number of Locations:</label>
                          <input
                            type="text"
                            name="settlement_num_loc"
                            disabled
                            value={
                              details.Electronic_Payments_Settlement
                                ?.settlement_num_loc
                            }
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is the provider a registered MSB?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                name="msb"
                                type="checkbox"
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.msb === "yes"
                                }
                                disabled
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Does the provider have a money transmitted license
                            in the state(s) of operations?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                name="money_transmitted_license"
                                type="checkbox"
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.money_transmitted_license === "Yes"
                                }
                                disabled
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Type(s) of payments provided:
                          </label>
                          <input
                            type="text"
                            name="types_payments"
                            disabled
                            value={
                              details.Electronic_Payments_Settlement
                                ?.types_payments
                            }
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Mobile delivery application?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="mobile_app"
                                disabled
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.mobile_app === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">POS terminal type:</label>
                          <input
                            type="text"
                            name="pos"
                            value={details.Electronic_Payments_Settlement?.pos}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Does the provider allow acceptance of credit card
                            payments?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                name="credit_card"
                                type="checkbox"
                                disabled
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.credit_card === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is the acquiring bank aware they are sponsoring a
                            cannabis-related transaction?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="bank_aware"
                                disabled
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.bank_aware === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">Name of merchant processor:</label>
                          <input
                            type="text"
                            name="merchant_processor"
                            disabled
                            value={
                              details.Electronic_Payments_Settlement
                                ?.merchant_processor
                            }
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is the solution a “cashless ATM” or
                            “Point-of-Banking” terminal?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="terminal"
                                disabled
                                checked={
                                  details.Electronic_Payments_Settlement
                                    ?.terminal === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ATM Machine Transactions */}
                    <div className="atmmtrancast bg_grey">
                      <h3 className="hdng_h3">ATM Machine Transactions</h3>
                      <div className="outr_finptgrp">
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Does the business own or lease the ATM(s) on the
                            premises?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="atm_own"
                                disabled
                                checked={details.ATM_Machine?.atm_own === "Yes"}
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Location of ATM (e.g., lobby or inside storefront):
                          </label>
                          <input
                            type="text"
                            disabled
                            value={details.ATM_Machine?.atm_loc}
                            name="atm_loc"
                          />
                        </div>
                        <div className="pflex">
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Does the business own or lease multiple ATMs?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="atm_multiple"
                                  disabled
                                  checked={
                                    details.ATM_Machine?.atm_multiple === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Does the business fill the ATM(s) itself?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="atm_fill"
                                  disabled
                                  checked={
                                    details.ATM_Machine?.atm_fill === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is the ATM filled by a third-party?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="atm_third_party"
                                disabled
                                checked={
                                  details.ATM_Machine?.atm_third_party === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="pflex">
                          <div className="form-input-group c">
                            <label htmlFor="">
                              Name of company or armored service that fills
                              ATM(s)
                            </label>
                            <input
                              type="text"
                              disabled
                              value={details.ATM_Machine?.atm_fill_company}
                              name="atm_fill_company"
                            />
                          </div>
                          <div className="form-input-group c">
                            <label htmlFor="">
                              How much currency are ATM(s) filled with weekly?
                            </label>
                            <input
                              type="text"
                              disabled
                              value={details.ATM_Machine?.atm_currency}
                              name="atm_currency"
                            />
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Does ATM accept or dispense cryptocurrency?
                          </label>
                          <input
                            type="text"
                            disabled
                            value={details.ATM_Machine?.atm_crypto}
                            name="atm_crypto"
                          />
                        </div>
                        <div className="pflex">
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Does the ATM(s) accept deposits?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="atm_deposite"
                                  disabled
                                  checked={
                                    details.ATM_Machine?.atm_deposite === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Does the ATM(s) dispense receipts rather than
                              cash?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="atm_receipt"
                                  disabled
                                  checked={
                                    details.ATM_Machine?.atm_receipt === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Accounting */}
                    <div className="accounting bg_grey">
                      <h3 className="hdng_h3">Accounting</h3>
                      <div className="outr_finptgrp">
                        <div className="form-input-group">
                          <label htmlFor="">
                            Does the business utilize an accounting system?
                          </label>
                          <input
                            type="text"
                            name="accounting_system"
                            disabled
                            value={details.Accounting?.accounting_system}
                          />
                        </div>

                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Is accounting handled internally or externally
                            through an accountant/firm?
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="accountant"
                                disabled
                                checked={
                                  details.Accounting?.accountant === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            If external, Accountant/Firm Name:
                          </label>
                          <input
                            type="text"
                            name="firm_name"
                            disabled
                            value={details.Accounting?.firm_name}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Compliance Details */}
                    <div className="com_dtails bg_grey">
                      <h3 className="hdng_h3">Compliance Details</h3>
                      <div className="outr_finptgrp">
                        <div className="pflex">
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Has the business ever cited for non-compliance?
                            </h5>

                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="non_compliance"
                                  disabled
                                  checked={
                                    details.Compliance_Details
                                      ?.non_compliance === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                          <div className="form-input-group c">
                            <label htmlFor="">
                              If yes, please provide a description:
                            </label>
                            <input
                              type="text"
                              disabled
                              value={
                                details.Compliance_Details?.compliance_desc
                              }
                              name="compliance_desc"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Business employs in-house Compliance Officer
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="compliance_officer"
                                disabled
                                checked={
                                  details.Compliance_Details
                                    ?.compliance_officer === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="pflex">
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Business engaged a third-party compliance partner.
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="compliance_partner"
                                  disabled
                                  checked={
                                    details.Compliance_Details
                                      ?.compliance_partner === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                          <div className="form-input-group c">
                            <label htmlFor="">
                              If yes, please provide name of entity and services
                              provided:
                            </label>
                            <input
                              type="text"
                              value={
                                details.Compliance_Details
                                  ?.compliance_partner_name
                              }
                              disabled
                              name="compliance_partner_name"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <h5 className="fromgroup_hdng">
                            Busines has auditor/compliance group to conduct
                            on-site inspections:
                          </h5>
                          <div className="switch_box">
                            <h4>no</h4>
                            <label className="switch2">
                              <input
                                type="checkbox"
                                name="onsite_inspection"
                                disabled
                                checked={
                                  details.Compliance_Details
                                    ?.onsite_inspection === "Yes"
                                }
                              />
                              <span className="slider round" />
                            </label>
                            <h4>yes</h4>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Date of most recently completed on-site inspection:
                          </label>
                          <input
                            type="text"
                            value={details.Compliance_Details?.compliance_date}
                            disabled
                            name="compliance_date"
                          />
                        </div>
                        <div className="form-input-group w100">
                          <label>
                            Does the onsite inspection support the structure,
                            security, operations, staff, and stated purpose of
                            the business and intended activity with the Bank?
                          </label>
                          <div className="frm_radio_prnt">
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Yes"
                                disabled
                                checked={
                                  details.Compliance_Details
                                    ?.compliance_group === "Yes"
                                }
                                id="yes1"
                                name="compliance_group"
                              />
                              {/* <span class="radio"></span>  */}
                              <label htmlFor="yes1" className="radio-button">
                                {" "}
                                Yes
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="No"
                                disabled
                                checked={
                                  details.Compliance_Details
                                    ?.compliance_group === "No"
                                }
                                id="no1"
                                name="compliance_group"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="no1" className="radio-button">
                                {" "}
                                No
                              </label>
                            </div>
                            <div className="form_radio_group">
                              <input
                                type="radio"
                                value="Unsure"
                                disabled
                                checked={
                                  details.Compliance_Details
                                    ?.compliance_group === "Unsure"
                                }
                                id="unsure1"
                                name="compliance_group"
                              />
                              {/* <span class="radio"></span> */}
                              <label htmlFor="unsure1" className="radio-button">
                                Unsure
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-input-group">
                          <label htmlFor="">
                            Frequency of compliance audits:
                          </label>
                          <input
                            type="text"
                            disabled
                            value={
                              details.Compliance_Details?.frequency_compliance
                            }
                            name="frequency_compliance"
                          />
                        </div>
                        <div className="pflex">
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Compliance Training conducted?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  checked={
                                    details.Compliance_Details
                                      ?.compliance_trainning === "Yes"
                                  }
                                  name="compliance_trainning"
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                          <div className="form-group">
                            <h5 className="fromgroup_hdng">
                              Standard Operating Procedures in place?
                            </h5>
                            <div className="switch_box">
                              <h4>no</h4>
                              <label className="switch2">
                                <input
                                  type="checkbox"
                                  name="operating_procedures"
                                  checked={
                                    details.Compliance_Details
                                      ?.operating_procedures === "Yes"
                                  }
                                />
                                <span className="slider round" />
                              </label>
                              <h4>yes</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Are you interested in any of the following services */}
                    <div className="fservc bg_grey">
                      <h3 className="hdng_h3">Interested Services</h3>
                      {/* {MatchUnmatchIdentifier("Interested_Services") ? (
                        <>❌</>
                      ) : (
                        // <>&#10060;</>
                        <div style={{ float: "right" }}>&#9989;</div>
                        // <FcCheckmark />
                      )} */}
                      <div className="check_form-group">
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            id="icheck2"
                            name="pg-bx"
                            value="Payment Processing - Credit Card"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - Credit Card"
                            )}
                          />
                          <label htmlFor="icheck2">
                            Payment Processing - Credit Card
                          </label>
                          <div
                            style={{ marginRight: "350px", display: "flex" }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - Credit Card" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            value="Payment Processing - Debit Card"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - Debit Card"
                            )}
                          />
                          <label> Payment Processing - Debit Card </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - Debit Card" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            value="Payment Processing - PIN Debit"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - PIN Debit"
                            )}
                          />
                          <label> Payment Processing - PIN Debit </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            value="Payment Processing - Cashless ATM"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - Cashless ATM"
                            )}
                          />
                          <label> Payment Processing - Cashless ATM </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - Cashless ATM" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            value="Payment Processing - ACH Processing"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - ACH Processing"
                            )}
                          />
                          <label> Payment Processing - ACH Processing </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - ACH Processing" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - Crypto Payments"
                            )}
                            value="Payment Processing - Crypto Payments"
                          />
                          <label> Payment Processing - Crypto Payments </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - Crypto Payments" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Payment Processing - Crypto Payments"
                            )}
                          />
                          <label> Payment Processing - Crypto Payments </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Payment Processing - Crypto Payments" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Lending Services"
                            )}
                            value="Lending Services"
                          />
                          <label htmlFor="icheck2"> Lending Services </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service === "Lending Services" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Insurance Services"
                            )}
                            value="Insurance Services"
                          />
                          <label> Insurance Services </label>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services.includes(
                              "Payroll / Benefits / 401K"
                            )}
                            value="Payroll / Benefits / 401K"
                          />
                          <label> Payroll / Benefits / 401K </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service === "Payroll / Benefits / 401K" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Human Resources"
                            )}
                            name="pg-bx"
                            value="Human Resources"
                          />
                          <label> Human Resources </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service === "Human Resources" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "Billboard / Outdoor Advertising"
                            )}
                            value="Billboard / Outdoor Advertising"
                          />
                          <label> Billboard / Outdoor Advertising </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service ===
                                      "Billboard / Outdoor Advertising" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="check_checking">
                          <input
                            type="checkbox"
                            name="pg-bx"
                            disabled
                            checked={details.Interested_Services?.includes(
                              "ERC Processing"
                            )}
                            value="ERC Processing"
                          />
                          <label> ERC Processing </label>
                          <div
                            style={{
                              marginLeft: "10rem",
                              display: "flex",
                              gap: "10rem",
                            }}
                          >
                            <p>
                              {
                                // details.Interested_Services?.map(k=> k.service).includes('Payment Processing - Credit Card') &&
                                // details.Interested_Services[ details.Interested_Services?.map(k=> k.service).indexOf('Payment Processing - Credit Card')].priority === 'required' && <>✅</>
                                details?.Interested_Services?.find(
                                  (i) =>
                                    i.service === "ERC Processing" &&
                                    i.priority === "required"
                                ) && <>Required✅</>
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            )}
            {sectionName === "Matched Fields" && (
              <div className="bank_content">
                <h2
                  style={{
                    fontWeight: "bold",
                    marginLeft: "40%",
                    fontSize: "3rem",
                  }}
                >
                  Matched Details
                </h2>
                <br />
                <br />
                {matchedDetails &&
                  matchedDetails.map((detail, index) => {
                    console.log(detail, "10230");
                    const key = Object.keys(detail);
                    console.log(key, "890");
                    const val = Object.values(detail);
                    console.log(val, "8908");
                    return (
                      <div className="inpt_bx">
                        <label>{key[0]}: &nbsp; &nbsp;</label>
                        <input type="text" value={val[0]} disabled />
                        <br />
                        <br />
                      </div>
                    );
                  })}
              </div>
            )}
            {sectionName === "Unmatched Fields" && (
              <div className="bank_content">
                <h2
                  style={{
                    fontWeight: "bold",
                    marginLeft: "40%",
                    fontSize: "3rem",
                  }}
                >
                  Unmatched Details
                </h2>
                <br />
                <br />
                {unmatchedDetails &&
                  unmatchedDetails.map((detail, index) => {
                    const key = Object.keys(detail);
                    const val = Object.values(detail);
                    return (
                      <div className="inpt_bx">
                        <label>{key[0]}: &nbsp; &nbsp;</label>
                        <input type="text" value={val[0]} disabled />
                        <br />
                        <br />
                      </div>
                    );
                  })}
              </div>
            )}
            {sectionName === "Services Offered" &&
            servicesOffered.length !== 0 ? (
              <div className="bank_content">
                <table>
                  <tbody>
                    <tr>
                      <th>Service</th>
                      <th>Price</th>
                    </tr>
                    {servicesOffered.map((service, index) => {
                      return (
                        <tr>
                          <td>{service.service}</td>
                          <td>{service.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                {sectionName === "Services Offered" ? (
                  <div className="bank_content">
                    <h1>No Proposal sent.</h1>
                    <br />
                    <button className="notification_btn">Send</button>
                  </div>
                ) : null}
              </>
            )}
          </>
        </div>
      ) : (
        <div
          className="mainsection bnk_sctn bank_content"
          style={{ fontWeight: "bold", marginLeft: "48%", fontSize: "3rem" }}
        >
          <FadeLoader color={"#b07f35"} />
        </div>
      )}
      {details && (
        <div className="button-container">
          <button
            className="btn btn-prev"
            onClick={() => {
              navigate(-1);
              window.close();
            }}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default MatchedOperatorDetails;
