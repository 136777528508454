import React, { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Admin_LOGIN_URL } from '../../Api/Api';

const AdminLogin = () => {
    // const dispatch = useDispatch()
    const [otp, setOtp] = useState('');
  useEffect(()=>{
   console.log(typeof otp,'otpppp')
  },[otp])
    const [loadingstate, setLoadingState] = useState('initial')
    const [isLoading,setIsLoading]= useState(false)
    const navigate = useNavigate()
    const [user, setUser] = useState('Operator')
    const [credentials, setCredentials] = useState({
      email: '', password: ''
    })
    console.log(user, 'op')
    const CredentialsChangeHandler = (event) => {
      setCredentials({
        ...credentials, [event.target.name]: event.target.value
      })
    }
    // const RadioHandler = (event) => {
    //   setUser(event.target.value)
    // }
    const LoginHandler = async (event) => {
      if(event){
        event.preventDefault()
      }
     setIsLoading(true)
      await axios.post(Admin_LOGIN_URL, credentials, {
        params: {
          role: 'admin'
        }
      }).then(res => {
        setLoadingState('showverifyotp')
        setIsLoading(false)
        swal('Done!',`${res.data.message}`,'success')
        // swal({
        //   content: "input",
        // });
        // Swal.fire('Success!', `${res.data.message}`, 'success')
        console.log(res.data, 'target')
        localStorage.setItem('admintoken',res.data.token)
        localStorage.setItem('role',res.data.role.role)
        localStorage.setItem('name',res.data.user.adminName)
          //  sessionStorage.setItem('token',res.data.token)
          //  sessionStorage.setItem('role',res.data.role.role)
          //  sessionStorage.setItem('name',res.data.user.adminName)
          
        navigate('/admin-bank-listing')
        //   console.log(typeof res.data.user.matchedApplicationPercentage,'checkkk')
        //   localStorage.setItem('token',res.data.token)
        //   localStorage.setItem('role',res.data.role)
        //  if(res.data.role === 'bank'){
        //   localStorage.setItem('logo',res.data.user.bankLogo)
        //   localStorage.setItem('name',res.data.user.bankName)
        //   localStorage.setItem('access',res.data.user.access)
        //   localStorage.setItem('formFilled',res.data.user.formFilled)
        //   localStorage.setItem('matchedApplicationPercentage',res.data.user.matchedApplicationPercentage)
        //  }else{
        //   // localStorage.setItem('logo',res.data.user.operatorLogo)
        //   localStorage.setItem('name',res.data.user.fullName)
        //   localStorage.setItem('access',res.data.user.access)
        //   localStorage.setItem('phoneNumber',res.data.user.phoneNumber)
        //   localStorage.setItem('email',res.data.user.email)
        //   localStorage.setItem('formFilled',res.data.user.formFilled)
        //  }
  
        //   dispatch(userRoleSetter(res.data.role))
        //   dispatch(userLogin(true))
        //   dispatch(successfullLogin(res.data.user))
        //   if(res.data.role === 'operator'){
        //     // navigate('/operator-dashboard')
        //     if(res.data.user.formFilled){
        //       navigate('/operator-applications')
        //     }else{
        //       navigate('/home')
        //     }
        //   }else{
        //     navigate('/bank-dashboard')
        //   }
      }).catch(err => {
        console.log(err,'errorit')
        Swal.fire('Oops!', `${err.response.data.message}`, 'error')
      })
    }
    
  return (
    <>
      <header className="sign_up_header">
        <div className="container">
          <a href="javascript:void(0)" className="logo">
            <img src={require("../../Assets/images/logo.png")} alt="" />
          </a>
        </div>
      </header>
      <main>
        <section className="bnr_sec">
          <div className="container">
            <div className="bnr_hdng">
              <h1>
                Build Your Business <br />
                An Unshakable Foundation.
              </h1>
            </div>
          </div>
          <div className="bnr_img">
            <img src={require("../../Assets/images/mn-bnr-bg.jpg")} alt="" />
          </div>
        </section>
        <div className="form_sec">

        
          <form action="" onSubmit={LoginHandler}>
            <div className="frm_hdng">
              <h3>Log In As ADMIN</h3>
            </div>
            <div className="sign_up_flx_bx">
              
            </div>
            <div className="sign_up-otr" id="grdn_sgn_up_oprtr">
              <div className="sgn_up_cntnt" id="oprtr_cntnt">
                <div className="inpt_bx">
                  <input type="text" name="email" placeholder="Email/Phone" required onChange={CredentialsChangeHandler} />
                </div>
                <div className="inpt_bx">
                  <input type="password" name="password" placeholder="Password" required onChange={CredentialsChangeHandler} />
                </div>

                <div className="inpt_bx">
                  <input type="submit" defaultValue="NEXT" required
                   value={isLoading ? 'SUBMITING...' : 'SUBMIT'}

                  // onClick={()=>{
                  //   localStorage.setItem('token','tyui65#$090JkmYCtuf2')
                  //   navigate('/home')

                  // }}
                  />
                </div>
                <br />
                {/* <p>Not registered yet? &nbsp;
                  <Link to='/' onClick={() => {
                    window.scrollTo(0, 0)
                  }}>Register yourself</Link></p> */}
                <br />
                {/* <p>
                  <Link to='/forget-password' onClick={() => {
                    window.scrollTo(0, 0)
                  }}>Forget password? </Link></p> */}
              </div>
            </div>
            <div className="sign_up-otr" id="grdn_sgn_up_bnk">
              <div className="sgn_up_cntnt" id="oprtr_cntnt">
                <div className="inpt_bx">
                  <input type="email" name="email" placeholder="Email" required="" />
                </div>
                <div className="inpt_bx">
                  <input type="password" name="password" placeholder="Password" required="" />
                </div>
                <div className="inpt_bx">
                  <input type="submit" defaultValue="NEXT" required="" />
                </div>
              </div>
            </div>
          </form>
          {
            loadingstate === 'showverifyotp' &&
            <div className=''
            >
              <div className="" style={{ borderRadius: '20px' }}>
                
                
                    {/* <div className="cmn_hdr" >
                         <h6>OTP VERIFICATION</h6>
                      <br />
                      
                      <br/>
                      <OtpInput 
                      // style={{marginLeft:'10rem'}}
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                isInputNum={true}
                                renderSeparator={<span>&nbsp; &nbsp; &nbsp; &nbsp; </span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                  border: "1px solid transparent",
                                  borderRadius: "15px",
                                  width: "54px",
                                  height: "54px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid #CFD3DB",
                                  outline: "none"
                                }}
                              />
                              <br/>
                     <CountdownTimer 
                     credentials={credentials} user={user} LoginHandler={LoginHandler}
                     totalSec={180 * 1000} />
                    </div> */}
                  
                <div className="footer">
                  {/* <button type='submit' className='notification_btn ' style={{marginLeft:'28%'}}
                   
                    color="primary" >
                    Change
                  </button>{' '} &nbsp; */}
                 {/* { otp.length !== 0 && <button type='' className='notification_btn' style={{marginLeft:'17rem'}}
                  // disabled={otp.length === 0}
                    onClick={VerifyOTP}
                    color="primary" >
              Verify
                  </button>
                  }{' '} */}
                </div>
              </div>
            </div>
          }
        </div>
      </main>
      
    </>
  )
}

export default AdminLogin