const baseURL = "https://api.guardianbankingapplications.com";

// http://localhost:3000/bank-dashboard
const Operator_SignUp_URL = `${baseURL}/user/register`;
const OperatorApplicationsList_URL = `${baseURL}/applicant/formList`;
const OperatorSingleApplication_URL = `${baseURL}/applicant/Oneoperatorform`;
// https://guardianbankingsolutions.net/applicant/Oneoperatorform/:applicationID
const OperatorProposalsListGet_URL = `${baseURL}/applicant/proposal`;
const OperatorSingleProposalGet_URL = `${baseURL}/applicant/proposal`;
const OperatorApprove_Proposal_URL = `${baseURL}/applicant/proposal/accept`;
const OperatorDecline_Proposal_URL = `${baseURL}/applicant/proposal/decline`;
const OperatorProposalbankDetails_URL = `${baseURL}/applicant/proposal/bankDetails`;
//  const OperatorSingleProposalGet_URL = `${baseURL}/applicant/proposal`
// https://guardianbankingsolutions.net/user/activation
const Bank_SignUp_URL = `${baseURL}/user/signup`;
const Login_URl = `${baseURL}/user/login`;
const OTPVerification_URL = `${baseURL}/user/verify`;
const ForgotPassword_URL = `${baseURL}/user/forgotPassword`;
const ResetPassword_URL = `${baseURL}/user/resetPassword`;
const Sign_Up_URL = `${baseURL}/user/signup`;
const Registration_Successfull_URL = `${baseURL}/user/activation`;
const Customer_Form_FillUp = `${baseURL}/applicant/form`;

const Operaor_BusinessSetUp_URL = `${baseURL}/applicant/setup/create`;
const Getall_Bank_Applications = `${baseURL}/bank/settings/details`;
const GetBankSingleForm_URL = `${baseURL}/bank/Onebankform`;
const GetBank_Profile = `${baseURL}/bank/profile`;
const GetOperator_Profile = `${baseURL}/applicant/profile`;
const Bank_ProfileUpdate = `${baseURL}/bank/profile/update`;
const Operator_ProfileUpdate = `${baseURL}/applicant/profile/update`;
const Fill_BusinessSetUp_URL = `${baseURL}/bank/setup/create`;
const GetAll_Matching_Operators_URL = `${baseURL}/bank/matchingApplications`;
const GetAllBank_Approved_Operators_URL = `${baseURL}/bank/approvedApplications`;
const Bank_ApplyChargeOnViewApplication_URL = `${baseURL}/bank/viewApplication`;
// https://guardianbankingsolutions.net/bank/viewApplication
const GetBank_SetUp_URL = `${baseURL}/bank/setup`;
const Getbank_ServicesOffered_URL = `${baseURL}/bank/servicesOffered`;
// https://guardianbankingapplications.com/bank/servicesOffered
const UpdateBankSetup_URL = `${baseURL}/bank/setup/update`;
const DeleteBankSetup_URL = `${baseURL}/bank/setup/delete`;
//Bank Form update Url:-
const BankSettings_Update_URL = `${baseURL}/bank/settings/update`;
//Get Single Matched Operator Details Api:-
const BankGetMatchedOperatorDetails_URL = `${baseURL}/bank/matchingApplications/details`;
//Get Accepted forms:-
const Bank_GetAll_AcceptedForms_URL = `${baseURL}/bank/acceptedApplications`;
//bank Setting Fill up:-
const Bank_FillupSettings_URL = `${baseURL}/bank/settings/fillup`;
//Bank Accept Form:-
const BankAcceptForm_URL = `${baseURL}/bank/acceptApplication`;
//Bank Password Change:-
const BankUpdate_Password_URL = `${baseURL}/bank/profile/password`;
//Bank Send Proposal:-
const BankSendProposal_URL = `${baseURL}/bank/sendproposal`;
//Bank See Proposal Details:_
const BankProposalDetails_URL = `${baseURL}/bank/getSentProposal`;
//Bank Open Account :-
const BankOpenAccount = `${baseURL}/bank/account/open`;
//Bank Decline Account
const BankDeclineAccount = `${baseURL}/bank/account/decline`;
// https://guardianbankingsolutions.net/bank/settings/Details
//Bank Setting-Details:-
const Bank_GetSettingDetails_URL = `${baseURL}/bank/settings/Details`;
//Bank-Settings Ipdate:-
const Bank_UpdateSettings_URL = `${baseURL}/bank/settings/update`;
// https://guardianbankingsolutions.net/bank/settings/update
//Bank Dashboard:
const Get_BankDashboard_URL = `${baseURL}/bank/dashboard`;
//Operator Dashboard:-
const Get_OperatorDashboard_URL = `${baseURL}/applicant/dashboard`;
const Get_BankViewed_Applications_URL = `${baseURL}/bank/viewedApplications`;

//Matched Operators Filter by percentage:-
// const BankMatchedOperators_FilterBy_URL = `${baseURL}/bank/acceptForm`
// https://guardianbankingapplications.com/applicant/dashboard
//Admin
const Admin_LOGIN_URL = `${baseURL}/admin/login`;
const GetAllbanks_URL = `${baseURL}/admin/banks`;
const GetAlloperators_URL = `${baseURL}/admin/operators`;
const AdminAsUser_LogIn_URL = `${baseURL}/user/login`;
const Admin_UpdateBankStage_URL = `${baseURL}/admin/banks/paymentStage`;
const Admin_ActiveBankAccountStatus_URL = `${baseURL}/admin/banks/activate`;
const Admin_Report_URL = `${baseURL}/admin/banks/reports`;
// https://guardianbankingapplications.com/admin/banks/update/:id
export {
  Operator_SignUp_URL,
  Bank_SignUp_URL,
  Login_URl,
  Sign_Up_URL,
  Customer_Form_FillUp,
  GetBank_Profile,
  Getall_Bank_Applications,
  Bank_ProfileUpdate,
  GetAll_Matching_Operators_URL,
  GetOperator_Profile,
  Operator_ProfileUpdate,
  Fill_BusinessSetUp_URL,
  GetBank_SetUp_URL,
  UpdateBankSetup_URL,
  DeleteBankSetup_URL,
  BankGetMatchedOperatorDetails_URL,
  BankSettings_Update_URL,
  Bank_GetAll_AcceptedForms_URL,
  Bank_FillupSettings_URL,
  BankAcceptForm_URL,
  BankUpdate_Password_URL,
  OperatorApplicationsList_URL,
  ForgotPassword_URL,
  ResetPassword_URL,
  Operaor_BusinessSetUp_URL,
  BankSendProposal_URL,
  OperatorSingleApplication_URL,
  OperatorProposalsListGet_URL,
  OperatorSingleProposalGet_URL,
  GetBankSingleForm_URL,
  OperatorApprove_Proposal_URL,
  OperatorDecline_Proposal_URL,
  GetAllBank_Approved_Operators_URL,
  BankProposalDetails_URL,
  BankOpenAccount,
  BankDeclineAccount,
  OperatorProposalbankDetails_URL,
  OTPVerification_URL,
  Admin_LOGIN_URL,
  GetAllbanks_URL,
  GetAlloperators_URL,
  AdminAsUser_LogIn_URL,
  Admin_UpdateBankStage_URL,
  Admin_ActiveBankAccountStatus_URL,
  Get_BankDashboard_URL,
  Get_OperatorDashboard_URL,
  Getbank_ServicesOffered_URL,
  Registration_Successfull_URL,
  Bank_ApplyChargeOnViewApplication_URL,
  Get_BankViewed_Applications_URL,
  Admin_Report_URL,
  Bank_GetSettingDetails_URL,
  Bank_UpdateSettings_URL,
};
//https://guardianbankingapplications.com/admin/banks/activate/:id
