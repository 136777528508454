import axios from 'axios'
import React from 'react'
import { BankUpdate_Password_URL, Bank_ProfileUpdate } from '../../Api/Api'
import swal from 'sweetalert'
import { useState } from 'react'
import Swal from 'sweetalert2'
import '../Modal/Modal.css'

const BankSettings = () => {
  
  const [percentage, setPercentage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: ''
  })
  const PasswordChangeHandler = (event) => {
    setPassword({
      ...password, [event.target.name]: event.target.value
    })
  }
  const PasswordChangeModalController = () => {
    if(password.oldPassword !== password.newPassword ){
      axios.put(BankUpdate_Password_URL, password, {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      }).then(res=>{
        swal('Success!',`${res.data.message}`,'success')
        setShow(false)
      }).catch(err=>{
        swal('Oops!',`${err.response.data.message}`,'error')
        console.log(err,'error')})
    }else{
   swal('Oops!','Old and new password cannot be same!','error')
    }
    // Swal.fire({
    //   title: 'Login Form',
    //   html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
    //   <input type="password" id="password" class="swal2-input" placeholder="Password">`,
    //   confirmButtonText: 'Sign in',
    //   focusConfirm: false,
    //   preConfirm: () => {
    //     const login = Swal.getPopup().querySelector('#login').value
    //     const password = Swal.getPopup().querySelector('#password').value
    //     if (!login || !password) {
    //       Swal.showValidationMessage(`Please enter login and password`)
    //     }
    //     return { login: login, password: password }
    //   }
    // }).then((result) => {
    //   Swal.fire(`
    //     Login: ${result.value.login}
    //     Password: ${result.value.password}
    //   `.trim())
    // })


  }
  const SetMatchingpercentage = () => {
    swal({
      title: "Are you sure to set matching percentage?",
      buttons: {
        confirm: 'YES',
        cancel: 'NO'
      },
      type: "success"

    }).then((okay) => {
      if (okay) {
        setIsLoading(true)
        const formdata = new FormData()
        formdata.append('matchedApplicationPercentage', percentage)
        axios.put(Bank_ProfileUpdate, formdata, {

          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).then(res => {
          localStorage.setItem('matchedApplicationPercentage', percentage)
          setIsLoading(false)
          swal('Updated', 'Percentage set succesfully!', 'success')
        }).catch(err => console.log(err, 'error'))



      }
    })



  }
  return (
    <div className='mainsection bnk_sctn'>
      {show &&
        <div className='modal'
        >
          <div className="content-passwordreset" style={{ borderRadius: '20px' }}>
            <h4>Reset Password</h4>
            <div className="">
              <div className="">
                <div className="cmn_hdr" style={{marginLeft:'30%'}}>
                  <div className="inpt_bx">
                    <input type="password" name='oldPassword' placeholder="Old Password" required onChange={PasswordChangeHandler} />
                  </div>
                  <br />
                  <div className="inpt_bx">
                    <input type="password" name='newPassword' placeholder="New Password" required onChange={PasswordChangeHandler} />
                  </div>
                </div>
              </div>

            </div>
            <div className="footer">
              <button type='submit' className='notification_btn ' style={{marginLeft:'28%'}}
                onClick={PasswordChangeModalController}
                color="primary" >
                Change
              </button>{' '} &nbsp;
              <button type='submit' className='notification_btn ' 
                onClick={()=>{setShow(false)}}
                color="primary" >
                Cancel
              </button>{' '}
            </div>
          </div>
        </div>
      }
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2 style={{fontWeight:'bold'}}>Settings</h2>
          <div className='pg1-prt-2-inrfld'>
            <h6>Select Matching Percentage</h6>
            <select className="" style={{ float: '', width: '40%' }}

              onChange={(event) => {
                setPercentage(Number(event.target.value))


              }}
            >

              <option value={0} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 0 ? true : false}>0</option>
              <option value={10} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 10 ? true : false}>10</option>
              <option value={20} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 20 ? true : false}>20</option>
              <option value={30} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 30 ? true : false}>30</option>
              <option value={40} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 40 ? true : false}>40</option>
              <option value={50} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 50 ? true : false}>50</option>
              <option value={60} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 60 ? true : false}>60</option>
              <option value={70} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 70 ? true : false}>70</option>
              <option value={80} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 80 ? true : false}>80</option>
              <option value={90} selected={Number(localStorage.getItem('matchedApplicationPercentage')) === 90 ? true : false}>90</option>
            </select>
            <br /><br />
            <button className='notification_btn' onClick={SetMatchingpercentage}>{isLoading ? 'Setting..' : 'SET'}</button>
          </div>
          <br />
          <div className='pg1-prt-2-inrfld'>



            <button className='notification_btn' onClick={() => { setShow(true) }}>Change Password</button>
          </div>
        </div></div>
    </div>
  )
}

export default BankSettings