import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BankForm_Update_URL,
  Bank_ProfileUpdate,
  Getall_Bank_Applications,
} from "../../Api/Api";
import swal from "sweetalert";
import { FadeLoader } from "react-spinners";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const BankApplications = () => {
  const [application, setApplication] = useState();
  const [sectionName, setSectionName] = useState("Bank Settings");
  const [toggle, setToggle] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [activityStatus,setActivitystatus] = useState(false)
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(Getall_Bank_Applications, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        console.log(response.data, "allapps");
        setApplication(response.data);
        // setActivitystatus(response.data.active)
        setIsLoading(false);
      })
      .catch((err) => console.log(err, "erriooo"));
  }, []);

  //For Handling the status:-
  const StatusHandler = (event) => {
    {
      swal({
        title:
          "Receive application status will be changed. Are you sure to continue?",
        buttons: {
          confirm: { text: "YES", className: "sweet-warning" },
          cancel: "NO",
        },
        type: "success",
      }).then((okay) => {
        if (okay) {
          setIsLoading(true);

          axios
            .put(
              Bank_ProfileUpdate,
              {
                isActive:
                  localStorage.getItem("isActive") === "true" ? false : true,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              console.log(res.data, "afterupdatebankform");
              // setActivitystatus(res.data.newProfile.isActive)
              localStorage.setItem("isActive", res.data.newProfile.isActive);
              setIsLoading(false);
              swal("Updated!", `Form Status Updated!`, "success");
            });
        } else {
          // setActivitystatus('No')
        }
      });
    }
  };
  return (
    <div className="mainsection bnk_sctn">
      <div className="bank_content">
        <div className="cmn_hdr">
          <h2 style={{ fontWeight: "bold" }}>Bank Settings</h2>
          <br />
          <div className="tabs">
            <div
              className={`${toggle === 1 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(1);
                setSectionName("Bank Settings");
              }}
            >
              Bank Settings
            </div>
            <div
              className={`${toggle === 2 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(2);
                setSectionName("Communication Settings");
              }}
            >
              Communication Settings
            </div>
            <div
              className={`${toggle === 3 ? "tab active-tab" : "tab"}`}
              onClick={() => {
                setToggle(3);
                setSectionName("Payout Settings");
              }}
            >
              Payout Settings
            </div>
          </div>
          <br />
        </div>
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Created at</th>
              <th>Receive Application Status</th>
              <th>View</th>
            </tr>
            {
              // applications.length !== 0 ? applications.map((application,index)=>{
              //   return
              application ? (
                <tr>
                  <td>Settings</td>
                  <td>{application.createdAt}</td>
                  <td>
                    <div className="status">
                      {/* <div className="status__light">
                <div className="status__light__ring" />
                <div className="status__light__led" />
              </div> */}
                      {/* <div className="status__message">{application.formStatus}</div> */}
                      <div className="switch_box">
                        <label className="switch2">
                          <input
                            style={{}}
                            type="checkbox"
                            checked={
                              localStorage.getItem("isActive") === "true"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              StatusHandler(event);
                            }}
                            name="products_purchased_providers"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      onClick={() => {
                        navigate(`/settings-details`);
                      }}
                      style={{
                        marginLeft: "2rem",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                    >
                      <BsEyeFill />
                    </span>
                  </td>
                </tr>
              ) : (
                <div
                  className="mainsection bnk_sctn bank_content"
                  style={{
                    fontWeight: "bold",
                    marginLeft: "60%",
                    fontSize: "3rem",
                  }}
                >
                  <FadeLoader color={"#b07f35"} />
                </div>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BankApplications;
