import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { BankSendProposal_URL, Getbank_ServicesOffered_URL } from '../../Api/Api'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
const BankProposalToOperator = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [servicesPrice, setServicesPrice] = useState({

    })
    const [servicesOffered, setServicesOffered] = useState([])
    useEffect(() => {
        axios.get(Getbank_ServicesOffered_URL, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).then(res => {
            console.log(res.data.servicesOffered, 'ioprhje')
            setServicesOffered(res.data.servicesOffered.map(x => x.service))
            for (let o = 0; o < res.data.servicesOffered.length; o++) {
                servicesPrice[res.data.servicesOffered[o].service] = res.data.servicesOffered[o].price
            }
            console.log(servicesPrice, 'servicesPrice')
        }).catch(err => console.log(err, 'error'))
    }, [])
    const ServicesOfferedHandler = (e) => {
        const data = [...servicesOffered]
        if (e.target.checked) {
            data.push(e.target.value)
            setServicesOffered([...data])
        } else {
            setServicesOffered(data.filter(i => i !== e.target.value))
        }
    }
    const ServicesPriceChangeHandler = (e) => {
        setServicesPrice({ ...servicesPrice, [e.target.name]: e.target.value })
    }
    return (
        <div className='mainsection bnk_sctn'>
            {
                servicesOffered.length !== 0 && <div className='content'>
                    <div className="cmn_hdr">
                        <div className="pg3-mn-otrfld">

                            <div className='check__in__bx'>
                                <h6>Services Offered:</h6>
                                <div className="pg3-mn-inrfld" >
                                    <input type="checkbox" name="pg-bx" value='Business Checking'
                                        checked={servicesOffered.includes('Business Checking')}

                                        onChange={ServicesOfferedHandler}
                                    />
                                    <label>Business Checking</label>

                                </div>
                                {servicesOffered.includes('Business Checking') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Business Checking']}
                                                name='Business Checking'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}

                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Interest Bearing Accounts')}
                                        onChange={ServicesOfferedHandler}
                                        value='Interest Bearing Accounts' />
                                    <label>Interest Bearing Accounts</label>

                                </div>
                                {servicesOffered.includes('Interest Bearing Accounts') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Interest Bearing Accounts']}
                                                name='Interest Bearing Accounts'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Debit Cards')}

                                        onChange={ServicesOfferedHandler}
                                        value='Debit Cards'
                                    />
                                    <label>
                                        Debit Cards
                                    </label>

                                </div>
                                {servicesOffered.includes('Debit Cards') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Debit Cards']}
                                                name='Debit Cards'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('ACH')}

                                        onChange={ServicesOfferedHandler}
                                        value='ACH'
                                    />
                                    <label>ACH</label>

                                </div>
                                {servicesOffered.includes('ACH') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['ACH']}
                                                name='ACH'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Wires')}
                                        onChange={ServicesOfferedHandler}
                                        value='Wires'
                                    />
                                    <label>
                                        Wires
                                    </label>

                                </div>
                                {servicesOffered.includes('Wires') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Wires']}
                                                name='Wires'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>

                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Discount for Social Equity')}
                                        onChange={ServicesOfferedHandler}
                                        value='Discount for Social Equity'
                                    />
                                    <label>
                                        Discount for Social Equity
                                    </label>

                                </div>
                                {servicesOffered.includes('Discount for Social Equity') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Discount for Social Equity']}
                                                name='Discount for Social Equity'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Accounts for Employees')}
                                        onChange={ServicesOfferedHandler}
                                        value='Accounts for Employees'
                                    />
                                    <label>
                                        Accounts for Employees
                                    </label>

                                </div>
                                {servicesOffered.includes('Accounts for Employees') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                                value={servicesPrice['Accounts for Employees']}

                                                name='Accounts for Employees'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                            <div className='check__in__bx'>
                                <div className="pg3-mn-inrfld">
                                    <input type="checkbox" name="pg-bx"
                                        checked={servicesOffered.includes('Cash Pickup Services')}
                                        onChange={ServicesOfferedHandler}
                                        value='Cash Pickup Services'
                                    />
                                    <label>
                                        Cash Pickup Services
                                    </label>

                                </div>
                                {servicesOffered.includes('Cash Pickup Services') &&
                                    <div className='price_box' >
                                        <h5>Price</h5>
                                        <div className="" >
                                            <input type="number"
                                            value={servicesPrice['Cash Pickup Services']}
                                                name='Cash Pickup Services'
                                                placeholder="price" required
                                                onChange={ServicesPriceChangeHandler}
                                            />
                                        </div>
                                        <h3 style={{ color: 'red' }}>
                                            {/* {errorState} */}
                                        </h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer">
      
      <button className='notification_btn'
        onClick={() => {
          console.log(servicesOffered,'1234567890')
          console.log(servicesPrice,'1234567890')
          const finalData = servicesOffered.map(t=>{
            return {
                service:t,price:Number(servicesPrice[t])
            }
          })
          console.log(finalData,'1234567890')
        


       
           
          swal({
            title: 'Are you sure you want to send proposal?',
            buttons: {
                confirm: 'YES',
                cancel: 'NO'
            },
            type: "success"
      
        }).then((okay) => {
            if (okay) {
             

                axios.post(`${BankSendProposal_URL}/${id}`, {
                  servicesOffered: finalData
                }, {
                  headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                }).then(res => {
    
                  console.log(res, '70000')
                  swal('Sent!', `${res.data.message}`, 'success')
                  setTimeout(()=>{
                    navigate('/bank-dashboard')
                  },2000)
                }).catch((err)=>{
                  console.log(err,'error')
                  swal('Oops!', `${err.response.data.message}`, 'error')
                })
    
              
                 
                 
                
            }else{
              // setActivitystatus('No')
            }
        })
          


        }}
        
        color="primary" >
        Send Proposal
      </button>
      {' '}

    </div>
                </div>
            }
        </div>
    )
}

export default BankProposalToOperator