import React, { useEffect, useState } from "react";
import useForm from "../../CustomHook/useForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { nextOne, nextOneBank } from "../../Store/userSlice";
import CreatableSelect from "react-select/creatable";
import { omit } from "lodash";
import { BankValidator } from "../../Helper/BankValidator";
import NumberFormat from "react-number-format";
import {
  BankSettings_Update_URL,
  Bank_GetSettingDetails_URL,
} from "../../Api/Api";
import axios from "axios";
import CurrencyInput from "../../Helper/CurrencyInput";
import swal from "sweetalert";
const MySettingsDetails = () => {
  const [api, setApi] = useState(false);
  const [errosBank, setErrorsBank] = useState({});
  const [servicesOffered, setServicesOffered] = useState();
  const ServicesOfferedHandler = (event) => {
    if (event.target.checked) {
      setServicesOffered([...servicesOffered, event.target.value]);
    } else {
      const data = servicesOffered.filter(
        (service) => service !== event.target.value
      );
      setServicesOffered([...data]);
    }
  };
  const [servicesPrices, setServicesPrices] = useState({});
  const ServicesPriceChangeHandler = (event) => {
    setServicesPrices({
      ...servicesPrices,
      [event.target.name]: event.target.value,
    });
  };
  const [acc_creation_time, setAcc_creation_time] = useState({
    convertedToDays: "",
    input: "",
    unit: "Days",
  });
  const AccountCreationTimehandler = (event) => {
    console.log(event.target.value, "AccountCreationTimehandler");
    // Validate(event, event.target.name, event.target.value, errors, setErrors);

    setAcc_creation_time({
      ...acc_creation_time,
      [event.target.name]: event.target.value,
    });
  };
  const [errorState, setErrorstate] = useState({});
  const [Risk_classification_Bank, setRisk_classification_Bank] = useState([]);
  const RiskClassificationBankhandler = (event) => {
    if (event.target.checked) {
      const data = [...Risk_classification_Bank, event.target.value];
      setRisk_classification_Bank(data);
    } else {
      const data = Risk_classification_Bank.filter(
        (x) => x !== event.target.value
      );
      setRisk_classification_Bank(data);
    }
  };
  const [Legal_Business_Bank, setLegal_Business_Bank] = useState({});
  const BankLegalBusinessInfoHandler = (event) => {
    console.log(event, "event");
    if (event.target.name === "licensing_Type") {
      if (event.target.checked) {
        const data = [
          ...Legal_Business_Bank.licensing_Type,
          event.target.value,
        ];
        console.log(data, "001");
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          licensing_Type: data,
        });
      } else {
        const data = Legal_Business_Bank.licensing_Type.filter(
          (term) => term !== event.target.value
        );
        console.log(data, "002");
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          licensing_Type: data,
        });
      }
    } else {
      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          setLegal_Business_Bank({
            ...Legal_Business_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setLegal_Business_Bank({
            ...Legal_Business_Bank,
            [event.target.name]: "no",
          });
        }
      } else if (event.target.name !== "accepted_states") {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        // BankValidator(event, name, val, errosBank, setErrorsBank);
        setLegal_Business_Bank({
          ...Legal_Business_Bank,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  const [Primary_Information_Bank, setPrimary_Information_Bank] = useState({});
  const BankPrimaryContactInfoHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setPrimary_Information_Bank({
          ...Primary_Information_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setPrimary_Information_Bank({
          ...Primary_Information_Bank,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setPrimary_Information_Bank({
        ...Primary_Information_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  //2nd form:-
  const [Operational_details_Bank, setOperational_Details_Bank] = useState({});
  const BankOperationalDetailsHandler = (event) => {
    if (event.target.name === "types_customers") {
      if (event.target.checked) {
        const data = [
          ...Operational_details_Bank.types_customers,
          event.target.value,
        ];
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          types_customers: data,
        });
      } else {
        const data = Operational_details_Bank.types_customers.filter(
          (x) => x !== event.target.value
        );
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          types_customers: data,
        });
      }
    }
    if (
      event.target.type === "checkbox" &&
      event.target.name !== "types_customers"
    ) {
      if (event.target.checked) {
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setOperational_Details_Bank({
          ...Operational_details_Bank,
          [event.target.name]: "no",
        });
      }
    } else if (event.target.name !== "types_customers") {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      // BankValidator(event, name, val, errosBank, setErrorsBank);
      setOperational_Details_Bank({
        ...Operational_details_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const [Other_Operational_Details_Bank, setOther_Operational_Details_Bank] =
    useState({});
  const BankOtherOperationaldetailsHandler = (event) => {
    if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setOther_Operational_Details_Bank({
          ...Other_Operational_Details_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setOther_Operational_Details_Bank({
          ...Other_Operational_Details_Bank,
          [event.target.name]: "no",
        });
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      // BankValidator(event, name, val, errosBank, setErrorsBank);
      setOther_Operational_Details_Bank({
        ...Other_Operational_Details_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const [
    Anticipated_Transaction_Activity_Bank,
    setAnticipated_Transaction_Activity_Bank,
  ] = useState({});
  const BankAnticipatedTransactionActivityHandler = (event) => {
    console.log(event.target.value, "888");
    console.log(event.target.name, "888");
    if (event.target.type === "checkbox") {
      if (
        event.target.name === "anticipate_cash_withdrawals" ||
        event.target.name === "cash_pick_ups" ||
        event.target.name === "anticipate_cash_deposits"
      ) {
        if (event.target.checked) {
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            [event.target.name]: "no",
          });
        }
      } else if (event.target.name === "frequency_cash_withdrawals") {
        console.log("8908");
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals,
            event.target.value,
          ];
          console.log(data, "my-data");
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_withdrawals: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_withdrawals: data,
          });
        }
      } else if (event.target.name === "frequency_cash_pick_ups") {
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups,
            event.target.value,
          ];
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_pick_ups: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_pick_ups: data,
          });
        }
      } else if (event.target.name === "frequency_cash_deposits") {
        if (event.target.checked) {
          const data = [
            ...Anticipated_Transaction_Activity_Bank.frequency_cash_deposits,
            event.target.value,
          ];
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_deposits: data,
          });
        } else {
          const data =
            Anticipated_Transaction_Activity_Bank.frequency_cash_deposits.filter(
              (i) => i !== event.target.value
            );
          setAnticipated_Transaction_Activity_Bank({
            ...Anticipated_Transaction_Activity_Bank,
            frequency_cash_deposits: data,
          });
        }
      }
    } else {
      event.persist();
      let name = event.target.name;
      let val = event.target.value;
      console.log(val, name, "uio");
      // calling the custom validate function
      BankValidator(event, name, val, errosBank, setErrorsBank);
      setAnticipated_Transaction_Activity_Bank({
        ...Anticipated_Transaction_Activity_Bank,
        [event.target.name]: event.target.value,
      });
    }
  };
  const [Company_Documentation_Bank, setCompany_Documentation_Bank] = useState(
    []
  );
  const BankCompanyDocumentationHandler = (event) => {
    if (event.target.checked) {
      setCompany_Documentation_Bank([
        ...Company_Documentation_Bank,
        event.target.value,
      ]);
    } else {
      setCompany_Documentation_Bank(
        Company_Documentation_Bank.filter((x) => x !== event.target.value)
      );
    }
  };
  const [Legacy_Cash_Bank, setLegacy_Cash_Bank] = useState({});
  const BankLegacyCashhandler = (event) => {
    console.log(event.target.name, "122222");
    if (event.target.name === "legacy_cash") {
      if (event.target.checked) {
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          [event.target.name]: "yes",
        });
      } else {
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          [event.target.name]: "no",
        });
      }
    } else if (event.target.name === "documents_available") {
      console.log("111111");
      if (event.target.checked) {
        const data = [
          ...Legacy_Cash_Bank.documents_available,
          event.target.value,
        ];

        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          documents_available: data,
        });
      } else {
        const data = Legacy_Cash_Bank.documents_available.filter(
          (x) => x !== event.target.value
        );
        setLegacy_Cash_Bank({
          ...Legacy_Cash_Bank,
          documents_available: data,
        });
      }
    }
  };

  const [Cash_Management_Bank, setCash_Management_Bank] = useState({});
  const BankCashManagementhandler = (event) => {
    if (event.target.name === "vendor_payment_methods") {
      if (event.target.checked) {
        setCash_Management_Bank({
          ...Cash_Management_Bank,
          vendor_payment_methods: [
            ...Cash_Management_Bank.vendor_payment_methods,
            event.target.value,
          ],
        });
      } else {
        // console.log(Cash_Management.vendor_payment_methods, '4555555')
        const data = Cash_Management_Bank.vendor_payment_methods.filter(
          (term) => term !== event.target.value
        );
        console.log(data, "09237529357");
        setCash_Management_Bank({
          ...Cash_Management_Bank,
          vendor_payment_methods: data,
        });
      }
    } else {
      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          setCash_Management_Bank({
            ...Cash_Management_Bank,
            [event.target.name]: "yes",
          });
        } else {
          setCash_Management_Bank({
            ...Cash_Management_Bank,
            [event.target.name]: "no",
          });
        }
      } else if (event.target.name === "lengthTimeInput") {
        // Cash_Management_Bank.length_time.lengthTimeInput
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        // BankValidator(event, name, val, errosBank, setErrorsBank);
        Cash_Management_Bank.length_time.lengthTimeInput = val;
        setCash_Management_Bank({ ...Cash_Management_Bank });
      } else if (event.target.name === "lengthTimeUnit") {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        // BankValidator(event, name, val, errosBank, setErrorsBank);
        Cash_Management_Bank.length_time.lengthTimeUnit = val;
        setCash_Management_Bank({ ...Cash_Management_Bank });
      } else {
        event.persist();
        let name = event.target.name;
        let val = event.target.value;
        console.log(val, name, "uio");
        // calling the custom validate function
        // BankValidator(event, name, val, errosBank, setErrorsBank);

        setCash_Management_Bank({ ...Cash_Management_Bank, [name]: val });
      }
    }
  };
  const [Transfer_Existing_Bank_Bank, setTransfer_Existing_Bank_Bank] =
    useState({});
  const BankTransferExistingBankhandler = (event) => {
    if (event.target.checked) {
      setTransfer_Existing_Bank_Bank({
        ...Transfer_Existing_Bank_Bank,
        [event.target.name]: "yes",
      });
    } else {
      setTransfer_Existing_Bank_Bank({
        ...Transfer_Existing_Bank_Bank,
        [event.target.name]: "no",
      });
    }
  };
  const USASTATES = [
    "Alabama(AL)",
    "Alaska (AK)",
    "Arizona (AZ)",
    " Arkansas (AR)",
    " California (CA)",
    "Colorado (CO)",
    "Connecticut (CT)",
    " Delaware (DE)",
    "Florida (FL)",
    "Georgia (GA)",
    "Hawaii (HI)",
    "Idaho (ID)",
    "Illinois (IL)",
    "Indiana (IN)",
    "Iowa (IA)",
    "Kansas (KS)",
    "Kentucky (KY)",
    "Louisiana (LA)",
    " Maine (ME)",
    "Maryland (MD)",
    "Massachusetts (MA)",
    "Michigan (MI)",
    "Minnesota (MN)",
    "Mississippi (MS)",
    " Missouri (MO)",
    "Montana (MT)",
    "Nebraska (NE)",
    "Nevada (NV)",
    "New Hampshire (NH)",
    "New Jersey (NJ)",
    "NewMexico (NM)",
    "NewYork (NY)",
    "North Carolina (NC)",
    "North Dakota (ND)",
    "Ohio (OH)",
    "Oklahoma (OK)",
    "Oregon (OR)",
    "Pennsylvania (PA)",
    "RhodeIsland (RI)",
    " South Carolina (SC)",
    "South Dakota (SD)",
    "Tennessee (TN)",
    "Texas (TX)",
    " Utah (UT)",
    "Vermont (VT)",
    "Virginia (VA)",
    "Washington (WA)",
    " West Virginia (WV)",
    "Wisconsin (WI)",
    "Wyoming (WY)",
  ];

  const dispatch = useDispatch();
  const {
    // Operational_details_Bank,
    // Other_Operational_Details_Bank,
    // BankOperationalDetailsHandler,
    // BankOtherOperationaldetailsHandler,
    // Anticipated_Transaction_Activity_Bank,
    // BankAnticipatedTransactionActivityHandler,
  } = useForm();
  const {
    // RiskClassificationBankhandler,
    // Risk_classification_Bank,
    // Legal_Business_Bank,
    // setLegal_Business_Bank,
    // BankLegalBusinessInfoHandler,
    // BankPrimaryContactInfoHandler,
    // Primary_Information_Bank,
    // errosBank,
    // setErrorsBank,
    // acc_creation_time,
    // AccountCreationTimehandler,
    // servicesOffered,
    // ServicesOfferedHandler,
    // servicesPrices,
    // ServicesPriceChangeHandler,
  } = useForm();

  const {
    // Legacy_Cash_Bank,
    // BankLegacyCashhandler,
    // Cash_Management_Bank,
    // BankCashManagementhandler,
    // Transfer_Existing_Bank_Bank,
    // BankTransferExistingBankhandler,
  } = useForm();
  toast.configure({
    autoClose: 500000,
    draggable: true,
  });
  // const [errorState, setErrorstate] = useState(false);
  const StateOptions = USASTATES.map((x) => {
    return {
      value: x,
      label: x,
      color: "#00B8D9",
    };
  });
  useEffect(() => {
    axios
      .get(Bank_GetSettingDetails_URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        console.log(res.data, "wholedata");
        console.log(
          res.data.servicesOffered.map((i) => i.service),
          "812828228"
        );
        setServicesOffered(res.data.servicesOffered.map((i) => i.service));
        const pricesObject = {};
        let x = res.data.servicesOffered.map((i) => {
          pricesObject[i.service] = i.price;
        });
        setServicesPrices({ ...pricesObject });
        setAcc_creation_time(res.data.acc_creation_time);
        setRisk_classification_Bank(res.data.risk_classification);
        setLegal_Business_Bank(res.data.Legal_Business);
        setPrimary_Information_Bank(res.data.Primary_Information);
        setOperational_Details_Bank(res.data.Operational_Details);
        setOther_Operational_Details_Bank(res.data.Other_Operational_Details);
        setOther_Operational_Details_Bank(res.data.Other_Operational_Details);
        setAnticipated_Transaction_Activity_Bank(
          res.data.Anticipated_Transaction_Activity
        );
        setCompany_Documentation_Bank(res.data.Company_Documentation);
        setLegacy_Cash_Bank(res.data.Legacy_Cash);
        setCash_Management_Bank(res.data.Cash_Management);
        setTransfer_Existing_Bank_Bank(res.data.Transfer_Existing_Bank);
      })
      .catch((err) => console.log(err, "error"));
  }, [api]);
  const SubmitHandler = (event) => {
    event.preventDefault();
    console.log(typeof Cash_Management_Bank.number_vendors_max, "7890");
    //Validation for Services Offered:-
    if (servicesOffered.length === 0) {
      errosBank.servicesOffered = "Please select atleast one option";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.servicesOffered;
      setErrorsBank({ ...errosBank });
    }
    for (let item of servicesOffered) {
      if (servicesPrices[item] === "") {
        errosBank[item] = "Cannot be empty";
        setErrorsBank({ ...errosBank });
      } else {
        delete errosBank[item];
        setErrorsBank({ ...errosBank });
      }
    }
    //Validation for acc_creation_time:-
    if (acc_creation_time.input === "") {
      errosBank.input = "Cannot be empty";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.input;
      setErrorsBank({ ...errosBank });
    }
    //Validation for Risk classification:-
    if (Risk_classification_Bank.length === 0) {
      errosBank.Risk_classification_Bank = "Please select atleast one option";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.Risk_classification_Bank;
      setErrorsBank({ ...errosBank });
    }
    //Validation for Legal Business Information:-

    if (Legal_Business_Bank?.licensing_Type.length === 0) {
      errosBank.licensing_Type = "Please select atleast one option";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.licensing_Type;
      setErrorsBank({ ...errosBank });
    }
    if (Legal_Business_Bank.licensing_Type.includes("Other")) {
      console.log(Legal_Business_Bank.other, "inside");
      if (
        Legal_Business_Bank.other === "" ||
        Legal_Business_Bank.other === undefined
      ) {
        errosBank.other = "Cannot be empty";
        setErrorsBank({ ...errosBank });
      } else {
        delete errosBank.other;
        setErrorsBank({ ...errosBank });
      }
    } else {
      delete errosBank.other;
      setErrorsBank({ ...errosBank });
    }
    if (Legal_Business_Bank.accepted_states.length === 0) {
      errosBank.accepted_states = "Please select atleast one option";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.accepted_states;
      setErrorsBank({ ...errosBank });
    }
    if (Legal_Business_Bank.num_employees_max === "") {
      errosBank.num_employees_max = "Cannot be empty";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.num_employees_max;
      setErrorsBank({ ...errosBank });
    }
    if (Legal_Business_Bank.num_employees_min === "") {
      errosBank.num_employees_min = "Cannot be empty";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.num_employees_min;
      setErrorsBank({ ...errosBank });
    }
    if (
      Number(
        String(Legal_Business_Bank.num_employees_max).replaceAll(",", "")
      ) < Number(Legal_Business_Bank.num_employees_min)
    ) {
      errosBank.num_employeesError =
        "Maximum value must be greater than the minimum value";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.num_employeesError;
      setErrorsBank({ ...errosBank });
    }

    //Validation for Primary Contact Information::-
    if (Primary_Information_Bank.ownership_Percentage === "") {
      errosBank.ownership_Percentage = "Cannot be empty";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.ownership_Percentage;
      setErrorsBank({ ...errosBank });
    }
    //Validation for Operational Details;_

    for (let x in Operational_details_Bank) {
      if (x !== "types_customers") {
        if (Operational_details_Bank[x] === "") {
          errosBank[x] = "cannot be empty";
          setErrorsBank({ ...errosBank });
        } else {
          delete errosBank[x];
          setErrorsBank({ ...errosBank });
        }
      } else {
        if (Operational_details_Bank.types_customers.length === 0) {
          errosBank.types_customers = "Please select atleast one option";
          setErrorsBank({ ...errosBank });
        } else {
          delete errosBank.types_customers;
          setErrorsBank({ ...errosBank });
        }
      }
    }
    if (
      Number(
        String(Operational_details_Bank.acc_need_max).replaceAll(",", "")
      ) < Number(Operational_details_Bank.acc_need_min)
    ) {
      errosBank.acc_needErr =
        "Maximum value must be greater than the minimum value";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.acc_needErr;
      setErrorsBank({ ...errosBank });
    }
    if (
      Number(
        String(Operational_details_Bank.num_locs_max).replaceAll(",", "")
      ) < Number(Operational_details_Bank.num_locs_min)
    ) {
      errosBank.num_locsErr =
        "Maximum value must be greater than the minimum value";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.num_locsErr;
      setErrorsBank({ ...errosBank });
    }
    if (
      Number(
        String(Operational_details_Bank.transactions_Per_month_max).replaceAll(
          ",",
          ""
        )
      ) < Number(Operational_details_Bank.transactions_Per_month_min)
    ) {
      errosBank.transactions_Per_monthErr =
        "Maximum value must be greater than the minimum value";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.transactions_Per_monthErr;
      setErrorsBank({ ...errosBank });
    }
    //Validation for Other Operational Details:-
    for (let x in Other_Operational_Details_Bank) {
      if (Other_Operational_Details_Bank[x] === "") {
        errosBank[x] = "Cannot be empty";
        setErrorsBank({ ...errosBank });
      } else {
        delete errosBank[x];
        setErrorsBank({ ...errosBank });
      }
    }
    if (
      Number(
        String(
          Other_Operational_Details_Bank.number_beneficial_owner_max
        ).replaceAll(",", "")
      ) < Number(Other_Operational_Details_Bank.number_beneficial_owner_min)
    ) {
      errosBank.number_beneficial_ownerErr =
        "Maximum value must be greater than the minimum value";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.number_beneficial_ownerErr;
      setErrorsBank({ ...errosBank });
    }

    //Validation for cash management:-
    for (let x in Cash_Management_Bank) {
      if (x !== "vendor_payment_methods" && x !== "length_time") {
        if (Cash_Management_Bank[x] === "") {
          errosBank[x] = "Cannot be empty";
          setErrorsBank({ ...errosBank });
        } else {
          delete errosBank[x];
          setErrorsBank({ ...errosBank });
        }
      } else {
        if (Cash_Management_Bank.business_acc === "yes") {
          if (Cash_Management_Bank.length_time.lengthTimeInput === "") {
            errosBank.length_time = "Cannot be empty";
            setErrorsBank({ ...errosBank });
          } else {
            delete errosBank.length_time;
            setErrorsBank({ ...errosBank });
          }
        } else {
          delete errosBank.length_time;
          setErrorsBank({ ...errosBank });
        }
        if (Cash_Management_Bank.vendor_payment_methods.length === 0) {
          errosBank.vendor_payment_methods =
            "Please select atleast one method!";
          setErrorsBank({ ...errosBank });
        } else {
          delete errosBank.vendor_payment_methods;
          setErrorsBank({ ...errosBank });
        }
      }
    }
    console.log(Cash_Management_Bank.number_vendors_max, "1200");
    if (
      Number(
        String(Cash_Management_Bank?.number_vendors_max)?.replaceAll(",", "")
      ) < Number(Cash_Management_Bank.number_vendors_min)
    ) {
      errosBank.number_vendorsErr =
        "Maximum number must be greater than the minimum number";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.number_vendorsErr;
      setErrorsBank({ ...errosBank });
    }

    //Validation for legacy cash:-
    if (Legacy_Cash_Bank.legacy_cash === "yes") {
      if (Legacy_Cash_Bank.documents_available.length === 0) {
        errosBank.documents_available = "Please select atleast one option";
        setErrorsBank({ ...errosBank });
      } else {
        delete errosBank.documents_available;
        setErrorsBank({ ...errosBank });
      }
    } else {
      delete errosBank.documents_available;
      setErrorsBank({ ...errosBank });
    }

    //validation for Company Documentation:-
    if (Company_Documentation_Bank.length === 0) {
      errosBank.Company_Documentation_Bank = "Please select atleast one option";
      setErrorsBank({ ...errosBank });
    } else {
      delete errosBank.Company_Documentation_Bank;
      setErrorsBank({ ...errosBank });
    }
    console.log(errosBank, "the4567");
    // input: '2', unit: 'Months', convertedToDays: 1
    let days = 0;
    if (acc_creation_time.unit === "Months") {
      days = Number(acc_creation_time.input) * 30;
    } else if (acc_creation_time.unit === "Years") {
      days = Number(acc_creation_time.input) * 365;
    } else {
      days = Number(acc_creation_time.input);
    }
    let length_timedays = 0;
    if (Cash_Management_Bank.length_time.lengthTimeUnit === "Months") {
      length_timedays =
        Number(Cash_Management_Bank.length_time.lengthTimeInput) * 30;
    } else if (Cash_Management_Bank.length_time.lengthTimeUnit === "Years") {
      length_timedays =
        Number(Cash_Management_Bank.length_time.lengthTimeInput) * 365;
    } else {
      length_timedays = Number(
        Cash_Management_Bank.length_time.lengthTimeInput
      );
    }
    const finalServicesOffered = servicesOffered.map((i) => {
      return {
        service: i,
        price: servicesPrices[i],
      };
    });
    const FinalSettingsData = {
      servicesOffered: finalServicesOffered,
      acc_creation_time: {
        input: acc_creation_time.input,
        unit: acc_creation_time.unit,
        convertedToDays: days,
      },
      risk_classification: Risk_classification_Bank,
      Legal_Business: {
        licensing_Type: Legal_Business_Bank.licensing_Type,
        num_employees_min: Legal_Business_Bank.num_employees_min,
        num_employees_max: Legal_Business_Bank.num_employees_max,
        accepted_states: Legal_Business_Bank.accepted_states,
        foreign_operations: Legal_Business_Bank.foreign_operations,
      },
      Primary_Information: {
        ownership_Percentage: Primary_Information_Bank.ownership_Percentage,
        us_Citizenship: Primary_Information_Bank.us_Citizenship,
      },
      Operational_Details: {
        products_purchased_providers:
          Operational_details_Bank.products_purchased_providers,
        licensed_provider: Operational_details_Bank.licensed_provider,
        acc_need_min: Operational_details_Bank.acc_need_min,
        acc_need_max: Operational_details_Bank.acc_need_max,
        num_locs_min: Operational_details_Bank.num_locs_min,
        num_locs_max: Operational_details_Bank.num_locs_max,
        transactions_Per_month_min:
          Operational_details_Bank.transactions_Per_month_min,
        transactions_Per_month_max:
          Operational_details_Bank.transactions_Per_month_max,
        facility: Operational_details_Bank.facility,
        types_customers: Operational_details_Bank.types_customers,
      },
      Other_Operational_Details: {
        principal_business: Other_Operational_Details_Bank.principal_business,
        beneficial_owners: Other_Operational_Details_Bank.beneficial_owners,
        number_beneficial_owner_min:
          Other_Operational_Details_Bank.number_beneficial_owner_min,
        number_beneficial_owner_max:
          Other_Operational_Details_Bank.number_beneficial_owner_max,
      },
      Anticipated_Transaction_Activity: {
        amount_Initial_Deposit_min:
          Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min,
        amount_Initial_Deposit_max:
          Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max,
        estimated_total_amount_monthly_deposit_min:
          Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_min,

        estimated_total_amount_monthly_deposit_max:
          Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_max,

        estimated_total_num_monthly_deposit_min:
          Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_min,

        estimated_total_num_monthly_deposit_max:
          Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_max,
        anticipate_cash_deposits:
          Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits,
        amount_cash_deposits_min:
          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min,
        amount_cash_deposits_max:
          Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max,
        frequency_cash_deposits:
          Anticipated_Transaction_Activity_Bank.frequency_cash_deposits,
        estimated_spend_min:
          Anticipated_Transaction_Activity_Bank.estimated_spend_min,
        estimated_spend_max:
          Anticipated_Transaction_Activity_Bank.estimated_spend_max,
        anticipate_cash_withdrawals:
          Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals,
        amount_cash_withdrawals_min:
          Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min,
        amount_cash_withdrawals_max:
          Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max,
        frequency_cash_withdrawals:
          Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals,
        monthly_payroll_min:
          Anticipated_Transaction_Activity_Bank.monthly_payroll_min,
        monthly_payroll_max:
          Anticipated_Transaction_Activity_Bank.monthly_payroll_max,
        cash_pick_ups: Anticipated_Transaction_Activity_Bank.cash_pick_ups,
        frequency_cash_pick_ups:
          Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups,
        estimated_cash_pick_ups_min:
          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min,
        estimated_cash_pick_ups_max:
          Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max,
      },
      Company_Documentation: Company_Documentation_Bank,
      Legacy_Cash: {
        legacy_cash: Legacy_Cash_Bank.legacy_cash,
        documents_available: Legacy_Cash_Bank.documents_available,
      },
      Cash_Management: {
        business_acc: Cash_Management_Bank.business_acc,
        length_time: {
          lengthTimeInput: Cash_Management_Bank.length_time.lengthTimeInput,
          lengthTimeUnit: Cash_Management_Bank.length_time.lengthTimeUnit,
          lengthTimeConvertedToDays: length_timedays,
        },
        penalty_cash: Cash_Management_Bank.penalty_cash,
        tax_payment: Cash_Management_Bank.tax_payment,
        number_vendors_min: Cash_Management_Bank.number_vendors_min,
        number_vendors_max: Cash_Management_Bank.number_vendors_max,
        vendor_payment_methods: Cash_Management_Bank.vendor_payment_methods,
        international_vendor: Cash_Management_Bank.international_vendor,
      },
      Transfer_Existing_Bank: {
        prev_bank_verified: Transfer_Existing_Bank_Bank.prev_bank_verified,
        prev_bank_aware: Transfer_Existing_Bank_Bank.prev_bank_aware,
      },
    };
    console.log(finalServicesOffered, "FinalSettingsData");
    if (Object.keys(errosBank).length === 0) {
      swal({
        title:
          "Bank settings will be updated. Are you sure you want to continue?",
        className: "custom-content",
        buttons: {
          confirm: {
            text: "YES",
            className: "sweet-warning",
          },
          cancel: "NO",
          // confirmButtonColor: '#8CD4F5',
        },
        type: "success",
      }).then((okay) => {
        if (okay) {
          axios
            .put(BankSettings_Update_URL, FinalSettingsData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              swal("Done", `${res.data.message}`, "success");
              setApi(!api);
            })
            .catch((err) => console.log(err, "error"));
        } else {
          // setActivitystatus('No')
        }
      });
    } else {
      for (let error in errosBank) {
        toast.error(
          `${error.toUpperCase().replaceAll("-", "").replaceAll("_", " ")}-${
            errosBank[error]
          }`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
  };
  const data = Legal_Business_Bank?.accepted_states?.map((i) => {
    console.log(i, "iiiiiiii");
    let Obj = {
      value: i,
      label: i,
      color: "#0052CC",
    };
    console.log(Obj, "iiiiiiii");
    return Obj;
  });
  console.log(data, "iop");
  return (
    <div className="mainsection bnk_sctn">
      <section id="section1" className="section1">
        <form>
          <div className="pg1-mn-otr">
            <div className="pg1-mn-inr">
              {servicesOffered && (
                <div className="pg1-prt-1">
                  <div className="pg1-prt-1-txt">
                    <div className="cmn_hdr">
                      <div className="pg3-mn-otrfld">
                        <div className="check__in__bx">
                          <h6>Services Offered:</h6>

                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              value="Business Checking"
                              checked={servicesOffered.includes(
                                "Business Checking"
                              )}
                              onChange={ServicesOfferedHandler}
                            />
                            <label>Business Checking</label>
                            {servicesOffered.includes("Business Checking") && (
                              <div
                                className="price_box"
                                style={{ marginTop: "0rem" }}
                              >
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    value={
                                      servicesPrices &&
                                      servicesPrices["Business Checking"]
                                    }
                                    name="Business Checking"
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>
                                <p style={{ color: "red" }}>
                                  {errosBank["Business Checking"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes(
                                "Interest Bearing Accounts"
                              )}
                              onChange={ServicesOfferedHandler}
                              value="Interest Bearing Accounts"
                            />
                            <label>Interest Bearing Accounts</label>
                            {servicesOffered.includes(
                              "Interest Bearing Accounts"
                            ) && (
                              <div
                                className="price_box"
                                style={{ marginTop: "0rem" }}
                              >
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    value={
                                      servicesPrices &&
                                      servicesPrices[
                                        "Interest Bearing Accounts"
                                      ]
                                    }
                                    name="Interest Bearing Accounts"
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>

                                <p style={{ color: "red" }}>
                                  {errosBank["Interest Bearing Accounts"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes("Debit Cards")}
                              onChange={ServicesOfferedHandler}
                              value="Debit Cards"
                            />
                            <label>Debit Cards</label>
                            {servicesOffered.includes("Debit Cards") && (
                              <div
                                className="price_box"
                                syle={{ marginLeft: "0rem" }}
                              >
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    value={
                                      servicesPrices &&
                                      servicesPrices["Debit Cards"]
                                    }
                                    name="Debit Cards"
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>

                                <p style={{ color: "red" }}>
                                  {errosBank["Debit Cards"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes("ACH")}
                              onChange={ServicesOfferedHandler}
                              value="ACH"
                            />
                            <label>ACH</label>
                            {servicesOffered.includes("ACH") && (
                              <div className="price_box">
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    style={{ marginTop: "0rem" }}
                                    name="ACH"
                                    value={
                                      servicesPrices && servicesPrices["ACH"]
                                    }
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>
                                <p style={{ color: "red" }}>
                                  {errosBank["ACH"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes("Wires")}
                              onChange={ServicesOfferedHandler}
                              value="Wires"
                            />
                            <label>Wires</label>
                            {servicesOffered.includes("Wires") && (
                              <div className="price_box">
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    style={{ marginTop: "0rem" }}
                                    name="Wires"
                                    value={servicesPrices?.Wires}
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>
                                <p style={{ color: "red" }}>
                                  {errosBank["Wires"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes(
                                "Discount for Social Equity"
                              )}
                              onChange={ServicesOfferedHandler}
                              value="Discount for Social Equity"
                            />
                            <label>Discount for Social Equity</label>
                            {servicesOffered.includes(
                              "Discount for Social Equity"
                            ) && (
                              <div className="price_box">
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    style={{ marginTop: "0rem" }}
                                    name="Discount for Social Equity"
                                    value={
                                      servicesPrices &&
                                      servicesPrices[
                                        "Discount for Social Equity"
                                      ]
                                    }
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>
                                <p style={{ color: "red" }}>
                                  {errosBank["WirDiscount for Social Equityes"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="check__in__bx">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              name="pg-bx"
                              checked={servicesOffered.includes(
                                "Accounts for Employees"
                              )}
                              onChange={ServicesOfferedHandler}
                              value="Accounts for Employees"
                            />
                            <label>Accounts for Employees</label>
                            {servicesOffered.includes(
                              "Accounts for Employees"
                            ) && (
                              <div
                                className="price_box"
                                style={{ marginTop: "0rem" }}
                              >
                                <h5>Price</h5>
                                <div className="">
                                  <input
                                    type="number"
                                    name="Accounts for Employees"
                                    value={
                                      servicesPrices &&
                                      servicesPrices["Accounts for Employees"]
                                    }
                                    placeholder="price"
                                    required
                                    onChange={ServicesPriceChangeHandler}
                                  />
                                </div>
                                <p style={{ color: "red" }}>
                                  {errosBank["Accounts for Employees"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {errosBank?.servicesOffered && (
                          <p style={{ color: "red" }}>
                            {errosBank?.servicesOffered}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <div className="pg3-mn-otrfld">
                      <div className="check__in__bx">
                        <h6>Timeframe to open a bank account:</h6>

                        <div style={{ display: "flex", marginRight: "30rem" }}>
                          {" "}
                          <div className="pg1-prt-2-lwr-inrfld">
                            <input
                              onInput={(e) => {
                                if (e.target.value.length > 3) {
                                  e.target.value = e.target.value.slice(0, 3);
                                }
                              }}
                              style={{ height: "4rem" }}
                              type="number"
                              name="input"
                              value={acc_creation_time?.input}
                              onChange={AccountCreationTimehandler}
                              className="form-control"
                              // defaultValue={10}
                            />
                            <a href="javascript:void(0);" className="nd3-a">
                              <i className="fal fa-plus" />
                            </a>
                            {errosBank?.input && (
                              <p style={{ color: "red" }}>{errosBank.input}</p>
                            )}
                          </div>
                          <div>
                            <select
                              name="unit"
                              style={{
                                width: "150px",
                                height: "42px",
                                cursor: "pointer",
                                marginLeft: "1rem",
                              }}
                              onChange={AccountCreationTimehandler}
                            >
                              <option
                                selected={acc_creation_time.unit === "Years"}
                              >
                                Years
                              </option>
                              <option
                                selected={acc_creation_time.unit === "Months"}
                              >
                                Months
                              </option>
                              <option
                                selected={acc_creation_time.unit === "Days"}
                              >
                                Days
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pg1-prt-1">
                <div className="pg1-prt-1-txt">
                  <div className="cmn_hdr">
                    <h6>Select Accepted Risk Classifications:</h6>
                    {errosBank?.Risk_classification_Bank && (
                      <span style={{ color: "red" }}>
                        {errosBank.Risk_classification_Bank}
                      </span>
                    )}
                    <div className="pg1-prt-1-inrfld bnkpge">
                      <input
                        type="checkbox"
                        name="pg-rd"
                        checked={Risk_classification_Bank?.includes("option1")}
                        value="option1"
                        onChange={RiskClassificationBankhandler}
                      />
                      <label>
                        High Risk: Business activity meets regulatory definition
                        of a Marijuana-Related Business such that the business
                        “touches” marijuana at any point from seed to sale. This
                        includes any business or farm that manufactures,
                        processes, or distributes illegal hemp. Income relies
                        strictly on the marijuana industry.
                      </label>
                    </div>
                    <div className="pg1-prt-1-inrfld bnkpge">
                      <input
                        type="checkbox"
                        name="pg-rd"
                        checked={Risk_classification_Bank?.includes("option2")}
                        value="option2"
                        onChange={RiskClassificationBankhandler}
                      />
                      <label>
                        Moderate Risk: Business activity focuses on providing
                        products and services specifically to Marijuana-Related
                        Businesses and the cannabis industry as a whole.
                        Business revenue is expected to come from Tier 1 MRB’s
                        and cannabis-related activities.
                      </label>
                    </div>
                    <div className="pg1-prt-1-inrfld bnkpge">
                      <input
                        type="checkbox"
                        name="pg-rd"
                        checked={Risk_classification_Bank?.includes("option3")}
                        value="option3"
                        onChange={RiskClassificationBankhandler}
                      />
                      <label>
                        Low Risk: Business activity is not specifically focused
                        on providing services to Tier 1 MRB’s or the cannabis
                        industry, but rather such services are incidental to
                        their overall business and revenue.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {Legal_Business_Bank?.accepted_states && (
                <div className="pg1-prt-2">
                  <div className="pg1-prt-2-txt">
                    <div className="cmn_hdr">
                      <h6>Legal Business Information</h6>

                      <div className="pg1-prt-2-otrfld">
                        <div className="pg1-prt-2-inrfld nd2">
                          <label>Accepting License Types:</label>
                          <div className="pg1-prt-2-prt-otrfld">
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Cultivation"
                                )}
                                value="Cultivation"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Cultivation</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Manufacturer"
                                )}
                                value="Manufacturer"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Manufacturer</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Distribution"
                                )}
                                value="Distribution"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Distribution</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Testing Lab"
                                )}
                                value="Testing Lab"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Testing Lab</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Dispensary"
                                )}
                                value="Dispensary"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Dispensary</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Delivery"
                                )}
                                value="Delivery"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Delivery</label>
                            </div>
                            <div className="pg3-mn-inrfld">
                              <input
                                type="checkbox"
                                name="licensing_Type"
                                checked={Legal_Business_Bank?.licensing_Type?.includes(
                                  "Other"
                                )}
                                value="Other"
                                onChange={BankLegalBusinessInfoHandler}
                              />
                              <label>Other</label>
                            </div>
                          </div>
                          {errosBank?.licensing_Type && (
                            <span style={{ color: "red" }}>
                              {errosBank.licensing_Type}
                            </span>
                          )}
                        </div>
                        <div className="pg1-prt-2-inrfld nd3">
                          <div className="pg1-prt-2-lwr-otrfld">
                            {Legal_Business_Bank?.licensing_Type?.includes(
                              "Other"
                            ) && (
                              <div className="pg1-prt-2-lwr-inrfld">
                                <label>Other:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="other"
                                  maxLength={20}
                                  value={Legal_Business_Bank.other}
                                  onChange={BankLegalBusinessInfoHandler}
                                />
                                {errosBank?.other && (
                                  <span style={{ color: "red" }}>
                                    {errosBank.other}
                                  </span>
                                )}
                              </div>
                            )}
                            <div className="pg1-prt-2-lwr-inrfld bnk1a">
                              <label>Min Number of Employees:</label>

                              <input
                                type="number"
                                id="input"
                                name="num_employees_min"
                                value={Legal_Business_Bank.num_employees_min}
                                onInput={(e) => {
                                  if (e.target.value.length > 3) {
                                    e.target.value = e.target.value.slice(0, 3);
                                  }
                                }}
                                onChange={BankLegalBusinessInfoHandler}
                                className="form-control"
                                // onScroll={()=>{console.log('onwheel')}}
                                // defaultValue={10}
                              />
                              <a href="javascript:void(0);" className="nd3-a">
                                {/* <i className="fal fa-plus" /> */}
                                {/* <i onClick={()=>{
                            const data = Number(Legal_Business_Bank.num_employees) + 1
                            setLegal_Business_Bank({
                              ...Legal_Business_Bank, num_employees : data
                            })
                          }}>+</i> */}
                              </a>
                              {errosBank?.num_employees_min && (
                                <p style={{ color: "red" }}>
                                  {errosBank.num_employees_min}
                                </p>
                              )}
                            </div>
                            <div className="pg1-prt-2-lwr-inrfld bnk1a">
                              <label htmlFor="num_employees_max">
                                Max Number of Employees:
                              </label>

                              <NumberFormat
                                style={{ width: "20rem" }}
                                maxLength={19}
                                value={Legal_Business_Bank.num_employees_max}
                                // format={"$##,###,###,###"}
                                // 78,979,797,979,797
                                thousandSeparator={","}
                                // decimalSeparator={"."}
                                // decimalScale={2}
                                className="form-control"
                                onChange={BankLegalBusinessInfoHandler}
                                name="num_employees_max"
                              />
                              <a href="javascript:void(0);" className="nd3-a">
                                {/* <i className="fal fa-plus" /> */}
                                {/* <i onClick={()=>{
                            const data = Number(Legal_Business_Bank.num_employees) + 1
                            setLegal_Business_Bank({
                              ...Legal_Business_Bank, num_employees : data
                            })
                          }}>+</i> */}
                              </a>
                              {errosBank?.num_employees_max && (
                                <p style={{ color: "red" }}>
                                  {errosBank.num_employees_max}
                                </p>
                              )}
                              {errosBank?.num_employeesError && (
                                <p style={{ color: "red" }}>
                                  {errosBank.num_employeesError}
                                </p>
                              )}
                            </div>
                            <div className="pg1-prt-2-lwr-inrfld bnk1b">
                              <label>Accepted States:</label>
                              <CreatableSelect
                                name="accepted_states"
                                defaultValue={Legal_Business_Bank?.accepted_states?.map(
                                  (i) => {
                                    console.log(i, "iiiiiiii");
                                    let Obj = {
                                      value: i,
                                      label: i,
                                      color: "#0052CC",
                                    };
                                    console.log(Obj, "iiiiiiii");
                                    return Obj;
                                  }
                                )}
                                // defaultValue={[
                                //   {
                                //     value: "Connecticut (CT)",
                                //     label: "Connecticut (CT)",
                                //     color: "#0052C",
                                //   },
                                // ]}
                                isMulti
                                options={StateOptions}
                                onChange={(val) => {
                                  console.log(val, "states");
                                  const data = val.map((item) => item.value);
                                  //  setAcceptedStates([...data])

                                  setLegal_Business_Bank({
                                    ...Legal_Business_Bank,
                                    accepted_states: data,
                                  });
                                }}
                              />
                              {errosBank?.accepted_states && (
                                <p style={{ color: "red" }}>
                                  {errosBank.accepted_states}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="pg1-prt-2-inrfld nd4">
                          <div className="pg1-prt-2-end-otrfld">
                            <div className="pg1-prt-2-end-inrfld">
                              <label>Foreign operations acceepted?</label>
                              <div className="pg1-prt-2-end-prtfld">
                                <span>No</span>
                                <span className="toggle-bg">
                                  <label className="switch2">
                                    <input
                                      type="checkbox"
                                      name="foreign_operations"
                                      checked={
                                        Legal_Business_Bank.foreign_operations ===
                                        "yes"
                                      }
                                      onChange={BankLegalBusinessInfoHandler}
                                    />
                                    <span className="slider round" />
                                  </label>
                                </span>
                                <span>Yes</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="pg1-prt-3">
                <div className="pg1-prt-3-txt">
                  <div className="cmn_hdr">
                    <h6>Primary Contact Information:</h6>
                    {/* <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                  <div className="pg1-prt-2-inrfld">
                    <label>Minimum Ownership % of Primary Contact:</label>
                    <input type="text" name="minimum_ownership" className="form-control"
                      value={Primary_Information_Bank.minimum_ownership}
                      onChange={BankPrimaryContactInfoHandler}
                    />
                    {
                      errorState?.minimum_ownership && <p style={{ color: 'red' }}>{errorState.minimum_ownership}</p>

                    }
                  </div>
                </div> */}
                    <div className="bnk_add_grp">
                      <p>Minimum Ownership % of Primary Contact</p>
                      <div className="pg1-prt-2-lwr-inrfld bnk1a">
                        {/* <label>Minimum:</label> */}
                        <input
                          type="number"
                          className="form-control"
                          // defaultValue={2}
                          onInput={(e) => {
                            if (e.target.value.length > 2) {
                              e.target.value = e.target.value.slice(0, 2);
                            }
                          }}
                          name="ownership_Percentage"
                          value={Primary_Information_Bank.ownership_Percentage}
                          onChange={BankPrimaryContactInfoHandler}
                        />
                        {/* <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                <br/> */}
                        {errosBank?.ownership_Percentage && (
                          <p style={{ color: "red" }}>
                            {errosBank.ownership_Percentage}
                          </p>
                        )}
                      </div>
                      {/* <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Maximum:</label>
                <input type="number" className="form-control" defaultValue={10}
                onInput={(e)=>{
               
                  if(e.target.value.length > 3){
                   e.target.value = e.target.value.slice(0,3)
                  }
             }}
                  name='ownership_Percentage_max'
                  value={Primary_Information_Bank.ownership_Percentage_max}
                  onChange={BankPrimaryContactInfoHandler} />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                {
                    errorState?.ownership_Percentage_max && <p style={{ color: 'red'}}>{errorState.ownership_Percentage_max}</p>

                  }
              </div> */}
                    </div>
                    <div className="pg1-prt-2-otrfld pg1-prt-3-otrfld">
                      <div className="pg1-prt-2-inrfld pg1-prt-3-end-inrfld">
                        <label>Accept Non-U.S. Citizen?</label>
                        <div className="pg1-prt-2-end-prtfld">
                          <span>No</span>
                          <span className="toggle-bg">
                            <label className="switch2">
                              <input
                                type="checkbox"
                                checked={
                                  Primary_Information_Bank.us_Citizenship ===
                                  "yes"
                                }
                                name="us_Citizenship"
                                onChange={BankPrimaryContactInfoHandler}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                          <span>Yes</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            {/* <button className='btn btn-prev' onClick={PreviousHandler}>Previous</button> */}
            {/* <button className="btn btn-next" type="submit">
            Next
          </button> */}
          </div>
        </form>
      </section>
      {/* second form */}
      <section id="section2" className="section2">
        <form action="">
          {Operational_details_Bank && (
            <div className="ope_dtls bg_grey">
              <p className="hdng_p">Operational Details</p>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Products purchased from providers?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={
                        Operational_details_Bank.products_purchased_providers ===
                        "yes"
                      }
                      name="products_purchased_providers"
                      onChange={BankOperationalDetailsHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <h5 className="fromgroup_hdng">
                  Licesened Providers Accepted?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={
                        Operational_details_Bank.licensed_provider === "yes"
                      }
                      name="licensed_provider"
                      onChange={BankOperationalDetailsHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <div className="bnk_add_grp">
                  <p>How many accounts per CRB accepted?</p>
                  <div className="pg1-prt-2-lwr-inrfld bnk1a">
                    <label>Minimum:</label>
                    <input
                      type="number"
                      className="form-control"
                      // defaultValue={2}
                      name="acc_need_min"
                      onInput={(e) => {
                        if (e.target.value.length > 2) {
                          e.target.value = e.target.value.slice(0, 2);
                        }
                      }}
                      value={Operational_details_Bank.acc_need_min}
                      onChange={BankOperationalDetailsHandler}
                    />
                    {/* <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                <br/> */}
                    {errosBank?.acc_need_min && (
                      <p style={{ color: "red" }}>{errosBank.acc_need_min}</p>
                    )}
                  </div>
                  <div className="pg1-prt-2-lwr-inrfld bnk1a">
                    <label>Maximum:</label>
                    <NumberFormat
                      style={{ width: "20rem" }}
                      maxLength={19}
                      value={Operational_details_Bank.acc_need_max}
                      // format={"$##,###,###,###"}

                      // 78,979,797,979,797
                      thousandSeparator={","}
                      // decimalSeparator={"."}
                      // decimalScale={2}
                      className="form-control"
                      onChange={BankOperationalDetailsHandler}
                      name="acc_need_max"
                    />
                    <a href="javascript:void(0);" className="nd3-a">
                      <i className="fal fa-plus" />
                    </a>
                    {errosBank?.acc_need_max && (
                      <p style={{ color: "red" }}>{errosBank.acc_need_max}</p>
                    )}
                  </div>
                  {errosBank.acc_needErr && (
                    <p style={{ color: "red" }}>{errosBank.acc_needErr}</p>
                  )}
                </div>
              </div>
              <div className="parent_forminput_group">
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Number of Locations Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={2}
                        name="num_locs_min"
                        onInput={(e) => {
                          if (e.target.value.length > 2) {
                            e.target.value = e.target.value.slice(0, 2);
                          }
                        }}
                        value={Operational_details_Bank.num_locs_min}
                        onChange={BankOperationalDetailsHandler}
                      />
                      {/* <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                <br/> */}
                      {errosBank?.num_locs_min && (
                        <p style={{ color: "red" }}>{errosBank.num_locs_min}</p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <NumberFormat
                        style={{ width: "20rem" }}
                        maxLength={19}
                        value={Operational_details_Bank.num_locs_max}
                        // format={"$##,###,###,###"}
                        // 78,979,797,979,797
                        thousandSeparator={","}
                        // decimalSeparator={"."}
                        // decimalScale={2}
                        className="form-control"
                        onChange={BankOperationalDetailsHandler}
                        name="num_locs_max"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errosBank?.num_locs_max && (
                        <p style={{ color: "red" }}>{errosBank.num_locs_max}</p>
                      )}
                    </div>
                  </div>
                  {errosBank.num_locsErr && (
                    <p style={{ color: "red" }}>{errosBank.num_locsErr}</p>
                  )}
                </div>

                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Number of Transactions Per Month Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <input
                        type="number"
                        onInput={(e) => {
                          if (e.target.value.length > 2) {
                            e.target.value = e.target.value.slice(0, 2);
                          }
                        }}
                        name="transactions_Per_month_min"
                        value={
                          Operational_details_Bank.transactions_Per_month_min
                        }
                        onChange={BankOperationalDetailsHandler}
                        className="form-control"
                        defaultValue={2}
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errosBank?.transactions_Per_month_min && (
                        <p style={{ color: "red" }}>
                          {errosBank.transactions_Per_month_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <NumberFormat
                        style={{ width: "20rem" }}
                        maxLength={19}
                        value={
                          Operational_details_Bank.transactions_Per_month_max
                        }
                        // format={"$##,###,###,###"}
                        // 78,979,797,979,797
                        thousandSeparator={","}
                        // decimalSeparator={"."}
                        // decimalScale={2}
                        className="form-control"
                        onChange={BankOperationalDetailsHandler}
                        name="transactions_Per_month_max"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errosBank?.transactions_Per_month_max && (
                        <p style={{ color: "red" }}>
                          {errosBank.transactions_Per_month_max}
                        </p>
                      )}
                    </div>
                  </div>
                  {errosBank.transactions_Per_monthErr && (
                    <p style={{ color: "red" }}>
                      {errosBank.transactions_Per_monthErr}
                    </p>
                  )}
                </div>

                <div className="form-input-group">
                  <h5 className="fromgroup_hdng">Facility Owned or Leased?</h5>
                  <div className="frm_radio_prnt">
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Owned"
                        checked={
                          Operational_details_Bank.facility === "Owned"
                            ? true
                            : false
                        }
                        onChange={BankOperationalDetailsHandler}
                        id="Owned"
                        name="facility"
                        // checked={Operational_Details.types_customers === "Medical"}
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="Owned" className="radio-button">
                        Owned
                      </label>
                    </div>
                    <div className="form_radio_group">
                      <input
                        type="radio"
                        value="Leased"
                        checked={
                          Operational_details_Bank.facility === "Leased"
                            ? true
                            : false
                        }
                        onChange={BankOperationalDetailsHandler}
                        id="Leased"
                        name="facility"
                        // checked={Operational_Details.types_customers === "Recreational"}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="Leased" className="radio-button">
                        Leased
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-input-group">
                  <label>Types of Customers Accepted:</label>
                  <br />
                  <div className="pg1-prt-2-prt-otrfld">
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        name="types_customers"
                        checked={Operational_details_Bank.types_customers?.includes(
                          "Medical"
                        )}
                        value="Medical"
                        onChange={BankOperationalDetailsHandler}
                      />
                      <label>Medical</label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        name="types_customers"
                        checked={Operational_details_Bank.types_customers?.includes(
                          "Recreational"
                        )}
                        value="Recreational"
                        onChange={BankOperationalDetailsHandler}
                      />
                      <label>Recreational</label>
                    </div>
                  </div>
                  {errosBank?.types_customers && (
                    <p style={{ color: "red" }}>{errosBank.types_customers}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          {Other_Operational_Details_Bank && (
            <div className="bg_grey ooop_dtls">
              <p className="hdng_p">Other Operational Details</p>
              <div className="form-group wmcontent">
                <h5 className="fromgroup_hdng wmcontent">
                  Principals of other entities that conducts marijuana-related
                  business in other states accepted?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={
                        Other_Operational_Details_Bank.principal_business ===
                        "yes"
                      }
                      name="principal_business"
                      onChange={BankOtherOperationaldetailsHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group wmcontent">
                <h5 className="fromgroup_hdng wmcontent">
                  Beneficial Owners (anyone with stake of 25% or more in the
                  business) Accepted?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={
                        Other_Operational_Details_Bank.beneficial_owners ===
                        "yes"
                      }
                      name="beneficial_owners"
                      onChange={BankOtherOperationaldetailsHandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              <div className="form-group">
                <div className="bnk_add_grp">
                  <p>Number of Beneficial Owners Accepted:</p>
                  <div className="pg1-prt-2-lwr-inrfld bnk1a">
                    <label>Minimum:</label>
                    <input
                      type="number"
                      className="form-control"
                      // defaultValue={2}
                      name="number_beneficial_owner_min"
                      onInput={(e) => {
                        if (e.target.value.length > 2) {
                          e.target.value = e.target.value.slice(0, 2);
                        }
                      }}
                      value={
                        Other_Operational_Details_Bank.number_beneficial_owner_min
                      }
                      onChange={BankOtherOperationaldetailsHandler}
                    />
                    <a href="javascript:void(0);" className="nd3-a">
                      <i className="fal fa-plus" />
                    </a>
                    {errosBank?.number_beneficial_owner_min && (
                      <p style={{ color: "red" }}>
                        {errosBank.number_beneficial_owner_min}
                      </p>
                    )}
                  </div>
                  <div className="pg1-prt-2-lwr-inrfld bnk1a">
                    <label>Maximum:</label>
                    <NumberFormat
                      style={{ width: "20rem" }}
                      maxLength={19}
                      value={
                        Other_Operational_Details_Bank.number_beneficial_owner_max
                      }
                      // format={"$##,###,###,###"}
                      // 78,979,797,979,797
                      thousandSeparator={","}
                      // decimalSeparator={"."}
                      // decimalScale={2}
                      className="form-control"
                      onChange={BankOtherOperationaldetailsHandler}
                      name="number_beneficial_owner_max"
                    />
                    <a href="javascript:void(0);" className="nd3-a">
                      <i className="fal fa-plus" />
                    </a>
                    {errosBank?.number_beneficial_owner_max && (
                      <p style={{ color: "red" }}>
                        {errosBank.number_beneficial_owner_max}
                      </p>
                    )}
                  </div>
                  {errosBank?.number_beneficial_ownerErr && (
                    <p style={{ color: "red" }}>
                      {errosBank.number_beneficial_ownerErr}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {Anticipated_Transaction_Activity_Bank && (
            <div className="bg_grey bnk_atactivity">
              <p className="hdng_p">Anticipated Transaction Activity</p>
              <div className="parent_forminput_group">
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Amount of Initial Deposit Accepted (USD):</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={9}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_min
                        }
                        type="text"
                        name="amount_Initial_Deposit_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_Initial_Deposit_min && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_Initial_Deposit_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.amount_Initial_Deposit_max
                        }
                        type="text"
                        name="amount_Initial_Deposit_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.amount_Initial_Deposit_max && (
                        <p style={{ color: "red" }}>
                          {errorState.amount_Initial_Deposit_max}
                        </p>
                      )}
                    </div>
                    {errorState?.amount_Initial_DepositErr && (
                      <p style={{ color: "red" }}>
                        {errorState.amount_Initial_DepositErr}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>
                      Estimated Total Amount of Monthly Deposits (USD) Accepted:
                    </p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={9}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_min
                        }
                        type="text"
                        name="estimated_total_amount_monthly_deposit_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_total_amount_monthly_deposit_min && (
                        <p style={{ color: "red" }}>
                          {
                            errorState.estimated_total_amount_monthly_deposit_min
                          }
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_total_amount_monthly_deposit_max
                        }
                        type="text"
                        name="estimated_total_amount_monthly_deposit_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_total_amount_monthly_deposit_max && (
                        <p style={{ color: "red" }}>
                          {
                            errorState.estimated_total_amount_monthly_deposit_max
                          }
                        </p>
                      )}
                    </div>
                    {errorState?.estimated_total_amount_monthly_depositErr && (
                      <p style={{ color: "red" }}>
                        {errorState.estimated_total_amount_monthly_depositErr}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Estimated Total Number of Monthly Deposits Accepted:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={2}
                        onInput={(e) => {
                          if (e.target.value.length > 3) {
                            e.target.value = e.target.value.slice(0, 3);
                          }
                        }}
                        name="estimated_total_num_monthly_deposit_min"
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_min
                        }
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_total_num_monthly_deposit_min && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_total_num_monthly_deposit_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <NumberFormat
                        style={{ width: "20rem" }}
                        maxLength={19}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_total_num_monthly_deposit_max
                        }
                        // format={"$##,###,###,###"}
                        // 78,979,797,979,797
                        thousandSeparator={","}
                        // decimalSeparator={"."}
                        // decimalScale={2}
                        className="form-control"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        name="estimated_total_num_monthly_deposit_max"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_total_num_monthly_deposit_max && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_total_num_monthly_deposit_max}
                        </p>
                      )}
                    </div>
                    {errorState?.estimated_total_num_monthly_depositErr && (
                      <p style={{ color: "red" }}>
                        {errorState.estimated_total_num_monthly_depositErr}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group wmcontent w100">
                  <h5 className="fromgroup_hdng wmcontent">
                    Cash Deposits Accepted:
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        checked={
                          Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits ===
                          "yes"
                        }
                        name="anticipate_cash_deposits"
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                {Anticipated_Transaction_Activity_Bank.anticipate_cash_deposits ===
                  "yes" && (
                  <>
                    <div className="form-group">
                      <div className="bnk_add_grp">
                        <p>Amount of Monthly Cash Deposits (USD) Accepted:</p>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Minimum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={9}
                            value={
                              Anticipated_Transaction_Activity_Bank.amount_cash_deposits_min
                            }
                            type="text"
                            name="amount_cash_deposits_min"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.amount_cash_deposits_min && (
                            <p style={{ color: "red" }}>
                              {errorState.amount_cash_deposits_min}
                            </p>
                          )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Maximum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={20}
                            value={
                              Anticipated_Transaction_Activity_Bank.amount_cash_deposits_max
                            }
                            type="text"
                            name="amount_cash_deposits_max"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.amount_cash_deposits_max && (
                            <p style={{ color: "red" }}>
                              {errorState.amount_cash_deposits_max}
                            </p>
                          )}
                        </div>
                        {errorState?.amount_cash_depositsErr && (
                          <p style={{ color: "red" }}>
                            {errorState.amount_cash_depositsErr}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="form-group">
            <div className="bnk_add_grp">
              <p>Frequency of Cash Deposits Accepted:</p>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Minimum:</label>
                <input type="number" className="form-control" defaultValue={2}
                  onInput={(e) => {

                    if (e.target.value.length > 3) {
                      e.target.value = e.target.value.slice(0, 3)
                    }
                  }}
                  name='frequency_cash_deposits_min' value={Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_min} onChange={BankAnticipatedTransactionActivityHandler}
                />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>

                {
                  errorState?.frequency_cash_deposits_min && <p style={{ color: 'red' }}>{errorState.frequency_cash_deposits_min}</p>

                }
              </div>
              <div className="pg1-prt-2-lwr-inrfld bnk1a">
                <label>Maximum:</label>
                < NumberFormat
                style={{ width: '20rem' }}
                maxLength={19}
                value={Anticipated_Transaction_Activity_Bank.frequency_cash_deposits_max}
                // format={"$##,###,###,###"}
                // 78,979,797,979,797
                thousandSeparator={","}
                // decimalSeparator={"."}
                // decimalScale={2}
                className='form-control'
                onChange={BankAnticipatedTransactionActivityHandler} name='frequency_cash_deposits_max'
              />
                <a href="javascript:void(0);" className="nd3-a">
                  <i className="fal fa-plus" />
                </a>
                {
                  errorState?.frequency_cash_deposits_max && <p style={{ color: 'red' }}>{errorState.frequency_cash_deposits_max}</p>

                }
              </div>
              {
                  errorState?.frequency_cash_depositsErr && <p style={{ color: 'red' }}>{errorState.frequency_cash_depositsErr}</p>

                }
            </div>
          </div> */}
                    <div className="form-input-group">
                      <label>Frequency of Cash Deposits Accepted:</label>
                      <div className="frm_radio_prnt">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Daily"
                            checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                              "Daily"
                            )}
                            name="frequency_cash_deposits"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="ACH1"
                          />
                          {/* <span class="radio"></span>  */}
                          <label htmlFor="ACH1" className="radio-button">
                            {" "}
                            Daily
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Weekly"
                            checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                              "Weekly"
                            )}
                            name="frequency_cash_deposits"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="check1"
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="check1" className="radio-button">
                            {" "}
                            Weekly
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Bi-Weekly"
                            checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                              "Bi-Weekly"
                            )}
                            name="frequency_cash_deposits"
                            onChange={BankAnticipatedTransactionActivityHandler}
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="wire1" className="radio-button">
                            {" "}
                            Bi-Weekly
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Monthly"
                            checked={Anticipated_Transaction_Activity_Bank?.frequency_cash_deposits?.includes(
                              "Monthly"
                            )}
                            name="frequency_cash_deposits"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="cash1"
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="cash1" className="radio-button">
                            {" "}
                            Monthly
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group w100">
                  <div className="bnk_add_grp">
                    <p>
                      Estimated Spend or Withdrawals Monthly (USD) Accepted:
                    </p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={9}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_spend_min
                        }
                        type="text"
                        name="estimated_spend_min"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_spend_min && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_spend_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <CurrencyInput
                        placeholder="$0.00"
                        maxLength={20}
                        value={
                          Anticipated_Transaction_Activity_Bank.estimated_spend_max
                        }
                        type="text"
                        name="estimated_spend_max"
                        onChange={BankAnticipatedTransactionActivityHandler}
                        className="form-control"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errorState?.estimated_spend_max && (
                        <p style={{ color: "red" }}>
                          {errorState.estimated_spend_max}
                        </p>
                      )}
                    </div>
                    {errorState?.estimated_spendErr && (
                      <p style={{ color: "red" }}>
                        {errorState.estimated_spendErr}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group wmcontent">
                  <h5 className="fromgroup_hdng wmcontent">
                    Cash Withdrawals Accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        checked={
                          Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
                          "yes"
                        }
                        name="anticipate_cash_withdrawals"
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                {Anticipated_Transaction_Activity_Bank.anticipate_cash_withdrawals ===
                  "yes" && (
                  <>
                    <div className="form-group w100">
                      <div className="bnk_add_grp">
                        <p>
                          Amount of Monthly Cash Withdrawals (USD) Accepted:
                        </p>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Minimum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={20}
                            value={
                              Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_min
                            }
                            type="text"
                            name="amount_cash_withdrawals_min"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.amount_cash_withdrawals_min && (
                            <p style={{ color: "red" }}>
                              {errorState.amount_cash_withdrawals_min}
                            </p>
                          )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Maximum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={20}
                            value={
                              Anticipated_Transaction_Activity_Bank.amount_cash_withdrawals_max
                            }
                            type="text"
                            name="amount_cash_withdrawals_max"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.amount_cash_withdrawals_max && (
                            <p style={{ color: "red" }}>
                              {errorState.amount_cash_withdrawals_max}
                            </p>
                          )}
                        </div>
                        {errorState?.amount_cash_withdrawalsErr && (
                          <p style={{ color: "red" }}>
                            {errorState.amount_cash_withdrawalsErr}
                          </p>
                        )}
                      </div>
                      {/* 
              <div className="form-input-group">
                <label>Frequency of Cash Withdrawals Accepted: </label>
                <div className="frm_radio_prnt">
                  <div className="form_radio_group">
                    <input type="radio"
                      checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Daily'}
                      name='frequency_cash_withdrawals' value='Daily' onChange={BankAnticipatedTransactionActivityHandler}
                      id="daily3" />
                    
                    <label htmlFor="ACH1" className="radio-button">
                      {" "}
                      Daily
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input type="radio"
                      checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Weekly'}
                      name='frequency_cash_withdrawals' value='Weekly' onChange={BankAnticipatedTransactionActivityHandler} />
                
                    <label htmlFor="check1" className="radio-button">
                      {" "}
                      Weekly
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input
                      type="radio"
                      checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Bi-Weekly'}
                      name='frequency_cash_withdrawals' value='Bi-Weekly' onChange={BankAnticipatedTransactionActivityHandler}
                    />

                    <label htmlFor="check1" className="radio-button">
                      Bi-Weekly
                    </label>
                  </div>
                  <div className="form_radio_group">
                    <input type="radio"
                      checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals === 'Monthly'}
                      name='frequency_cash_withdrawals' value='Monthly' onChange={BankAnticipatedTransactionActivityHandler} />
                   
                    <label htmlFor="check1" className="radio-button">
                      Monthly
                    </label>
                  </div>
                </div>
              </div> */}
                      <div className="form-input-group">
                        <label>Frequency of Cash Withdrawals Accepted:</label>
                        <div className="frm_radio_prnt">
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              value="Daily"
                              checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                                "Daily"
                              )}
                              name="frequency_cash_withdrawals"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                              id="ACH1"
                            />
                            {/* <span class="radio"></span>  */}
                            <label htmlFor="ACH1" className="radio-button">
                              {" "}
                              Daily
                            </label>
                          </div>
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              value="Weekly"
                              checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                                "Weekly"
                              )}
                              name="frequency_cash_withdrawals"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                              id="check1"
                            />
                            {/* <span class="radio"></span> */}
                            <label htmlFor="check1" className="radio-button">
                              {" "}
                              Weekly
                            </label>
                          </div>
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              value="Bi-Weekly"
                              id="wire1"
                              checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                                "Bi-Weekly"
                              )}
                              name="frequency_cash_withdrawals"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                            />
                            {/* <span class="radio"></span> */}
                            <label htmlFor="wire1" className="radio-button">
                              {" "}
                              Bi-Weekly
                            </label>
                          </div>
                          <div className="pg3-mn-inrfld">
                            <input
                              type="checkbox"
                              value="Monthly"
                              checked={Anticipated_Transaction_Activity_Bank.frequency_cash_withdrawals.includes(
                                "Monthly"
                              )}
                              name="frequency_cash_withdrawals"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                              id="cash1"
                            />
                            {/* <span class="radio"></span> */}
                            <label htmlFor="cash1" className="radio-button">
                              {" "}
                              Monthly
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group w100">
                      <div className="form-group">
                        <div className="bnk_add_grp">
                          <p>
                            Average Expected Monthly Payroll (USD) Accepted:
                          </p>
                          <div className="pg1-prt-2-lwr-inrfld bnk1a">
                            <label>Minimum:</label>
                            <CurrencyInput
                              style={{ width: "12rem" }}
                              placeholder="$0.00"
                              maxLength={9}
                              value={
                                Anticipated_Transaction_Activity_Bank.monthly_payroll_min
                              }
                              type="text"
                              name="monthly_payroll_min"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                              className="form-control"
                            />
                            <a href="javascript:void(0);" className="nd3-a">
                              <i className="fal fa-plus" />
                            </a>
                            {errorState?.monthly_payroll_min && (
                              <p style={{ color: "red" }}>
                                {errorState.monthly_payroll_min}
                              </p>
                            )}
                          </div>
                          <div
                            className="pg1-prt-2-lwr-inrfld bnk1a"
                            style={{ marginLeft: "0rem", width: "10rem" }}
                          >
                            <label>Maximum:</label>
                            <CurrencyInput
                              style={{ width: "20rem" }}
                              placeholder="$0.00"
                              maxLength={20}
                              value={
                                Anticipated_Transaction_Activity_Bank.monthly_payroll_max
                              }
                              type="text"
                              name="monthly_payroll_max"
                              onChange={
                                BankAnticipatedTransactionActivityHandler
                              }
                              className="form-control"
                            />
                            <a href="javascript:void(0);" className="nd3-a">
                              <i className="fal fa-plus" />
                            </a>
                            {errorState?.monthly_payroll_max && (
                              <p style={{ color: "red" }}>
                                {errorState.monthly_payroll_max}
                              </p>
                            )}
                          </div>
                          {errorState?.monthly_payrollErr && (
                            <p style={{ color: "red" }}>
                              {errorState.monthly_payrollErr}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="form-group w100">
                  <h5 className="fromgroup_hdng">Cash Pick-Ups Available?</h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="cash_pick_ups"
                        checked={
                          Anticipated_Transaction_Activity_Bank.cash_pick_ups ===
                          "yes"
                        }
                        onChange={BankAnticipatedTransactionActivityHandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                {Anticipated_Transaction_Activity_Bank.cash_pick_ups ===
                  "yes" && (
                  <>
                    <div className="form-input-group">
                      <label>Frequency of Cash Pick-Ups Accepted:</label>
                      <div className="frm_radio_prnt">
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Daily"
                            checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                              "Daily"
                            )}
                            name="frequency_cash_pick_ups"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="ACH1"
                          />
                          {/* <span class="radio"></span>  */}
                          <label htmlFor="ACH1" className="radio-button">
                            {" "}
                            Daily
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Weekly"
                            checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                              "Weekly"
                            )}
                            name="frequency_cash_pick_ups"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="check1"
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="check1" className="radio-button">
                            {" "}
                            Weekly
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Bi-Weekly"
                            checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                              "Bi-Weekly"
                            )}
                            name="frequency_cash_pick_ups"
                            onChange={BankAnticipatedTransactionActivityHandler}
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="wire1" className="radio-button">
                            {" "}
                            Bi-Weekly
                          </label>
                        </div>
                        <div className="pg3-mn-inrfld">
                          <input
                            type="checkbox"
                            value="Monthly"
                            checked={Anticipated_Transaction_Activity_Bank.frequency_cash_pick_ups.includes(
                              "Monthly"
                            )}
                            name="frequency_cash_pick_ups"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            id="cash1"
                          />
                          {/* <span class="radio"></span> */}
                          <label htmlFor="cash1" className="radio-button">
                            {" "}
                            Monthly
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="bnk_add_grp">
                        <p>Estimated Pick-Up Amount (USD) Accepted:</p>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Minimum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={9}
                            value={
                              Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_min
                            }
                            type="text"
                            name="estimated_cash_pick_ups_min"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.estimated_cash_pick_ups_min && (
                            <p style={{ color: "red" }}>
                              {errorState.estimated_cash_pick_ups_min}
                            </p>
                          )}
                        </div>
                        <div className="pg1-prt-2-lwr-inrfld bnk1a">
                          <label>Maximum:</label>
                          <CurrencyInput
                            placeholder="$0.00"
                            maxLength={20}
                            value={
                              Anticipated_Transaction_Activity_Bank.estimated_cash_pick_ups_max
                            }
                            type="text"
                            name="estimated_cash_pick_ups_max"
                            onChange={BankAnticipatedTransactionActivityHandler}
                            className="form-control"
                          />
                          <a href="javascript:void(0);" className="nd3-a">
                            <i className="fal fa-plus" />
                          </a>
                          {errorState?.estimated_cash_pick_ups_max && (
                            <p style={{ color: "red" }}>
                              {errorState.estimated_cash_pick_ups_max}
                            </p>
                          )}
                        </div>
                        {errorState?.estimated_cash_pick_upsErr && (
                          <p style={{ color: "red" }}>
                            {errorState.estimated_cash_pick_upsErr}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {/* <div className="button-container">
          <button className="btn btn-prev" onClick={PreviousHandler}>
            Previous
          </button>
          <button className="btn btn-next" type="submit">
            Next
          </button>
        </div> */}
        </form>
      </section>
      {/* Third form */}
      <section id="section3" className="section3">
        <form>
          <div className="pg3-mn-otr">
            <div className="pg3-mn-inr">
              <div className="cmn_hdr">
                <h6>Company Documentation</h6>
                {errosBank.Company_Documentation_Bank && (
                  <span style={{ color: "red", marginTop: "10rem" }}>
                    {errosBank.Company_Documentation_Bank}
                  </span>
                )}
                <p>Check Documents Available:</p>
                <div className="pg3-mn-otrfld">
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Audited Financial Statements"
                      )}
                      value="Audited Financial Statements"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Audited Financial Statements</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes("Policies")}
                      value="Policies"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Policies</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Articles of Formation (Organization or Incorporation)"
                      )}
                      value="Articles of Formation (Organization or Incorporation)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Articles of Formation (Organization or Incorporation)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Operating Agreement"
                      )}
                      value="Operating Agreement"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Operating Agreement</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Contracts with Armored Car Services to Transport Cash"
                      )}
                      value="Contracts with Armored Car Services to Transport Cash"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Contracts with Armored Car Services to Transport Cash
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Results of any inspections performed by the State"
                      )}
                      value="Results of any inspections performed by the State"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Results of any inspections performed by the State
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Federal and State Tax Returns (Last 3 Years Available)"
                      )}
                      value="Federal and State Tax Returns (Last 3 Years Available)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Federal and State Tax Returns (Last 3 Years Available)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                      )}
                      value="Compliance Attestation (Agreement with terms and conditions of account and fee schedule, statement that MRB is not in violation of Cole Memo Priorities or FinCEN guidelines)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Compliance Attestation (Agreement with terms and
                      conditions of account and fee schedule, statement that MRB
                      is not in violation of Cole Memo Priorities or FinCEN
                      guidelines)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Proof of vendor due diligence completed for all vendors used"
                      )}
                      value="Proof of vendor due diligence completed for all vendors used"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Proof of vendor due diligence completed for all vendors
                      used
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Most Recent Compliance Audit and Reports"
                      )}
                      value="Most Recent Compliance Audit and Reports"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Most Recent Compliance Audit and Reports</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Sales History from POS Records (90 Days Fully Operational)"
                      )}
                      value="Sales History from POS Records (90 Days Fully Operational)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Sales History from POS Records (90 Days Fully Operational)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Employee Training Manual"
                      )}
                      value="Employee Training Manual"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Employee Training Manual</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Signed Lease Agreement for each Location"
                      )}
                      value="Signed Lease Agreement for each Location"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Signed Lease Agreement for each Location</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Business Recovery Plan"
                      )}
                      value="Business Recovery Plan"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Business Recovery Plan</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Insurance Certificate (summary of coverage)"
                      )}
                      value="Insurance Certificate (summary of coverage)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Insurance Certificate (summary of coverage)</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "State Licensing"
                      )}
                      value="State Licensing"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>State Licensing</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Secretary of State Registration"
                      )}
                      value="Secretary of State Registration"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Secretary of State Registration</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Results of any on-site inspections"
                      )}
                      value="Results of any on-site inspections"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Results of any on-site inspections</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Financial Projections for Next 1-3 Years"
                      )}
                      value="Financial Projections for Next 1-3 Years"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Financial Projections for Next 1-3 Years</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                      )}
                      value="List of all vendors utilized including name, DBA, address, contact person, and services/products used"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      List of all vendors utilized including name, DBA, address,
                      contact person, and services/products used
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "List of all expected customers"
                      )}
                      value="List of all expected customers"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>List of all expected customers</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Attestation of Beneficial Ownership"
                      )}
                      value="Attestation of Beneficial Ownership"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Attestation of Beneficial Ownership</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Processing Statements (Last 3 Months)"
                      )}
                      value="Processing Statements (Last 3 Months)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>Processing Statements (Last 3 Months)</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                      )}
                      value="Balance Sheets, Cash Flow Statements, and P/L Statements (Last 3 Years)"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      Balance Sheets, Cash Flow Statements, and P/L Statements
                      (Last 3 Years)
                    </label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "California Verification Status of Entity"
                      )}
                      value="California Verification Status of Entity"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>California Verification Status of Entity</label>
                  </div>
                  <div className="pg3-mn-inrfld">
                    <input
                      type="checkbox"
                      name="pg-bx"
                      checked={Company_Documentation_Bank.includes(
                        "U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                      )}
                      value="U.S. Government issue ID/Driver’s License/Military ID/State ID or U.S. Passport for Administrators, Beneficial Owners, Officers, Directors, and Control Persons"
                      onChange={BankCompanyDocumentationHandler}
                    />
                    <label>
                      U.S. Government issue ID/Driver’s License/Military
                      ID/State ID or U.S. Passport for Administrators,
                      Beneficial Owners, Officers, Directors, and Control
                      Persons
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* Fourth form */}
      <section id="section4" className="section4">
        <form action="">
          {Legacy_Cash_Bank && (
            <div className="lgcy_cash bg_grey">
              <p className="hdng_p">Legacy Cash</p>
              {errosBank.documents_available && (
                <span style={{ color: "red", marginTop: "10rem" }}>
                  {errosBank.documents_available}
                </span>
              )}
              <div className="form-group bnk4_frm_grp">
                <h5 className="fromgroup_hdng">
                  Does your business have legacy cash?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      name="legacy_cash"
                      checked={Legacy_Cash_Bank.legacy_cash === "yes"}
                      onChange={BankLegacyCashhandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Legacy_Cash_Bank.legacy_cash === "yes" && (
                <div className="check_form-group">
                  <h5 className="fromgroup_hdng">Check Documents Available:</h5>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      id="icheck1"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                      )}
                      value="Quarterly federal, state, and municipal tax returns for each of the last five years, if applicable"
                      onChange={BankLegacyCashhandler}
                    />
                    <label htmlFor="icheck1">
                      Quarterly federal, state, and municipal tax returns for
                      each of the last five years, if applicable
                    </label>
                  </div>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "Financial statements, preferably audited that have been prepared for the business"
                      )}
                      value="Financial statements, preferably audited that have been prepared for the business"
                      onChange={BankLegacyCashhandler}
                    />
                    <label>
                      Financial statements, preferably audited that have been
                      prepared for the business
                    </label>
                  </div>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                      )}
                      value="Point-of-Sale (seed-to-sale) system reports: monthly and annual POS reports showing all wholesale inventory purchases and retail sales transactions"
                      onChange={BankLegacyCashhandler}
                    />
                    <label>
                      Point-of-Sale (seed-to-sale) system reports: monthly and
                      annual POS reports showing all wholesale inventory
                      purchases and retail sales transactions
                    </label>
                  </div>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                      )}
                      value="State database reporting: reports of amounts reported to the state tracking database for as long as the state database has been active, if applicable"
                      onChange={BankLegacyCashhandler}
                    />
                    <label>
                      State database reporting: reports of amounts reported to
                      the state tracking database for as long as the state
                      database has been active, if applicable
                    </label>
                  </div>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                      )}
                      value="Armored courier confirmed cash count: To the extent cash was couriered to a vault or other safekeeping location, obtain confirmed cash count slips from armored courier evidencing amount of cash counted and date of cash pick-up/count"
                      onChange={BankLegacyCashhandler}
                    />
                    <label>
                      Armored courier confirmed cash count: To the extent cash
                      was couriered to a vault or other safekeeping location,
                      obtain confirmed cash count slips from armored courier
                      evidencing amount of cash counted and date of cash
                      pick-up/count
                    </label>
                  </div>
                  <div className="check_checking">
                    <input
                      type="checkbox"
                      name="documents_available"
                      checked={Legacy_Cash_Bank.documents_available.includes(
                        "Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                      )}
                      value="Safekeeping receipt (vaulted cash): Obtain Safekeeping Receipt showing total cash vaulted and custodianship of cash"
                      onChange={BankLegacyCashhandler}
                    />
                    <label>
                      Safekeeping receipt (vaulted cash): Obtain Safekeeping
                      Receipt showing total cash vaulted and custodianship of
                      cash
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
          {Cash_Management_Bank && (
            <div className="csh_mngmnt bg_grey">
              <p className="hdng_p">Cash Management</p>
              <div className="parent_forminput_group form-group bnk4">
                <h5 className="fromgroup_hdng">
                  Business with no current bank account accepted?
                </h5>
                <div className="switch_box">
                  <h4>no</h4>
                  <label className="switch2">
                    <input
                      type="checkbox"
                      checked={Cash_Management_Bank.business_acc === "yes"}
                      name="business_acc"
                      onChange={BankCashManagementhandler}
                    />
                    <span className="slider round" />
                  </label>
                  <h4>yes</h4>
                </div>
              </div>
              {Cash_Management_Bank.business_acc === "yes" && (
                <div className="form-input-group">
                  <label htmlFor="">
                    Minimum Length of time for current bank account to be open:
                  </label>
                  <div
                    className="bnk_add_grp"
                    style={{ justifyContent: "start" }}
                  >
                    <input
                      type="number"
                      className="form-control"
                      onWheel={(e) => e.target.blur()}
                      defaultValue=""
                      name="lengthTimeInput"
                      onInput={(e) => {
                        if (e.target.value.length > 3) {
                          e.target.value = e.target.value.slice(0, 3);
                        }
                      }}
                      value={Cash_Management_Bank.length_time.lengthTimeInput}
                      onChange={BankCashManagementhandler}
                      style={{ width: "200px" }}
                    />
                    {errosBank?.length_time && (
                      <p style={{ color: "red" }}>{errosBank.length_time}</p>
                    )}
                    <select
                      name="lengthTimeUnit"
                      className="form-control"
                      style={{
                        width: "150px",
                        height: "50px",
                        cursor: "pointer",
                        marginLeft: "1rem",
                      }}
                      onChange={BankCashManagementhandler}
                    >
                      <option
                        selected={
                          Cash_Management_Bank.length_time.lengthTimeUnit ===
                          "Years"
                            ? true
                            : false
                        }
                      >
                        Years
                      </option>
                      <option
                        selected={
                          Cash_Management_Bank.length_time.lengthTimeUnit ===
                          "Months"
                            ? true
                            : false
                        }
                      >
                        Months
                      </option>
                      <option
                        selected={
                          Cash_Management_Bank.length_time.lengthTimeUnit ===
                          "Days"
                            ? true
                            : false
                        }
                      >
                        Days
                      </option>
                    </select>
                  </div>
                </div>
              )}
              <div className="parent_forminput_group">
                {/* <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Use of payroll service accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="payroll_service"
                        checked={Cash_Management_Bank.payroll_service === "yes"}
                        value={Cash_Management_Bank.payroll_service}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Employees paid in cash accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="paid_cash"
                        checked={Cash_Management_Bank.paid_cash === "yes"}
                        value={Cash_Management_Bank.paid_cash}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Use of payroll cards accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="payroll_accepted"
                        checked={
                          Cash_Management_Bank.payroll_accepted === "yes"
                        }
                        value={Cash_Management_Bank.payroll_accepted}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Taxes paid in cash accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="taxes_cash"
                        checked={Cash_Management_Bank.taxes_cash === "yes"}
                        value={Cash_Management_Bank.taxes_cash}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div> */}
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Taxes penalties paid in cash accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="penalty_cash"
                        checked={Cash_Management_Bank.penalty_cash === "yes"}
                        value={Cash_Management_Bank.penalty_cash}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Not current on tax payments with the state accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="tax_payment"
                        checked={Cash_Management_Bank.tax_payment === "yes"}
                        value={Cash_Management_Bank.tax_payment}
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group">
                  <div className="bnk_add_grp">
                    <p>Number of vendors paid monthly:</p>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Minimum:</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onInput={(e) => {
                          if (e.target.value.length > 2) {
                            e.target.value = e.target.value.slice(0, 2);
                          }
                        }}
                        name="number_vendors_min"
                        value={Cash_Management_Bank.number_vendors_min}
                        onChange={BankCashManagementhandler}
                        className="form-control"
                        defaultValue={10}
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errosBank?.number_vendors_min && (
                        <p style={{ color: "red" }}>
                          {errosBank.number_vendors_min}
                        </p>
                      )}
                    </div>
                    <div className="pg1-prt-2-lwr-inrfld bnk1a">
                      <label>Maximum:</label>
                      <NumberFormat
                        style={{ width: "20rem" }}
                        maxLength={19}
                        value={Cash_Management_Bank.number_vendors_max}
                        // format={"$##,###,###,###"}
                        // 78,979,797,979,797
                        thousandSeparator={","}
                        // decimalSeparator={"."}
                        // decimalScale={2}
                        className="form-control"
                        onChange={BankCashManagementhandler}
                        name="number_vendors_max"
                      />
                      <a href="javascript:void(0);" className="nd3-a">
                        <i className="fal fa-plus" />
                      </a>
                      {errosBank?.number_vendors_max && (
                        <p style={{ color: "red" }}>
                          {errosBank.number_vendors_max}
                        </p>
                      )}
                    </div>
                  </div>
                  <br />
                  {errosBank.number_vendorsErr && (
                    <p style={{ color: "red", marginTop: "10rem" }}>
                      {errosBank.number_vendorsErr}
                    </p>
                  )}
                </div>

                <div className="form-input-group">
                  <label>Accepted vendor payment methods:</label>
                  {errosBank.vendor_payment_methods && (
                    <span style={{ color: "red", marginTop: "10rem" }}>
                      {errosBank.vendor_payment_methods}
                    </span>
                  )}
                  <div className="frm_radio_prnt">
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="ACH"
                        checked={Cash_Management_Bank?.vendor_payment_methods?.includes(
                          "ACH"
                        )}
                        name="vendor_payment_methods"
                        onChange={BankCashManagementhandler}
                        id="ACH1"
                      />
                      {/* <span class="radio"></span>  */}
                      <label htmlFor="ACH1" className="radio-button">
                        {" "}
                        ACH
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Check"
                        checked={Cash_Management_Bank?.vendor_payment_methods?.includes(
                          "Check"
                        )}
                        name="vendor_payment_methods"
                        onChange={BankCashManagementhandler}
                        id="check1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="check1" className="radio-button">
                        {" "}
                        Check
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Wire"
                        checked={Cash_Management_Bank?.vendor_payment_methods?.includes(
                          "Wire"
                        )}
                        name="vendor_payment_methods"
                        onChange={BankCashManagementhandler}
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="wire1" className="radio-button">
                        {" "}
                        Wire
                      </label>
                    </div>
                    <div className="pg3-mn-inrfld">
                      <input
                        type="checkbox"
                        value="Cash"
                        checked={Cash_Management_Bank?.vendor_payment_methods?.includes(
                          "Cash"
                        )}
                        name="vendor_payment_methods"
                        onChange={BankCashManagementhandler}
                        id="cash1"
                      />
                      {/* <span class="radio"></span> */}
                      <label htmlFor="cash1" className="radio-button">
                        {" "}
                        Cash
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <h5 className="fromgroup_hdng">
                    Use of International vendors accepted:
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        checked={
                          Cash_Management_Bank.international_vendor === "yes"
                        }
                        name="international_vendor"
                        onChange={BankCashManagementhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {
            <div className="transferba bg_grey">
              <p className="hdng_p">Transfer from Existing Bank Account</p>
              <div className="parent_forminput_group">
                <div className="form-group w100">
                  <h5 className="fromgroup_hdng">
                    Non-verified source of deposit from original bank account
                    accpted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="prev_bank_verified"
                        checked={
                          Transfer_Existing_Bank_Bank.prev_bank_verified ===
                          "yes"
                        }
                        onChange={BankTransferExistingBankhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
                <div className="form-group w100">
                  <h5 className="fromgroup_hdng">
                    Previous bank not aware of cannabis-related business
                    accepted?
                  </h5>
                  <div className="switch_box">
                    <h4>no</h4>
                    <label className="switch2">
                      <input
                        type="checkbox"
                        name="prev_bank_aware"
                        checked={
                          Transfer_Existing_Bank_Bank.prev_bank_aware === "yes"
                        }
                        onChange={BankTransferExistingBankhandler}
                      />
                      <span className="slider round" />
                    </label>
                    <h4>yes</h4>
                  </div>
                </div>
              </div>
            </div>
          }
        </form>
        <div className="button-container">
          <button
            className="btn btn-next"
            type="submit"
            onClick={SubmitHandler}
          >
            Save
          </button>
        </div>
      </section>
    </div>
  );
};
export default MySettingsDetails;
