
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Get_BankViewed_Applications_URL } from '../../Api/Api';

const ViewedApplications = () => {
    const [data,setData]=useState()
useEffect(()=>{
   axios.get(Get_BankViewed_Applications_URL,{
    
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  }).then(res=>{
    console.log(res.data,'999')
    setData(res.data)
   }).catch(err=>{
    console.log(err,'error')
   })
},[])
  return (
    <div className='mainsection bnk_sctn'> 
    <div className='bank_content' style={{display:'flex'}}>
        <Card sx={{ maxWidth: 645 }}>
    <CardMedia
      sx={{ height: 340 }}
    //   image="https://purepng.com/public/uploads/large/purepng.com-dollar-signobjectsdollar-signmoney-cash-dollar-sign-object-currency-631522323964d8wuo.png"
      title="green iguana"
    />
    <CardContent>
      <Typography gutterBottom variant="h3" component="div">
        Total viewed applications
      </Typography>
      <Typography variant="h3" color="text.secondary">
        You have viewed {data?.numViewedApplications}  applications
      </Typography>
    </CardContent>
    {/* <CardActions>
      <Button size="small">Share</Button>
      <Button size="small">Learn More</Button>
    </CardActions> */}
  </Card>
  <Card sx={{ maxWidth: 645 ,marginLeft: 20 }}>
    {/* <CardMedia
      sx={{ height: 340 }}
      image="https://purepng.com/public/uploads/large/purepng.com-dollar-signobjectsdollar-signmoney-cash-dollar-sign-object-currency-631522323964d8wuo.png"
      title="green iguana"
    /> */}
    <CardContent>
      <Typography gutterBottom variant="h3" component="div">
  Viewed Applications
      </Typography>
      <Typography variant="h3" color="text.secondary">
       {
        data?.viewedApplications && data.viewedApplications?.map((i,index)=>{
            return <p>{i}</p>
        })
       }
      </Typography>
    </CardContent>
    
  </Card>
  </div>
  </div>
  )
}

export default ViewedApplications
